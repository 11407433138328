import { CompanyStatus } from '@generated/graphql';
import hexToRgba from '@utils/hexToRgba';
import { InvestmentType } from '@utils/investment';

import v2 from './v2';

const baseColors = {
  white: '#FFFFFF',
  black: '#2B3559',
  lightText: '#B4AEC9',
  background: '#F9F8FD',
  vLightGrey: '#F8F8FC',
  darkGray: '#7E788E',
  purple1: '#8778F7',
  purple2: '#A6C1FE',
  lightPurple: '#F8F7FF',
  green: '#70CB50',
  red: '#E95579',
  orange: '#F2994A',
  yellow: '#F2C94C',
};

const mainTheme = {
  v2,
  colors: {
    ...baseColors,
    text: {
      default: baseColors.black,
      gray: baseColors.darkGray,
      light: baseColors.lightText,
      white: baseColors.white,
    },
    button: {
      primary: {
        default: {
          color: baseColors.lightText,
          background: baseColors.vLightGrey,
        },
        hover: {
          color: baseColors.lightText,
          background: hexToRgba(baseColors.vLightGrey, 0.6),
        },
        active: {
          color: baseColors.lightText,
          background: hexToRgba(baseColors.vLightGrey, 0.8),
        },
      },
      secondary: {
        default: {
          color: baseColors.black,
          background: '#E9E9F3',
        },
        hover: {
          color: baseColors.black,
          background: hexToRgba('#E9E9F3', 0.6),
        },
        active: {
          color: baseColors.black,
          background: hexToRgba('#E9E9F3', 0.8),
        },
      },
      black: {
        default: {
          color: baseColors.white,
          background: baseColors.black,
        },
        hover: {
          color: baseColors.white,
          background: '#52477B',
        },
        active: {
          color: hexToRgba(baseColors.white, 0.5),
          background: baseColors.black,
        },
      },
      light: {
        default: {
          color: baseColors.lightText,
          background: baseColors.vLightGrey,
        },
        hover: {
          color: baseColors.lightText,
          background: hexToRgba(baseColors.vLightGrey, 0.6),
        },
        active: {
          color: baseColors.lightText,
          background: hexToRgba(baseColors.vLightGrey, 0.8),
        },
      },
      danger: {
        default: {
          color: baseColors.white,
          background: baseColors.red,
        },
        hover: {
          color: baseColors.white,
          background: hexToRgba(baseColors.red, 0.6),
        },
        active: {
          color: baseColors.white,
          background: hexToRgba(baseColors.red, 0.8),
        },
      },
      warning: {
        default: {
          color: baseColors.white,
          background: baseColors.yellow,
        },
        hover: {
          color: baseColors.white,
          background: hexToRgba(baseColors.yellow, 0.6),
        },
        active: {
          color: baseColors.white,
          background: hexToRgba(baseColors.yellow, 0.8),
        },
      },
      outlined: {
        default: {
          color: baseColors.darkGray,
          background: 'transparent',
        },
        hover: {
          color: baseColors.white,
          background: baseColors.darkGray,
        },
        active: {
          color: baseColors.white,
          background: baseColors.darkGray,
        },
      },
    },
    formElements: {
      field: {
        default: {
          text: baseColors.black,
          background: baseColors.white,
          border_focused: baseColors.purple2,
          border_hasValue: baseColors.lightText,
        },
        disabled: {
          text: baseColors.lightText,
          background: hexToRgba(baseColors.lightText, 0.2),
        },
        hasError: {
          text: baseColors.red,
          background: hexToRgba(baseColors.red, 0.2),
          border_focused: baseColors.red,
        },
        placeholder: baseColors.lightText,
      },
    },
    tab: {
      default: {
        background: hexToRgba(baseColors.lightText, 0.2),
        background_active: baseColors.black,
        text: baseColors.black,
        text_active: baseColors.white,
        background_disabled: hexToRgba(baseColors.lightText, 0.2),
        text_disabled: baseColors.lightText,
      },
      inverted: {
        background: baseColors.white,
        background_active: baseColors.black,
        text: baseColors.black,
        text_active: baseColors.white,
        background_disabled: baseColors.white,
        text_disabled: baseColors.lightText,
      },
    },
    alert: {
      default: {
        background: hexToRgba(baseColors.darkGray, 0.1),
        text: baseColors.lightText,
      },
      warning: {
        background: hexToRgba(baseColors.yellow, 0.1),
        text: baseColors.orange,
      },
      error: {
        background: hexToRgba(baseColors.red, 0.1),
        text: baseColors.red,
      },
    },
    eventCards: {
      outflows: baseColors.purple1,
      companyEvent: baseColors.darkGray,
      inflows: baseColors.purple2,
    },
    companyStatus: {
      [CompanyStatus.ACTIVE]: {
        background: baseColors.green,
      },
      [CompanyStatus.INACTIVE]: {
        background: baseColors.darkGray,
      },
      [CompanyStatus.STEALTH]: {
        background: baseColors.black,
      },
    },
    investmentType: {
      [InvestmentType.FUND]: {
        background: baseColors.orange,
        color: baseColors.orange,
      },
      [InvestmentType.CONVERTED]: {
        background: baseColors.green,
        color: baseColors.green,
      },
      [InvestmentType.UNCONVERTED]: {
        background: baseColors.yellow,
        color: baseColors.yellow,
      },
      [InvestmentType.EQUITY]: {
        background: '#6256FF',
        color: '#6256FF',
      },
      [InvestmentType.EQUITY_NO_SHARES]: {
        background: '#A056FF',
        color: '#A056FF',
      },
      [InvestmentType.SPV]: {
        background: baseColors.red,
        color: baseColors.red,
      },
      [InvestmentType.UNKNOWN]: {
        background: baseColors.black,
        color: baseColors.black,
      },
    },
    modal: {
      background: baseColors.white,
    },
  },
  gradient: {
    linear: 'linear-gradient(82.46deg, #8472F6 5.85%, #ABCDFF 94.15%);',
    key: 'linear-gradient(79.36deg, #8472F6 18.39%, #ABCDFF 102.7%)',
    light: 'linear-gradient(70.25deg, #9F8FFF 13.23%, #BBD6FF 87.97%);',
  },
  fonts: {
    h1: {
      fontFamily: 'Anthro, sans-serif',
      fontSize: '48px',
      lineHeight: '58px',
      fontWeight: '900',
    },
    h2: {
      fontFamily: 'Anthro, sans-serif',
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: '900',
    },
    h3: {
      fontFamily: 'Anthro, sans-serif',
      fontSize: '28px',
      lineHeight: '32px',
      fontWeight: '900',
    },
    t1: {
      regular: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '500',
      },
      bold: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '900',
      },
    },
    t2: {
      regular: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '400',
      },
      bold: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '700',
      },
    },
    t3: {
      regular: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '400',
      },
      bold: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '700',
      },
    },
    t4: {
      regular: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '12px',
        lineHeight: '15px',
        fontWeight: '400',
      },
      bold: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '12px',
        lineHeight: '15px',
        fontWeight: '600',
      },
    },
    t5: {
      regular: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '10px',
        lineHeight: '12px',
        fontWeight: '400',
      },
      bold: {
        fontFamily: 'Anthro, sans-serif',
        fontSize: '10px',
        lineHeight: '12px',
        fontWeight: '600',
      },
    },
  },
  zIndex: {
    companySearchInput: 6,
    modal: 9,
    header: 3,
    slidingTable: 2,
  },
  container: {
    maxWidth: '1920px',
    sidePadding: {
      default: '16px',
      xl: '62px',
    },
  },
};

export default mainTheme;
