import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const textareaTheme = defineStyleConfig({
  baseStyle: defineStyle({
    width: '100%',
    border: '1px solid',
    borderColor: 'grey.divider',
    borderRadius: '6px',
    padding: '7px 12px',
    fontSize: '14px',
    _focusVisible: {
      border: '1px solid',
      borderColor: 'purple.primary',
      boxShadow: '0px 0px 0px 3px #8778F71A',
      boxShadowColor: 'purple.primary',
      outline: 'none',
    },
  }),
});
