import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';

interface Config {
  case?: 'startCase' | 'capitalize' | 'lowerCase' | 'upperCase';
}

const underscorePattern = new RegExp('_', 'g');

const convertUnderscore = (
  value: string,
  config: Config = {
    case: 'startCase',
  },
) => {
  const resultValue = value.replace(underscorePattern, ' ').toLowerCase();

  switch (config.case) {
    case 'startCase':
      return startCase(resultValue);
    case 'capitalize':
      return capitalize(resultValue);
    case 'upperCase':
      return resultValue.toUpperCase();
    default:
      return resultValue;
  }
};

export default convertUnderscore;
