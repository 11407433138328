import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { useFeatureFlagsContext } from './feature_flag_provider';

interface DevMode {
  devMode: boolean;
  toggleDevMode: (devMode?: boolean) => void;
}
const defaultValue = import.meta.env.VITE_DEV_MODE_ON ? true : false;

export const DevModeContext = createContext<DevMode>({ devMode: false, toggleDevMode: () => {} });

export const DevModeProvider = ({ children }: { children: ReactNode }) => {
  const { isEnabled } = useFeatureFlagsContext();

  const [devMode, setDevMode] = useState<boolean>(defaultValue);

  const toggleDevMode = useCallback(
    (devMode?: boolean) => {
      setDevMode((prevDevMode) => (devMode === undefined ? !prevDevMode : devMode));
    },
    [setDevMode],
  );

  const devModeOnlyForUsersWithDevModeFlag = useMemo(() => isEnabled('DEV_MODE') && devMode, [devMode, isEnabled]);
  const value = useMemo(
    () => ({ devMode: devModeOnlyForUsersWithDevModeFlag, toggleDevMode }),
    [devModeOnlyForUsersWithDevModeFlag, toggleDevMode],
  );

  return <DevModeContext.Provider value={value}>{children}</DevModeContext.Provider>;
};

// ts-prune-ignore-next
export const useDevModeContext = () => {
  const context = useContext(DevModeContext);

  if (!context) {
    throw new Error('useDevModeContext must be used within the <DevModeProvider>');
  }

  return context.devMode;
};
