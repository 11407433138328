import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useTrackPosthogPageviewInSPA() {
  const location = useLocation();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);
}
export { useTrackPosthogPageviewInSPA };
