import { ReactNode, HTMLAttributes } from 'react';
import classNames from 'classnames';

import { AlertType, StyledAlert } from './styled';

interface Props extends HTMLAttributes<HTMLDivElement> {
  type?: AlertType;
  maxWidth?: number;
  children: ReactNode;
}

const Alert = ({ className, ...props }: Props) => {
  return <StyledAlert {...props} className={classNames('alert', className)} />;
};

export default Alert;
