enum ViewModeWidth {
  // 1588 and above: fullHD
  XXL = 1588, // and lower: widescreen
  XL = 1280, // and lower: desktop
  LG = 1024, // and lower: tablet
  MD = 768, // and lower: mobile
  SM = 576, // and lower: portrait mobile
}

export const ViewModeQueries = {
  XXL: `@media screen and (min-width: ${ViewModeWidth.XXL}px)`,
  XL: `@media screen and (min-width: ${ViewModeWidth.XL}px)`,
  LG: `@media screen and (min-width: ${ViewModeWidth.LG}px)`,
  MD: `@media screen and (min-width: ${ViewModeWidth.MD}px)`,
  SM: `@media screen and (min-width: ${ViewModeWidth.SM}px)`,
};
