import styled from 'styled-components';

import { BorderRadius, Color } from '@styled/themes/v2';

import { Container } from './Container';

export const Callout = styled(Container)<{
  backgroundColour?: string;
  borderRadius?: string;
  border?: string;
}>`
  border-radius: ${({ borderRadius }) => borderRadius ?? BorderRadius.MEDIUM}px;
  background-color: ${({ backgroundColour }) => backgroundColour ?? Color.GREY_BACKGROUND};
  border: ${({ border }) => border ?? ''};
`;

export const FancyCallout = styled(Callout)`
  @property --a {
    syntax: '<angle>';
    inherits: false;
    initial-value: 90deg;
  }

  position: relative;
  background: ${Color.GREY_CALLOUT_BACKGROUND};
  background-clip: padding-box;
  border: 1px solid transparent;

  ::after {
    @keyframes azava_spin_callout {
      0% {
        --a: 90deg;
      }
      20% {
        --a: 170deg;
      }
      80% {
        --a: 190deg;
      }
      100% {
        --a: 240deg;
      }
    }
    border-radius: ${BorderRadius.MEDIUM}px;

    inset: -1px;

    background: linear-gradient(var(--a), #8778f7 0%, #6bd6f8 48.96%, #d15df9 100%);
    content: '';
    z-index: -1;
    position: absolute;
    animation: 10s azava_spin_callout ease infinite;
  }
`;
