import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { Color } from '@styled/themes/v2';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  item: {
    // this will style the MenuItem and MenuItemOption components
    _hover: {
      bg: Color.GREY_BACKGROUND,
    },
    _focus: {
      bg: Color.GREY_BACKGROUND,
    },
  },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
