import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ChakraBaseProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { HelmetProvider } from 'react-helmet-async';

import { ApolloProvider } from '@services/apollo_provider';
import mainTheme from '@styled/themes/mainTheme';
import chakraTheme from '@styled/themes/chakra';
import GlobalStyle from '@styled/globalStyle';
import { AuthProvider } from '@services/authentication_provider';
import { TRPCProvider } from '@services/trpc';
import '@services/posthog';
import '@services/time_ago';
import '@services/sentry';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { DeviceMotionProvider } from './context/DeviceMotion';

const container = document.getElementById('root');
const root = createRoot(container!);

if (import.meta.env.VITE_ENV === 'production') {
  LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_ENTERPRISE_KEY);
}

const MaybeGoogleOAuthProvider = import.meta.env.VITE_GOOGLE_CLIENT_ID
  ? GoogleOAuthProvider
  : ({ children }: { children: React.ReactNode }) => <>{children}</>;

root.render(
  <StrictMode>
    <ChakraBaseProvider theme={chakraTheme}>
      <ThemeProvider theme={mainTheme}>
        <GlobalStyle />
        <DeviceMotionProvider>
          <Router>
            <MaybeGoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
              <AuthProvider>
                <ApolloProvider>
                  <TRPCProvider>
                    <HelmetProvider>
                      <App />
                    </HelmetProvider>
                  </TRPCProvider>
                </ApolloProvider>
              </AuthProvider>
            </MaybeGoogleOAuthProvider>
          </Router>
        </DeviceMotionProvider>
      </ThemeProvider>
    </ChakraBaseProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
