import { AnchorHTMLAttributes, FC } from 'react';
import classNames from 'classnames';

import { ButtonSize, StyledButton } from './styled';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  size?: ButtonSize;
}

const Link: FC<Props> = ({ children, className, ...props }) => {
  return (
    <StyledButton {...props} className={classNames('link-button', className)} as="a">
      {children}
    </StyledButton>
  );
};

export default Link;
