import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Button,
  ModalBody,
  Flex,
  Text,
  VStack,
  Heading,
  Card,
  HStack,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link as RRLink } from 'react-router-dom';

import { useUserContext } from '@services/user_provider';
import { Icon } from '@utils/icons';
import Spacer from '@components/util/Spacer';
import { InvestorDescriptorsForm } from '@pages/Profile/InvestorDescriptorsForm';
import { usePublicProfileFromSlugQuery, useUpdateUserSettingsMutation } from '@generated/graphql';
import { isGqlSubtype } from '@utils/isGqlSubtype';
import { ProfileCompactCard } from '@components/ProfileCompactCard';
import { useGlobalModalsContext } from 'src/context/GlobalModalsContext';
import { NemoLoading } from '@components/Loading';

function FillOutProfileModal({ type }: { type: string }) {
  const { closeGlobalModal } = useGlobalModalsContext();

  const { userSettings, publicProfile: publicProfileFromUserSettings, id: userId } = useUserContext();
  const slug = publicProfileFromUserSettings?.slug;
  const { data } = usePublicProfileFromSlugQuery({ variables: { slug } });
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const initialFocusRef = useRef(null);

  const publicProfile = useMemo(() => {
    if (isGqlSubtype(data?.publicProfileBySlug, 'Profile')) {
      return data.publicProfileBySlug;
    }
    return null;
  }, [data]);

  const [modalStep, setModalStep] = useState<'suggest' | 'investor_descriptors' | 'finish'>('suggest');

  const closeAndSetPromptSettingToFalse = useCallback(async () => {
    await updateUserSettings({
      variables: {
        userId,
        args: {
          promptProfileSetup: false,
        },
      },
    });
    closeGlobalModal();
  }, [updateUserSettings, closeGlobalModal]);

  if (!userSettings.promptProfileSetup || publicProfile === null || slug === null || slug === undefined) {
    return null;
  }
  return (
    <Modal isOpen={true} onClose={closeGlobalModal} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent maxWidth="unset" width="fit-content">
        {modalStep === 'suggest' ? (
          <>
            <ModalBody p="20px" minW={{ base: 'auto', md: '600px' }} maxW={{ base: '600px' }}>
              <VStack gap="0px">
                {type === 'from_deal_ingestion' ? (
                  <>
                    <Spacer size={16} />
                    <HStack>
                      <NemoLoading radius={6} thickness={2} />
                      <Text color="purple.primary" fontSize="12px">
                        Whilst Nemo processes your deal...
                      </Text>
                    </HStack>
                    <Spacer size={32} />
                  </>
                ) : type === 'from_request_info' ? (
                  <>
                    <Spacer size={16} />
                    <HStack gap="4px">
                      <Text color="purple.primary" fontSize="20px" fontWeight="semibold">
                        Request Sent
                      </Text>
                      <Icon name="check" color="purple.primary" size="6" />
                    </HStack>
                    <Spacer size={32} />
                  </>
                ) : null}
                <Heading fontSize="24px" fontWeight="semibold">
                  Complete your Investor Profile
                </Heading>
                {type === 'from_deal_ingestion' ? (
                  <>
                    <Spacer size={8} />
                    <Text fontSize="14px" textAlign="center">
                      When you share this deal, let people 'get' who you are.
                    </Text>
                  </>
                ) : type === 'from_request_info' ? (
                  <>
                    <Spacer size={8} />
                    <Text fontSize="14px" textAlign="center">
                      Help the investor you're connecting with 'get' who you are.
                    </Text>
                  </>
                ) : null}
                <Spacer size={20} />

                <Card>
                  <ProfileCompactCard slug={slug} />
                </Card>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Flex justify="space-between" w="100%">
                <HStack gap="0">
                  <Button mr={3} onClick={closeGlobalModal}>
                    Not right now
                  </Button>
                  <Button
                    variant="text"
                    mr={3}
                    onClick={closeAndSetPromptSettingToFalse}
                    color="grey.text_light"
                    fontSize="12px"
                  >
                    Don't ask again
                  </Button>
                </HStack>
                <Button
                  colorScheme="purple"
                  onClick={() => {
                    setModalStep('investor_descriptors');
                  }}
                  ref={initialFocusRef}
                >
                  <Text>Investor Tagline</Text>
                  <Icon name="chevronRight" mr="-6px" />
                </Button>
              </Flex>
            </ModalFooter>
          </>
        ) : modalStep === 'investor_descriptors' ? (
          <>
            <ModalBody p="20px" minW={{ base: 'auto', md: '600px' }} maxW={{ base: '600px', lg: '1000px' }}>
              <Heading fontSize="20px" fontWeight="semibold">
                Investor Tagline
              </Heading>
              <Spacer size={20} />
              <InvestorDescriptorsForm publicProfile={publicProfile} />
            </ModalBody>
            <ModalFooter>
              <Flex justify="space-between" w="100%">
                <HStack gap="0">
                  <Button mr={3} onClick={closeGlobalModal}>
                    Not right now
                  </Button>
                  <Button
                    variant="text"
                    mr={3}
                    onClick={closeAndSetPromptSettingToFalse}
                    color="grey.text_light"
                    fontSize="12px"
                  >
                    Don't ask again
                  </Button>
                </HStack>
                <Button
                  colorScheme="purple"
                  onClick={() => {
                    setModalStep('finish');
                  }}
                >
                  <Text>Next</Text>
                  <Icon name="chevronRight" mr="-6px" />
                </Button>
              </Flex>
            </ModalFooter>
          </>
        ) : modalStep === 'finish' ? (
          <>
            <ModalBody pt="20px" pb="4px">
              <Heading fontSize="20px" fontWeight="semibold">
                Nice work!
              </Heading>
              <Spacer size={12} />
              <Text>Here's what your profile looks like now</Text>
              <Spacer size={8} />

              <Card>
                <ProfileCompactCard slug={slug} />
              </Card>

              <Spacer size={40} />

              <Flex justifyContent="flex-end">
                <Heading fontSize="14px" color="grey.text_medium">
                  Want to add more details?
                </Heading>
              </Flex>
            </ModalBody>
            <ModalFooter pt="1px">
              <Flex justify="space-between" w="100%" gap="20px">
                <Button onClick={closeAndSetPromptSettingToFalse}>
                  <Text>Exit</Text>
                </Button>
                <Button
                  as={RRLink}
                  colorScheme="purple"
                  to={`/${slug}?addInvestment`}
                  target="_blank"
                  onClick={closeAndSetPromptSettingToFalse}
                >
                  Add an Investment Highlight
                  <Icon name="northEast" mr="-4px" />
                </Button>
              </Flex>
            </ModalFooter>
          </>
        ) : null}
      </ModalContent>
    </Modal>
  );
}

export { FillOutProfileModal };
