import { css } from 'styled-components';

const arrowTailMixin = (position: 'left' | 'right' | 'top' | 'bottom' = 'bottom') => css`
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background: inherit;
  margin: auto;

  ${position === 'top' &&
  css`
    top: -4px;
    left: 0;
    right: 0;
    transform: rotate(45deg);
  `};

  ${position === 'bottom' &&
  css`
    bottom: -4px;
    left: 0;
    right: 0;
    transform: rotate(45deg);
  `};

  ${position === 'left' &&
  css`
    left: -4px;
    top: 0;
    bottom: 0;
    transform: rotate(45deg);
  `};

  ${position === 'right' &&
  css`
    right: -4px;
    top: 0;
    bottom: 0;
    transform: rotate(45deg);
  `};
`;

const dividerMixin = (isVertical?: boolean) => css`
  opacity: 0.5;

  ${isVertical
    ? css`
        width: 2px;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          ${(props) => props.theme.colors.text.light} 2px,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-repeat: repeat-y;
        background-size: 2px 10px;
      `
    : css`
        height: 2px;
        width: 100%;
        background-image: linear-gradient(
          to right,
          ${(props) => props.theme.colors.text.light} 2px,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: top;
        background-repeat: repeat-x;
        background-size: 10px 2px;
      `}
`;

const hiddenScrollbarMixin = css`
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari and Opera
  }
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`;

export { arrowTailMixin, dividerMixin, hiddenScrollbarMixin };
