import { ComponentStyleConfig } from '@chakra-ui/react';

const dividerTheme: ComponentStyleConfig = {
  baseStyle: {
    border: 'none',
    height: '1px',
    background: 'grey.divider',
    color: 'white',
    width: '100%',
    margin: '0px',
  },
  sizes: {},
  variants: {
    fancy: {
      background: 'linear-gradient(92.63deg, #8778f7 0%, #6bd6f8 48.96%, #d15df9 100%)',
      height: '2px',
    },
  },
  defaultProps: {},
};

export { dividerTheme };
