import { extendBaseTheme, theme as chakraTheme } from '@chakra-ui/react';

import { buttonTheme } from './chakra/components/button';
import { dividerTheme } from './chakra/components/divider';
import { inputTheme } from './chakra/components/input';
import { menuTheme } from './chakra/components/menu';
import { radioTheme } from './chakra/components/radio';
import { selectTheme } from './chakra/components/select';
import { tabsTheme } from './chakra/components/tabs';
import { tagTheme } from './chakra/components/tag';
import { textTheme } from './chakra/components/text';
import { switchTheme } from './chakra/components/switch';
import { skeletonTheme } from './chakra/components/skeleton';
import { statTheme } from './chakra/components/stat';
import { cardTheme } from './chakra/components/card';
import { textareaTheme } from './chakra/components/textarea';
import { popoverTheme } from './chakra/components/popover';
import { checkboxTheme } from './chakra/components/checkbox';
import { avatarTheme } from './chakra/components/avatar';
import { spinnerTheme } from './chakra/components/spinner';

const { Accordion, Alert, Modal, Link, Table, NumberInput, Tooltip } = chakraTheme.components;

export default extendBaseTheme({
  components: {
    Alert,
    Accordion,
    Button: buttonTheme,
    Divider: dividerTheme,
    Input: inputTheme,
    Link,
    Menu: menuTheme,
    Modal,
    NumberInput,
    Radio: radioTheme,
    Select: selectTheme,
    Tabs: tabsTheme,
    Table,
    Tag: tagTheme,
    Tooltip,
    Text: textTheme,
    Skeleton: skeletonTheme,
    Switch: switchTheme,
    Stat: statTheme,
    Card: cardTheme,
    Popover: popoverTheme,
    Textarea: textareaTheme,
    Checkbox: checkboxTheme,
    Avatar: avatarTheme,
    Spinner: spinnerTheme,
  },
  colors: {
    purple: {
      // generated from #8778F7 using https://www.tailwindshades.com,
      // & hue shifted 600-900 to be more purple
      DEFAULT: '#8778F7',
      50: '#E7E4FD',
      100: '#DCD8FD',
      200: '#C7C0FB',
      300: '#B2A8FA',
      400: '#9C90F8',
      500: '#8778F7',
      600: '#775BF5',
      700: '#6B3EF4',
      800: '#6321F2',
      900: '#600EE7',
      950: '#600DD8',
      primary: '#8778F7',
      primaryHover: '#8778F740',
      primaryActive: '#8778F766',
      primaryHover2: '#8778F70D',
      accent: '#7260FA',
      mid: '#8778F7BF',
      light: '#9AB3F4',
      veryLight: '#8778F71A',
    },
    red: {
      // generated from #E85D5D using https://www.tailwindshades.com
      // & step up % 50-400 to be more red
      DEFAULT: '#E85D5D',
      50: '#FCEAEA',
      100: '#FADADA',
      200: '#F5BBBB',
      300: '#F19C9C',
      400: '#EC7C7C',
      500: '#E85D5D',
      600: '#E12C2C',
      700: '#BA1A1A',
      800: '#891313',
      900: '#580D0D',
      950: '#400909',
      primary: '#E85D5D',
      accent: '#DF2424',
    },
    grey: {
      DEFAULT: '#999999',
      50: '#F6F6F7',
      100: '#EAEAEA',
      200: '#DADADA',
      300: '#C0C0C0',
      400: '#A8A8A8',
      500: '#999999',
      600: '#7E7E7E',
      700: '#666666',
      800: '#4C4C4C',
      900: '#333333',
      950: '#1A1A1A',
      divider: '#F0F0F0',
      background: '#F6F6F7',
      text_light: '#B7B7B7',
      text_dark: '#616161',
      text_medium: '#999',
      border: '#CBD5E0', // gray.300
    },
    gray: {
      DEFAULT: '#999999',
      50: '#F6F6F7',
      100: '#EAEAEA',
      200: '#DADADA',
      300: '#C0C0C0',
      400: '#A8A8A8',
      500: '#999999',
      600: '#7E7E7E',
      700: '#666666',
      800: '#4C4C4C',
      900: '#333333',
      950: '#1A1A1A',
    },
  },
  fonts: {
    default: 'Anthro, sans-serif',
  },
  fontSizes: {
    default: '14px',
  },
});
