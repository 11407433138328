import { parse } from 'qs';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import { CurrencyIsoCode } from '@generated/graphql';
import { defaultCurrency } from '@utils/currency';

export function parseQueryParams() {
  const queryString = parse(window.location.search.slice(1));
  const asOfDate = (queryString.date ?? new Date().toISOString().substring(0, 10)) as string;
  const cohortYear: string | undefined = queryString.cohortYear as string | undefined;
  const userCurrency = queryString.currency?.toString().toUpperCase() ?? defaultCurrency;

  return {
    asOfDate,
    cohortYear,
    userCurrency: userCurrency as CurrencyIsoCode,
  };
}

export function useInitialSearchParam(key: string, { removeParam }: { removeParam?: boolean } = {}) {
  const [params, setParams] = useSearchParams();
  const [value, recalculate] = useReducer(
    (redirectPath: string | null, { params, key }: { params: URLSearchParams; key: string }) =>
      redirectPath ?? params.get(key),
    params.get(key),
  );

  useEffect(() => {
    recalculate({ params, key });
  }, [params, key, recalculate]);

  useEffect(() => {
    if (removeParam && value && params.has(key)) {
      params.delete(key);
      setParams(new URLSearchParams(params));
    }
  }, [params, setParams, key, value, removeParam]);

  return value;
}
