import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@styled/themes/v2';

type AvatarProps = Omit<StyledAvatarProps, 'size' | '$initials' | '$hideImage'> & {
  src?: string;
  initials: string;
  size?: number;
  alt?: string;
};

type StyledAvatarProps = {
  size: number;
  isCircle?: boolean;
  borderRadius?: number;
  border?: boolean;
  backgroundColor?: string;
  color?: string;
  $initials: string;
  $hideImage: boolean;
};

const StyledAvatar = styled.div<StyledAvatarProps>`
  position: relative;
  overflow: hidden;

  border-radius: ${({ isCircle, borderRadius }) => (isCircle ? '50%' : `${borderRadius}px`)};
  ${({ border }) => border && `border: 1px ${Color.GREY_DIVIDER} solid;`}

  ${({ size }) => css`
    width: ${size}px;
    min-width: ${size}px;
    height: ${size}px;
  `};

  img {
    ${({ $hideImage }) => ($hideImage ? 'display: none;' : '')}
    width: 100%;
    height: 100%;
  }

  // https://launchscout.com/blog/css-only-avatar-fallback
  ::after {
    content: ${({ $initials }) => `"${$initials}"`};
    position: absolute;
    text-align: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.lightText};
    color: ${({ color, theme }) => color ?? theme.colors.white};
    text-transform: uppercase;
    font-size: ${({ size }) => size / 2}px;
    font-weight: bold;
    line-height: ${({ size }) => size}px;
    ${({ $hideImage }) => ($hideImage ? '' : 'display: none;')}
  }
`;

const Avatar = ({ initials, src, alt, size = 40, borderRadius = 10, ...styledProps }: AvatarProps) => {
  const [hideImage, setHideImage] = useState(() => !src);

  useEffect(() => {
    setHideImage(!src);
  }, [src]);

  return (
    <StyledAvatar $initials={initials} $hideImage={hideImage} size={size} borderRadius={borderRadius} {...styledProps}>
      {src ? (
        <img
          src={src || undefined}
          alt={alt || initials}
          onLoad={() => setHideImage(false)}
          onError={() => setHideImage(true)}
        />
      ) : null}
    </StyledAvatar>
  );
};

export { Avatar };
