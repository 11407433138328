import { ConvertibleType } from '@generated/graphql';

export const convertibleNames = {
  [ConvertibleType.CONVERTIBLE_NOTE]: 'Convertible note',
  [ConvertibleType.ASA]: 'ASA',
  [ConvertibleType.LOAN]: 'Loan',
  [ConvertibleType.PRE_MONEY_SAFE]: 'Pre-money SAFE',
  [ConvertibleType.POST_MONEY_SAFE]: 'Post-money SAFE',
  [ConvertibleType.SAFT]: 'SAFT',
  [ConvertibleType.BSA_AIR]: 'BSA-AIR',
  [ConvertibleType.SEEDFAST]: 'SeedFAST',
  [ConvertibleType.SEEDNOTE]: 'SeedNOTE',
};
