import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  EmailAddress: { input: string; output: string };
  JSON: { input: any; output: any };
  URL: { input: string; output: string };
};

export type AccessKey = {
  __typename?: 'AccessKey';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  issuer: User;
  updatedAt: Scalars['DateTime']['output'];
  viewer: User;
};

export enum ActionType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  MANAGE = 'MANAGE',
  READ = 'READ',
  UPDATE = 'UPDATE',
}

export type AddEmailAddressInput = {
  email: Scalars['EmailAddress']['input'];
  userId: Scalars['ID']['input'];
};

export type AddInvestorToRoundInput = {
  eventId: Scalars['ID']['input'];
  investorId: Scalars['ID']['input'];
};

export type AddPhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type AddProfileInvestmentInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  fullname?: InputMaybe<Scalars['String']['input']>;
  investmentProfileId: Scalars['ID']['input'];
  investorProfileId?: InputMaybe<Scalars['ID']['input']>;
  legalEntityType?: InputMaybe<LegalEntityType>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['URL']['input']>;
  personalWebsite?: InputMaybe<Scalars['URL']['input']>;
  roundType?: InputMaybe<EquityRoundType>;
};

export type AddPublicInvestmentInput = {
  companyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  investorProfileId: Scalars['ID']['input'];
  roundType?: InputMaybe<EquityRoundType>;
};

export type AddSocialLinkInput = {
  profileId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['URL']['input'];
};

export type AskDocumentInput = {
  chunkSize?: InputMaybe<Scalars['Int']['input']>;
  chunkStrategy: ChunkStrategy;
  id: Scalars['ID']['input'];
  k?: InputMaybe<Scalars['Int']['input']>;
  openAiModel: Scalars['String']['input'];
  questions: Array<Scalars['String']['input']>;
};

export type AskSubmissionInput = {
  id: Scalars['ID']['input'];
  query: Scalars['String']['input'];
};

export type Asset = BaseAsset | ConvertibleAsset;

export type AssetMutations = {
  __typename?: 'AssetMutations';
  mergeDuplicate?: Maybe<Asset>;
  updateName?: Maybe<Asset>;
};

export type AssetMutationsMergeDuplicateArgs = {
  input: MergeDuplicateAssetInput;
};

export type AssetMutationsUpdateNameArgs = {
  input?: InputMaybe<UpdateAssetNameInput>;
};

export enum AssetType {
  CONVERTIBLE = 'CONVERTIBLE',
  CURRENCY = 'CURRENCY',
  EMPLOYEE_STOCK_OPTIONS = 'EMPLOYEE_STOCK_OPTIONS',
  EQUITY = 'EQUITY',
  EQUITY_UNKNOWN_SHARES = 'EQUITY_UNKNOWN_SHARES',
  LP_INTEREST_POINT = 'LP_INTEREST_POINT',
  SPV_INTEREST_POINT = 'SPV_INTEREST_POINT',
  UNKNOWN = 'UNKNOWN',
}

export type BaseAsset = {
  __typename?: 'BaseAsset';
  id: Scalars['ID']['output'];
  latestPrice?: Maybe<Price>;
  name: Scalars['String']['output'];
  type: AssetType;
};

export type BaseAssetLatestPriceArgs = {
  asOfDate: Scalars['Date']['input'];
};

export type ChangeLog = {
  __typename?: 'ChangeLog';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  legalEntity: LegalEntity;
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ChartData = {
  __typename?: 'ChartData';
  datasets: Array<Dataset>;
  labels: Array<Scalars['String']['output']>;
};

export enum ChunkStrategy {
  CHARACTERS = 'CHARACTERS',
  DOCUMENT = 'DOCUMENT',
  PAGE = 'PAGE',
}

export type CoinvestorInput = {
  legalEntityId: Scalars['ID']['input'];
};

export type CompanyDistribution = {
  __typename?: 'CompanyDistribution';
  companyName: Scalars['String']['output'];
  currency: CurrencyIsoCode;
  date: Scalars['Date']['output'];
  numAssets: Scalars['Float']['output'];
};

export type CompanyInner = {
  __typename?: 'CompanyInner';
  countryFlag?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type CompanyProfile = {
  __typename?: 'CompanyProfile';
  aliasTargetId?: Maybe<Scalars['ID']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  founders?: Maybe<Array<FounderProfile>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  otherNames: Array<Scalars['String']['output']>;
  rounds?: Maybe<Array<PublicRound>>;
  slug?: Maybe<Scalars['String']['output']>;
  status: CompanyStatus;
  submissions?: Maybe<Array<NemoSubmission>>;
  teamId?: Maybe<Scalars['ID']['output']>;
  themes: Array<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  yearFounded?: Maybe<Scalars['Int']['output']>;
};

export type CompanyProfileMutations = {
  __typename?: 'CompanyProfileMutations';
  create: CompanyProfile;
};

export type CompanyProfileMutationsCreateArgs = {
  input: CreateCompanyProfileInput;
};

export type CompanyProfileSuggestFields = {
  __typename?: 'CompanyProfileSuggestFields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  themes: Array<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  STEALTH = 'STEALTH',
}

export type ConvertConvertibleInput = {
  conversionDate: Scalars['Date']['input'];
  conversionPrice: Scalars['Float']['input'];
  firstSharesInput: SharesInput;
  interest?: InputMaybe<Scalars['Float']['input']>;
  secondSharesInput?: InputMaybe<SharesInput>;
  transactionId: Scalars['ID']['input'];
};

export type ConvertibleAsset = {
  __typename?: 'ConvertibleAsset';
  annualisedInterestRate?: Maybe<Scalars['Float']['output']>;
  assetId: Scalars['ID']['output'];
  conversionPrice?: Maybe<Scalars['Float']['output']>;
  convertibleType: ConvertibleType;
  currency: CurrencyIsoCode;
  discountRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  interest?: Maybe<Scalars['Float']['output']>;
  interestDayConvention?: Maybe<Scalars['Float']['output']>;
  latestPrice?: Maybe<Price>;
  maturityDate?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
  valuationCap?: Maybe<Scalars['Float']['output']>;
  valuationCapInPinnedUserCurrency?: Maybe<Scalars['Float']['output']>;
};

export type ConvertibleAssetLatestPriceArgs = {
  asOfDate: Scalars['Date']['input'];
};

export type ConvertibleAssetValuationCapInPinnedUserCurrencyArgs = {
  asOfDate: Scalars['Date']['input'];
  userCurrency: CurrencyIsoCode;
};

export type ConvertibleInput = {
  assetLegalName: Scalars['String']['input'];
  convertibleType: ConvertibleType;
  currency: CurrencyIsoCode;
  dayConvention?: InputMaybe<Scalars['Int']['input']>;
  discountRate?: InputMaybe<Scalars['Float']['input']>;
  interestRate?: InputMaybe<Scalars['Float']['input']>;
  investmentDate: Scalars['Date']['input'];
  investorLegalEntityId: Scalars['ID']['input'];
  issuerLegalEntityId: Scalars['ID']['input'];
  maturityDate?: InputMaybe<Scalars['Date']['input']>;
  totalPaidInCurrency: Scalars['Float']['input'];
  valuationCap?: InputMaybe<Scalars['Float']['input']>;
};

export enum ConvertibleType {
  ASA = 'ASA',
  BSA_AIR = 'BSA_AIR',
  CONVERTIBLE_NOTE = 'CONVERTIBLE_NOTE',
  LOAN = 'LOAN',
  POST_MONEY_SAFE = 'POST_MONEY_SAFE',
  PRE_MONEY_SAFE = 'PRE_MONEY_SAFE',
  SAFT = 'SAFT',
  SEEDFAST = 'SEEDFAST',
  SEEDNOTE = 'SEEDNOTE',
}

export type CreateCompanyProfileInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: CompanyStatus;
  themes: Array<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFounderIntroRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  nemoSubmissionId: Scalars['ID']['input'];
  profile?: InputMaybe<CreateProfileInput>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFounderProfileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullname: Scalars['String']['input'];
  isFounder: Scalars['Boolean']['input'];
  linkedin?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFromUrlPublicInvestmentInput = {
  investorId: Scalars['ID']['input'];
  url: Scalars['URL']['input'];
};

export type CreateInviteProposalInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fund?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalProfileId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLegalEntityInput = {
  entityName?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  type?: InputMaybe<LegalEntityType>;
};

export type CreateMarkdownInput = {
  date: Scalars['Date']['input'];
  investorId: Scalars['ID']['input'];
  percentage: Scalars['Float']['input'];
  portfolioCompanyId: Scalars['ID']['input'];
};

export type CreateNoteInput = {
  createdAt: Scalars['DateTime']['input'];
  message: Scalars['String']['input'];
  noteType: NoteType;
  referenceId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePermissionInput = {
  action: ActionType;
  scope?: ScopeType;
  teamId: Scalars['ID']['input'];
};

export type CreatePortfolioViewInput = {
  columnState: Scalars['JSON']['input'];
  data: Scalars['JSON']['input'];
  filterState: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProfileInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fullname: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['URL']['input']>;
  linkedin?: InputMaybe<Scalars['URL']['input']>;
  personalWebsite?: InputMaybe<Scalars['URL']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['URL']['input']>;
  type: LegalEntityType;
};

export type CreateRoundInput = {
  assetType: AssetType;
  coinvestors?: InputMaybe<Array<CoinvestorInput>>;
  date: Scalars['Date']['input'];
  investorId?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  portfolioCompanyId: Scalars['ID']['input'];
  price?: InputMaybe<MoneyInput>;
  raisedAmount?: InputMaybe<MoneyInput>;
  roundType: EquityRoundType;
  urlPressRelease?: InputMaybe<Scalars['String']['input']>;
  valuation?: InputMaybe<MoneyInput>;
  valuationType: ValuationType;
};

export type CreateRoundPriceInput = {
  date: Scalars['Date']['input'];
  price: MoneyInput;
  roundId: Scalars['ID']['input'];
};

export type CreateUserFromEmailOnlyInput = {
  email: Scalars['EmailAddress']['input'];
  isIssuingDataAccessKey?: InputMaybe<Scalars['Boolean']['input']>;
  proposer?: InputMaybe<CreateUserProposerInput>;
  team?: InputMaybe<CreateUserTeamInput>;
};

export type CreateUserInTeamInput = {
  email: Scalars['EmailAddress']['input'];
  teamId: Scalars['ID']['input'];
};

export type CreateUserInput = {
  email: Scalars['EmailAddress']['input'];
  portfolioName?: InputMaybe<Scalars['String']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserProposerInput = {
  id: Scalars['ID']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserTeamInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export enum CurrencyIsoCode {
  CHF = 'CHF',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  NOK = 'NOK',
  SEK = 'SEK',
  USD = 'USD',
}

export type Dataset = {
  __typename?: 'Dataset';
  data: Array<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
};

export type DealActivityNotification = {
  __typename?: 'DealActivityNotification';
  change: DealActivityNotificationChange;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: DealActivityNotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type DealActivityNotificationChange = NemoSubmissionChange | NemoSubmissionNote;

export enum DealActivityNotificationType {
  DEAL_ACTIVITY = 'DEAL_ACTIVITY',
}

export type DealDocument = {
  __typename?: 'DealDocument';
  document: Document;
  id: Scalars['ID']['output'];
};

export type DealDocumentMutations = {
  __typename?: 'DealDocumentMutations';
  delete: DealDocument;
};

export type DealDocumentMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type DealReceivedNotification = {
  __typename?: 'DealReceivedNotification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nemoSubmission?: Maybe<NemoSubmission>;
  sender?: Maybe<User>;
  type: DealReceivedNotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum DealReceivedNotificationType {
  DEAL_RECEIVED = 'DEAL_RECEIVED',
}

export type DealShareRequest = {
  __typename?: 'DealShareRequest';
  accepted: Scalars['Boolean']['output'];
  channel: DealflowChannel;
  dealOwner: User;
  dealOwnerId: Scalars['ID']['output'];
  dealRequester: User;
  dealRequesterId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  whatsAppIntroUrl?: Maybe<Scalars['String']['output']>;
};

export type DealShareRequestMutations = {
  __typename?: 'DealShareRequestMutations';
  accept?: Maybe<DealShareRequest>;
};

export type DealShareRequestMutationsAcceptArgs = {
  id: Scalars['ID']['input'];
};

export enum DealStatus {
  COMMITTED = 'COMMITTED',
  CONSIDERING = 'CONSIDERING',
  HELPING_FUNDRAISE = 'HELPING_FUNDRAISE',
  INVESTED = 'INVESTED',
  LOST = 'LOST',
  NO_STATUS = 'NO_STATUS',
  PASSED = 'PASSED',
  WATCHING = 'WATCHING',
}

export enum DealflowChannel {
  EMAIL = 'EMAIL',
  INTERNAL = 'INTERNAL',
  WEB = 'WEB',
  WHATSAPP = 'WHATSAPP',
}

export type DealflowInsights = {
  __typename?: 'DealflowInsights';
  chartData: ChartData;
  totalDealsAdded: Scalars['Int']['output'];
  totalDealsCommited: Scalars['Int']['output'];
  totalDealsConsidering: Scalars['Int']['output'];
  totalDealsInvested: Scalars['Int']['output'];
  totalDealsLost: Scalars['Int']['output'];
  totalDealsPassed: Scalars['Int']['output'];
};

export type DealflowInsightsFilter = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type DealflowPipeline = {
  __typename?: 'DealflowPipeline';
  communication?: Maybe<NemoCommunication>;
  creator: User;
  errors?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  status: DealflowPipelineStatus;
};

export enum DealflowPipelineStatus {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  PROCESSING = 'PROCESSING',
}

export type DeleteMarkdownInput = {
  eventId: Scalars['ID']['input'];
  investorId: Scalars['ID']['input'];
};

export type Diff = {
  __typename?: 'Diff';
  field: Scalars['String']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
};

export type Distribution = {
  __typename?: 'Distribution';
  amount: Scalars['Float']['output'];
  amountInUserCurrency: OverviewMetric;
  asOfDate: Scalars['Date']['output'];
  currency: CurrencyIsoCode;
  date: Scalars['Date']['output'];
  eventId: Scalars['ID']['output'];
  fromLegalEntityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  toLegalEntityId: Scalars['ID']['output'];
  type: EventType;
  userCurrency: CurrencyIsoCode;
};

export type DistributionInput = {
  amount: Scalars['Float']['input'];
  date: Scalars['Date']['input'];
};

export type DividendInput = {
  amount: Scalars['Float']['input'];
  currency: CurrencyIsoCode;
  date: Scalars['Date']['input'];
  investorEntityId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  portfolioCompanyId: Scalars['ID']['input'];
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  objectUri: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type DocumentMutations = {
  __typename?: 'DocumentMutations';
  ask: Array<Reply>;
};

export type DocumentMutationsAskArgs = {
  input: AskDocumentInput;
};

export enum EquityRoundType {
  PRE_PRE_SEED = 'PRE_PRE_SEED',
  PRE_SEED = 'PRE_SEED',
  SEED = 'SEED',
  SEED_EXT = 'SEED_EXT',
  SERIES_A = 'SERIES_A',
  SERIES_A2 = 'SERIES_A2',
  SERIES_A_EXT = 'SERIES_A_EXT',
  SERIES_B = 'SERIES_B',
  SERIES_B_EXT = 'SERIES_B_EXT',
  SERIES_C = 'SERIES_C',
  SERIES_C_EXT = 'SERIES_C_EXT',
  SERIES_D = 'SERIES_D',
  SERIES_E = 'SERIES_E',
  SERIES_F = 'SERIES_F',
  SERIES_G = 'SERIES_G',
  SERIES_H = 'SERIES_H',
  SERIES_I = 'SERIES_I',
  SERIES_J = 'SERIES_J',
  UNKNOWN = 'UNKNOWN',
}

export type Event = {
  __typename?: 'Event';
  data?: Maybe<Scalars['JSON']['output']>;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  investors: Array<Investor>;
  name: Scalars['String']['output'];
  otherPortfolios: Array<LegalEntity>;
  round?: Maybe<Round>;
  type: EventType;
};

export type EventOtherPortfoliosArgs = {
  portfolioId: Scalars['ID']['input'];
};

export type EventMutations = {
  __typename?: 'EventMutations';
  delete: Event;
};

export type EventMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export enum EventType {
  DISTRIBUTION = 'DISTRIBUTION',
  DIVIDEND = 'DIVIDEND',
  FOUNDER_EQUITY_SPLIT = 'FOUNDER_EQUITY_SPLIT',
  FUND_CLOSE = 'FUND_CLOSE',
  FUND_DISTRIBUTION = 'FUND_DISTRIBUTION',
  INVESTMENT_ROUND = 'INVESTMENT_ROUND',
  MARKDOWN = 'MARKDOWN',
  SECONDARY_SALE = 'SECONDARY_SALE',
  SHARE_REVERSE_SPLIT = 'SHARE_REVERSE_SPLIT',
  SHARE_SPLIT = 'SHARE_SPLIT',
}

export enum ExploreFeedFilterEnum {
  ALL = 'ALL',
  BOOKMARKED = 'BOOKMARKED',
  CONNECTIONS = 'CONNECTIONS',
  MINE = 'MINE',
}

export type ExploreFeedInput = {
  days?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ExploreFeedFilterEnum>;
  meta?: InputMaybe<ExploreFeedMeta>;
};

export type ExploreFeedMeta = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type FeedBackNemoSubmissionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  thumbsUp: Scalars['Boolean']['input'];
  wrongCompanyInfo?: InputMaybe<Scalars['Boolean']['input']>;
  wrongDealInfo?: InputMaybe<Scalars['Boolean']['input']>;
  wrongSomethingElse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FillOutUserEmailOnlyAccountInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  linkedInURL?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileImageId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  username: Scalars['String']['input'];
};

export type Founder = {
  __typename?: 'Founder';
  fullname: Scalars['String']['output'];
  linkedin?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type FounderIntroRequest = {
  __typename?: 'FounderIntroRequest';
  accepted: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nemoSubmission: NemoSubmission;
  profile: Profile;
};

export type FounderIntroRequestMutations = {
  __typename?: 'FounderIntroRequestMutations';
  accept: FounderIntroRequest;
  create: FounderIntroRequest;
};

export type FounderIntroRequestMutationsAcceptArgs = {
  id: Scalars['ID']['input'];
};

export type FounderIntroRequestMutationsCreateArgs = {
  input: CreateFounderIntroRequestInput;
};

export type FounderIntroRequestNotification = {
  __typename?: 'FounderIntroRequestNotification';
  createdAt: Scalars['DateTime']['output'];
  founderIntroRequest: FounderIntroRequest;
  id: Scalars['ID']['output'];
  type: FounderIntroRequestNotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum FounderIntroRequestNotificationType {
  FOUNDER_INTRO_REQUEST = 'FOUNDER_INTRO_REQUEST',
}

export type FounderProfile = {
  __typename?: 'FounderProfile';
  description?: Maybe<Scalars['String']['output']>;
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isFounder: Scalars['Boolean']['output'];
  linkedin?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
};

export type FounderProfileMutations = {
  __typename?: 'FounderProfileMutations';
  create: FounderProfile;
};

export type FounderProfileMutationsCreateArgs = {
  companySearch: SearchCompanyInput;
  input: CreateFounderProfileInput;
};

export type GrantPermissionInput = {
  permissionId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type GrantRoleInput = {
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GraphCreatePublicInvestmentsCompanyInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  newData?: InputMaybe<GraphCreatePublicInvestmentsNewCompanyInput>;
};

export type GraphCreatePublicInvestmentsInput = {
  company: GraphCreatePublicInvestmentsCompanyInput;
  investors: Array<GraphCreatePublicInvestmentsProfileAndAttributionsInput>;
  pressReleaseUrl: Scalars['String']['input'];
  privateRoundId?: InputMaybe<Scalars['ID']['input']>;
  round: GraphCreatePublicInvestmentsRoundInput;
};

export type GraphCreatePublicInvestmentsNewCompanyInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  themes: Array<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type GraphCreatePublicInvestmentsNewProfileInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fullname: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  personalWebsite?: InputMaybe<Scalars['String']['input']>;
  referenceId: Scalars['String']['input'];
  roles: Array<GraphCreatePublicInvestmentsProfileRoleInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type: LegalEntityType;
};

export type GraphCreatePublicInvestmentsNewRoundInput = {
  announcedAmount?: InputMaybe<Scalars['Float']['input']>;
  announcedCurrency?: InputMaybe<CurrencyIsoCode>;
  announcedDate?: InputMaybe<Scalars['Date']['input']>;
  announcedValuation?: InputMaybe<Scalars['Float']['input']>;
  roundType: EquityRoundType;
  valuationType?: InputMaybe<ValuationType>;
};

export type GraphCreatePublicInvestmentsProfileAndAttributionsInput = {
  attributions: Array<GraphCreatePublicInvestmentsProfileInput>;
  investor: GraphCreatePublicInvestmentsProfileInput;
};

export type GraphCreatePublicInvestmentsProfileInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  newData?: InputMaybe<GraphCreatePublicInvestmentsNewProfileInput>;
};

export type GraphCreatePublicInvestmentsProfileRoleEntityInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  newData?: InputMaybe<GraphCreatePublicInvestmentsNewProfileInput>;
};

export type GraphCreatePublicInvestmentsProfileRoleInput = {
  description: Scalars['String']['input'];
  entity?: InputMaybe<GraphCreatePublicInvestmentsProfileRoleEntityInput>;
};

export type GraphCreatePublicInvestmentsRoundInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  newData?: InputMaybe<GraphCreatePublicInvestmentsNewRoundInput>;
};

export type HeldAsset = {
  __typename?: 'HeldAsset';
  asset: BaseAsset;
  assetHolder: LegalEntity;
  numAssets?: Maybe<Scalars['Float']['output']>;
};

export type ImportDistributionInput = {
  currency: CurrencyIsoCode;
  distributions: Array<DistributionInput>;
  exitDate: Scalars['Date']['input'];
  investorLegalEntityId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  targetLegalEntityId: Scalars['ID']['input'];
};

export type ImportFundDistributionsInput = {
  currency: CurrencyIsoCode;
  distributions: Array<DistributionInput>;
  investorLegalEntityId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  targetLegalEntityId: Scalars['ID']['input'];
};

export type InteractionNotification = {
  __typename?: 'InteractionNotification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  interaction?: Maybe<PostInteraction>;
  type: InteractionNotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum InteractionNotificationType {
  CURIOUS = 'CURIOUS',
  LIKE = 'LIKE',
}

export enum InteractionType {
  BOOKMARK = 'BOOKMARK',
  CURIOUS = 'CURIOUS',
  LIKE = 'LIKE',
}

export type InvestingEntity = {
  __typename?: 'InvestingEntity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  teamId: Scalars['ID']['output'];
};

export type Investment = {
  __typename?: 'Investment';
  asset: Asset;
  conversion?: Maybe<InvestmentConversion>;
  documents: Array<Document>;
  event?: Maybe<Event>;
  id: Scalars['ID']['output'];
  investmentMetrics: InvestmentMetrics;
  isSecondary: Scalars['Boolean']['output'];
  issuedByLegalEntity: LegalEntity;
  notes: Array<Note>;
  numAssets?: Maybe<Scalars['Float']['output']>;
  spvLegalEntity?: Maybe<LegalEntity>;
  transactionDate: Scalars['Date']['output'];
};

export type InvestmentConversion = {
  __typename?: 'InvestmentConversion';
  conversionDate: Scalars['Date']['output'];
  conversionPrice?: Maybe<Scalars['Float']['output']>;
  convertedInto: Array<BaseAsset>;
  documents: Array<Document>;
  event?: Maybe<Event>;
  id: Scalars['ID']['output'];
  notes: Array<Note>;
  numConvertedAssets: Array<Scalars['Float']['output']>;
};

export type InvestmentMetrics = {
  __typename?: 'InvestmentMetrics';
  asOfDate: Scalars['Date']['output'];
  holdingsValue?: Maybe<Metric>;
  investedAmount?: Maybe<Metric>;
  investmentCurrency?: Maybe<CurrencyIsoCode>;
  irr?: Maybe<Metric>;
  moic?: Maybe<Metric>;
  userCurrency: CurrencyIsoCode;
};

export type InvestmentRelation = {
  __typename?: 'InvestmentRelation';
  company: LegalEntityWithMeta;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  roundType: EquityRoundType;
};

export enum InvestmentStatus {
  ACTIVE = 'ACTIVE',
  REALISED = 'REALISED',
  STEALTH = 'STEALTH',
}

export type Investor = {
  __typename?: 'Investor';
  coInvestors: Array<Investor>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investingEntity?: Maybe<InvestingEntity>;
  investments: Array<InvestmentRelation>;
  type?: Maybe<LegalEntityType>;
};

export type InviteProposal = {
  __typename?: 'InviteProposal';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['String']['output'];
  creator: User;
  descriptorsGeo: Array<Scalars['String']['output']>;
  descriptorsInvestorType: Array<Scalars['String']['output']>;
  descriptorsMiscTags: Array<Scalars['String']['output']>;
  descriptorsStage: Array<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  fund?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedin?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status: ProposalStatus;
  updatedAt: Scalars['Date']['output'];
};

export type InviteProposalMutations = {
  __typename?: 'InviteProposalMutations';
  create: InviteProposal;
  update: InviteProposal;
};

export type InviteProposalMutationsCreateArgs = {
  input: CreateInviteProposalInput;
};

export type InviteProposalMutationsUpdateArgs = {
  input: UpdateInviteProposalInput;
};

export type InviteeJoinedNotification = {
  __typename?: 'InviteeJoinedNotification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invitee?: Maybe<User>;
  type: InviteeJoinedNotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum InviteeJoinedNotificationType {
  INVITEE_JOINED = 'INVITEE_JOINED',
}

export type LastRoundValuation = {
  __typename?: 'LastRoundValuation';
  date?: Maybe<Scalars['Date']['output']>;
  roundType?: Maybe<EquityRoundType>;
  valuation?: Maybe<Valuation>;
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  allDetailedInvestments: Array<Investment>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investingEntityId?: Maybe<Scalars['ID']['output']>;
  isPortfolio?: Maybe<Scalars['Boolean']['output']>;
  issuedAssets: Array<HeldAsset>;
  legalName?: Maybe<Scalars['String']['output']>;
  portfolioCompanies: Array<PortfolioCompany>;
  portfolioCompany?: Maybe<PortfolioCompany>;
  portfolioCompanyDistributions: Array<CompanyDistribution>;
  portfolioMetrics: PortfolioMetrics;
  profileLink?: Maybe<Scalars['String']['output']>;
  publicProfile?: Maybe<PublicProfile>;
  rounds?: Maybe<Array<Maybe<EquityRoundType>>>;
  teamId: Scalars['ID']['output'];
  transactionlessEvents: Array<Event>;
  type?: Maybe<LegalEntityType>;
};

export type LegalEntityAllDetailedInvestmentsArgs = {
  asOfDate: Scalars['Date']['input'];
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
  userCurrency: CurrencyIsoCode;
};

export type LegalEntityIssuedAssetsArgs = {
  asOfDate: Scalars['Date']['input'];
};

export type LegalEntityPortfolioCompaniesArgs = {
  asOfDate: Scalars['Date']['input'];
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
  userCurrency: CurrencyIsoCode;
};

export type LegalEntityPortfolioCompanyArgs = {
  asOfDate: Scalars['Date']['input'];
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
  portfolioCompanyId: Scalars['ID']['input'];
  userCurrency: CurrencyIsoCode;
};

export type LegalEntityPortfolioMetricsArgs = {
  asOfDate: Scalars['Date']['input'];
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
  userCurrency: CurrencyIsoCode;
};

export type LegalEntityTransactionlessEventsArgs = {
  asOfDate: Scalars['Date']['input'];
  investorId: Scalars['ID']['input'];
};

export type LegalEntityMutations = {
  __typename?: 'LegalEntityMutations';
  create: LegalEntity;
  delete: LegalEntity;
  updateDisplayName: LegalEntity;
  updateInvestingEntity: LegalEntity;
};

export type LegalEntityMutationsCreateArgs = {
  input: CreateLegalEntityInput;
};

export type LegalEntityMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type LegalEntityMutationsUpdateDisplayNameArgs = {
  input: UpdateLegalEntityDisplayNameInput;
};

export type LegalEntityMutationsUpdateInvestingEntityArgs = {
  input: UpdateLegalEntityInvestingEntityInput;
};

export type LegalEntitySearchInput = {
  matchWholeName: Scalars['Boolean']['input'];
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
  searchName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LegalEntityType>;
};

export enum LegalEntityType {
  COMPANY = 'COMPANY',
  ESOP = 'ESOP',
  FUND = 'FUND',
  NATURAL_PERSON = 'NATURAL_PERSON',
  PORTFOLIO_COMPANY = 'PORTFOLIO_COMPANY',
  SPV = 'SPV',
}

export type LegalEntityWithMeta = {
  __typename?: 'LegalEntityWithMeta';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locations: Array<Scalars['String']['output']>;
  publicProfile?: Maybe<PublicProfile>;
  themes: Array<Scalars['String']['output']>;
};

export type List = {
  __typename?: 'List';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  submissions: Array<NemoSubmission>;
};

export type MarkdownMutations = {
  __typename?: 'MarkdownMutations';
  create: Event;
  delete: Event;
  update: Event;
};

export type MarkdownMutationsCreateArgs = {
  input: CreateMarkdownInput;
};

export type MarkdownMutationsDeleteArgs = {
  input: DeleteMarkdownInput;
};

export type MarkdownMutationsUpdateArgs = {
  input: UpdateMarkdownInput;
};

export type MergeDuplicateAssetInput = {
  duplicateId: Scalars['ID']['input'];
  keepId: Scalars['ID']['input'];
};

export type Metric = {
  __typename?: 'Metric';
  inInvestmentCurrency?: Maybe<Scalars['Float']['output']>;
  inPinnedUserCurrency?: Maybe<Scalars['Float']['output']>;
  inUserCurrency?: Maybe<Scalars['Float']['output']>;
};

export type MoneyInput = {
  amount: Scalars['Float']['input'];
  currency: CurrencyIsoCode;
};

export type Mutation = {
  __typename?: 'Mutation';
  addInvestorToRound: Scalars['Boolean']['output'];
  asset: AssetMutations;
  companyProfile: CompanyProfileMutations;
  convertConvertible: Scalars['Boolean']['output'];
  createPortfolioView?: Maybe<PortfolioView>;
  createUserFromEmailOnly: User;
  dealDocument: DealDocumentMutations;
  dealShareRequest: DealShareRequestMutations;
  deleteDistribution: Scalars['Boolean']['output'];
  deleteDividend: Scalars['Boolean']['output'];
  deleteInvestment: Scalars['Boolean']['output'];
  deleteOwnership: Scalars['Boolean']['output'];
  deletePortfolioView?: Maybe<PortfolioView>;
  document: DocumentMutations;
  event: EventMutations;
  founderIntroRequest: FounderIntroRequestMutations;
  founderProfile: FounderProfileMutations;
  generateListPreview: List;
  graphCreatePublicInvestments: Scalars['Boolean']['output'];
  importDistributions: Scalars['Boolean']['output'];
  importFundDistributions: Scalars['Boolean']['output'];
  inviteProposal: InviteProposalMutations;
  legalEntity: LegalEntityMutations;
  markdown: MarkdownMutations;
  markupValuationByAmount: Scalars['Boolean']['output'];
  nemoSubmission: NemoSubmissionMutations;
  nemoSubmissionNote: NemoSubmissionNoteMutations;
  nemoSubmissionTag: NemoSubmissionTagMutations;
  note: NoteMutations;
  permission: PermissionMutations;
  portfolio: PortfolioMutations;
  post: PostMutations;
  postInteraction: PostInteractionMutations;
  profile: ProfileMutations;
  publicInvestment: PublicInvestmentMutations;
  role: RoleMutations;
  round: RoundMutations;
  roundDocument: RoundDocumentMutations;
  roundPrice: RoundPriceMutations;
  submissionFounder: SubmissionFounderMutations;
  suggestInvestors: Scalars['Boolean']['output'];
  team: TeamMutations;
  touchDealFeedLastSeenAt: UserSettings;
  touchNotificationsLastSeenAt: UserSettings;
  updateConvertibleProperties: Scalars['Boolean']['output'];
  updateDistributionAmount: Scalars['Boolean']['output'];
  updateDistributionDate: Scalars['Boolean']['output'];
  updateDividend: Scalars['Boolean']['output'];
  updateInvestedAmount: Scalars['Boolean']['output'];
  updateInvestmentDate: Scalars['Boolean']['output'];
  updateNumAssets: Scalars['Boolean']['output'];
  upsertConvertibleAndNote: UpsertInvestmentAndNoteResponse;
  upsertDividend?: Maybe<Note>;
  upsertEquityAndNote: UpsertInvestmentAndNoteResponse;
  upsertEquityByAmountAndNote: UpsertInvestmentAndNoteResponse;
  upsertOwnership: Scalars['Boolean']['output'];
  upsertSecondaryAndNote: UpsertInvestmentAndNoteResponse;
  upsertSpvAndNote: UpsertInvestmentAndNoteResponse;
  user: UserMutations;
  userSettings: UserSettingsMutations;
};

export type MutationAddInvestorToRoundArgs = {
  input: AddInvestorToRoundInput;
};

export type MutationConvertConvertibleArgs = {
  input: ConvertConvertibleInput;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreatePortfolioViewArgs = {
  input: CreatePortfolioViewInput;
};

export type MutationCreateUserFromEmailOnlyArgs = {
  input: CreateUserFromEmailOnlyInput;
};

export type MutationDeleteDistributionArgs = {
  assetTransferId: Scalars['ID']['input'];
};

export type MutationDeleteDividendArgs = {
  assetTransferId: Scalars['ID']['input'];
};

export type MutationDeleteInvestmentArgs = {
  transactionId: Scalars['ID']['input'];
};

export type MutationDeleteOwnershipArgs = {
  portfolioCompanyMetricsId: Scalars['ID']['input'];
};

export type MutationDeletePortfolioViewArgs = {
  id: Scalars['ID']['input'];
};

export type MutationGenerateListPreviewArgs = {
  id: Scalars['String']['input'];
};

export type MutationGraphCreatePublicInvestmentsArgs = {
  input: GraphCreatePublicInvestmentsInput;
};

export type MutationImportDistributionsArgs = {
  input: ImportDistributionInput;
};

export type MutationImportFundDistributionsArgs = {
  input: ImportFundDistributionsInput;
};

export type MutationMarkupValuationByAmountArgs = {
  assetId: Scalars['ID']['input'];
  markupDate: Scalars['Date']['input'];
  newValue: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['ID']['input'];
  valueCurrency: CurrencyIsoCode;
};

export type MutationSuggestInvestorsArgs = {
  feedback?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateConvertiblePropertiesArgs = {
  input: UpdateConvertiblePropertiesInput;
};

export type MutationUpdateDistributionAmountArgs = {
  assetTransferId: Scalars['ID']['input'];
  newAmount: Scalars['Float']['input'];
  newCurrency: CurrencyIsoCode;
};

export type MutationUpdateDistributionDateArgs = {
  assetTransferId: Scalars['ID']['input'];
  newDate: Scalars['Date']['input'];
};

export type MutationUpdateDividendArgs = {
  assetTransferId: Scalars['ID']['input'];
  newAmountInput?: InputMaybe<MoneyInput>;
  newDate?: InputMaybe<Scalars['Date']['input']>;
};

export type MutationUpdateInvestedAmountArgs = {
  input: UpdateInvestedAmountInput;
};

export type MutationUpdateInvestmentDateArgs = {
  input: UpdateInvestmentDateInput;
};

export type MutationUpdateNumAssetsArgs = {
  input?: InputMaybe<UpdateNumAssetsInput>;
};

export type MutationUpsertConvertibleAndNoteArgs = {
  convertibleInput: ConvertibleInput;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpsertDividendArgs = {
  input: DividendInput;
};

export type MutationUpsertEquityAndNoteArgs = {
  input: UpsertEquityInput;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpsertEquityByAmountAndNoteArgs = {
  input: UpsertEquityByAmountInput;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpsertOwnershipArgs = {
  companyId: Scalars['ID']['input'];
  investorId: Scalars['ID']['input'];
  newValue: Scalars['Float']['input'];
};

export type MutationUpsertSecondaryAndNoteArgs = {
  input: UpsertSecondaryInput;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpsertSpvAndNoteArgs = {
  input: UpsertSpvInput;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type NemoActivity = NemoSubmission | NemoSubmissionChange | NemoSubmissionNote;

export type NemoCommunication = {
  __typename?: 'NemoCommunication';
  id: Scalars['ID']['output'];
  outputChannel?: Maybe<DealflowChannel>;
};

export type NemoSubmission = {
  __typename?: 'NemoSubmission';
  assignee?: Maybe<User>;
  augmentedMessage?: Maybe<Scalars['String']['output']>;
  businessModels: Array<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyProfile>;
  companyCity?: Maybe<Scalars['String']['output']>;
  companyCountry?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyProfile?: Maybe<Profile>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  creator: User;
  customerTypes: Array<Scalars['String']['output']>;
  dealDocuments: Array<DealDocument>;
  deckUrl?: Maybe<Scalars['String']['output']>;
  feedbackGiven: Scalars['Boolean']['output'];
  forkedFrom?: Maybe<NemoSubmission>;
  forks: Array<NemoSubmission>;
  founders?: Maybe<Array<SubmissionFounder>>;
  hasLead: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDemo: Scalars['Boolean']['output'];
  isOriginal: Scalars['Boolean']['output'];
  leadBy?: Maybe<Scalars['String']['output']>;
  list?: Maybe<List>;
  markets: Array<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  myForks: Array<NemoSubmission>;
  notificationAsked: Scalars['Boolean']['output'];
  numSeen: Scalars['Int']['output'];
  post?: Maybe<Post>;
  raisedAmount?: Maybe<Scalars['Float']['output']>;
  raisedCurrency?: Maybe<CurrencyIsoCode>;
  rawSource?: Maybe<Scalars['String']['output']>;
  roundType: EquityRoundType;
  secondarySubmissions: Array<NemoSubmission>;
  seenBy: Array<User>;
  sharedToDealFeedAt?: Maybe<Scalars['DateTime']['output']>;
  shortSummary?: Maybe<Scalars['String']['output']>;
  sourceProfiles: Array<Profile>;
  status: DealStatus;
  tags: Array<NemoSubmissionTag>;
  teamId: Scalars['ID']['output'];
  teaserSummary?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userTags: Array<Scalars['String']['output']>;
};

export type NemoSubmissionChange = {
  __typename?: 'NemoSubmissionChange';
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  diffs: Array<Diff>;
  id: Scalars['ID']['output'];
  section: Scalars['String']['output'];
  submission: NemoSubmission;
};

export type NemoSubmissionForDigest = {
  __typename?: 'NemoSubmissionForDigest';
  amount?: Maybe<Scalars['String']['output']>;
  azavaUrl: Scalars['String']['output'];
  company: CompanyInner;
  founders: Array<Maybe<Founder>>;
  id?: Maybe<Scalars['String']['output']>;
  leadBy?: Maybe<Scalars['String']['output']>;
  roundName?: Maybe<Scalars['String']['output']>;
  shortSummary?: Maybe<Scalars['String']['output']>;
  status: DealStatus;
  teaserSummary?: Maybe<Scalars['String']['output']>;
};

export type NemoSubmissionList = {
  __typename?: 'NemoSubmissionList';
  count: Scalars['Int']['output'];
  submissions: Array<NemoSubmission>;
};

export type NemoSubmissionMutations = {
  __typename?: 'NemoSubmissionMutations';
  createBlank: NemoSubmission;
  delete: NemoSubmission;
  feedback: NemoSubmission;
  fork: NemoSubmission;
  notifyWhenOthersAdd: NemoSubmission;
  update: NemoSubmission;
};

export type NemoSubmissionMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type NemoSubmissionMutationsFeedbackArgs = {
  args: FeedBackNemoSubmissionInput;
  id: Scalars['ID']['input'];
};

export type NemoSubmissionMutationsForkArgs = {
  id: Scalars['ID']['input'];
};

export type NemoSubmissionMutationsNotifyWhenOthersAddArgs = {
  id: Scalars['ID']['input'];
};

export type NemoSubmissionMutationsUpdateArgs = {
  args: UpdateNemoSubmissionInput;
  id: Scalars['ID']['input'];
};

export type NemoSubmissionNote = {
  __typename?: 'NemoSubmissionNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  id: Scalars['ID']['output'];
  submission: NemoSubmission;
  updatedAt: Scalars['DateTime']['output'];
};

export type NemoSubmissionNoteCreateInput = {
  content: Scalars['String']['input'];
  nemoSubmissionId: Scalars['ID']['input'];
};

export type NemoSubmissionNoteMutations = {
  __typename?: 'NemoSubmissionNoteMutations';
  create: NemoSubmissionNote;
  delete: Scalars['Boolean']['output'];
  update: NemoSubmissionNote;
};

export type NemoSubmissionNoteMutationsCreateArgs = {
  input: NemoSubmissionNoteCreateInput;
};

export type NemoSubmissionNoteMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type NemoSubmissionNoteMutationsUpdateArgs = {
  input: NemoSubmissionNoteUpdateInput;
};

export type NemoSubmissionNoteUpdateInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type NemoSubmissionTag = {
  __typename?: 'NemoSubmissionTag';
  id: Scalars['ID']['output'];
  tag: Scalars['String']['output'];
};

export type NemoSubmissionTagCreateInput = {
  nemoSubmissionId: Scalars['ID']['input'];
  tag: Scalars['String']['input'];
};

export type NemoSubmissionTagMutations = {
  __typename?: 'NemoSubmissionTagMutations';
  create: NemoSubmissionTag;
  delete: NemoSubmissionTag;
};

export type NemoSubmissionTagMutationsCreateArgs = {
  input: NemoSubmissionTagCreateInput;
};

export type NemoSubmissionTagMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  noteType: NoteType;
  referenceId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy?: Maybe<User>;
};

export type NoteMutations = {
  __typename?: 'NoteMutations';
  create: Note;
  delete?: Maybe<Note>;
  update: Note;
};

export type NoteMutationsCreateArgs = {
  input: CreateNoteInput;
};

export type NoteMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type NoteMutationsUpdateArgs = {
  input: UpdateNoteInput;
};

export enum NoteType {
  EVENT = 'EVENT',
  PRICE = 'PRICE',
  PROFILE = 'PROFILE',
  TRANSACTION = 'TRANSACTION',
}

export type Notification =
  | DealActivityNotification
  | DealReceivedNotification
  | FounderIntroRequestNotification
  | InteractionNotification
  | InviteeJoinedNotification
  | ReceivedDealflowAccessNotification;

export type NotificationGroup = {
  __typename?: 'NotificationGroup';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nemoSubmission?: Maybe<NemoSubmission>;
  notifications: Array<Notification>;
  type: NotificationType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type NotificationMeta = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum NotificationType {
  CURIOUS = 'CURIOUS',
  DEAL_ACTIVITY = 'DEAL_ACTIVITY',
  DEAL_RECEIVED = 'DEAL_RECEIVED',
  FOUNDER_INTRO_REQUEST = 'FOUNDER_INTRO_REQUEST',
  INVITEE_JOINED = 'INVITEE_JOINED',
  LIKE = 'LIKE',
  RECEIVED_DEALFLOW_ACCESS = 'RECEIVED_DEALFLOW_ACCESS',
}

export type OrganisationsFilter = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  roundType?: InputMaybe<Array<EquityRoundType>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DealStatus>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewMetric = {
  __typename?: 'OverviewMetric';
  inPinnedUserCurrency?: Maybe<Scalars['Float']['output']>;
  inUserCurrency?: Maybe<Scalars['Float']['output']>;
};

export type PaginatedResult = {
  __typename?: 'PaginatedResult';
  profiles: Array<Profile>;
  totalCount: Scalars['Int']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  action: ActionType;
  id: Scalars['ID']['output'];
  scope: ScopeType;
  teamId: Scalars['ID']['output'];
};

export type PermissionMutations = {
  __typename?: 'PermissionMutations';
  create: Permission;
  grant: RolePermission;
  revoke: RolePermission;
};

export type PermissionMutationsCreateArgs = {
  input: CreatePermissionInput;
};

export type PermissionMutationsGrantArgs = {
  input: GrantPermissionInput;
};

export type PermissionMutationsRevokeArgs = {
  input: RevokePermissionInput;
};

export type PermissionSummary = {
  __typename?: 'PermissionSummary';
  readonly: Scalars['Boolean']['output'];
  readonlyByTeam: Array<ReadonlyByTeam>;
};

export type PortfolioCompany = {
  __typename?: 'PortfolioCompany';
  companyMetrics: PortfolioCompanyMetrics;
  distributions: Array<Distribution>;
  dividends: Array<Distribution>;
  fundDistributions: Array<Distribution>;
  investments: Array<Investment>;
  legalEntity: LegalEntity;
  profileSlug?: Maybe<Scalars['String']['output']>;
};

export type PortfolioCompanyMetrics = {
  __typename?: 'PortfolioCompanyMetrics';
  asOfDate: Scalars['Date']['output'];
  firstInvestedDate?: Maybe<Scalars['Date']['output']>;
  investedAmount?: Maybe<Scalars['Float']['output']>;
  irr?: Maybe<OverviewMetric>;
  lastPricePerShare?: Maybe<Scalars['Float']['output']>;
  lastUpdated?: Maybe<Scalars['Date']['output']>;
  moic?: Maybe<OverviewMetric>;
  numInvestments: Scalars['Int']['output'];
  realisedValue?: Maybe<OverviewMetric>;
  stored?: Maybe<StoredPortfolioCompanyMetrics>;
  unrealisedValue?: Maybe<OverviewMetric>;
  userCurrency: CurrencyIsoCode;
};

export type PortfolioMetrics = {
  __typename?: 'PortfolioMetrics';
  asOfDate: Scalars['Date']['output'];
  dealIrr?: Maybe<OverviewMetric>;
  investedAmount?: Maybe<Scalars['Float']['output']>;
  lpIrr?: Maybe<OverviewMetric>;
  moic?: Maybe<OverviewMetric>;
  paidIntoFund?: Maybe<Scalars['Float']['output']>;
  realisedValue?: Maybe<OverviewMetric>;
  tvpi?: Maybe<OverviewMetric>;
  unrealisedValue?: Maybe<OverviewMetric>;
  userCurrency: CurrencyIsoCode;
};

export type PortfolioMutations = {
  __typename?: 'PortfolioMutations';
  delete: LegalEntity;
};

export type PortfolioMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type PortfolioView = {
  __typename?: 'PortfolioView';
  columnState?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  data: Scalars['JSON']['output'];
  filterState?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profileSlug?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type Post = {
  __typename?: 'Post';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  creator: User;
  fidelity: PostFidelity;
  id: Scalars['ID']['output'];
  interactions: Array<PostInteraction>;
  nemoSubmission: NemoSubmission;
  slug?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export enum PostFidelity {
  ACCEPTED_DEAL_SHARE_REQUEST = 'ACCEPTED_DEAL_SHARE_REQUEST',
  ANONYMOUS = 'ANONYMOUS',
  DISPLAY_NAME_AND_SUMMARY = 'DISPLAY_NAME_AND_SUMMARY',
}

export type PostInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  nemoSubmissionId: Scalars['ID']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PostInteraction = {
  __typename?: 'PostInteraction';
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  dealShareRequests: Array<DealShareRequest>;
  id: Scalars['ID']['output'];
  postId: Scalars['ID']['output'];
  type: InteractionType;
};

export type PostInteractionInput = {
  postId: Scalars['ID']['input'];
  type: InteractionType;
};

export type PostInteractionMutations = {
  __typename?: 'PostInteractionMutations';
  create: PostInteraction;
  delete: PostInteraction;
};

export type PostInteractionMutationsCreateArgs = {
  input: PostInteractionInput;
};

export type PostInteractionMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type PostMutations = {
  __typename?: 'PostMutations';
  createOrUpdate: Post;
  delete: Post;
  deleteBySubmissionId: Post;
};

export type PostMutationsCreateOrUpdateArgs = {
  input: PostInput;
};

export type PostMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type PostMutationsDeleteBySubmissionIdArgs = {
  submissionId: Scalars['ID']['input'];
};

export type Price = {
  __typename?: 'Price';
  currency: CurrencyIsoCode;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  azavaUsers: Array<User>;
  businessModels: Array<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  competitors: Array<Profile>;
  connections?: Maybe<Array<Scalars['String']['output']>>;
  country?: Maybe<Scalars['String']['output']>;
  customers: Array<Scalars['String']['output']>;
  deals: Array<NemoSubmission>;
  dealsSourced: Array<NemoSubmission>;
  description: Scalars['String']['output'];
  descriptorsGeo: Array<Scalars['String']['output']>;
  descriptorsInvestorType: Array<Scalars['String']['output']>;
  descriptorsMiscTags: Array<Scalars['String']['output']>;
  descriptorsStage: Array<Scalars['String']['output']>;
  emails: Array<ProfileEmail>;
  firstCoinvestmentProfile?: Maybe<Profile>;
  firstInvested?: Maybe<Scalars['String']['output']>;
  founders: Array<Profile>;
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  investingEntities: Array<LegalEntity>;
  investmentStatus?: Maybe<InvestmentStatus>;
  investments?: Maybe<Array<PublicInvestment>>;
  inviteProposalStatus?: Maybe<ProposalStatus>;
  lastInteractedWith?: Maybe<Scalars['Date']['output']>;
  lastRound?: Maybe<ProfileInvestment>;
  latestRoundValuation?: Maybe<LastRoundValuation>;
  legalEntity?: Maybe<LegalEntity>;
  legalEntityInvestments: Array<LegalEntity>;
  linkedin?: Maybe<Scalars['String']['output']>;
  locations: Array<Scalars['String']['output']>;
  markets: Array<Scalars['String']['output']>;
  notionNotesImported: Scalars['Boolean']['output'];
  personalWebsite?: Maybe<Scalars['String']['output']>;
  privateProfiles: Array<Profile>;
  profileInvestments: Array<ProfileInvestment>;
  profileInvestors: Array<ProfileInvestor>;
  publicProfileInvestments: Array<ProfileInvestment>;
  roles: Array<ProfileRole>;
  roundTypes: Array<EquityRoundType>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  socialLinks: Array<ProfileSocialLink>;
  stages: Array<Scalars['String']['output']>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']['output']>;
  themes: Array<Scalars['String']['output']>;
  totalInvested?: Maybe<Scalars['Float']['output']>;
  totalValue?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<LegalEntityType>;
};

export type ProfileByNameOrWebsiteInput = {
  name: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileEmail = {
  __typename?: 'ProfileEmail';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProfileInvestment = {
  __typename?: 'ProfileInvestment';
  company: Profile;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  roundType: EquityRoundType;
};

export type ProfileInvestor = {
  __typename?: 'ProfileInvestor';
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  investor: Profile;
  roundType: EquityRoundType;
};

export type ProfileMatchSuggestion = {
  __typename?: 'ProfileMatchSuggestion';
  id: Scalars['ID']['output'];
  privateProfile: Profile;
  publicProfile: Profile;
  status?: Maybe<SuggestionStatus>;
  team: Team;
};

export type ProfileMutations = {
  __typename?: 'ProfileMutations';
  acceptMatchSuggestion: ProfileMatchSuggestion;
  addProfileInvestment: ProfileInvestment;
  addPublicInvestment: ProfileInvestment;
  addSocialLink: Profile;
  create: Profile;
  deletePublicInvestment: ProfileInvestment;
  rejectMatchSuggestion: ProfileMatchSuggestion;
  updateDescriptors: Profile;
  updateImageUrl: Profile;
  updateInvestmentStatus: Profile;
  updateProfile: Profile;
};

export type ProfileMutationsAcceptMatchSuggestionArgs = {
  input: UpdateSuggestionInput;
};

export type ProfileMutationsAddProfileInvestmentArgs = {
  input: AddProfileInvestmentInput;
};

export type ProfileMutationsAddPublicInvestmentArgs = {
  input: AddPublicInvestmentInput;
};

export type ProfileMutationsAddSocialLinkArgs = {
  input: AddSocialLinkInput;
};

export type ProfileMutationsCreateArgs = {
  input: CreateProfileInput;
};

export type ProfileMutationsDeletePublicInvestmentArgs = {
  id: Scalars['ID']['input'];
};

export type ProfileMutationsRejectMatchSuggestionArgs = {
  input: UpdateSuggestionInput;
};

export type ProfileMutationsUpdateDescriptorsArgs = {
  input: UpdateDescriptorsInput;
};

export type ProfileMutationsUpdateImageUrlArgs = {
  input: UpdateProfileImageInput;
};

export type ProfileMutationsUpdateInvestmentStatusArgs = {
  input: UpdateInvestmentStatusInput;
};

export type ProfileMutationsUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type ProfileRole = {
  __typename?: 'ProfileRole';
  description?: Maybe<Scalars['String']['output']>;
  entityProfile?: Maybe<Profile>;
  id: Scalars['ID']['output'];
};

export type ProfileSocialLink = {
  __typename?: 'ProfileSocialLink';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export enum ProfileType {
  FOUNDER = 'FOUNDER',
  INVESTOR = 'INVESTOR',
}

export type ProfilesFilter = {
  coInvestedWithMe?: InputMaybe<Scalars['Boolean']['input']>;
  dealRecipientId?: InputMaybe<Scalars['String']['input']>;
  externalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  onAzava?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioOnly?: InputMaybe<Scalars['Boolean']['input']>;
  profileType?: InputMaybe<ProfileType>;
  sentMeDeals?: InputMaybe<Scalars['Boolean']['input']>;
  stages?: InputMaybe<Array<InputMaybe<EquityRoundType>>>;
  textFilter?: InputMaybe<Scalars['String']['input']>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProfilesOrderBy = {
  firstInvested?: InputMaybe<Sort>;
  fullname?: InputMaybe<Sort>;
  lastInteracted?: InputMaybe<Sort>;
  lastRoundDate?: InputMaybe<Sort>;
  nemoSubmissionsSourced?: InputMaybe<Sort>;
};

export type ProfilesQueryMeta = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export enum ProposalStatus {
  INVITATION_SENT = 'INVITATION_SENT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  USER_SIGNED_UP = 'USER_SIGNED_UP',
  WAITLISTED = 'WAITLISTED',
}

export type PublicInvestment = {
  __typename?: 'PublicInvestment';
  companyName: Scalars['String']['output'];
  companyProfile?: Maybe<CompanyProfile>;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  round: PublicRound;
  roundType: EquityRoundType;
  source?: Maybe<Scalars['String']['output']>;
};

export type PublicInvestmentMutations = {
  __typename?: 'PublicInvestmentMutations';
  createFromUrl: Array<PublicInvestment>;
};

export type PublicInvestmentMutationsCreateFromUrlArgs = {
  input: CreateFromUrlPublicInvestmentInput;
};

export type PublicProfile = CompanyProfile | Profile;

export type PublicRound = {
  __typename?: 'PublicRound';
  companyProfile?: Maybe<CompanyProfile>;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  roundType: EquityRoundType;
};

export type Query = {
  __typename?: 'Query';
  adminRoundsToCheck: Array<Round>;
  askSubmission: Scalars['String']['output'];
  changeLogs: Array<ChangeLog>;
  checkSlugIsValid: Scalars['Boolean']['output'];
  coInvestorsNotOnAzava: Array<Profile>;
  companyProfile: CompanyProfile;
  dealActivity: Array<NemoActivity>;
  dealFeed: Array<NemoSubmission>;
  dealFeedMyDeals: Array<NemoSubmission>;
  dealflowInsights: DealflowInsights;
  dealsMatchingThisOrganisation: Array<NemoSubmission>;
  demoDeal?: Maybe<NemoSubmission>;
  exploreFeed: Array<Post>;
  explorePost: Post;
  findCompanyProfilesByName: Array<CompanyProfile>;
  findFirstPostCreatedByMe?: Maybe<Post>;
  findManyCompanyProfiles: Array<Profile>;
  findManyProfiles: PaginatedResult;
  findProfilesByFullname: Array<Profile>;
  generateCompanyProfileFields: CompanyProfileSuggestFields;
  hardCodedUsersForWelcomeScreen: Array<Profile>;
  investingEntities: Array<InvestingEntity>;
  investingEntity?: Maybe<InvestingEntity>;
  investors: Array<Investor>;
  investorsByRound: Array<Investor>;
  inviteProposalForUser?: Maybe<InviteProposal>;
  inviteProposals: Array<InviteProposal>;
  isEmailTaken: Scalars['Boolean']['output'];
  legalEntity?: Maybe<LegalEntity>;
  list: List;
  listBySlug?: Maybe<List>;
  listHistory: Array<List>;
  myIssuedAccessKeys: Array<AccessKey>;
  myVisibleAccessKeys: Array<AccessKey>;
  nemoProfiles: Array<Profile>;
  nemoSubmissionTags: Array<NemoSubmissionTag>;
  notes: Array<Note>;
  notificationGroups: Array<NotificationGroup>;
  notificationsCount: Scalars['Int']['output'];
  organisations: NemoSubmissionList;
  portfolioViewBySlug?: Maybe<PortfolioView>;
  portfolioViews?: Maybe<Array<Maybe<PortfolioView>>>;
  pricesByIssuerId: Array<Price>;
  processingDealflowPipelines: Array<DealflowPipeline>;
  profileBySlug?: Maybe<Profile>;
  profileSlugs: Array<Scalars['String']['output']>;
  profiles: Array<Profile>;
  profilesMatchSuggestions: Array<ProfileMatchSuggestion>;
  profilesWithInvestments: Array<Profile>;
  publicProfileByNameOrWebsite?: Maybe<PublicProfile>;
  publicProfileBySlug?: Maybe<PublicProfile>;
  rounds: Array<Round>;
  schemaHash: Scalars['ID']['output'];
  stats: Stats;
  submission: NemoSubmission;
  submittedDeals: NemoSubmissionList;
  sugestedTags: Array<Scalars['String']['output']>;
  team: Team;
  teamsByName: Array<Team>;
  uploadedDealCount: Scalars['Int']['output'];
  user: User;
  userByEmail: User;
  userDealDigest: Array<NemoSubmissionForDigest>;
  usersBlocked: Array<User>;
  usersByEmailPrefix: Array<User>;
  usersNotCompletedRegistration: Array<User>;
};

export type QueryAskSubmissionArgs = {
  input: AskSubmissionInput;
};

export type QueryChangeLogsArgs = {
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCheckSlugIsValidArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCoInvestorsNotOnAzavaArgs = {
  fullname?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompanyProfileArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDealActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDealFeedArgs = {
  days?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDealflowInsightsArgs = {
  filter: DealflowInsightsFilter;
};

export type QueryDealsMatchingThisOrganisationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryExploreFeedArgs = {
  input: ExploreFeedInput;
};

export type QueryExplorePostArgs = {
  slug: Scalars['String']['input'];
};

export type QueryFindCompanyProfilesByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryFindManyCompanyProfilesArgs = {
  filter: ProfilesFilter;
  meta?: InputMaybe<ProfilesQueryMeta>;
  orderBy?: InputMaybe<ProfilesOrderBy>;
};

export type QueryFindManyProfilesArgs = {
  filter: ProfilesFilter;
  meta?: InputMaybe<ProfilesQueryMeta>;
  orderBy?: InputMaybe<ProfilesOrderBy>;
};

export type QueryFindProfilesByFullnameArgs = {
  fullname: Scalars['String']['input'];
};

export type QueryGenerateCompanyProfileFieldsArgs = {
  text: Scalars['String']['input'];
};

export type QueryInvestingEntityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInvestorsByRoundArgs = {
  eventId: Scalars['ID']['input'];
};

export type QueryIsEmailTakenArgs = {
  email: Scalars['EmailAddress']['input'];
};

export type QueryLegalEntityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryListArgs = {
  id: Scalars['String']['input'];
};

export type QueryListBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryNotesArgs = {
  referenceIds: Array<Scalars['ID']['input']>;
};

export type QueryNotificationGroupsArgs = {
  meta: NotificationMeta;
};

export type QueryNotificationsCountArgs = {
  since?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryOrganisationsArgs = {
  filter: OrganisationsFilter;
  meta: SubmittedDealsMeta;
};

export type QueryPortfolioViewBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryPricesByIssuerIdArgs = {
  issuedByLegalEntityId: Scalars['ID']['input'];
};

export type QueryProfileBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProfilesArgs = {
  meta: ProfilesQueryMeta;
};

export type QueryPublicProfileByNameOrWebsiteArgs = {
  input: ProfileByNameOrWebsiteInput;
};

export type QueryPublicProfileBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRoundsArgs = {
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryStatsArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerySubmissionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySubmittedDealsArgs = {
  filter: SubmittedDealsFilter;
  meta: SubmittedDealsMeta;
  orderBy?: InputMaybe<SubmittedDealsOrderBy>;
};

export type QuerySugestedTagsArgs = {
  submissionId: Scalars['ID']['input'];
};

export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTeamsByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserByEmailArgs = {
  email: Scalars['EmailAddress']['input'];
};

export type QueryUserDealDigestArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryUsersByEmailPrefixArgs = {
  emailPrefix: Scalars['String']['input'];
};

export type ReadonlyByTeam = {
  __typename?: 'ReadonlyByTeam';
  id: Scalars['ID']['output'];
  readonly: Scalars['Boolean']['output'];
  teamId: Scalars['ID']['output'];
};

export type ReceivedDealflowAccessNotification = {
  __typename?: 'ReceivedDealflowAccessNotification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  issuer?: Maybe<User>;
  type: ReceivedDealflowAccessNotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ReceivedDealflowAccessNotificationType {
  RECEIVED_DEALFLOW_ACCESS = 'RECEIVED_DEALFLOW_ACCESS',
}

export type Reply = {
  __typename?: 'Reply';
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type RevokeAccessInput = {
  email: Scalars['EmailAddress']['input'];
};

export type RevokePermissionInput = {
  permissionId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type RevokeRoleInput = {
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type RoleMutations = {
  __typename?: 'RoleMutations';
  create: Role;
  grant: UserRole;
  revoke: UserRole;
  revokeAll: Scalars['Int']['output'];
};

export type RoleMutationsCreateArgs = {
  name: Scalars['String']['input'];
};

export type RoleMutationsGrantArgs = {
  input: GrantRoleInput;
};

export type RoleMutationsRevokeArgs = {
  input: RevokeRoleInput;
};

export type RoleMutationsRevokeAllArgs = {
  userId: Scalars['ID']['input'];
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  permissionId: Scalars['ID']['output'];
  roleId: Scalars['ID']['output'];
};

export type Round = {
  __typename?: 'Round';
  assetType: AssetType;
  company: LegalEntity;
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  lastCheckedAt?: Maybe<Scalars['Date']['output']>;
  price?: Maybe<Price>;
  raisedAmount?: Maybe<Scalars['Float']['output']>;
  raisedAmountInPinnedUserCurrency?: Maybe<Scalars['Float']['output']>;
  raisedCurrency?: Maybe<CurrencyIsoCode>;
  roundDocuments: Array<RoundDocument>;
  roundType: EquityRoundType;
  team: Team;
  urlPressRelease?: Maybe<Scalars['String']['output']>;
  valuation?: Maybe<Scalars['Float']['output']>;
  valuationCurrency?: Maybe<CurrencyIsoCode>;
  valuationInPinnedUserCurrency?: Maybe<Scalars['Float']['output']>;
  valuationType: ValuationType;
};

export type RoundRaisedAmountInPinnedUserCurrencyArgs = {
  asOfDate: Scalars['Date']['input'];
  userCurrency: CurrencyIsoCode;
};

export type RoundValuationInPinnedUserCurrencyArgs = {
  asOfDate: Scalars['Date']['input'];
  userCurrency: CurrencyIsoCode;
};

export type RoundDocument = {
  __typename?: 'RoundDocument';
  createdAt: Scalars['Date']['output'];
  creator: User;
  document: Document;
  downloadURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type RoundDocumentMutations = {
  __typename?: 'RoundDocumentMutations';
  delete: DealDocument;
};

export type RoundDocumentMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type RoundMutations = {
  __typename?: 'RoundMutations';
  adminMarkNeverPublic: Round;
  adminSnooze: Round;
  create: RoundPriceResponse;
  update?: Maybe<Round>;
};

export type RoundMutationsAdminMarkNeverPublicArgs = {
  id: Scalars['ID']['input'];
};

export type RoundMutationsAdminSnoozeArgs = {
  id: Scalars['ID']['input'];
};

export type RoundMutationsCreateArgs = {
  input: CreateRoundInput;
};

export type RoundMutationsUpdateArgs = {
  input?: InputMaybe<UpdateRoundInput>;
};

export type RoundPriceMutations = {
  __typename?: 'RoundPriceMutations';
  create: RoundPriceResponse;
  update: RoundPriceResponse;
};

export type RoundPriceMutationsCreateArgs = {
  input: CreateRoundPriceInput;
};

export type RoundPriceMutationsUpdateArgs = {
  input: UpdateRoundPriceInput;
};

export type RoundPriceResponse = {
  __typename?: 'RoundPriceResponse';
  price?: Maybe<Price>;
  round?: Maybe<Round>;
};

export enum ScopeType {
  COMPANY_NAMES = 'COMPANY_NAMES',
  NOTES = 'NOTES',
  PORTFOLIO_ALL = 'PORTFOLIO_ALL',
  PORTFOLIO_METRICS = 'PORTFOLIO_METRICS',
}

export type SearchCompanyInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SetLastRemindedAtInput = {
  email: Scalars['EmailAddress']['input'];
};

export type SharesInput = {
  assetLegalName: Scalars['String']['input'];
  numAssets: Scalars['Float']['input'];
};

export enum ShowHide {
  HIDE = 'HIDE',
  SHOW = 'SHOW',
}

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

export type Stats = {
  __typename?: 'Stats';
  chartData: ChartData;
  totalDealsAdded: Scalars['Int']['output'];
  totalDealsCommited: Scalars['Int']['output'];
  totalDealsConsidering: Scalars['Int']['output'];
  totalDealsInvested: Scalars['Int']['output'];
  totalDealsPassed: Scalars['Int']['output'];
};

export type StoredPortfolioCompanyMetrics = {
  __typename?: 'StoredPortfolioCompanyMetrics';
  id: Scalars['ID']['output'];
  markdownDate?: Maybe<Scalars['Date']['output']>;
  owned?: Maybe<Scalars['Float']['output']>;
};

export type SubmissionFounder = {
  __typename?: 'SubmissionFounder';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSource?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  linkedinData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  profilePicUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
};

export type SubmissionFounderInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['ID']['input'];
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export type SubmissionFounderMutations = {
  __typename?: 'SubmissionFounderMutations';
  create: SubmissionFounder;
  delete: SubmissionFounder;
  update: SubmissionFounder;
};

export type SubmissionFounderMutationsCreateArgs = {
  input: SubmissionFounderInput;
};

export type SubmissionFounderMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type SubmissionFounderMutationsUpdateArgs = {
  id: Scalars['ID']['input'];
  input: SubmissionFounderUpdateInput;
};

export type SubmissionFounderUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export type SubmittedDealsFilter = {
  assignedTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  roundType?: InputMaybe<Array<EquityRoundType>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<DealStatus>>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type SubmittedDealsMeta = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type SubmittedDealsOrderBy = {
  companyName?: InputMaybe<Sort>;
  createdAt?: InputMaybe<Sort>;
};

export type Subscription = {
  __typename?: 'Subscription';
  myNewSubmissions: NemoSubmission;
  newNotification: NotificationGroup;
  /** @deprecated Use myNewSubmissions */
  newSubmissions: NemoSubmission;
  updatedDealflowPipeline: DealflowPipeline;
};

export type SubscriptionNewSubmissionsArgs = {
  teamId: Scalars['String']['input'];
};

export enum SuggestionStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID']['output'];
  investedInto: Array<LegalEntity>;
  members: Array<User>;
  name: Scalars['String']['output'];
  portfolios: Array<LegalEntity>;
};

export type TeamInvestedIntoArgs = {
  input: LegalEntitySearchInput;
};

export type TeamMutations = {
  __typename?: 'TeamMutations';
  create: Team;
};

export type TeamMutationsCreateArgs = {
  name: Scalars['String']['input'];
};

export type UpdateAssetNameInput = {
  assetId: Scalars['ID']['input'];
  newAssetName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConvertiblePropertiesInput = {
  convertibleAssetId: Scalars['ID']['input'];
  newConversionPrice?: InputMaybe<Scalars['Float']['input']>;
  newConvertibleType: ConvertibleType;
  newDiscountRate?: InputMaybe<Scalars['Float']['input']>;
  newInterest?: InputMaybe<Scalars['Float']['input']>;
  newInterestRate?: InputMaybe<Scalars['Float']['input']>;
  newMaturityDate?: InputMaybe<Scalars['Date']['input']>;
  newValuationCap?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateDescriptorsInput = {
  descriptorsGeo: Array<Scalars['String']['input']>;
  descriptorsInvestorType: Array<Scalars['String']['input']>;
  descriptorsMiscTags: Array<Scalars['String']['input']>;
  descriptorsStage: Array<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
};

export type UpdateInvestedAmountInput = {
  newAmount?: InputMaybe<MoneyInput>;
  transactionId: Scalars['ID']['input'];
  viewerLegalEntityId: Scalars['ID']['input'];
};

export type UpdateInvestmentDateInput = {
  newDate?: InputMaybe<Scalars['Date']['input']>;
  transactionId: Scalars['ID']['input'];
};

export type UpdateInvestmentStatusInput = {
  newStatus: InvestmentStatus;
  profileId: Scalars['ID']['input'];
};

export type UpdateInviteProposalInput = {
  id: Scalars['ID']['input'];
  status: ProposalStatus;
};

export type UpdateLegalEntityDisplayNameInput = {
  id: Scalars['ID']['input'];
  newName: Scalars['String']['input'];
};

export type UpdateLegalEntityInvestingEntityInput = {
  id: Scalars['ID']['input'];
  investingEntityId: Scalars['ID']['input'];
};

export type UpdateMarkdownInput = {
  eventId: Scalars['ID']['input'];
  investorId: Scalars['ID']['input'];
  newPercentage: Scalars['Float']['input'];
};

export type UpdateNemoSubmissionInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  companyCity?: InputMaybe<Scalars['String']['input']>;
  companyCountry?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  deckUrl?: InputMaybe<Scalars['URL']['input']>;
  feedbackGiven?: InputMaybe<Scalars['Boolean']['input']>;
  hasLead?: InputMaybe<Scalars['Boolean']['input']>;
  leadBy?: InputMaybe<Scalars['String']['input']>;
  markets?: InputMaybe<Array<Scalars['String']['input']>>;
  notificationAsked?: InputMaybe<Scalars['Boolean']['input']>;
  raisedAmount?: InputMaybe<Scalars['Float']['input']>;
  raisedCurrency?: InputMaybe<CurrencyIsoCode>;
  roundType?: InputMaybe<EquityRoundType>;
  section?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DealStatus>;
  teaserSummary?: InputMaybe<Scalars['String']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateNoteInput = {
  newMessage: Scalars['String']['input'];
  noteId: Scalars['ID']['input'];
};

export type UpdateNumAssetsInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  newNumAssets?: InputMaybe<Scalars['Float']['input']>;
  transactionId: Scalars['ID']['input'];
  viewerLegalEntityId: Scalars['ID']['input'];
};

export type UpdateProfileImageInput = {
  cloudflareImageId?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
};

export type UpdateProfileInput = {
  alsoKnownAs?: InputMaybe<Scalars['String']['input']>;
  businessModels?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customers?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  fullname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  investmentStatus?: InputMaybe<InvestmentStatus>;
  markets?: InputMaybe<Array<Scalars['String']['input']>>;
  personalWebsite?: InputMaybe<Scalars['String']['input']>;
  themes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePublicProfileInput = {
  fund?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type UpdateRoundInput = {
  assetType: AssetType;
  date: Scalars['Date']['input'];
  raisedAmount?: InputMaybe<MoneyInput>;
  roundId: Scalars['ID']['input'];
  roundType: EquityRoundType;
  urlPressRelease?: InputMaybe<Scalars['String']['input']>;
  valuation?: InputMaybe<MoneyInput>;
  valuationType: ValuationType;
};

export type UpdateRoundPriceInput = {
  newDate?: InputMaybe<Scalars['Date']['input']>;
  newPrice?: InputMaybe<MoneyInput>;
  priceId: Scalars['ID']['input'];
};

export type UpdateSuggestionInput = {
  privateProfileId: Scalars['ID']['input'];
  publicProfileId: Scalars['ID']['input'];
  suggestionId: Scalars['ID']['input'];
};

export type UpdateUsernameInput = {
  newUsername: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type UpsertEquityByAmountInput = {
  assetLegalName: Scalars['String']['input'];
  investmentDate: Scalars['Date']['input'];
  investorLegalEntityId: Scalars['ID']['input'];
  issuerLegalEntityId: Scalars['ID']['input'];
  roundType: EquityRoundType;
  totalPaid: MoneyInput;
};

export type UpsertEquityInput = {
  assetLegalName: Scalars['String']['input'];
  investmentDate: Scalars['Date']['input'];
  investorLegalEntityId: Scalars['ID']['input'];
  issuerLegalEntityId: Scalars['ID']['input'];
  numAssets: Scalars['Float']['input'];
  pricePerShare: MoneyInput;
  roundType: EquityRoundType;
  totalPaid?: InputMaybe<MoneyInput>;
};

export type UpsertInvestmentAndNoteResponse = {
  __typename?: 'UpsertInvestmentAndNoteResponse';
  note?: Maybe<Note>;
  transactionId?: Maybe<Scalars['ID']['output']>;
};

export type UpsertSecondaryInput = {
  assetName: Scalars['String']['input'];
  investmentDate: Scalars['Date']['input'];
  investorLegalEntityId: Scalars['ID']['input'];
  issuerLegalEntityId: Scalars['ID']['input'];
  price: MoneyInput;
  sellerLegalEntityId: Scalars['ID']['input'];
  shares: Scalars['Float']['input'];
  totalPaid: MoneyInput;
};

export type UpsertSpvInput = {
  investmentDate: Scalars['Date']['input'];
  investorLegalEntityId: Scalars['ID']['input'];
  issuerLegalEntityId: Scalars['ID']['input'];
  roundType: EquityRoundType;
  spvLegalEntityId: Scalars['ID']['input'];
  totalPaid: MoneyInput;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  defaultTeam: Team;
  /** @deprecated Use username */
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  hasAccess: Scalars['Boolean']['output'];
  hasCompletedRegistration: Scalars['Boolean']['output'];
  hasPublishedToDealFeed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isPlatformAdmin: Scalars['Boolean']['output'];
  lastRemindedAt?: Maybe<Scalars['DateTime']['output']>;
  nemoToken: Scalars['String']['output'];
  permission: PermissionSummary;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  publicProfile?: Maybe<Profile>;
  secondaryEmails: Array<Scalars['EmailAddress']['output']>;
  teamHasDeals: Scalars['Boolean']['output'];
  teams: Array<Team>;
  updatedAt: Scalars['DateTime']['output'];
  userSettings: UserSettings;
  username: Scalars['String']['output'];
};

export type UserMutations = {
  __typename?: 'UserMutations';
  addEmailAddress: User;
  addPhoneNumber: User;
  create: User;
  createInTeam: User;
  fillOutUserEmailOnlyAccount: User;
  revokeAccess: User;
  setLastRemindedAt: User;
  updatePublicProfile: User;
  updateUsername: User;
};

export type UserMutationsAddEmailAddressArgs = {
  input: AddEmailAddressInput;
};

export type UserMutationsAddPhoneNumberArgs = {
  input: AddPhoneNumberInput;
};

export type UserMutationsCreateArgs = {
  input: CreateUserInput;
};

export type UserMutationsCreateInTeamArgs = {
  input: CreateUserInTeamInput;
};

export type UserMutationsFillOutUserEmailOnlyAccountArgs = {
  input: FillOutUserEmailOnlyAccountInput;
};

export type UserMutationsRevokeAccessArgs = {
  input: RevokeAccessInput;
};

export type UserMutationsSetLastRemindedAtArgs = {
  input: SetLastRemindedAtInput;
};

export type UserMutationsUpdatePublicProfileArgs = {
  input: UpdatePublicProfileInput;
};

export type UserMutationsUpdateUsernameArgs = {
  input: UpdateUsernameInput;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  roleId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  dealFeedDefaultShared: Scalars['Boolean']['output'];
  dealFeedLastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  dealFeedShowIntroCallout: ShowHide;
  discoverableInConnections: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  notificationsLastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  notifyCompletedDeals: Scalars['Boolean']['output'];
  notifyCuriousEmail: Scalars['Boolean']['output'];
  notifyCuriousWhatsapp: Scalars['Boolean']['output'];
  notifyLikesEmail: Scalars['Boolean']['output'];
  notifyLikesWhatsapp: Scalars['Boolean']['output'];
  notifySameDealEmail: Scalars['Boolean']['output'];
  notifySameDealWhatsapp: Scalars['Boolean']['output'];
  promptProfileSetup: Scalars['Boolean']['output'];
};

export type UserSettingsMutations = {
  __typename?: 'UserSettingsMutations';
  updateByUserId: UserSettings;
};

export type UserSettingsMutationsUpdateByUserIdArgs = {
  args: UserSettingsUpdateInput;
  userId: Scalars['ID']['input'];
};

export type UserSettingsUpdateInput = {
  dealFeedDefaultShared?: InputMaybe<Scalars['Boolean']['input']>;
  dealFeedShowIntroCallout?: InputMaybe<ShowHide>;
  discoverableInConnections?: InputMaybe<Scalars['Boolean']['input']>;
  notifyCompletedDeals?: InputMaybe<Scalars['Boolean']['input']>;
  notifyCuriousEmail?: InputMaybe<Scalars['Boolean']['input']>;
  notifyCuriousWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  notifyLikesEmail?: InputMaybe<Scalars['Boolean']['input']>;
  notifyLikesWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  notifySameDealEmail?: InputMaybe<Scalars['Boolean']['input']>;
  notifySameDealWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  promptProfileSetup?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Valuation = {
  __typename?: 'Valuation';
  valuationType?: Maybe<ValuationType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum ValuationType {
  POST_MONEY = 'POST_MONEY',
  PRE_MONEY = 'PRE_MONEY',
}

export type NotificationDisplayFieldsFragment = {
  __typename?: 'NotificationGroup';
  id: string;
  type: NotificationType;
  updatedAt: string;
  nemoSubmission?: {
    __typename?: 'NemoSubmission';
    id: string;
    companyName?: string | null;
    companyUrl?: string | null;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    sharedToDealFeedAt?: string | null;
    list?: { __typename?: 'List'; slug: string } | null;
  } | null;
  notifications: Array<
    | {
        __typename: 'DealActivityNotification';
        id: string;
        updatedAt: string;
        change:
          | {
              __typename: 'NemoSubmissionChange';
              id: string;
              createdAt: string;
              section: string;
              creator: {
                __typename?: 'User';
                id: string;
                email: string;
                username: string;
                publicProfile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  slug?: string | null;
                  imageUrl?: string | null;
                  roles: Array<{
                    __typename?: 'ProfileRole';
                    id: string;
                    description?: string | null;
                    entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                  }>;
                } | null;
              };
              diffs: Array<{
                __typename?: 'Diff';
                field: string;
                identifier?: string | null;
                oldValue?: string | null;
                newValue?: string | null;
              }>;
            }
          | {
              __typename: 'NemoSubmissionNote';
              id: string;
              createdAt: string;
              content: string;
              creator: {
                __typename?: 'User';
                id: string;
                email: string;
                username: string;
                publicProfile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  slug?: string | null;
                  imageUrl?: string | null;
                } | null;
              };
            };
      }
    | {
        __typename: 'DealReceivedNotification';
        id: string;
        updatedAt: string;
        nemoSubmission?: {
          __typename?: 'NemoSubmission';
          id: string;
          message?: string | null;
          companyName?: string | null;
          companyUrl?: string | null;
          teaserSummary?: string | null;
          raisedAmount?: number | null;
          roundType: EquityRoundType;
          raisedCurrency?: CurrencyIsoCode | null;
          list?: { __typename?: 'List'; slug: string } | null;
        } | null;
        sender?: {
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            slug?: string | null;
            fullname: string;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        } | null;
      }
    | {
        __typename: 'FounderIntroRequestNotification';
        id: string;
        updatedAt: string;
        founderIntroRequest: {
          __typename?: 'FounderIntroRequest';
          id: string;
          accepted: boolean;
          comment?: string | null;
          profile: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            imageUrl?: string | null;
            slug?: string | null;
            emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          };
          nemoSubmission: {
            __typename?: 'NemoSubmission';
            id: string;
            founders?: Array<{
              __typename?: 'SubmissionFounder';
              id: string;
              isSource?: boolean | null;
              profile?: {
                __typename?: 'Profile';
                id: string;
                fullname: string;
                emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
              } | null;
            }> | null;
          };
        };
      }
    | {
        __typename: 'InteractionNotification';
        id: string;
        updatedAt: string;
        interaction?: {
          __typename?: 'PostInteraction';
          id: string;
          type: InteractionType;
          createdAt: string;
          postId: string;
          dealShareRequests: Array<{
            __typename?: 'DealShareRequest';
            id: string;
            channel: DealflowChannel;
            accepted: boolean;
            whatsAppIntroUrl?: string | null;
          }>;
          creator: {
            __typename?: 'User';
            id: string;
            phoneNumber?: string | null;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              imageUrl?: string | null;
              slug?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          };
        } | null;
      }
    | {
        __typename: 'InviteeJoinedNotification';
        id: string;
        updatedAt: string;
        invitee?: {
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            slug?: string | null;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        } | null;
      }
    | {
        __typename: 'ReceivedDealflowAccessNotification';
        id: string;
        updatedAt: string;
        issuer?: {
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            slug?: string | null;
            fullname: string;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        } | null;
      }
  >;
};

export type MyNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type MyNotificationsQuery = {
  __typename?: 'Query';
  notificationGroups: Array<{
    __typename?: 'NotificationGroup';
    id: string;
    type: NotificationType;
    updatedAt: string;
    nemoSubmission?: {
      __typename?: 'NemoSubmission';
      id: string;
      companyName?: string | null;
      companyUrl?: string | null;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      createdAt: string;
      companyCity?: string | null;
      companyCountry?: string | null;
      teaserSummary?: string | null;
      sharedToDealFeedAt?: string | null;
      list?: { __typename?: 'List'; slug: string } | null;
    } | null;
    notifications: Array<
      | {
          __typename: 'DealActivityNotification';
          id: string;
          updatedAt: string;
          change:
            | {
                __typename: 'NemoSubmissionChange';
                id: string;
                createdAt: string;
                section: string;
                creator: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  username: string;
                  publicProfile?: {
                    __typename?: 'Profile';
                    id: string;
                    fullname: string;
                    slug?: string | null;
                    imageUrl?: string | null;
                    roles: Array<{
                      __typename?: 'ProfileRole';
                      id: string;
                      description?: string | null;
                      entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                    }>;
                  } | null;
                };
                diffs: Array<{
                  __typename?: 'Diff';
                  field: string;
                  identifier?: string | null;
                  oldValue?: string | null;
                  newValue?: string | null;
                }>;
              }
            | {
                __typename: 'NemoSubmissionNote';
                id: string;
                createdAt: string;
                content: string;
                creator: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  username: string;
                  publicProfile?: {
                    __typename?: 'Profile';
                    id: string;
                    fullname: string;
                    slug?: string | null;
                    imageUrl?: string | null;
                  } | null;
                };
              };
        }
      | {
          __typename: 'DealReceivedNotification';
          id: string;
          updatedAt: string;
          nemoSubmission?: {
            __typename?: 'NemoSubmission';
            id: string;
            message?: string | null;
            companyName?: string | null;
            companyUrl?: string | null;
            teaserSummary?: string | null;
            raisedAmount?: number | null;
            roundType: EquityRoundType;
            raisedCurrency?: CurrencyIsoCode | null;
            list?: { __typename?: 'List'; slug: string } | null;
          } | null;
          sender?: {
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              slug?: string | null;
              fullname: string;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          } | null;
        }
      | {
          __typename: 'FounderIntroRequestNotification';
          id: string;
          updatedAt: string;
          founderIntroRequest: {
            __typename?: 'FounderIntroRequest';
            id: string;
            accepted: boolean;
            comment?: string | null;
            profile: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              imageUrl?: string | null;
              slug?: string | null;
              emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            };
            nemoSubmission: {
              __typename?: 'NemoSubmission';
              id: string;
              founders?: Array<{
                __typename?: 'SubmissionFounder';
                id: string;
                isSource?: boolean | null;
                profile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
                } | null;
              }> | null;
            };
          };
        }
      | {
          __typename: 'InteractionNotification';
          id: string;
          updatedAt: string;
          interaction?: {
            __typename?: 'PostInteraction';
            id: string;
            type: InteractionType;
            createdAt: string;
            postId: string;
            dealShareRequests: Array<{
              __typename?: 'DealShareRequest';
              id: string;
              channel: DealflowChannel;
              accepted: boolean;
              whatsAppIntroUrl?: string | null;
            }>;
            creator: {
              __typename?: 'User';
              id: string;
              phoneNumber?: string | null;
              publicProfile?: {
                __typename?: 'Profile';
                id: string;
                fullname: string;
                imageUrl?: string | null;
                slug?: string | null;
                roles: Array<{
                  __typename?: 'ProfileRole';
                  id: string;
                  description?: string | null;
                  entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                }>;
              } | null;
            };
          } | null;
        }
      | {
          __typename: 'InviteeJoinedNotification';
          id: string;
          updatedAt: string;
          invitee?: {
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              slug?: string | null;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          } | null;
        }
      | {
          __typename: 'ReceivedDealflowAccessNotification';
          id: string;
          updatedAt: string;
          issuer?: {
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              slug?: string | null;
              fullname: string;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          } | null;
        }
    >;
  }>;
};

export type NotificationsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NotificationsSubscriptionSubscription = {
  __typename?: 'Subscription';
  newNotification: {
    __typename?: 'NotificationGroup';
    id: string;
    type: NotificationType;
    updatedAt: string;
    nemoSubmission?: {
      __typename?: 'NemoSubmission';
      id: string;
      companyName?: string | null;
      companyUrl?: string | null;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      createdAt: string;
      companyCity?: string | null;
      companyCountry?: string | null;
      teaserSummary?: string | null;
      sharedToDealFeedAt?: string | null;
      list?: { __typename?: 'List'; slug: string } | null;
    } | null;
    notifications: Array<
      | {
          __typename: 'DealActivityNotification';
          id: string;
          updatedAt: string;
          change:
            | {
                __typename: 'NemoSubmissionChange';
                id: string;
                createdAt: string;
                section: string;
                creator: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  username: string;
                  publicProfile?: {
                    __typename?: 'Profile';
                    id: string;
                    fullname: string;
                    slug?: string | null;
                    imageUrl?: string | null;
                    roles: Array<{
                      __typename?: 'ProfileRole';
                      id: string;
                      description?: string | null;
                      entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                    }>;
                  } | null;
                };
                diffs: Array<{
                  __typename?: 'Diff';
                  field: string;
                  identifier?: string | null;
                  oldValue?: string | null;
                  newValue?: string | null;
                }>;
              }
            | {
                __typename: 'NemoSubmissionNote';
                id: string;
                createdAt: string;
                content: string;
                creator: {
                  __typename?: 'User';
                  id: string;
                  email: string;
                  username: string;
                  publicProfile?: {
                    __typename?: 'Profile';
                    id: string;
                    fullname: string;
                    slug?: string | null;
                    imageUrl?: string | null;
                  } | null;
                };
              };
        }
      | {
          __typename: 'DealReceivedNotification';
          id: string;
          updatedAt: string;
          nemoSubmission?: {
            __typename?: 'NemoSubmission';
            id: string;
            message?: string | null;
            companyName?: string | null;
            companyUrl?: string | null;
            teaserSummary?: string | null;
            raisedAmount?: number | null;
            roundType: EquityRoundType;
            raisedCurrency?: CurrencyIsoCode | null;
            list?: { __typename?: 'List'; slug: string } | null;
          } | null;
          sender?: {
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              slug?: string | null;
              fullname: string;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          } | null;
        }
      | {
          __typename: 'FounderIntroRequestNotification';
          id: string;
          updatedAt: string;
          founderIntroRequest: {
            __typename?: 'FounderIntroRequest';
            id: string;
            accepted: boolean;
            comment?: string | null;
            profile: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              imageUrl?: string | null;
              slug?: string | null;
              emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            };
            nemoSubmission: {
              __typename?: 'NemoSubmission';
              id: string;
              founders?: Array<{
                __typename?: 'SubmissionFounder';
                id: string;
                isSource?: boolean | null;
                profile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
                } | null;
              }> | null;
            };
          };
        }
      | {
          __typename: 'InteractionNotification';
          id: string;
          updatedAt: string;
          interaction?: {
            __typename?: 'PostInteraction';
            id: string;
            type: InteractionType;
            createdAt: string;
            postId: string;
            dealShareRequests: Array<{
              __typename?: 'DealShareRequest';
              id: string;
              channel: DealflowChannel;
              accepted: boolean;
              whatsAppIntroUrl?: string | null;
            }>;
            creator: {
              __typename?: 'User';
              id: string;
              phoneNumber?: string | null;
              publicProfile?: {
                __typename?: 'Profile';
                id: string;
                fullname: string;
                imageUrl?: string | null;
                slug?: string | null;
                roles: Array<{
                  __typename?: 'ProfileRole';
                  id: string;
                  description?: string | null;
                  entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                }>;
              } | null;
            };
          } | null;
        }
      | {
          __typename: 'InviteeJoinedNotification';
          id: string;
          updatedAt: string;
          invitee?: {
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              slug?: string | null;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          } | null;
        }
      | {
          __typename: 'ReceivedDealflowAccessNotification';
          id: string;
          updatedAt: string;
          issuer?: {
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              slug?: string | null;
              fullname: string;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          } | null;
        }
    >;
  };
};

export type NotificationsCountQueryVariables = Exact<{
  since?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type NotificationsCountQuery = { __typename?: 'Query'; notificationsCount: number };

export type NotificationsCountSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NotificationsCountSubscriptionSubscription = {
  __typename?: 'Subscription';
  newNotification: { __typename?: 'NotificationGroup'; id: string };
};

export type AcceptDealShareRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AcceptDealShareRequestMutation = {
  __typename?: 'Mutation';
  dealShareRequest: {
    __typename?: 'DealShareRequestMutations';
    accept?: {
      __typename?: 'DealShareRequest';
      id: string;
      accepted: boolean;
      whatsAppIntroUrl?: string | null;
    } | null;
  };
};

export type AdminRoundsToCheckQueryVariables = Exact<{ [key: string]: never }>;

export type AdminRoundsToCheckQuery = {
  __typename?: 'Query';
  adminRoundsToCheck: Array<{
    __typename?: 'Round';
    id: string;
    roundType: EquityRoundType;
    lastCheckedAt?: string | null;
    company: { __typename?: 'LegalEntity'; id: string; displayName?: string | null };
    team: { __typename?: 'Team'; id: string; name: string };
  }>;
};

export type ProfilesMatchSuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilesMatchSuggestionsQuery = {
  __typename?: 'Query';
  profilesMatchSuggestions: Array<{
    __typename?: 'ProfileMatchSuggestion';
    id: string;
    publicProfile: {
      __typename?: 'Profile';
      id: string;
      type?: LegalEntityType | null;
      slug?: string | null;
      fullname: string;
      imageUrl?: string | null;
      description: string;
      personalWebsite?: string | null;
      city?: string | null;
      country?: string | null;
      socialLinks: Array<{ __typename?: 'ProfileSocialLink'; url: string }>;
      emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
    };
    privateProfile: {
      __typename?: 'Profile';
      id: string;
      slug?: string | null;
      type?: LegalEntityType | null;
      fullname: string;
      imageUrl?: string | null;
      description: string;
      personalWebsite?: string | null;
      city?: string | null;
      country?: string | null;
      team?: { __typename?: 'Team'; name: string } | null;
      socialLinks: Array<{ __typename?: 'ProfileSocialLink'; url: string }>;
      emails: Array<{ __typename?: 'ProfileEmail'; email: string }>;
    };
    team: { __typename?: 'Team'; id: string; name: string };
  }>;
};

export type AcceptMatchSuggestionMutationVariables = Exact<{
  input: UpdateSuggestionInput;
}>;

export type AcceptMatchSuggestionMutation = {
  __typename?: 'Mutation';
  profile: {
    __typename?: 'ProfileMutations';
    acceptMatchSuggestion: { __typename?: 'ProfileMatchSuggestion'; id: string };
  };
};

export type RejectMatchSuggestionMutationVariables = Exact<{
  input: UpdateSuggestionInput;
}>;

export type RejectMatchSuggestionMutation = {
  __typename?: 'Mutation';
  profile: {
    __typename?: 'ProfileMutations';
    rejectMatchSuggestion: { __typename?: 'ProfileMatchSuggestion'; id: string };
  };
};

export type AdminSnoozeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminSnoozeMutation = {
  __typename?: 'Mutation';
  round: { __typename?: 'RoundMutations'; adminSnooze: { __typename?: 'Round'; id: string } };
};

export type AdminMarkNeverPublicMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminMarkNeverPublicMutation = {
  __typename?: 'Mutation';
  round: { __typename?: 'RoundMutations'; adminMarkNeverPublic: { __typename?: 'Round'; id: string } };
};

export type AdminInviteProposalsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminInviteProposalsQuery = {
  __typename?: 'Query';
  inviteProposals: Array<{
    __typename?: 'InviteProposal';
    id: string;
    name?: string | null;
    fund?: string | null;
    status: ProposalStatus;
    createdAt: string;
    comment?: string | null;
    linkedin?: string | null;
    email?: string | null;
    emailVerified: boolean;
    descriptorsGeo: Array<string>;
    descriptorsInvestorType: Array<string>;
    descriptorsMiscTags: Array<string>;
    descriptorsStage: Array<string>;
    creator: { __typename?: 'User'; username: string; email: string };
  }>;
};

export type UpdateInviteProposalMutationVariables = Exact<{
  input: UpdateInviteProposalInput;
}>;

export type UpdateInviteProposalMutation = {
  __typename?: 'Mutation';
  inviteProposal: { __typename?: 'InviteProposalMutations'; update: { __typename?: 'InviteProposal'; id: string } };
};

export type CapTableQueryVariables = Exact<{
  legalEntityId: Scalars['ID']['input'];
  asOfDate: Scalars['Date']['input'];
}>;

export type CapTableQuery = {
  __typename?: 'Query';
  legalEntity?: {
    __typename?: 'LegalEntity';
    issuedAssets: Array<{
      __typename?: 'HeldAsset';
      numAssets?: number | null;
      asset: { __typename?: 'BaseAsset'; id: string; type: AssetType; name: string };
      assetHolder: {
        __typename?: 'LegalEntity';
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
      };
    }>;
  } | null;
};

export type ChangeLogQueryVariables = Exact<{
  portfolioId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ChangeLogQuery = {
  __typename?: 'Query';
  changeLogs: Array<{
    __typename?: 'ChangeLog';
    id: string;
    createdAt: string;
    type: string;
    message: string;
    createdBy: { __typename?: 'User'; id: string; email: string };
    legalEntity: {
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    };
  }>;
};

export type CompanyProfileFieldsFragment = {
  __typename?: 'CompanyProfile';
  id: string;
  name: string;
  slug?: string | null;
  otherNames: Array<string>;
  website?: string | null;
  description: string;
  themes: Array<string>;
  country?: string | null;
  city?: string | null;
  status: CompanyStatus;
  yearFounded?: number | null;
  teamId?: string | null;
};

export type ProfileFieldsFragment = {
  __typename?: 'Profile';
  teamId?: string | null;
  id: string;
  firstInvested?: string | null;
  type?: LegalEntityType | null;
  fullname: string;
  slug?: string | null;
  imageUrl?: string | null;
  country?: string | null;
  city?: string | null;
  description: string;
  personalWebsite?: string | null;
  linkedin?: string | null;
  locations: Array<string>;
  themes: Array<string>;
  stages: Array<string>;
  markets: Array<string>;
  customers: Array<string>;
  businessModels: Array<string>;
  totalInvested?: number | null;
  totalValue?: number | null;
  lastInteractedWith?: string | null;
  deals: Array<{
    __typename?: 'NemoSubmission';
    createdAt: string;
    roundType: EquityRoundType;
    companyName?: string | null;
    raisedAmount?: number | null;
    raisedCurrency?: CurrencyIsoCode | null;
    creator: { __typename?: 'User'; username: string };
    list?: { __typename?: 'List'; slug: string } | null;
    sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
  }>;
  investments?: Array<{
    __typename?: 'PublicInvestment';
    id: string;
    roundType: EquityRoundType;
    date?: string | null;
    companyName: string;
    companyProfile?: {
      __typename?: 'CompanyProfile';
      id: string;
      name: string;
      slug?: string | null;
      otherNames: Array<string>;
      website?: string | null;
      description: string;
      themes: Array<string>;
      country?: string | null;
      city?: string | null;
      status: CompanyStatus;
      yearFounded?: number | null;
      teamId?: string | null;
      founders?: Array<{
        __typename?: 'FounderProfile';
        id: string;
        fullname: string;
        linkedin?: string | null;
      }> | null;
    } | null;
  }> | null;
  socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
  roles: Array<{
    __typename?: 'ProfileRole';
    id: string;
    description?: string | null;
    entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
  }>;
  emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
  azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
  dealsSourced: Array<{
    __typename?: 'NemoSubmission';
    id: string;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    status: DealStatus;
    teamId: string;
    userTags: Array<string>;
    markets: Array<string>;
    sharedToDealFeedAt?: string | null;
    seenBy: Array<{
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    } | null;
  }>;
};

export type ProfileFieldsNestedFragment = {
  __typename?: 'Profile';
  teamId?: string | null;
  id: string;
  firstInvested?: string | null;
  type?: LegalEntityType | null;
  fullname: string;
  slug?: string | null;
  imageUrl?: string | null;
  country?: string | null;
  city?: string | null;
  description: string;
  personalWebsite?: string | null;
  linkedin?: string | null;
  locations: Array<string>;
  themes: Array<string>;
  stages: Array<string>;
  markets: Array<string>;
  customers: Array<string>;
  businessModels: Array<string>;
  totalInvested?: number | null;
  totalValue?: number | null;
  lastInteractedWith?: string | null;
  privateProfiles: Array<{
    __typename?: 'Profile';
    teamId?: string | null;
    id: string;
    firstInvested?: string | null;
    type?: LegalEntityType | null;
    fullname: string;
    slug?: string | null;
    imageUrl?: string | null;
    country?: string | null;
    city?: string | null;
    description: string;
    personalWebsite?: string | null;
    linkedin?: string | null;
    locations: Array<string>;
    themes: Array<string>;
    stages: Array<string>;
    markets: Array<string>;
    customers: Array<string>;
    businessModels: Array<string>;
    totalInvested?: number | null;
    totalValue?: number | null;
    lastInteractedWith?: string | null;
    deals: Array<{
      __typename?: 'NemoSubmission';
      createdAt: string;
      roundType: EquityRoundType;
      companyName?: string | null;
      raisedAmount?: number | null;
      raisedCurrency?: CurrencyIsoCode | null;
      creator: { __typename?: 'User'; username: string };
      list?: { __typename?: 'List'; slug: string } | null;
      sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
    }>;
    investments?: Array<{
      __typename?: 'PublicInvestment';
      id: string;
      roundType: EquityRoundType;
      date?: string | null;
      companyName: string;
      companyProfile?: {
        __typename?: 'CompanyProfile';
        id: string;
        name: string;
        slug?: string | null;
        otherNames: Array<string>;
        website?: string | null;
        description: string;
        themes: Array<string>;
        country?: string | null;
        city?: string | null;
        status: CompanyStatus;
        yearFounded?: number | null;
        teamId?: string | null;
        founders?: Array<{
          __typename?: 'FounderProfile';
          id: string;
          fullname: string;
          linkedin?: string | null;
        }> | null;
      } | null;
    }> | null;
    socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
    roles: Array<{
      __typename?: 'ProfileRole';
      id: string;
      description?: string | null;
      entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
    }>;
    emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
    azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
    dealsSourced: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      teaserSummary?: string | null;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      createdAt: string;
      status: DealStatus;
      teamId: string;
      userTags: Array<string>;
      markets: Array<string>;
      sharedToDealFeedAt?: string | null;
      seenBy: Array<{
        __typename?: 'User';
        id: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        } | null;
      }>;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
      } | null;
    }>;
  }>;
  deals: Array<{
    __typename?: 'NemoSubmission';
    createdAt: string;
    roundType: EquityRoundType;
    companyName?: string | null;
    raisedAmount?: number | null;
    raisedCurrency?: CurrencyIsoCode | null;
    creator: { __typename?: 'User'; username: string };
    list?: { __typename?: 'List'; slug: string } | null;
    sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
  }>;
  investments?: Array<{
    __typename?: 'PublicInvestment';
    id: string;
    roundType: EquityRoundType;
    date?: string | null;
    companyName: string;
    companyProfile?: {
      __typename?: 'CompanyProfile';
      id: string;
      name: string;
      slug?: string | null;
      otherNames: Array<string>;
      website?: string | null;
      description: string;
      themes: Array<string>;
      country?: string | null;
      city?: string | null;
      status: CompanyStatus;
      yearFounded?: number | null;
      teamId?: string | null;
      founders?: Array<{
        __typename?: 'FounderProfile';
        id: string;
        fullname: string;
        linkedin?: string | null;
      }> | null;
    } | null;
  }> | null;
  socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
  roles: Array<{
    __typename?: 'ProfileRole';
    id: string;
    description?: string | null;
    entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
  }>;
  emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
  azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
  dealsSourced: Array<{
    __typename?: 'NemoSubmission';
    id: string;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    status: DealStatus;
    teamId: string;
    userTags: Array<string>;
    markets: Array<string>;
    sharedToDealFeedAt?: string | null;
    seenBy: Array<{
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    } | null;
  }>;
};

export type PublicProfileBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type PublicProfileBySlugQuery = {
  __typename?: 'Query';
  publicProfileBySlug?:
    | {
        __typename?: 'CompanyProfile';
        id: string;
        name: string;
        slug?: string | null;
        otherNames: Array<string>;
        website?: string | null;
        description: string;
        themes: Array<string>;
        country?: string | null;
        city?: string | null;
        status: CompanyStatus;
        yearFounded?: number | null;
        teamId?: string | null;
      }
    | {
        __typename?: 'Profile';
        investmentStatus?: InvestmentStatus | null;
        teamId?: string | null;
        id: string;
        firstInvested?: string | null;
        type?: LegalEntityType | null;
        fullname: string;
        slug?: string | null;
        imageUrl?: string | null;
        country?: string | null;
        city?: string | null;
        description: string;
        personalWebsite?: string | null;
        linkedin?: string | null;
        locations: Array<string>;
        themes: Array<string>;
        stages: Array<string>;
        markets: Array<string>;
        customers: Array<string>;
        businessModels: Array<string>;
        totalInvested?: number | null;
        totalValue?: number | null;
        lastInteractedWith?: string | null;
        legalEntity?: { __typename?: 'LegalEntity'; id: string } | null;
        investingEntities: Array<{ __typename?: 'LegalEntity'; id: string; displayName?: string | null }>;
        profileInvestments: Array<{
          __typename?: 'ProfileInvestment';
          id: string;
          roundType: EquityRoundType;
          date?: string | null;
          company: {
            __typename?: 'Profile';
            teamId?: string | null;
            id: string;
            firstInvested?: string | null;
            type?: LegalEntityType | null;
            fullname: string;
            slug?: string | null;
            imageUrl?: string | null;
            country?: string | null;
            city?: string | null;
            description: string;
            personalWebsite?: string | null;
            linkedin?: string | null;
            locations: Array<string>;
            themes: Array<string>;
            stages: Array<string>;
            markets: Array<string>;
            customers: Array<string>;
            businessModels: Array<string>;
            totalInvested?: number | null;
            totalValue?: number | null;
            lastInteractedWith?: string | null;
            deals: Array<{
              __typename?: 'NemoSubmission';
              createdAt: string;
              roundType: EquityRoundType;
              companyName?: string | null;
              raisedAmount?: number | null;
              raisedCurrency?: CurrencyIsoCode | null;
              creator: { __typename?: 'User'; username: string };
              list?: { __typename?: 'List'; slug: string } | null;
              sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
            }>;
            investments?: Array<{
              __typename?: 'PublicInvestment';
              id: string;
              roundType: EquityRoundType;
              date?: string | null;
              companyName: string;
              companyProfile?: {
                __typename?: 'CompanyProfile';
                id: string;
                name: string;
                slug?: string | null;
                otherNames: Array<string>;
                website?: string | null;
                description: string;
                themes: Array<string>;
                country?: string | null;
                city?: string | null;
                status: CompanyStatus;
                yearFounded?: number | null;
                teamId?: string | null;
                founders?: Array<{
                  __typename?: 'FounderProfile';
                  id: string;
                  fullname: string;
                  linkedin?: string | null;
                }> | null;
              } | null;
            }> | null;
            socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
            }>;
            emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
            azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
            dealsSourced: Array<{
              __typename?: 'NemoSubmission';
              id: string;
              companyName?: string | null;
              companyUrl?: string | null;
              companyCity?: string | null;
              companyCountry?: string | null;
              teaserSummary?: string | null;
              roundType: EquityRoundType;
              raisedCurrency?: CurrencyIsoCode | null;
              raisedAmount?: number | null;
              createdAt: string;
              status: DealStatus;
              teamId: string;
              userTags: Array<string>;
              markets: Array<string>;
              sharedToDealFeedAt?: string | null;
              seenBy: Array<{
                __typename?: 'User';
                id: string;
                publicProfile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  imageUrl?: string | null;
                  roles: Array<{
                    __typename?: 'ProfileRole';
                    id: string;
                    description?: string | null;
                    entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                  }>;
                } | null;
              }>;
              list?: { __typename?: 'List'; id: string; slug: string } | null;
              assignee?: {
                __typename?: 'User';
                id: string;
                email: string;
                username: string;
                publicProfile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  imageUrl?: string | null;
                } | null;
              } | null;
            }>;
          };
        }>;
        profileInvestors: Array<{
          __typename?: 'ProfileInvestor';
          id: string;
          roundType: EquityRoundType;
          date?: string | null;
          investor: {
            __typename?: 'Profile';
            teamId?: string | null;
            id: string;
            firstInvested?: string | null;
            type?: LegalEntityType | null;
            fullname: string;
            slug?: string | null;
            imageUrl?: string | null;
            country?: string | null;
            city?: string | null;
            description: string;
            personalWebsite?: string | null;
            linkedin?: string | null;
            locations: Array<string>;
            themes: Array<string>;
            stages: Array<string>;
            markets: Array<string>;
            customers: Array<string>;
            businessModels: Array<string>;
            totalInvested?: number | null;
            totalValue?: number | null;
            lastInteractedWith?: string | null;
            deals: Array<{
              __typename?: 'NemoSubmission';
              createdAt: string;
              roundType: EquityRoundType;
              companyName?: string | null;
              raisedAmount?: number | null;
              raisedCurrency?: CurrencyIsoCode | null;
              creator: { __typename?: 'User'; username: string };
              list?: { __typename?: 'List'; slug: string } | null;
              sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
            }>;
            investments?: Array<{
              __typename?: 'PublicInvestment';
              id: string;
              roundType: EquityRoundType;
              date?: string | null;
              companyName: string;
              companyProfile?: {
                __typename?: 'CompanyProfile';
                id: string;
                name: string;
                slug?: string | null;
                otherNames: Array<string>;
                website?: string | null;
                description: string;
                themes: Array<string>;
                country?: string | null;
                city?: string | null;
                status: CompanyStatus;
                yearFounded?: number | null;
                teamId?: string | null;
                founders?: Array<{
                  __typename?: 'FounderProfile';
                  id: string;
                  fullname: string;
                  linkedin?: string | null;
                }> | null;
              } | null;
            }> | null;
            socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
            }>;
            emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
            azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
            dealsSourced: Array<{
              __typename?: 'NemoSubmission';
              id: string;
              companyName?: string | null;
              companyUrl?: string | null;
              companyCity?: string | null;
              companyCountry?: string | null;
              teaserSummary?: string | null;
              roundType: EquityRoundType;
              raisedCurrency?: CurrencyIsoCode | null;
              raisedAmount?: number | null;
              createdAt: string;
              status: DealStatus;
              teamId: string;
              userTags: Array<string>;
              markets: Array<string>;
              sharedToDealFeedAt?: string | null;
              seenBy: Array<{
                __typename?: 'User';
                id: string;
                publicProfile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  imageUrl?: string | null;
                  roles: Array<{
                    __typename?: 'ProfileRole';
                    id: string;
                    description?: string | null;
                    entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                  }>;
                } | null;
              }>;
              list?: { __typename?: 'List'; id: string; slug: string } | null;
              assignee?: {
                __typename?: 'User';
                id: string;
                email: string;
                username: string;
                publicProfile?: {
                  __typename?: 'Profile';
                  id: string;
                  fullname: string;
                  imageUrl?: string | null;
                } | null;
              } | null;
            }>;
          };
        }>;
        privateProfiles: Array<{
          __typename?: 'Profile';
          teamId?: string | null;
          id: string;
          firstInvested?: string | null;
          type?: LegalEntityType | null;
          fullname: string;
          slug?: string | null;
          imageUrl?: string | null;
          country?: string | null;
          city?: string | null;
          description: string;
          personalWebsite?: string | null;
          linkedin?: string | null;
          locations: Array<string>;
          themes: Array<string>;
          stages: Array<string>;
          markets: Array<string>;
          customers: Array<string>;
          businessModels: Array<string>;
          totalInvested?: number | null;
          totalValue?: number | null;
          lastInteractedWith?: string | null;
          deals: Array<{
            __typename?: 'NemoSubmission';
            createdAt: string;
            roundType: EquityRoundType;
            companyName?: string | null;
            raisedAmount?: number | null;
            raisedCurrency?: CurrencyIsoCode | null;
            creator: { __typename?: 'User'; username: string };
            list?: { __typename?: 'List'; slug: string } | null;
            sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
          }>;
          investments?: Array<{
            __typename?: 'PublicInvestment';
            id: string;
            roundType: EquityRoundType;
            date?: string | null;
            companyName: string;
            companyProfile?: {
              __typename?: 'CompanyProfile';
              id: string;
              name: string;
              slug?: string | null;
              otherNames: Array<string>;
              website?: string | null;
              description: string;
              themes: Array<string>;
              country?: string | null;
              city?: string | null;
              status: CompanyStatus;
              yearFounded?: number | null;
              teamId?: string | null;
              founders?: Array<{
                __typename?: 'FounderProfile';
                id: string;
                fullname: string;
                linkedin?: string | null;
              }> | null;
            } | null;
          }> | null;
          socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
          }>;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
          azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
          dealsSourced: Array<{
            __typename?: 'NemoSubmission';
            id: string;
            companyName?: string | null;
            companyUrl?: string | null;
            companyCity?: string | null;
            companyCountry?: string | null;
            teaserSummary?: string | null;
            roundType: EquityRoundType;
            raisedCurrency?: CurrencyIsoCode | null;
            raisedAmount?: number | null;
            createdAt: string;
            status: DealStatus;
            teamId: string;
            userTags: Array<string>;
            markets: Array<string>;
            sharedToDealFeedAt?: string | null;
            seenBy: Array<{
              __typename?: 'User';
              id: string;
              publicProfile?: {
                __typename?: 'Profile';
                id: string;
                fullname: string;
                imageUrl?: string | null;
                roles: Array<{
                  __typename?: 'ProfileRole';
                  id: string;
                  description?: string | null;
                  entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                }>;
              } | null;
            }>;
            list?: { __typename?: 'List'; id: string; slug: string } | null;
            assignee?: {
              __typename?: 'User';
              id: string;
              email: string;
              username: string;
              publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
            } | null;
          }>;
        }>;
        deals: Array<{
          __typename?: 'NemoSubmission';
          createdAt: string;
          roundType: EquityRoundType;
          companyName?: string | null;
          raisedAmount?: number | null;
          raisedCurrency?: CurrencyIsoCode | null;
          creator: { __typename?: 'User'; username: string };
          list?: { __typename?: 'List'; slug: string } | null;
          sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
        }>;
        investments?: Array<{
          __typename?: 'PublicInvestment';
          id: string;
          roundType: EquityRoundType;
          date?: string | null;
          companyName: string;
          companyProfile?: {
            __typename?: 'CompanyProfile';
            id: string;
            name: string;
            slug?: string | null;
            otherNames: Array<string>;
            website?: string | null;
            description: string;
            themes: Array<string>;
            country?: string | null;
            city?: string | null;
            status: CompanyStatus;
            yearFounded?: number | null;
            teamId?: string | null;
            founders?: Array<{
              __typename?: 'FounderProfile';
              id: string;
              fullname: string;
              linkedin?: string | null;
            }> | null;
          } | null;
        }> | null;
        socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
        }>;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
        dealsSourced: Array<{
          __typename?: 'NemoSubmission';
          id: string;
          companyName?: string | null;
          companyUrl?: string | null;
          companyCity?: string | null;
          companyCountry?: string | null;
          teaserSummary?: string | null;
          roundType: EquityRoundType;
          raisedCurrency?: CurrencyIsoCode | null;
          raisedAmount?: number | null;
          createdAt: string;
          status: DealStatus;
          teamId: string;
          userTags: Array<string>;
          markets: Array<string>;
          sharedToDealFeedAt?: string | null;
          seenBy: Array<{
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          }>;
          list?: { __typename?: 'List'; id: string; slug: string } | null;
          assignee?: {
            __typename?: 'User';
            id: string;
            email: string;
            username: string;
            publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
          } | null;
        }>;
      }
    | null;
};

export type PublicProfileByNameOrWebsiteQueryVariables = Exact<{
  input: ProfileByNameOrWebsiteInput;
}>;

export type PublicProfileByNameOrWebsiteQuery = {
  __typename?: 'Query';
  publicProfileByNameOrWebsite?:
    | {
        __typename?: 'CompanyProfile';
        id: string;
        name: string;
        slug?: string | null;
        otherNames: Array<string>;
        website?: string | null;
        description: string;
        themes: Array<string>;
        country?: string | null;
        city?: string | null;
        status: CompanyStatus;
        yearFounded?: number | null;
        teamId?: string | null;
      }
    | { __typename?: 'Profile' }
    | null;
};

export type CreateCompanyProfileMutationVariables = Exact<{
  input: CreateCompanyProfileInput;
}>;

export type CreateCompanyProfileMutation = {
  __typename?: 'Mutation';
  companyProfile: {
    __typename?: 'CompanyProfileMutations';
    create: {
      __typename?: 'CompanyProfile';
      id: string;
      name: string;
      slug?: string | null;
      otherNames: Array<string>;
      website?: string | null;
      description: string;
      themes: Array<string>;
      country?: string | null;
      city?: string | null;
      status: CompanyStatus;
      yearFounded?: number | null;
      teamId?: string | null;
    };
  };
};

export type UpdateInvestmentStatusMutationVariables = Exact<{
  input: UpdateInvestmentStatusInput;
}>;

export type UpdateInvestmentStatusMutation = {
  __typename?: 'Mutation';
  profile: {
    __typename?: 'ProfileMutations';
    updateInvestmentStatus: { __typename?: 'Profile'; id: string; investmentStatus?: InvestmentStatus | null };
  };
};

export type UpdateProfileDescriptorsMutationVariables = Exact<{
  input: UpdateDescriptorsInput;
}>;

export type UpdateProfileDescriptorsMutation = {
  __typename?: 'Mutation';
  profile: {
    __typename?: 'ProfileMutations';
    updateDescriptors: {
      __typename?: 'Profile';
      id: string;
      descriptorsGeo: Array<string>;
      descriptorsInvestorType: Array<string>;
      descriptorsStage: Array<string>;
      descriptorsMiscTags: Array<string>;
    };
  };
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  profile: { __typename?: 'ProfileMutations'; updateProfile: { __typename?: 'Profile'; id: string } };
};

export type FindCompanyProfilesByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type FindCompanyProfilesByNameQuery = {
  __typename?: 'Query';
  findCompanyProfilesByName: Array<{ __typename?: 'CompanyProfile'; id: string; name: string }>;
};

export type GetCompanyProfileByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCompanyProfileByIdQuery = {
  __typename?: 'Query';
  companyProfile: {
    __typename?: 'CompanyProfile';
    id: string;
    name: string;
    slug?: string | null;
    otherNames: Array<string>;
    website?: string | null;
    description: string;
    themes: Array<string>;
    country?: string | null;
    city?: string | null;
    status: CompanyStatus;
    yearFounded?: number | null;
    teamId?: string | null;
    rounds?: Array<{ __typename?: 'PublicRound'; id: string; roundType: EquityRoundType; date?: string | null }> | null;
  };
};

export type GenerateCompanyProfileFieldsQueryVariables = Exact<{
  text: Scalars['String']['input'];
}>;

export type GenerateCompanyProfileFieldsQuery = {
  __typename?: 'Query';
  generateCompanyProfileFields: {
    __typename?: 'CompanyProfileSuggestFields';
    website?: string | null;
    description?: string | null;
    themes: Array<string>;
    country?: string | null;
    city?: string | null;
  };
};

export type DealflowInsightsQueryVariables = Exact<{
  filter: DealflowInsightsFilter;
}>;

export type DealflowInsightsQuery = {
  __typename?: 'Query';
  dealflowInsights: {
    __typename?: 'DealflowInsights';
    totalDealsAdded: number;
    totalDealsConsidering: number;
    totalDealsCommited: number;
    totalDealsInvested: number;
    totalDealsPassed: number;
    totalDealsLost: number;
    chartData: {
      __typename?: 'ChartData';
      labels: Array<string>;
      datasets: Array<{ __typename?: 'Dataset'; label: string; data: Array<number> }>;
    };
  };
};

export type DirectoryQueryVariables = Exact<{
  filter: ProfilesFilter;
  meta?: InputMaybe<ProfilesQueryMeta>;
  orderBy?: InputMaybe<ProfilesOrderBy>;
}>;

export type DirectoryQuery = {
  __typename?: 'Query';
  findManyProfiles: {
    __typename?: 'PaginatedResult';
    totalCount: number;
    profiles: Array<{
      __typename?: 'Profile';
      teamId?: string | null;
      id: string;
      firstInvested?: string | null;
      type?: LegalEntityType | null;
      fullname: string;
      slug?: string | null;
      imageUrl?: string | null;
      country?: string | null;
      city?: string | null;
      description: string;
      personalWebsite?: string | null;
      linkedin?: string | null;
      locations: Array<string>;
      themes: Array<string>;
      stages: Array<string>;
      markets: Array<string>;
      customers: Array<string>;
      businessModels: Array<string>;
      totalInvested?: number | null;
      totalValue?: number | null;
      lastInteractedWith?: string | null;
      emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
      privateProfiles: Array<{
        __typename?: 'Profile';
        teamId?: string | null;
        id: string;
        firstInvested?: string | null;
        type?: LegalEntityType | null;
        fullname: string;
        slug?: string | null;
        imageUrl?: string | null;
        country?: string | null;
        city?: string | null;
        description: string;
        personalWebsite?: string | null;
        linkedin?: string | null;
        locations: Array<string>;
        themes: Array<string>;
        stages: Array<string>;
        markets: Array<string>;
        customers: Array<string>;
        businessModels: Array<string>;
        totalInvested?: number | null;
        totalValue?: number | null;
        lastInteractedWith?: string | null;
        deals: Array<{
          __typename?: 'NemoSubmission';
          createdAt: string;
          roundType: EquityRoundType;
          companyName?: string | null;
          raisedAmount?: number | null;
          raisedCurrency?: CurrencyIsoCode | null;
          creator: { __typename?: 'User'; username: string };
          list?: { __typename?: 'List'; slug: string } | null;
          sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
        }>;
        investments?: Array<{
          __typename?: 'PublicInvestment';
          id: string;
          roundType: EquityRoundType;
          date?: string | null;
          companyName: string;
          companyProfile?: {
            __typename?: 'CompanyProfile';
            id: string;
            name: string;
            slug?: string | null;
            otherNames: Array<string>;
            website?: string | null;
            description: string;
            themes: Array<string>;
            country?: string | null;
            city?: string | null;
            status: CompanyStatus;
            yearFounded?: number | null;
            teamId?: string | null;
            founders?: Array<{
              __typename?: 'FounderProfile';
              id: string;
              fullname: string;
              linkedin?: string | null;
            }> | null;
          } | null;
        }> | null;
        socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
        }>;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
        dealsSourced: Array<{
          __typename?: 'NemoSubmission';
          id: string;
          companyName?: string | null;
          companyUrl?: string | null;
          companyCity?: string | null;
          companyCountry?: string | null;
          teaserSummary?: string | null;
          roundType: EquityRoundType;
          raisedCurrency?: CurrencyIsoCode | null;
          raisedAmount?: number | null;
          createdAt: string;
          status: DealStatus;
          teamId: string;
          userTags: Array<string>;
          markets: Array<string>;
          sharedToDealFeedAt?: string | null;
          seenBy: Array<{
            __typename?: 'User';
            id: string;
            publicProfile?: {
              __typename?: 'Profile';
              id: string;
              fullname: string;
              imageUrl?: string | null;
              roles: Array<{
                __typename?: 'ProfileRole';
                id: string;
                description?: string | null;
                entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
              }>;
            } | null;
          }>;
          list?: { __typename?: 'List'; id: string; slug: string } | null;
          assignee?: {
            __typename?: 'User';
            id: string;
            email: string;
            username: string;
            publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
          } | null;
        }>;
      }>;
      deals: Array<{
        __typename?: 'NemoSubmission';
        createdAt: string;
        roundType: EquityRoundType;
        companyName?: string | null;
        raisedAmount?: number | null;
        raisedCurrency?: CurrencyIsoCode | null;
        creator: { __typename?: 'User'; username: string };
        list?: { __typename?: 'List'; slug: string } | null;
        sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
      }>;
      investments?: Array<{
        __typename?: 'PublicInvestment';
        id: string;
        roundType: EquityRoundType;
        date?: string | null;
        companyName: string;
        companyProfile?: {
          __typename?: 'CompanyProfile';
          id: string;
          name: string;
          slug?: string | null;
          otherNames: Array<string>;
          website?: string | null;
          description: string;
          themes: Array<string>;
          country?: string | null;
          city?: string | null;
          status: CompanyStatus;
          yearFounded?: number | null;
          teamId?: string | null;
          founders?: Array<{
            __typename?: 'FounderProfile';
            id: string;
            fullname: string;
            linkedin?: string | null;
          }> | null;
        } | null;
      }> | null;
      socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
      roles: Array<{
        __typename?: 'ProfileRole';
        id: string;
        description?: string | null;
        entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
      }>;
      dealsSourced: Array<{
        __typename?: 'NemoSubmission';
        id: string;
        companyName?: string | null;
        companyUrl?: string | null;
        companyCity?: string | null;
        companyCountry?: string | null;
        teaserSummary?: string | null;
        roundType: EquityRoundType;
        raisedCurrency?: CurrencyIsoCode | null;
        raisedAmount?: number | null;
        createdAt: string;
        status: DealStatus;
        teamId: string;
        userTags: Array<string>;
        markets: Array<string>;
        sharedToDealFeedAt?: string | null;
        seenBy: Array<{
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        }>;
        list?: { __typename?: 'List'; id: string; slug: string } | null;
        assignee?: {
          __typename?: 'User';
          id: string;
          email: string;
          username: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
        } | null;
      }>;
    }>;
  };
};

export type CoInvestorsQueryVariables = Exact<{
  filter: ProfilesFilter;
  meta?: InputMaybe<ProfilesQueryMeta>;
  orderBy?: InputMaybe<ProfilesOrderBy>;
}>;

export type CoInvestorsQuery = {
  __typename?: 'Query';
  findManyProfiles: {
    __typename?: 'PaginatedResult';
    totalCount: number;
    profiles: Array<{
      __typename?: 'Profile';
      roundTypes: Array<EquityRoundType>;
      teamId?: string | null;
      id: string;
      firstInvested?: string | null;
      type?: LegalEntityType | null;
      fullname: string;
      slug?: string | null;
      imageUrl?: string | null;
      country?: string | null;
      city?: string | null;
      description: string;
      personalWebsite?: string | null;
      linkedin?: string | null;
      locations: Array<string>;
      themes: Array<string>;
      stages: Array<string>;
      markets: Array<string>;
      customers: Array<string>;
      businessModels: Array<string>;
      totalInvested?: number | null;
      totalValue?: number | null;
      lastInteractedWith?: string | null;
      profileInvestments: Array<{
        __typename?: 'ProfileInvestment';
        id: string;
        roundType: EquityRoundType;
        date?: string | null;
        company: {
          __typename?: 'Profile';
          teamId?: string | null;
          id: string;
          firstInvested?: string | null;
          type?: LegalEntityType | null;
          fullname: string;
          slug?: string | null;
          imageUrl?: string | null;
          country?: string | null;
          city?: string | null;
          description: string;
          personalWebsite?: string | null;
          linkedin?: string | null;
          locations: Array<string>;
          themes: Array<string>;
          stages: Array<string>;
          markets: Array<string>;
          customers: Array<string>;
          businessModels: Array<string>;
          totalInvested?: number | null;
          totalValue?: number | null;
          lastInteractedWith?: string | null;
          deals: Array<{
            __typename?: 'NemoSubmission';
            createdAt: string;
            roundType: EquityRoundType;
            companyName?: string | null;
            raisedAmount?: number | null;
            raisedCurrency?: CurrencyIsoCode | null;
            creator: { __typename?: 'User'; username: string };
            list?: { __typename?: 'List'; slug: string } | null;
            sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
          }>;
          investments?: Array<{
            __typename?: 'PublicInvestment';
            id: string;
            roundType: EquityRoundType;
            date?: string | null;
            companyName: string;
            companyProfile?: {
              __typename?: 'CompanyProfile';
              id: string;
              name: string;
              slug?: string | null;
              otherNames: Array<string>;
              website?: string | null;
              description: string;
              themes: Array<string>;
              country?: string | null;
              city?: string | null;
              status: CompanyStatus;
              yearFounded?: number | null;
              teamId?: string | null;
              founders?: Array<{
                __typename?: 'FounderProfile';
                id: string;
                fullname: string;
                linkedin?: string | null;
              }> | null;
            } | null;
          }> | null;
          socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
          }>;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
          azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
          dealsSourced: Array<{
            __typename?: 'NemoSubmission';
            id: string;
            companyName?: string | null;
            companyUrl?: string | null;
            companyCity?: string | null;
            companyCountry?: string | null;
            teaserSummary?: string | null;
            roundType: EquityRoundType;
            raisedCurrency?: CurrencyIsoCode | null;
            raisedAmount?: number | null;
            createdAt: string;
            status: DealStatus;
            teamId: string;
            userTags: Array<string>;
            markets: Array<string>;
            sharedToDealFeedAt?: string | null;
            seenBy: Array<{
              __typename?: 'User';
              id: string;
              publicProfile?: {
                __typename?: 'Profile';
                id: string;
                fullname: string;
                imageUrl?: string | null;
                roles: Array<{
                  __typename?: 'ProfileRole';
                  id: string;
                  description?: string | null;
                  entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
                }>;
              } | null;
            }>;
            list?: { __typename?: 'List'; id: string; slug: string } | null;
            assignee?: {
              __typename?: 'User';
              id: string;
              email: string;
              username: string;
              publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
            } | null;
          }>;
        };
      }>;
      deals: Array<{
        __typename?: 'NemoSubmission';
        createdAt: string;
        roundType: EquityRoundType;
        companyName?: string | null;
        raisedAmount?: number | null;
        raisedCurrency?: CurrencyIsoCode | null;
        creator: { __typename?: 'User'; username: string };
        list?: { __typename?: 'List'; slug: string } | null;
        sourceProfiles: Array<{ __typename?: 'Profile'; slug?: string | null; fullname: string }>;
      }>;
      investments?: Array<{
        __typename?: 'PublicInvestment';
        id: string;
        roundType: EquityRoundType;
        date?: string | null;
        companyName: string;
        companyProfile?: {
          __typename?: 'CompanyProfile';
          id: string;
          name: string;
          slug?: string | null;
          otherNames: Array<string>;
          website?: string | null;
          description: string;
          themes: Array<string>;
          country?: string | null;
          city?: string | null;
          status: CompanyStatus;
          yearFounded?: number | null;
          teamId?: string | null;
          founders?: Array<{
            __typename?: 'FounderProfile';
            id: string;
            fullname: string;
            linkedin?: string | null;
          }> | null;
        } | null;
      }> | null;
      socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
      roles: Array<{
        __typename?: 'ProfileRole';
        id: string;
        description?: string | null;
        entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
      }>;
      emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      azavaUsers: Array<{ __typename?: 'User'; id: string; username: string }>;
      dealsSourced: Array<{
        __typename?: 'NemoSubmission';
        id: string;
        companyName?: string | null;
        companyUrl?: string | null;
        companyCity?: string | null;
        companyCountry?: string | null;
        teaserSummary?: string | null;
        roundType: EquityRoundType;
        raisedCurrency?: CurrencyIsoCode | null;
        raisedAmount?: number | null;
        createdAt: string;
        status: DealStatus;
        teamId: string;
        userTags: Array<string>;
        markets: Array<string>;
        sharedToDealFeedAt?: string | null;
        seenBy: Array<{
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        }>;
        list?: { __typename?: 'List'; id: string; slug: string } | null;
        assignee?: {
          __typename?: 'User';
          id: string;
          email: string;
          username: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
        } | null;
      }>;
    }>;
  };
};

export type CompanyProfilesQueryVariables = Exact<{
  filter: ProfilesFilter;
  meta?: InputMaybe<ProfilesQueryMeta>;
  orderBy?: InputMaybe<ProfilesOrderBy>;
}>;

export type CompanyProfilesQuery = {
  __typename?: 'Query';
  findManyCompanyProfiles: Array<{
    __typename?: 'Profile';
    id: string;
    fullname: string;
    personalWebsite?: string | null;
    description: string;
    firstInvested?: string | null;
    slug?: string | null;
    investmentStatus?: InvestmentStatus | null;
    totalInvested?: number | null;
    totalValue?: number | null;
    markets: Array<string>;
    customers: Array<string>;
    businessModels: Array<string>;
    notionNotesImported: boolean;
    country?: string | null;
    themes: Array<string>;
    latestRoundValuation?: {
      __typename?: 'LastRoundValuation';
      date?: string | null;
      roundType?: EquityRoundType | null;
      valuation?: { __typename?: 'Valuation'; valuationType?: ValuationType | null; value?: number | null } | null;
    } | null;
    legalEntity?: { __typename?: 'LegalEntity'; id: string } | null;
    investingEntities: Array<{ __typename?: 'LegalEntity'; id: string; displayName?: string | null }>;
  }>;
};

export type CreatePortfolioViewMutationVariables = Exact<{
  input: CreatePortfolioViewInput;
}>;

export type CreatePortfolioViewMutation = {
  __typename?: 'Mutation';
  createPortfolioView?: { __typename?: 'PortfolioView'; slug: string } | null;
};

export type PortfolioViewBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PortfolioViewBySlugQuery = {
  __typename?: 'Query';
  portfolioViewBySlug?: {
    __typename?: 'PortfolioView';
    id: string;
    name: string;
    data: any;
    filterState?: any | null;
    columnState?: any | null;
    profileSlug?: string | null;
  } | null;
};

export type GetPortfolioViewsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPortfolioViewsQuery = {
  __typename?: 'Query';
  portfolioViews?: Array<{ __typename?: 'PortfolioView'; id: string; name: string; slug: string } | null> | null;
};

export type DeletePortfolioViewMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePortfolioViewMutation = {
  __typename?: 'Mutation';
  deletePortfolioView?: { __typename?: 'PortfolioView'; id: string } | null;
};

export type PorfolioViewFieldsFragment = { __typename?: 'PortfolioView'; id: string; name: string; slug: string };

export type UpsertConvertibleAndNoteMutationVariables = Exact<{
  convertibleInput: ConvertibleInput;
  transactionNote?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpsertConvertibleAndNoteMutation = {
  __typename?: 'Mutation';
  upsertConvertibleAndNote: { __typename?: 'UpsertInvestmentAndNoteResponse'; transactionId?: string | null };
};

export type UpdateConvertibleTransactionMutationVariables = Exact<{
  investedAmountInput: UpdateInvestedAmountInput;
  investmentDateInput: UpdateInvestmentDateInput;
  assetNameInput: UpdateAssetNameInput;
  convertibleInput: UpdateConvertiblePropertiesInput;
}>;

export type UpdateConvertibleTransactionMutation = {
  __typename?: 'Mutation';
  updateInvestedAmount: boolean;
  updateInvestmentDate: boolean;
  updateConvertibleProperties: boolean;
  asset: {
    __typename?: 'AssetMutations';
    updateName?: { __typename?: 'BaseAsset'; name: string } | { __typename?: 'ConvertibleAsset'; name: string } | null;
  };
};

export type ConvertConvertibleMutationVariables = Exact<{
  input: ConvertConvertibleInput;
  note?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConvertConvertibleMutation = { __typename?: 'Mutation'; convertConvertible: boolean };

export type UpdateConversionMutationVariables = Exact<{
  investedAmountInput: UpdateInvestedAmountInput;
  investmentDateInput: UpdateInvestmentDateInput;
  conversionDateInput: UpdateInvestmentDateInput;
  numAssetsFirstInput: UpdateNumAssetsInput;
  assetNameFirstInput: UpdateAssetNameInput;
  numAssetsSecondInput?: InputMaybe<UpdateNumAssetsInput>;
  assetNameSecondInput?: InputMaybe<UpdateAssetNameInput>;
  convertibleInput: UpdateConvertiblePropertiesInput;
}>;

export type UpdateConversionMutation = {
  __typename?: 'Mutation';
  updateInvestedAmount: boolean;
  updateConvertibleProperties: boolean;
  updateInvestmentDate: boolean;
  updateConversionDate: boolean;
  updateShares1: boolean;
  updateShares2: boolean;
  updateSharesName1: {
    __typename?: 'AssetMutations';
    updateName?: { __typename?: 'BaseAsset'; name: string } | { __typename?: 'ConvertibleAsset'; name: string } | null;
  };
  updateSharesName2: {
    __typename?: 'AssetMutations';
    updateName?: { __typename?: 'BaseAsset'; name: string } | { __typename?: 'ConvertibleAsset'; name: string } | null;
  };
};

export type UpdateDistributionMutationVariables = Exact<{
  assetTransferId: Scalars['ID']['input'];
  newDate: Scalars['Date']['input'];
  newAmount: Scalars['Float']['input'];
  newCurrency: CurrencyIsoCode;
}>;

export type UpdateDistributionMutation = {
  __typename?: 'Mutation';
  updateDistributionDate: boolean;
  updateDistributionAmount: boolean;
};

export type DeleteDistributionMutationVariables = Exact<{
  assetTransferId: Scalars['ID']['input'];
}>;

export type DeleteDistributionMutation = { __typename?: 'Mutation'; deleteDistribution: boolean };

export type ImportDistributionsMutationVariables = Exact<{
  input: ImportDistributionInput;
}>;

export type ImportDistributionsMutation = { __typename?: 'Mutation'; importDistributions: boolean };

export type ImportFundDistributionsMutationVariables = Exact<{
  input: ImportFundDistributionsInput;
}>;

export type ImportFundDistributionsMutation = { __typename?: 'Mutation'; importFundDistributions: boolean };

export type UpsertDividendMutationVariables = Exact<{
  input: DividendInput;
}>;

export type UpsertDividendMutation = {
  __typename?: 'Mutation';
  upsertDividend?: { __typename?: 'Note'; id: string } | null;
};

export type UpdateDividendMutationVariables = Exact<{
  assetTransferId: Scalars['ID']['input'];
  newAmountInput?: InputMaybe<MoneyInput>;
  newDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type UpdateDividendMutation = { __typename?: 'Mutation'; updateDividend: boolean };

export type DeleteDividendMutationVariables = Exact<{
  assetTransferId: Scalars['ID']['input'];
}>;

export type DeleteDividendMutation = { __typename?: 'Mutation'; deleteDividend: boolean };

export type UpdateEquityTransactionMutationVariables = Exact<{
  investedAmountInput: UpdateInvestedAmountInput;
  numAssetsInput: UpdateNumAssetsInput;
  investmentDateInput: UpdateInvestmentDateInput;
  assetNameInput: UpdateAssetNameInput;
}>;

export type UpdateEquityTransactionMutation = {
  __typename?: 'Mutation';
  updateInvestedAmount: boolean;
  updateNumAssets: boolean;
  updateInvestmentDate: boolean;
  asset: {
    __typename?: 'AssetMutations';
    updateName?: { __typename?: 'BaseAsset'; name: string } | { __typename?: 'ConvertibleAsset'; name: string } | null;
  };
};

export type UpsertEquityAndNoteMutationVariables = Exact<{
  input: UpsertEquityInput;
  transactionNote?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpsertEquityAndNoteMutation = {
  __typename?: 'Mutation';
  upsertEquityAndNote: {
    __typename?: 'UpsertInvestmentAndNoteResponse';
    note?: { __typename?: 'Note'; id: string; message: string } | null;
  };
};

export type UpsertEquityByAmountAndNoteMutationVariables = Exact<{
  input: UpsertEquityByAmountInput;
  transactionNote?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpsertEquityByAmountAndNoteMutation = {
  __typename?: 'Mutation';
  upsertEquityByAmountAndNote: {
    __typename?: 'UpsertInvestmentAndNoteResponse';
    note?: { __typename?: 'Note'; id: string; message: string } | null;
  };
};

export type UpsertSecondaryEquityAndNoteMutationVariables = Exact<{
  input: UpsertSecondaryInput;
  transactionNote?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpsertSecondaryEquityAndNoteMutation = {
  __typename?: 'Mutation';
  upsertSecondaryAndNote: {
    __typename?: 'UpsertInvestmentAndNoteResponse';
    note?: { __typename?: 'Note'; id: string; message: string } | null;
  };
};

export type UpdateFundTransactionMutationVariables = Exact<{
  investedAmountInput: UpdateInvestedAmountInput;
  investmentDateInput: UpdateInvestmentDateInput;
}>;

export type UpdateFundTransactionMutation = {
  __typename?: 'Mutation';
  updateInvestedAmount: boolean;
  updateInvestmentDate: boolean;
};

export type EventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  date: string;
  type: EventType;
  data?: any | null;
};

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  event: { __typename?: 'EventMutations'; delete: { __typename?: 'Event'; id: string } };
};

export type CreateMarkdownEventMutationVariables = Exact<{
  input: CreateMarkdownInput;
}>;

export type CreateMarkdownEventMutation = {
  __typename?: 'Mutation';
  markdown: { __typename?: 'MarkdownMutations'; create: { __typename?: 'Event'; id: string } };
};

export type UpdateMarkdownEventMutationVariables = Exact<{
  input: UpdateMarkdownInput;
}>;

export type UpdateMarkdownEventMutation = {
  __typename?: 'Mutation';
  markdown: { __typename?: 'MarkdownMutations'; update: { __typename?: 'Event'; id: string } };
};

export type DeleteMarkdownEventMutationVariables = Exact<{
  input: DeleteMarkdownInput;
}>;

export type DeleteMarkdownEventMutation = {
  __typename?: 'Mutation';
  markdown: { __typename?: 'MarkdownMutations'; delete: { __typename?: 'Event'; id: string } };
};

export type UpdateSpvTransactionMutationVariables = Exact<{
  investedAmountInput: UpdateInvestedAmountInput;
  investmentDateInput: UpdateInvestmentDateInput;
}>;

export type UpdateSpvTransactionMutation = {
  __typename?: 'Mutation';
  updateInvestedAmount: boolean;
  updateInvestmentDate: boolean;
};

export type UpsertSpvAndNoteMutationVariables = Exact<{
  input: UpsertSpvInput;
  transactionNote?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpsertSpvAndNoteMutation = {
  __typename?: 'Mutation';
  upsertSpvAndNote: {
    __typename?: 'UpsertInvestmentAndNoteResponse';
    note?: { __typename?: 'Note'; id: string; message: string } | null;
  };
};

export type PostDisplayFieldsFragment = {
  __typename?: 'Post';
  id: string;
  comment?: string | null;
  createdAt: string;
  fidelity: PostFidelity;
  tags?: Array<string> | null;
  interactions: Array<{
    __typename?: 'PostInteraction';
    id: string;
    type: InteractionType;
    creator: { __typename?: 'User'; id: string };
    dealShareRequests: Array<{
      __typename?: 'DealShareRequest';
      accepted: boolean;
      dealRequester: {
        __typename?: 'User';
        id: string;
        publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      };
      dealOwner: {
        __typename?: 'User';
        id: string;
        publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      };
    }>;
  }>;
  nemoSubmission: {
    __typename?: 'NemoSubmission';
    id: string;
    teamId: string;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    hasLead: boolean;
    leadBy?: string | null;
    comment?: string | null;
    feedbackGiven: boolean;
    notificationAsked: boolean;
    rawSource?: string | null;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    status: DealStatus;
    sharedToDealFeedAt?: string | null;
    teaserSummary?: string | null;
    markets: Array<string>;
    customerTypes: Array<string>;
    businessModels: Array<string>;
    tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    } | null;
    creator: { __typename?: 'User'; id: string; email: string; username: string };
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      role?: string | null;
      description?: string | null;
      isSource?: boolean | null;
      profile?: {
        __typename?: 'Profile';
        id: string;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      } | null;
    }> | null;
  };
  creator: {
    __typename?: 'User';
    id: string;
    publicProfile?: {
      __typename?: 'Profile';
      id: string;
      fullname: string;
      shortDescription?: string | null;
      imageUrl?: string | null;
      descriptorsGeo: Array<string>;
      descriptorsInvestorType: Array<string>;
      descriptorsStage: Array<string>;
      descriptorsMiscTags: Array<string>;
      roles: Array<{
        __typename?: 'ProfileRole';
        id: string;
        description?: string | null;
        entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      }>;
      firstCoinvestmentProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
    } | null;
  };
};

export type PostDealToExploreFeedMutationVariables = Exact<{
  input: PostInput;
}>;

export type PostDealToExploreFeedMutation = {
  __typename?: 'Mutation';
  post: { __typename?: 'PostMutations'; createOrUpdate: { __typename?: 'Post'; id: string } };
};

export type RemoveDealFromExploreFeedMutationVariables = Exact<{
  submissionId: Scalars['ID']['input'];
}>;

export type RemoveDealFromExploreFeedMutation = {
  __typename?: 'Mutation';
  post: { __typename?: 'PostMutations'; deleteBySubmissionId: { __typename?: 'Post'; id: string } };
};

export type PostInteractionDisplayFieldsFragment = {
  __typename?: 'PostInteraction';
  id: string;
  type: InteractionType;
  createdAt: string;
  postId: string;
  creator: {
    __typename?: 'User';
    id: string;
    phoneNumber?: string | null;
    publicProfile?: {
      __typename?: 'Profile';
      id: string;
      fullname: string;
      imageUrl?: string | null;
      slug?: string | null;
      roles: Array<{
        __typename?: 'ProfileRole';
        id: string;
        description?: string | null;
        entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      }>;
    } | null;
  };
};

export type ExploreFeedQueryVariables = Exact<{
  input: ExploreFeedInput;
}>;

export type ExploreFeedQuery = {
  __typename?: 'Query';
  exploreFeed: Array<{
    __typename?: 'Post';
    id: string;
    comment?: string | null;
    createdAt: string;
    fidelity: PostFidelity;
    tags?: Array<string> | null;
    interactions: Array<{
      __typename?: 'PostInteraction';
      id: string;
      type: InteractionType;
      creator: { __typename?: 'User'; id: string };
      dealShareRequests: Array<{
        __typename?: 'DealShareRequest';
        accepted: boolean;
        dealRequester: {
          __typename?: 'User';
          id: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        };
        dealOwner: {
          __typename?: 'User';
          id: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        };
      }>;
    }>;
    nemoSubmission: {
      __typename?: 'NemoSubmission';
      id: string;
      teamId: string;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      hasLead: boolean;
      leadBy?: string | null;
      comment?: string | null;
      feedbackGiven: boolean;
      notificationAsked: boolean;
      rawSource?: string | null;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      status: DealStatus;
      sharedToDealFeedAt?: string | null;
      teaserSummary?: string | null;
      markets: Array<string>;
      customerTypes: Array<string>;
      businessModels: Array<string>;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
      } | null;
      creator: { __typename?: 'User'; id: string; email: string; username: string };
      founders?: Array<{
        __typename?: 'SubmissionFounder';
        id: string;
        name: string;
        linkedin?: string | null;
        role?: string | null;
        description?: string | null;
        isSource?: boolean | null;
        profile?: {
          __typename?: 'Profile';
          id: string;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        } | null;
      }> | null;
    };
    creator: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        shortDescription?: string | null;
        imageUrl?: string | null;
        descriptorsGeo: Array<string>;
        descriptorsInvestorType: Array<string>;
        descriptorsStage: Array<string>;
        descriptorsMiscTags: Array<string>;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
        firstCoinvestmentProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      } | null;
    };
  }>;
};

export type SuggestedTagsQueryVariables = Exact<{
  submissionId: Scalars['ID']['input'];
}>;

export type SuggestedTagsQuery = { __typename?: 'Query'; sugestedTags: Array<string> };

export type CreatePostInteractionMutationVariables = Exact<{
  input: PostInteractionInput;
}>;

export type CreatePostInteractionMutation = {
  __typename?: 'Mutation';
  postInteraction: {
    __typename?: 'PostInteractionMutations';
    create: {
      __typename?: 'PostInteraction';
      id: string;
      type: InteractionType;
      creator: { __typename?: 'User'; id: string };
    };
  };
};

export type DeletePostInteractionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePostInteractionMutation = {
  __typename?: 'Mutation';
  postInteraction: { __typename?: 'PostInteractionMutations'; delete: { __typename?: 'PostInteraction'; id: string } };
};

export type FindFirstPostCreatedByMeQueryVariables = Exact<{ [key: string]: never }>;

export type FindFirstPostCreatedByMeQuery = {
  __typename?: 'Query';
  findFirstPostCreatedByMe?: {
    __typename?: 'Post';
    id: string;
    comment?: string | null;
    createdAt: string;
    fidelity: PostFidelity;
    tags?: Array<string> | null;
    interactions: Array<{
      __typename?: 'PostInteraction';
      id: string;
      type: InteractionType;
      creator: { __typename?: 'User'; id: string };
      dealShareRequests: Array<{
        __typename?: 'DealShareRequest';
        accepted: boolean;
        dealRequester: {
          __typename?: 'User';
          id: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        };
        dealOwner: {
          __typename?: 'User';
          id: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        };
      }>;
    }>;
    nemoSubmission: {
      __typename?: 'NemoSubmission';
      id: string;
      teamId: string;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      hasLead: boolean;
      leadBy?: string | null;
      comment?: string | null;
      feedbackGiven: boolean;
      notificationAsked: boolean;
      rawSource?: string | null;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      status: DealStatus;
      sharedToDealFeedAt?: string | null;
      teaserSummary?: string | null;
      markets: Array<string>;
      customerTypes: Array<string>;
      businessModels: Array<string>;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
      } | null;
      creator: { __typename?: 'User'; id: string; email: string; username: string };
      founders?: Array<{
        __typename?: 'SubmissionFounder';
        id: string;
        name: string;
        linkedin?: string | null;
        role?: string | null;
        description?: string | null;
        isSource?: boolean | null;
        profile?: {
          __typename?: 'Profile';
          id: string;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        } | null;
      }> | null;
    };
    creator: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        shortDescription?: string | null;
        imageUrl?: string | null;
        descriptorsGeo: Array<string>;
        descriptorsInvestorType: Array<string>;
        descriptorsStage: Array<string>;
        descriptorsMiscTags: Array<string>;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
        firstCoinvestmentProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      } | null;
    };
  } | null;
};

export type FindPeopleIveGivenAccessQueryVariables = Exact<{ [key: string]: never }>;

export type FindPeopleIveGivenAccessQuery = {
  __typename?: 'Query';
  myIssuedAccessKeys: Array<{
    __typename?: 'AccessKey';
    id: string;
    viewer: {
      __typename?: 'User';
      id: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
    };
  }>;
};

export type FindPeopleWhoveGivenMeAccessQueryVariables = Exact<{ [key: string]: never }>;

export type FindPeopleWhoveGivenMeAccessQuery = {
  __typename?: 'Query';
  myVisibleAccessKeys: Array<{
    __typename?: 'AccessKey';
    id: string;
    issuer: {
      __typename?: 'User';
      id: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
    };
  }>;
};

export type ExplorePostQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ExplorePostQuery = {
  __typename?: 'Query';
  explorePost: {
    __typename?: 'Post';
    id: string;
    comment?: string | null;
    createdAt: string;
    fidelity: PostFidelity;
    tags?: Array<string> | null;
    interactions: Array<{
      __typename?: 'PostInteraction';
      id: string;
      type: InteractionType;
      creator: { __typename?: 'User'; id: string };
      dealShareRequests: Array<{
        __typename?: 'DealShareRequest';
        accepted: boolean;
        dealRequester: {
          __typename?: 'User';
          id: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        };
        dealOwner: {
          __typename?: 'User';
          id: string;
          publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        };
      }>;
    }>;
    nemoSubmission: {
      __typename?: 'NemoSubmission';
      id: string;
      teamId: string;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      hasLead: boolean;
      leadBy?: string | null;
      comment?: string | null;
      feedbackGiven: boolean;
      notificationAsked: boolean;
      rawSource?: string | null;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      status: DealStatus;
      sharedToDealFeedAt?: string | null;
      teaserSummary?: string | null;
      markets: Array<string>;
      customerTypes: Array<string>;
      businessModels: Array<string>;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
      } | null;
      creator: { __typename?: 'User'; id: string; email: string; username: string };
      founders?: Array<{
        __typename?: 'SubmissionFounder';
        id: string;
        name: string;
        linkedin?: string | null;
        role?: string | null;
        description?: string | null;
        isSource?: boolean | null;
        profile?: {
          __typename?: 'Profile';
          id: string;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        } | null;
      }> | null;
    };
    creator: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        shortDescription?: string | null;
        imageUrl?: string | null;
        descriptorsGeo: Array<string>;
        descriptorsInvestorType: Array<string>;
        descriptorsStage: Array<string>;
        descriptorsMiscTags: Array<string>;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
        firstCoinvestmentProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      } | null;
    };
  };
};

export type DetailedInvestmentsQueryVariables = Exact<{
  viewerId: Scalars['ID']['input'];
  asOfDate: Scalars['Date']['input'];
  userCurrency: CurrencyIsoCode;
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DetailedInvestmentsQuery = {
  __typename?: 'Query';
  viewerLegalEntity?: {
    __typename?: 'LegalEntity';
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
    portfolioCompanies: Array<{
      __typename?: 'PortfolioCompany';
      legalEntity: {
        __typename?: 'LegalEntity';
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
      };
      companyMetrics: { __typename?: 'PortfolioCompanyMetrics'; lastPricePerShare?: number | null };
    }>;
    allDetailedInvestments: Array<{
      __typename?: 'Investment';
      id: string;
      transactionDate: string;
      numAssets?: number | null;
      issuedByLegalEntity: {
        __typename?: 'LegalEntity';
        legalName?: string | null;
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
        publicProfile?:
          | {
              __typename?: 'CompanyProfile';
              city?: string | null;
              country?: string | null;
              themes: Array<string>;
              website?: string | null;
            }
          | { __typename?: 'Profile' }
          | null;
      };
      spvLegalEntity?: {
        __typename?: 'LegalEntity';
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
      } | null;
      asset:
        | { __typename: 'BaseAsset'; id: string; type: AssetType; name: string }
        | {
            __typename: 'ConvertibleAsset';
            id: string;
            name: string;
            convertibleType: ConvertibleType;
            currency: CurrencyIsoCode;
            valuationCap?: number | null;
            valuationCapInPinnedUserCurrency?: number | null;
          };
      conversion?: {
        __typename?: 'InvestmentConversion';
        id: string;
        conversionDate: string;
        numConvertedAssets: Array<number>;
        conversionPrice?: number | null;
        convertedInto: Array<{ __typename?: 'BaseAsset'; id: string; name: string }>;
        event?: {
          __typename?: 'Event';
          id: string;
          name: string;
          round?: {
            __typename?: 'Round';
            valuationCurrency?: CurrencyIsoCode | null;
            raisedCurrency?: CurrencyIsoCode | null;
          } | null;
          investors: Array<{
            __typename?: 'Investor';
            id: string;
            displayName?: string | null;
            investingEntity?: { __typename?: 'InvestingEntity'; id: string; name: string } | null;
          }>;
        } | null;
        notes: Array<{ __typename?: 'Note'; id: string; message: string; updatedAt: string; createdAt: string }>;
      } | null;
      event?: {
        __typename?: 'Event';
        id: string;
        name: string;
        round?: {
          __typename?: 'Round';
          valuationCurrency?: CurrencyIsoCode | null;
          raisedCurrency?: CurrencyIsoCode | null;
          raisedAmount?: number | null;
          raisedAmountInPinnedUserCurrency?: number | null;
          valuation?: number | null;
          valuationInPinnedUserCurrency?: number | null;
          valuationType: ValuationType;
        } | null;
        investors: Array<{
          __typename?: 'Investor';
          id: string;
          displayName?: string | null;
          investingEntity?: { __typename?: 'InvestingEntity'; id: string; name: string } | null;
        }>;
      } | null;
      investmentMetrics: {
        __typename?: 'InvestmentMetrics';
        userCurrency: CurrencyIsoCode;
        investmentCurrency?: CurrencyIsoCode | null;
        investedAmount?: {
          __typename?: 'Metric';
          inInvestmentCurrency?: number | null;
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        holdingsValue?: {
          __typename?: 'Metric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        moic?: { __typename?: 'Metric'; inUserCurrency?: number | null; inPinnedUserCurrency?: number | null } | null;
        irr?: { __typename?: 'Metric'; inUserCurrency?: number | null; inPinnedUserCurrency?: number | null } | null;
      };
      notes: Array<{ __typename?: 'Note'; id: string; message: string; updatedAt: string; createdAt: string }>;
    }>;
  } | null;
};

export type DetailedDistributionsQueryVariables = Exact<{
  viewerId: Scalars['ID']['input'];
}>;

export type DetailedDistributionsQuery = {
  __typename?: 'Query';
  viewerLegalEntity?: {
    __typename?: 'LegalEntity';
    legalName?: string | null;
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
    portfolioCompanyDistributions: Array<{
      __typename?: 'CompanyDistribution';
      companyName: string;
      date: string;
      currency: CurrencyIsoCode;
      numAssets: number;
    }>;
  } | null;
};

export type FeedDisplayFieldsFragment = {
  __typename?: 'NemoSubmission';
  id: string;
  teamId: string;
  roundType: EquityRoundType;
  raisedCurrency?: CurrencyIsoCode | null;
  raisedAmount?: number | null;
  createdAt: string;
  hasLead: boolean;
  leadBy?: string | null;
  comment?: string | null;
  feedbackGiven: boolean;
  notificationAsked: boolean;
  rawSource?: string | null;
  companyName?: string | null;
  companyUrl?: string | null;
  companyCity?: string | null;
  companyCountry?: string | null;
  teaserSummary?: string | null;
  sharedToDealFeedAt?: string | null;
  status: DealStatus;
  markets: Array<string>;
  customerTypes: Array<string>;
  businessModels: Array<string>;
  post?: { __typename?: 'Post'; id: string; comment?: string | null } | null;
  tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
  list?: { __typename?: 'List'; id: string; slug: string } | null;
  assignee?: {
    __typename?: 'User';
    id: string;
    publicProfile?: {
      __typename?: 'Profile';
      id: string;
      fullname: string;
      imageUrl?: string | null;
      roles: Array<{
        __typename?: 'ProfileRole';
        id: string;
        description?: string | null;
        entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
      }>;
    } | null;
  } | null;
  creator: { __typename?: 'User'; id: string; email: string; username: string };
  founders?: Array<{
    __typename?: 'SubmissionFounder';
    id: string;
    name: string;
    linkedin?: string | null;
    role?: string | null;
    description?: string | null;
    isSource?: boolean | null;
    profile?: {
      __typename?: 'Profile';
      id: string;
      emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
    } | null;
  }> | null;
};

export type DealFeedQueryVariables = Exact<{
  days?: InputMaybe<Scalars['Int']['input']>;
}>;

export type DealFeedQuery = {
  __typename?: 'Query';
  dealFeed: Array<{
    __typename?: 'NemoSubmission';
    id: string;
    teamId: string;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    hasLead: boolean;
    leadBy?: string | null;
    comment?: string | null;
    feedbackGiven: boolean;
    notificationAsked: boolean;
    rawSource?: string | null;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    sharedToDealFeedAt?: string | null;
    status: DealStatus;
    markets: Array<string>;
    customerTypes: Array<string>;
    businessModels: Array<string>;
    post?: { __typename?: 'Post'; id: string; comment?: string | null } | null;
    tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    } | null;
    creator: { __typename?: 'User'; id: string; email: string; username: string };
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      role?: string | null;
      description?: string | null;
      isSource?: boolean | null;
      profile?: {
        __typename?: 'Profile';
        id: string;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      } | null;
    }> | null;
  }>;
};

export type DealFeedMyDealsQueryVariables = Exact<{ [key: string]: never }>;

export type DealFeedMyDealsQuery = {
  __typename?: 'Query';
  dealFeedMyDeals: Array<{
    __typename?: 'NemoSubmission';
    id: string;
    teamId: string;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    hasLead: boolean;
    leadBy?: string | null;
    comment?: string | null;
    feedbackGiven: boolean;
    notificationAsked: boolean;
    rawSource?: string | null;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    sharedToDealFeedAt?: string | null;
    status: DealStatus;
    markets: Array<string>;
    customerTypes: Array<string>;
    businessModels: Array<string>;
    post?: { __typename?: 'Post'; id: string; comment?: string | null } | null;
    tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    } | null;
    creator: { __typename?: 'User'; id: string; email: string; username: string };
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      role?: string | null;
      description?: string | null;
      isSource?: boolean | null;
      profile?: {
        __typename?: 'Profile';
        id: string;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      } | null;
    }> | null;
  }>;
};

export type DealFeedDealByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DealFeedDealByIdQuery = {
  __typename?: 'Query';
  submission: {
    __typename?: 'NemoSubmission';
    id: string;
    teamId: string;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    hasLead: boolean;
    leadBy?: string | null;
    comment?: string | null;
    feedbackGiven: boolean;
    notificationAsked: boolean;
    rawSource?: string | null;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    sharedToDealFeedAt?: string | null;
    status: DealStatus;
    markets: Array<string>;
    customerTypes: Array<string>;
    businessModels: Array<string>;
    post?: { __typename?: 'Post'; id: string; comment?: string | null } | null;
    tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    } | null;
    creator: { __typename?: 'User'; id: string; email: string; username: string };
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      role?: string | null;
      description?: string | null;
      isSource?: boolean | null;
      profile?: {
        __typename?: 'Profile';
        id: string;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      } | null;
    }> | null;
  };
};

export type DemoDealQueryVariables = Exact<{ [key: string]: never }>;

export type DemoDealQuery = {
  __typename?: 'Query';
  demoDeal?: {
    __typename?: 'NemoSubmission';
    id: string;
    teamId: string;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    hasLead: boolean;
    leadBy?: string | null;
    comment?: string | null;
    feedbackGiven: boolean;
    notificationAsked: boolean;
    rawSource?: string | null;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    sharedToDealFeedAt?: string | null;
    status: DealStatus;
    markets: Array<string>;
    customerTypes: Array<string>;
    businessModels: Array<string>;
    post?: { __typename?: 'Post'; id: string; comment?: string | null } | null;
    tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    } | null;
    creator: { __typename?: 'User'; id: string; email: string; username: string };
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      role?: string | null;
      description?: string | null;
      isSource?: boolean | null;
      profile?: {
        __typename?: 'Profile';
        id: string;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      } | null;
    }> | null;
  } | null;
};

export type CreateFounderIntroRequestMutationVariables = Exact<{
  input: CreateFounderIntroRequestInput;
}>;

export type CreateFounderIntroRequestMutation = {
  __typename?: 'Mutation';
  founderIntroRequest: {
    __typename?: 'FounderIntroRequestMutations';
    create: { __typename?: 'FounderIntroRequest'; id: string };
  };
};

export type InvestingEntityQueryVariables = Exact<{
  investingEntityId: Scalars['ID']['input'];
}>;

export type InvestingEntityQuery = {
  __typename?: 'Query';
  investingEntity?: { __typename?: 'InvestingEntity'; id: string; name: string; teamId: string } | null;
};

export type InvestingEntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type InvestingEntitiesQuery = {
  __typename?: 'Query';
  investingEntities: Array<{ __typename?: 'InvestingEntity'; id: string; name: string; teamId: string }>;
};

export type DeleteInvestmentMutationVariables = Exact<{
  transactionId: Scalars['ID']['input'];
}>;

export type DeleteInvestmentMutation = { __typename?: 'Mutation'; deleteInvestment: boolean };

export type ProfilesQueryVariables = Exact<{
  meta: ProfilesQueryMeta;
}>;

export type ProfilesQuery = {
  __typename?: 'Query';
  profiles: Array<{
    __typename?: 'Profile';
    id: string;
    fullname: string;
    slug?: string | null;
    imageUrl?: string | null;
    roles: Array<{
      __typename?: 'ProfileRole';
      description?: string | null;
      entityProfile?: { __typename?: 'Profile'; fullname: string } | null;
    }>;
  }>;
};

export type HardCodedUsersForWelcomeScreenQueryVariables = Exact<{ [key: string]: never }>;

export type HardCodedUsersForWelcomeScreenQuery = {
  __typename?: 'Query';
  hardCodedUsersForWelcomeScreen: Array<{
    __typename?: 'Profile';
    id: string;
    fullname: string;
    slug?: string | null;
    imageUrl?: string | null;
    roles: Array<{
      __typename?: 'ProfileRole';
      description?: string | null;
      entityProfile?: { __typename?: 'Profile'; fullname: string } | null;
    }>;
  }>;
};

export type UpdateProfileImageMutationVariables = Exact<{
  input: UpdateProfileImageInput;
}>;

export type UpdateProfileImageMutation = {
  __typename?: 'Mutation';
  profile: {
    __typename?: 'ProfileMutations';
    updateImageUrl: { __typename?: 'Profile'; id: string; imageUrl?: string | null };
  };
};

export type AddProfileInvestmentMutationVariables = Exact<{
  input: AddProfileInvestmentInput;
}>;

export type AddProfileInvestmentMutation = {
  __typename?: 'Mutation';
  profile: { __typename?: 'ProfileMutations'; addProfileInvestment: { __typename?: 'ProfileInvestment'; id: string } };
};

export type AddProfilePublicInvestmentMutationVariables = Exact<{
  input: AddPublicInvestmentInput;
}>;

export type AddProfilePublicInvestmentMutation = {
  __typename?: 'Mutation';
  profile: { __typename?: 'ProfileMutations'; addPublicInvestment: { __typename?: 'ProfileInvestment'; id: string } };
};

export type DeleteProfilePublicInvestmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteProfilePublicInvestmentMutation = {
  __typename?: 'Mutation';
  profile: {
    __typename?: 'ProfileMutations';
    deletePublicInvestment: { __typename?: 'ProfileInvestment'; id: string };
  };
};

export type PublicProfileFromSlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type PublicProfileFromSlugQuery = {
  __typename?: 'Query';
  publicProfileBySlug?:
    | { __typename?: 'CompanyProfile' }
    | {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        description: string;
        linkedin?: string | null;
        descriptorsGeo: Array<string>;
        descriptorsInvestorType: Array<string>;
        descriptorsStage: Array<string>;
        descriptorsMiscTags: Array<string>;
        inviteProposalStatus?: ProposalStatus | null;
        connections?: Array<string> | null;
        slug?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            slug?: string | null;
            personalWebsite?: string | null;
          } | null;
        }>;
        publicProfileInvestments: Array<{
          __typename?: 'ProfileInvestment';
          id: string;
          roundType: EquityRoundType;
          date?: string | null;
          company: { __typename?: 'Profile'; id: string; fullname: string; personalWebsite?: string | null };
        }>;
      }
    | null;
};

export type InvestorsQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorsQuery = {
  __typename?: 'Query';
  investors: Array<{
    __typename?: 'Investor';
    id: string;
    displayName?: string | null;
    type?: LegalEntityType | null;
    investingEntity?: { __typename?: 'InvestingEntity'; id: string; name: string } | null;
    investments: Array<{
      __typename?: 'InvestmentRelation';
      id: string;
      roundType: EquityRoundType;
      date: string;
      company: {
        __typename?: 'LegalEntityWithMeta';
        id: string;
        displayName?: string | null;
        locations: Array<string>;
        themes: Array<string>;
        publicProfile?:
          | { __typename?: 'CompanyProfile'; id: string; slug?: string | null }
          | { __typename?: 'Profile'; id: string; slug?: string | null }
          | null;
      };
    }>;
    coInvestors: Array<{
      __typename?: 'Investor';
      id: string;
      displayName?: string | null;
      type?: LegalEntityType | null;
    }>;
  }>;
};

export type AllInvestorsQueryVariables = Exact<{ [key: string]: never }>;

export type AllInvestorsQuery = {
  __typename?: 'Query';
  investors: Array<{
    __typename?: 'Investor';
    id: string;
    displayName?: string | null;
    type?: LegalEntityType | null;
    investingEntity?: { __typename?: 'InvestingEntity'; id: string; name: string } | null;
  }>;
};

export type FindInvestorsByFullnameQueryVariables = Exact<{
  fullname: Scalars['String']['input'];
}>;

export type FindInvestorsByFullnameQuery = {
  __typename?: 'Query';
  findProfilesByFullname: Array<{
    __typename?: 'Profile';
    id: string;
    fullname: string;
    type?: LegalEntityType | null;
  }>;
};

export type InvestorsByRoundQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type InvestorsByRoundQuery = {
  __typename?: 'Query';
  investorsByRound: Array<{
    __typename?: 'Investor';
    id: string;
    displayName?: string | null;
    type?: LegalEntityType | null;
  }>;
};

export type AddInvestorToRoundMutationVariables = Exact<{
  input: AddInvestorToRoundInput;
}>;

export type AddInvestorToRoundMutation = { __typename?: 'Mutation'; addInvestorToRound: boolean };

export type SuggestInvestorsMutationVariables = Exact<{
  feedback: Scalars['String']['input'];
}>;

export type SuggestInvestorsMutation = { __typename?: 'Mutation'; suggestInvestors: boolean };

export type CreatePublicInvestmentsMutationVariables = Exact<{
  input: GraphCreatePublicInvestmentsInput;
}>;

export type CreatePublicInvestmentsMutation = { __typename?: 'Mutation'; graphCreatePublicInvestments: boolean };

export type CreateInviteProposalMutationVariables = Exact<{
  input: CreateInviteProposalInput;
}>;

export type CreateInviteProposalMutation = {
  __typename?: 'Mutation';
  inviteProposal: {
    __typename?: 'InviteProposalMutations';
    create: { __typename?: 'InviteProposal'; id: string; name?: string | null; fund?: string | null };
  };
};

export type CoinvestorsNotOnAzavaQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type CoinvestorsNotOnAzavaQuery = {
  __typename?: 'Query';
  coInvestorsNotOnAzava: Array<{
    __typename?: 'Profile';
    id: string;
    fullname: string;
    emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
  }>;
};

export type InviteProposalForUserQueryVariables = Exact<{ [key: string]: never }>;

export type InviteProposalForUserQuery = {
  __typename?: 'Query';
  inviteProposalForUser?: {
    __typename?: 'InviteProposal';
    id: string;
    linkedin?: string | null;
    fund?: string | null;
  } | null;
};

export type LegalEntityDisplayFieldsFragment = {
  __typename?: 'LegalEntity';
  id: string;
  displayName?: string | null;
  investingEntityId?: string | null;
  profileLink?: string | null;
};

export type LegalEntityFieldsFragment = {
  __typename?: 'LegalEntity';
  legalName?: string | null;
  id: string;
  displayName?: string | null;
  investingEntityId?: string | null;
  profileLink?: string | null;
};

export type LegalEntityQueryVariables = Exact<{
  legalEntityId: Scalars['ID']['input'];
}>;

export type LegalEntityQuery = {
  __typename?: 'Query';
  legalEntity?: {
    __typename?: 'LegalEntity';
    legalName?: string | null;
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
    publicProfile?:
      | { __typename?: 'CompanyProfile'; id: string; slug?: string | null; website?: string | null }
      | {
          __typename?: 'Profile';
          id: string;
          slug?: string | null;
          personalWebsite?: string | null;
          linkedin?: string | null;
          imageUrl?: string | null;
        }
      | null;
  } | null;
};

export type CreateLegalEntityMutationVariables = Exact<{
  input: CreateLegalEntityInput;
}>;

export type CreateLegalEntityMutation = {
  __typename?: 'Mutation';
  legalEntity: {
    __typename?: 'LegalEntityMutations';
    create: {
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    };
  };
};

export type UpdateDisplayNameMutationVariables = Exact<{
  input: UpdateLegalEntityDisplayNameInput;
}>;

export type UpdateDisplayNameMutation = {
  __typename?: 'Mutation';
  legalEntity: {
    __typename?: 'LegalEntityMutations';
    updateDisplayName: {
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    };
  };
};

export type UpdateInvestingEntityMutationVariables = Exact<{
  input: UpdateLegalEntityInvestingEntityInput;
}>;

export type UpdateInvestingEntityMutation = {
  __typename?: 'Mutation';
  legalEntity: {
    __typename?: 'LegalEntityMutations';
    updateInvestingEntity: {
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    };
  };
};

export type ListDisplayFieldsFragment = {
  __typename?: 'List';
  id: string;
  slug: string;
  submissions: Array<{
    __typename?: 'NemoSubmission';
    id: string;
    teamId: string;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    hasLead: boolean;
    leadBy?: string | null;
    comment?: string | null;
    feedbackGiven: boolean;
    notificationAsked: boolean;
    rawSource?: string | null;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    status: DealStatus;
    sharedToDealFeedAt?: string | null;
    teaserSummary?: string | null;
    isDemo: boolean;
    markets: Array<string>;
    userTags: Array<string>;
    customerTypes: Array<string>;
    businessModels: Array<string>;
    secondarySubmissions: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
    }>;
    seenBy: Array<{
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          description?: string | null;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    }>;
    tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        imageUrl?: string | null;
        slug?: string | null;
      } | null;
    } | null;
    creator: { __typename?: 'User'; id: string; email: string; username: string };
    dealDocuments: Array<{
      __typename?: 'DealDocument';
      id: string;
      document: { __typename?: 'Document'; id: string; description?: string | null; objectUri: string };
    }>;
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      linkedinData?: any | null;
      profilePicUrl?: string | null;
      role?: string | null;
      description?: string | null;
      isSource?: boolean | null;
      profile?: {
        __typename?: 'Profile';
        id: string;
        emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
      } | null;
    }> | null;
    myForks: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
    }>;
    post?: {
      __typename?: 'Post';
      id: string;
      comment?: string | null;
      createdAt: string;
      tags?: Array<string> | null;
      interactions: Array<{
        __typename?: 'PostInteraction';
        id: string;
        type: InteractionType;
        creator: { __typename?: 'User'; id: string };
      }>;
      creator: {
        __typename?: 'User';
        id: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        } | null;
      };
    } | null;
    companyProfile?: {
      __typename?: 'Profile';
      id: string;
      competitors: Array<{ __typename?: 'Profile'; id: string; fullname: string }>;
    } | null;
  }>;
};

export type ListByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ListByIdQuery = {
  __typename?: 'Query';
  list: {
    __typename?: 'List';
    id: string;
    slug: string;
    submissions: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      teamId: string;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      hasLead: boolean;
      leadBy?: string | null;
      comment?: string | null;
      feedbackGiven: boolean;
      notificationAsked: boolean;
      rawSource?: string | null;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      status: DealStatus;
      sharedToDealFeedAt?: string | null;
      teaserSummary?: string | null;
      isDemo: boolean;
      markets: Array<string>;
      userTags: Array<string>;
      customerTypes: Array<string>;
      businessModels: Array<string>;
      secondarySubmissions: Array<{
        __typename?: 'NemoSubmission';
        id: string;
        list?: { __typename?: 'List'; id: string; slug: string } | null;
      }>;
      seenBy: Array<{
        __typename?: 'User';
        id: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        } | null;
      }>;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      creator: { __typename?: 'User'; id: string; email: string; username: string };
      dealDocuments: Array<{
        __typename?: 'DealDocument';
        id: string;
        document: { __typename?: 'Document'; id: string; description?: string | null; objectUri: string };
      }>;
      founders?: Array<{
        __typename?: 'SubmissionFounder';
        id: string;
        name: string;
        linkedin?: string | null;
        linkedinData?: any | null;
        profilePicUrl?: string | null;
        role?: string | null;
        description?: string | null;
        isSource?: boolean | null;
        profile?: {
          __typename?: 'Profile';
          id: string;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        } | null;
      }> | null;
      myForks: Array<{
        __typename?: 'NemoSubmission';
        id: string;
        list?: { __typename?: 'List'; id: string; slug: string } | null;
      }>;
      post?: {
        __typename?: 'Post';
        id: string;
        comment?: string | null;
        createdAt: string;
        tags?: Array<string> | null;
        interactions: Array<{
          __typename?: 'PostInteraction';
          id: string;
          type: InteractionType;
          creator: { __typename?: 'User'; id: string };
        }>;
        creator: {
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        };
      } | null;
      companyProfile?: {
        __typename?: 'Profile';
        id: string;
        competitors: Array<{ __typename?: 'Profile'; id: string; fullname: string }>;
      } | null;
    }>;
  };
};

export type ListBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ListBySlugQuery = {
  __typename?: 'Query';
  listBySlug?: {
    __typename?: 'List';
    id: string;
    slug: string;
    submissions: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      teamId: string;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      hasLead: boolean;
      leadBy?: string | null;
      comment?: string | null;
      feedbackGiven: boolean;
      notificationAsked: boolean;
      rawSource?: string | null;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      status: DealStatus;
      sharedToDealFeedAt?: string | null;
      teaserSummary?: string | null;
      isDemo: boolean;
      markets: Array<string>;
      userTags: Array<string>;
      customerTypes: Array<string>;
      businessModels: Array<string>;
      secondarySubmissions: Array<{
        __typename?: 'NemoSubmission';
        id: string;
        list?: { __typename?: 'List'; id: string; slug: string } | null;
      }>;
      seenBy: Array<{
        __typename?: 'User';
        id: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        } | null;
      }>;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      creator: { __typename?: 'User'; id: string; email: string; username: string };
      dealDocuments: Array<{
        __typename?: 'DealDocument';
        id: string;
        document: { __typename?: 'Document'; id: string; description?: string | null; objectUri: string };
      }>;
      founders?: Array<{
        __typename?: 'SubmissionFounder';
        id: string;
        name: string;
        linkedin?: string | null;
        linkedinData?: any | null;
        profilePicUrl?: string | null;
        role?: string | null;
        description?: string | null;
        isSource?: boolean | null;
        profile?: {
          __typename?: 'Profile';
          id: string;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        } | null;
      }> | null;
      myForks: Array<{
        __typename?: 'NemoSubmission';
        id: string;
        list?: { __typename?: 'List'; id: string; slug: string } | null;
      }>;
      post?: {
        __typename?: 'Post';
        id: string;
        comment?: string | null;
        createdAt: string;
        tags?: Array<string> | null;
        interactions: Array<{
          __typename?: 'PostInteraction';
          id: string;
          type: InteractionType;
          creator: { __typename?: 'User'; id: string };
        }>;
        creator: {
          __typename?: 'User';
          id: string;
          publicProfile?: {
            __typename?: 'Profile';
            id: string;
            fullname: string;
            imageUrl?: string | null;
            roles: Array<{
              __typename?: 'ProfileRole';
              id: string;
              description?: string | null;
              entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
            }>;
          } | null;
        };
      } | null;
      companyProfile?: {
        __typename?: 'Profile';
        id: string;
        competitors: Array<{ __typename?: 'Profile'; id: string; fullname: string }>;
      } | null;
    }>;
  } | null;
};

export type SubmissionSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubmissionSubscriptionSubscription = {
  __typename?: 'Subscription';
  myNewSubmissions: {
    __typename?: 'NemoSubmission';
    id: string;
    companyName?: string | null;
    companyUrl?: string | null;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    status: DealStatus;
    teamId: string;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    } | null;
  };
};

export type PipelineSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type PipelineSubscriptionSubscription = {
  __typename?: 'Subscription';
  updatedDealflowPipeline: {
    __typename?: 'DealflowPipeline';
    id: string;
    status: DealflowPipelineStatus;
    errors?: any | null;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    };
  };
};

export type ProcessingPipelinesQueryVariables = Exact<{ [key: string]: never }>;

export type ProcessingPipelinesQuery = {
  __typename?: 'Query';
  processingDealflowPipelines: Array<{
    __typename?: 'DealflowPipeline';
    id: string;
    status: DealflowPipelineStatus;
    creator: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    };
    communication?: { __typename?: 'NemoCommunication'; outputChannel?: DealflowChannel | null } | null;
  }>;
};

export type CreateBlankSubmissionMutationVariables = Exact<{ [key: string]: never }>;

export type CreateBlankSubmissionMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    createBlank: {
      __typename?: 'NemoSubmission';
      id: string;
      status: DealStatus;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
    };
  };
};

export type DealWithAugmentedMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DealWithAugmentedMessageQuery = {
  __typename?: 'Query';
  submission: {
    __typename?: 'NemoSubmission';
    id: string;
    companyName?: string | null;
    teaserSummary?: string | null;
    shortSummary?: string | null;
    augmentedMessage?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    comment?: string | null;
    companyCountry?: string | null;
    raisedAmount?: number | null;
    raisedCurrency?: CurrencyIsoCode | null;
    roundType: EquityRoundType;
    founders?: Array<{
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      linkedin?: string | null;
      role?: string | null;
    }> | null;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
  };
};

export type ForkDealMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ForkDealMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    fork: {
      __typename?: 'NemoSubmission';
      id: string;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
    };
  };
};

export type SubmittedDealsQueryVariables = Exact<{
  filter: SubmittedDealsFilter;
  meta: SubmittedDealsMeta;
  orderBy?: InputMaybe<SubmittedDealsOrderBy>;
}>;

export type SubmittedDealsQuery = {
  __typename?: 'Query';
  submittedDeals: {
    __typename?: 'NemoSubmissionList';
    count: number;
    submissions: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      teaserSummary?: string | null;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      createdAt: string;
      status: DealStatus;
      teamId: string;
      sharedToDealFeedAt?: string | null;
      markets: Array<string>;
      userTags: Array<string>;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; tag: string }>;
      seenBy: Array<{
        __typename?: 'User';
        id: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        } | null;
      }>;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          slug?: string | null;
        } | null;
      } | null;
    }>;
  };
};

export type OrganisationsQueryVariables = Exact<{
  filter: SubmittedDealsFilter;
  meta: SubmittedDealsMeta;
  orderBy?: InputMaybe<SubmittedDealsOrderBy>;
}>;

export type OrganisationsQuery = {
  __typename?: 'Query';
  submittedDeals: {
    __typename?: 'NemoSubmissionList';
    count: number;
    submissions: Array<{
      __typename?: 'NemoSubmission';
      id: string;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      teaserSummary?: string | null;
      roundType: EquityRoundType;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      createdAt: string;
      status: DealStatus;
      teamId: string;
      sharedToDealFeedAt?: string | null;
      tags: Array<{ __typename?: 'NemoSubmissionTag'; tag: string }>;
      seenBy: Array<{
        __typename?: 'User';
        id: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        } | null;
      }>;
      list?: { __typename?: 'List'; id: string; slug: string } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: {
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      founders?: Array<{
        __typename?: 'SubmissionFounder';
        id: string;
        isSource?: boolean | null;
        profile?: {
          __typename?: 'Profile';
          id: string;
          emails: Array<{ __typename?: 'ProfileEmail'; id: string; email: string }>;
        } | null;
      }> | null;
    }>;
  };
};

export type UpdateSubmissionRoundDetailsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  raisedAmount?: InputMaybe<Scalars['Float']['input']>;
  raisedCurrency?: InputMaybe<CurrencyIsoCode>;
  roundType?: InputMaybe<EquityRoundType>;
  leadBy?: InputMaybe<Scalars['String']['input']>;
  hasLead?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateSubmissionRoundDetailsMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: {
      __typename?: 'NemoSubmission';
      id: string;
      raisedCurrency?: CurrencyIsoCode | null;
      raisedAmount?: number | null;
      hasLead: boolean;
      leadBy?: string | null;
      roundType: EquityRoundType;
    };
  };
};

export type UpdateSubmissionCompanyDetailsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  companyCity?: InputMaybe<Scalars['String']['input']>;
  companyCountry?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  teaserSummary?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateSubmissionCompanyDetailsMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: {
      __typename?: 'NemoSubmission';
      id: string;
      companyName?: string | null;
      companyUrl?: string | null;
      companyCity?: string | null;
      companyCountry?: string | null;
      companyDescription?: string | null;
      teaserSummary?: string | null;
    };
  };
};

export type UpdateSubmissionAssigneeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  assignedTo?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateSubmissionAssigneeMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: {
      __typename?: 'NemoSubmission';
      id: string;
      sharedToDealFeedAt?: string | null;
      assignee?: { __typename?: 'User'; id: string; email: string; username: string } | null;
    };
  };
};

export type AddDeckUrlToSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deckUrl: Scalars['URL']['input'];
}>;

export type AddDeckUrlToSubmissionMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; deckUrl?: string | null };
  };
};

export type RemoveDeckUrlFromSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveDeckUrlFromSubmissionMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; deckUrl?: string | null };
  };
};

export type UpdateSubmissionCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
}>;

export type UpdateSubmissionCommentMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; comment?: string | null };
  };
};

export type RemoveSubmissionCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveSubmissionCommentMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; comment?: string | null };
  };
};

export type UpdateSubmissionStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: DealStatus;
}>;

export type UpdateSubmissionStatusMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; status: DealStatus };
  };
};

export type DeleteSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubmissionMutation = {
  __typename?: 'Mutation';
  nemoSubmission: { __typename?: 'NemoSubmissionMutations'; delete: { __typename?: 'NemoSubmission'; id: string } };
};

export type GiveFeedbackOnSubmissionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  args: FeedBackNemoSubmissionInput;
}>;

export type GiveFeedbackOnSubmissionMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    feedback: { __typename?: 'NemoSubmission'; id: string; feedbackGiven: boolean };
  };
};

export type NotifyWhenOthersAddMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type NotifyWhenOthersAddMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    notifyWhenOthersAdd: { __typename?: 'NemoSubmission'; id: string; notificationAsked: boolean };
  };
};

export type AskSubmissionQueryVariables = Exact<{
  input: AskSubmissionInput;
}>;

export type AskSubmissionQuery = { __typename?: 'Query'; askSubmission: string };

export type AskDocumentMutationVariables = Exact<{
  input: AskDocumentInput;
}>;

export type AskDocumentMutation = {
  __typename?: 'Mutation';
  document: {
    __typename?: 'DocumentMutations';
    ask: Array<{ __typename?: 'Reply'; question: string; answer: string }>;
  };
};

export type NemoParticipantsQueryVariables = Exact<{ [key: string]: never }>;

export type NemoParticipantsQuery = {
  __typename?: 'Query';
  nemoProfiles: Array<{
    __typename?: 'Profile';
    id: string;
    type?: LegalEntityType | null;
    fullname: string;
    imageUrl?: string | null;
    slug?: string | null;
    socialLinks: Array<{ __typename?: 'ProfileSocialLink'; id: string; url: string }>;
    roles: Array<{
      __typename?: 'ProfileRole';
      id: string;
      description?: string | null;
      entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
    }>;
    investments?: Array<{
      __typename?: 'PublicInvestment';
      id: string;
      companyName: string;
      roundType: EquityRoundType;
      companyProfile?: {
        __typename?: 'CompanyProfile';
        id: string;
        themes: Array<string>;
        country?: string | null;
      } | null;
    }> | null;
    dealsSourced: Array<{ __typename?: 'NemoSubmission'; id: string }>;
  }>;
};

export type CreateSubmissionFounderMutationVariables = Exact<{
  submissionId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateSubmissionFounderMutation = {
  __typename?: 'Mutation';
  submissionFounder: {
    __typename?: 'SubmissionFounderMutations';
    create: {
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      description?: string | null;
      linkedin?: string | null;
      twitter?: string | null;
      role?: string | null;
    };
  };
};

export type UpdateSubmissionFounderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateSubmissionFounderMutation = {
  __typename?: 'Mutation';
  submissionFounder: {
    __typename?: 'SubmissionFounderMutations';
    update: {
      __typename?: 'SubmissionFounder';
      id: string;
      name: string;
      description?: string | null;
      linkedin?: string | null;
      twitter?: string | null;
      role?: string | null;
    };
  };
};

export type DeleteSubmissionFounderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubmissionFounderMutation = {
  __typename?: 'Mutation';
  submissionFounder: {
    __typename?: 'SubmissionFounderMutations';
    delete: { __typename?: 'SubmissionFounder'; id: string };
  };
};

export type DealActivityQueryVariables = Exact<{
  submissionId: Scalars['ID']['input'];
}>;

export type DealActivityQuery = {
  __typename?: 'Query';
  dealActivity: Array<
    | {
        __typename?: 'NemoSubmission';
        id: string;
        createdAt: string;
        rawSource?: string | null;
        creator: {
          __typename?: 'User';
          id: string;
          email: string;
          username: string;
          publicProfile?: { __typename?: 'Profile'; id: string; imageUrl?: string | null } | null;
        };
        forkedFrom?: {
          __typename?: 'NemoSubmission';
          id: string;
          list?: { __typename?: 'List'; id: string; slug: string } | null;
        } | null;
        sourceProfiles: Array<{
          __typename?: 'Profile';
          id: string;
          fullname: string;
          imageUrl?: string | null;
          roles: Array<{
            __typename?: 'ProfileRole';
            id: string;
            description?: string | null;
            entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
          }>;
        }>;
      }
    | {
        __typename?: 'NemoSubmissionChange';
        id: string;
        createdAt: string;
        section: string;
        creator: {
          __typename?: 'User';
          id: string;
          email: string;
          username: string;
          publicProfile?: { __typename?: 'Profile'; id: string; imageUrl?: string | null } | null;
        };
        diffs: Array<{
          __typename?: 'Diff';
          field: string;
          identifier?: string | null;
          oldValue?: string | null;
          newValue?: string | null;
        }>;
      }
    | {
        __typename?: 'NemoSubmissionNote';
        id: string;
        createdAt: string;
        content: string;
        creator: {
          __typename?: 'User';
          id: string;
          email: string;
          username: string;
          publicProfile?: { __typename?: 'Profile'; id: string; imageUrl?: string | null } | null;
        };
      }
  >;
};

export type CreateSubmissionNoteMutationVariables = Exact<{
  submissionId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;

export type CreateSubmissionNoteMutation = {
  __typename?: 'Mutation';
  nemoSubmissionNote: {
    __typename?: 'NemoSubmissionNoteMutations';
    create: { __typename?: 'NemoSubmissionNote'; id: string; content: string };
  };
};

export type DeleteSubmissionNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubmissionNoteMutation = {
  __typename?: 'Mutation';
  nemoSubmissionNote: { __typename?: 'NemoSubmissionNoteMutations'; delete: boolean };
};

export type UpdateSubmissionNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;

export type UpdateSubmissionNoteMutation = {
  __typename?: 'Mutation';
  nemoSubmissionNote: {
    __typename?: 'NemoSubmissionNoteMutations';
    update: { __typename?: 'NemoSubmissionNote'; id: string; content: string };
  };
};

export type DeleteDealDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteDealDocumentMutation = {
  __typename?: 'Mutation';
  dealDocument: { __typename?: 'DealDocumentMutations'; delete: { __typename?: 'DealDocument'; id: string } };
};

export type CreateSubmissionTagMutationVariables = Exact<{
  submissionId: Scalars['ID']['input'];
  tag: Scalars['String']['input'];
}>;

export type CreateSubmissionTagMutation = {
  __typename?: 'Mutation';
  nemoSubmissionTag: {
    __typename?: 'NemoSubmissionTagMutations';
    create: { __typename?: 'NemoSubmissionTag'; id: string; tag: string };
  };
};

export type DeleteSubmissionTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubmissionTagMutation = {
  __typename?: 'Mutation';
  nemoSubmissionTag: {
    __typename?: 'NemoSubmissionTagMutations';
    delete: { __typename?: 'NemoSubmissionTag'; id: string };
  };
};

export type GetAllTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTagsQuery = {
  __typename?: 'Query';
  nemoSubmissionTags: Array<{ __typename?: 'NemoSubmissionTag'; id: string; tag: string }>;
};

export type GetUploadedCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetUploadedCountQuery = { __typename?: 'Query'; uploadedDealCount: number };

export type DealsMatchingThisOrganisationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DealsMatchingThisOrganisationQuery = {
  __typename?: 'Query';
  dealsMatchingThisOrganisation: Array<{
    __typename?: 'NemoSubmission';
    id: string;
    createdAt: string;
    updatedAt: string;
    status: DealStatus;
    teamId: string;
    sharedToDealFeedAt?: string | null;
    roundType: EquityRoundType;
    list?: { __typename?: 'List'; id: string; slug: string } | null;
    creator: { __typename?: 'User'; publicProfile?: { __typename?: 'Profile'; fullname: string } | null };
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    } | null;
    sourceProfiles: Array<{ __typename?: 'Profile'; id: string; fullname: string }>;
  }>;
};

export type DealByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DealByIdQuery = {
  __typename?: 'Query';
  submission: {
    __typename?: 'NemoSubmission';
    id: string;
    companyName?: string | null;
    companyUrl?: string | null;
    companyCity?: string | null;
    companyCountry?: string | null;
    teaserSummary?: string | null;
    roundType: EquityRoundType;
    raisedCurrency?: CurrencyIsoCode | null;
    raisedAmount?: number | null;
    createdAt: string;
    updatedAt: string;
    status: DealStatus;
    teamId: string;
    sharedToDealFeedAt?: string | null;
    creator: { __typename?: 'User'; publicProfile?: { __typename?: 'Profile'; fullname: string } | null };
    assignee?: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; id: string; fullname: string; imageUrl?: string | null } | null;
    } | null;
    sourceProfiles: Array<{ __typename?: 'Profile'; id: string; fullname: string }>;
  };
};

export type UserDealDigestQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserDealDigestQuery = {
  __typename?: 'Query';
  userDealDigest: Array<{
    __typename?: 'NemoSubmissionForDigest';
    id?: string | null;
    teaserSummary?: string | null;
    roundName?: string | null;
    amount?: string | null;
    status: DealStatus;
    azavaUrl: string;
    leadBy?: string | null;
    company: {
      __typename?: 'CompanyInner';
      name: string;
      website?: string | null;
      location?: string | null;
      countryFlag?: string | null;
    };
    founders: Array<{
      __typename?: 'Founder';
      fullname: string;
      role?: string | null;
      linkedin?: string | null;
    } | null>;
  }>;
};

export type UpdateSubmissionMarketsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  markets: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type UpdateSubmissionMarketsMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; markets: Array<string> };
  };
};

export type UpdateSubmissionTagsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  userTags: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type UpdateSubmissionTagsMutation = {
  __typename?: 'Mutation';
  nemoSubmission: {
    __typename?: 'NemoSubmissionMutations';
    update: { __typename?: 'NemoSubmission'; id: string; userTags: Array<string> };
  };
};

export type NoteFieldsFragment = {
  __typename?: 'Note';
  id: string;
  message: string;
  updatedAt: string;
  createdAt: string;
};

export type NotesQueryVariables = Exact<{
  referenceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type NotesQuery = {
  __typename?: 'Query';
  notes: Array<{
    __typename?: 'Note';
    id: string;
    message: string;
    updatedAt: string;
    createdAt: string;
    createdBy: {
      __typename?: 'User';
      id: string;
      email: string;
      username: string;
      publicProfile?: { __typename?: 'Profile'; imageUrl?: string | null } | null;
    };
  }>;
};

export type DeleteNoteMutationVariables = Exact<{
  noteId: Scalars['ID']['input'];
}>;

export type DeleteNoteMutation = {
  __typename?: 'Mutation';
  note: { __typename?: 'NoteMutations'; delete?: { __typename?: 'Note'; id: string } | null };
};

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;

export type CreateNoteMutation = {
  __typename?: 'Mutation';
  note: { __typename?: 'NoteMutations'; create: { __typename?: 'Note'; id: string } };
};

export type UpdateNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;

export type UpdateNoteMutation = {
  __typename?: 'Mutation';
  note: { __typename?: 'NoteMutations'; update: { __typename?: 'Note'; id: string } };
};

export type UpdateUserSettingsMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  args: UserSettingsUpdateInput;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: 'Mutation';
  userSettings: {
    __typename?: 'UserSettingsMutations';
    updateByUserId: {
      __typename?: 'UserSettings';
      id: string;
      notifyCompletedDeals: boolean;
      notifySameDealEmail: boolean;
      notifySameDealWhatsapp: boolean;
      notifyCuriousEmail: boolean;
      notifyCuriousWhatsapp: boolean;
      notifyLikesEmail: boolean;
      notifyLikesWhatsapp: boolean;
      dealFeedDefaultShared: boolean;
      dealFeedShowIntroCallout: ShowHide;
    };
  };
};

export type UpdateDealFeedShowIntroMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  showIntro: ShowHide;
}>;

export type UpdateDealFeedShowIntroMutation = {
  __typename?: 'Mutation';
  userSettings: {
    __typename?: 'UserSettingsMutations';
    updateByUserId: { __typename?: 'UserSettings'; id: string; dealFeedShowIntroCallout: ShowHide };
  };
};

export type TouchDealFeedLastSeenAtMutationVariables = Exact<{ [key: string]: never }>;

export type TouchDealFeedLastSeenAtMutation = {
  __typename?: 'Mutation';
  touchDealFeedLastSeenAt: { __typename?: 'UserSettings'; id: string; dealFeedLastSeenAt?: string | null };
};

export type TouchNotificationsLastSeenAtMutationVariables = Exact<{ [key: string]: never }>;

export type TouchNotificationsLastSeenAtMutation = {
  __typename?: 'Mutation';
  touchNotificationsLastSeenAt: { __typename?: 'UserSettings'; id: string; notificationsLastSeenAt?: string | null };
};

export type FindPortfoliosQueryVariables = Exact<{
  teamId: Scalars['ID']['input'];
}>;

export type FindPortfoliosQuery = {
  __typename?: 'Query';
  team: {
    __typename?: 'Team';
    id: string;
    portfolios: Array<{
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    }>;
  };
};

export type SearchInvestedIntoQueryVariables = Exact<{
  teamId: Scalars['ID']['input'];
  input: LegalEntitySearchInput;
}>;

export type SearchInvestedIntoQuery = {
  __typename?: 'Query';
  team: {
    __typename?: 'Team';
    id: string;
    investedInto: Array<{
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    }>;
  };
};

export type ActivityPriceFragment = { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode };

export type ActivityRoundFragment = {
  __typename?: 'Round';
  id: string;
  date: string;
  roundType: EquityRoundType;
  urlPressRelease?: string | null;
  raisedAmount?: number | null;
  raisedCurrency?: CurrencyIsoCode | null;
  valuation?: number | null;
  valuationCurrency?: CurrencyIsoCode | null;
  valuationType: ValuationType;
  legalEntity: {
    __typename?: 'LegalEntity';
    legalName?: string | null;
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
  };
  price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
};

export type ActivityInvestmentFragment = {
  __typename?: 'Investment';
  id: string;
  isSecondary: boolean;
  numAssets?: number | null;
  date: string;
  conversion?: { __typename?: 'InvestmentConversion'; id: string } | null;
  asset:
    | { __typename: 'BaseAsset'; id: string; type: AssetType; name: string }
    | { __typename: 'ConvertibleAsset'; id: string; name: string; convertibleType: ConvertibleType };
  event?: {
    __typename?: 'Event';
    id: string;
    type: EventType;
    round?: {
      __typename?: 'Round';
      id: string;
      date: string;
      roundType: EquityRoundType;
      urlPressRelease?: string | null;
      raisedAmount?: number | null;
      raisedCurrency?: CurrencyIsoCode | null;
      valuation?: number | null;
      valuationCurrency?: CurrencyIsoCode | null;
      valuationType: ValuationType;
      legalEntity: {
        __typename?: 'LegalEntity';
        legalName?: string | null;
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
      };
      price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
    } | null;
  } | null;
  issuedByLegalEntity: { __typename?: 'LegalEntity'; id: string; displayName?: string | null };
  investmentMetrics: {
    __typename?: 'InvestmentMetrics';
    userCurrency: CurrencyIsoCode;
    investedAmount?: { __typename?: 'Metric'; inUserCurrency?: number | null } | null;
    moic?: { __typename?: 'Metric'; inUserCurrency?: number | null } | null;
  };
};

export type ActivityDistributionFragment = {
  __typename?: 'Distribution';
  id: string;
  date: string;
  amount: number;
  currency: CurrencyIsoCode;
};

export type ActivityFundDistributionFragment = {
  __typename?: 'Distribution';
  id: string;
  date: string;
  amount: number;
  currency: CurrencyIsoCode;
};

export type ActivityEventFragment = { __typename?: 'Event'; id: string; date: string; name: string; type: EventType };

export type PortfolioActivityQueryVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  asOfDate: Scalars['Date']['input'];
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
  userCurrency: CurrencyIsoCode;
}>;

export type PortfolioActivityQuery = {
  __typename?: 'Query';
  rounds: Array<{
    __typename?: 'Round';
    id: string;
    date: string;
    roundType: EquityRoundType;
    urlPressRelease?: string | null;
    raisedAmount?: number | null;
    raisedCurrency?: CurrencyIsoCode | null;
    valuation?: number | null;
    valuationCurrency?: CurrencyIsoCode | null;
    valuationType: ValuationType;
    legalEntity: {
      __typename?: 'LegalEntity';
      legalName?: string | null;
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    };
    price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
  }>;
  legalEntity?: {
    __typename?: 'LegalEntity';
    transactionlessEvents: Array<{
      __typename?: 'Event';
      id: string;
      date: string;
      type: EventType;
      data?: any | null;
    }>;
    portfolioCompanies: Array<{
      __typename?: 'PortfolioCompany';
      legalEntity: {
        __typename?: 'LegalEntity';
        legalName?: string | null;
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
        transactionlessEvents: Array<{ __typename?: 'Event'; id: string; date: string; name: string; type: EventType }>;
      };
      distributions: Array<{
        __typename?: 'Distribution';
        id: string;
        date: string;
        amount: number;
        currency: CurrencyIsoCode;
      }>;
      dividends: Array<{
        __typename?: 'Distribution';
        id: string;
        date: string;
        amount: number;
        currency: CurrencyIsoCode;
      }>;
      investments: Array<{
        __typename?: 'Investment';
        id: string;
        isSecondary: boolean;
        numAssets?: number | null;
        date: string;
        conversion?: { __typename?: 'InvestmentConversion'; id: string } | null;
        asset:
          | { __typename: 'BaseAsset'; id: string; type: AssetType; name: string }
          | { __typename: 'ConvertibleAsset'; id: string; name: string; convertibleType: ConvertibleType };
        event?: {
          __typename?: 'Event';
          id: string;
          type: EventType;
          round?: {
            __typename?: 'Round';
            id: string;
            date: string;
            roundType: EquityRoundType;
            urlPressRelease?: string | null;
            raisedAmount?: number | null;
            raisedCurrency?: CurrencyIsoCode | null;
            valuation?: number | null;
            valuationCurrency?: CurrencyIsoCode | null;
            valuationType: ValuationType;
            legalEntity: {
              __typename?: 'LegalEntity';
              legalName?: string | null;
              id: string;
              displayName?: string | null;
              investingEntityId?: string | null;
              profileLink?: string | null;
            };
            price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
          } | null;
        } | null;
        issuedByLegalEntity: { __typename?: 'LegalEntity'; id: string; displayName?: string | null };
        investmentMetrics: {
          __typename?: 'InvestmentMetrics';
          userCurrency: CurrencyIsoCode;
          investedAmount?: { __typename?: 'Metric'; inUserCurrency?: number | null } | null;
          moic?: { __typename?: 'Metric'; inUserCurrency?: number | null } | null;
        };
      }>;
    }>;
  } | null;
};

export type ConvertibleAssetFieldsFragment = {
  __typename?: 'ConvertibleAsset';
  id: string;
  assetId: string;
  name: string;
  convertibleType: ConvertibleType;
  currency: CurrencyIsoCode;
  valuationCap?: number | null;
  discountRate?: number | null;
  annualisedInterestRate?: number | null;
  maturityDate?: string | null;
  conversionPrice?: number | null;
  interest?: number | null;
};

export type ConversionFieldsFragment = {
  __typename?: 'InvestmentConversion';
  id: string;
  conversionDate: string;
  numConvertedAssets: Array<number>;
  convertedInto: Array<{ __typename?: 'BaseAsset'; id: string; type: AssetType; name: string }>;
};

export type InvestmentFieldsFragment = {
  __typename?: 'Investment';
  id: string;
  transactionDate: string;
  isSecondary: boolean;
  numAssets?: number | null;
  issuedByLegalEntity: {
    __typename?: 'LegalEntity';
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
  };
  spvLegalEntity?: {
    __typename?: 'LegalEntity';
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
  } | null;
  asset:
    | {
        __typename: 'BaseAsset';
        id: string;
        type: AssetType;
        name: string;
        latestPrice?: {
          __typename?: 'Price';
          date: string;
          id: string;
          price: number;
          currency: CurrencyIsoCode;
        } | null;
      }
    | {
        __typename: 'ConvertibleAsset';
        id: string;
        assetId: string;
        name: string;
        convertibleType: ConvertibleType;
        currency: CurrencyIsoCode;
        valuationCap?: number | null;
        discountRate?: number | null;
        annualisedInterestRate?: number | null;
        maturityDate?: string | null;
        conversionPrice?: number | null;
        interest?: number | null;
        latestPrice?: {
          __typename?: 'Price';
          date: string;
          id: string;
          price: number;
          currency: CurrencyIsoCode;
        } | null;
      };
  conversion?: {
    __typename?: 'InvestmentConversion';
    id: string;
    conversionDate: string;
    numConvertedAssets: Array<number>;
    event?: {
      __typename?: 'Event';
      id: string;
      date: string;
      type: EventType;
      data?: any | null;
      round?: {
        __typename?: 'Round';
        id: string;
        roundType: EquityRoundType;
        date: string;
        raisedAmount?: number | null;
        raisedCurrency?: CurrencyIsoCode | null;
        valuation?: number | null;
        valuationCurrency?: CurrencyIsoCode | null;
        valuationType: ValuationType;
        price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
        roundDocuments: Array<{
          __typename?: 'RoundDocument';
          id: string;
          createdAt: string;
          downloadURL?: string | null;
          document: { __typename?: 'Document'; id: string; description?: string | null };
          creator: { __typename?: 'User'; id: string; email: string };
        }>;
      } | null;
    } | null;
    convertedInto: Array<{ __typename?: 'BaseAsset'; id: string; type: AssetType; name: string }>;
  } | null;
  event?: {
    __typename?: 'Event';
    id: string;
    date: string;
    type: EventType;
    data?: any | null;
    round?: {
      __typename?: 'Round';
      id: string;
      roundType: EquityRoundType;
      date: string;
      raisedAmount?: number | null;
      raisedCurrency?: CurrencyIsoCode | null;
      valuation?: number | null;
      valuationCurrency?: CurrencyIsoCode | null;
      valuationType: ValuationType;
      price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
      roundDocuments: Array<{
        __typename?: 'RoundDocument';
        id: string;
        createdAt: string;
        downloadURL?: string | null;
        document: { __typename?: 'Document'; id: string; description?: string | null };
        creator: { __typename?: 'User'; id: string; email: string };
      }>;
    } | null;
    otherPortfolios: Array<{
      __typename?: 'LegalEntity';
      id: string;
      displayName?: string | null;
      investingEntityId?: string | null;
      profileLink?: string | null;
    }>;
  } | null;
  investmentMetrics: {
    __typename?: 'InvestmentMetrics';
    asOfDate: string;
    userCurrency: CurrencyIsoCode;
    investmentCurrency?: CurrencyIsoCode | null;
    investedAmount?: {
      __typename?: 'Metric';
      inInvestmentCurrency?: number | null;
      inPinnedUserCurrency?: number | null;
      inUserCurrency?: number | null;
    } | null;
    holdingsValue?: {
      __typename?: 'Metric';
      inPinnedUserCurrency?: number | null;
      inUserCurrency?: number | null;
    } | null;
    moic?: { __typename?: 'Metric'; inPinnedUserCurrency?: number | null; inUserCurrency?: number | null } | null;
    irr?: { __typename?: 'Metric'; inPinnedUserCurrency?: number | null; inUserCurrency?: number | null } | null;
  };
};

export type DistributionFieldsFragment = {
  __typename?: 'Distribution';
  id: string;
  type: EventType;
  eventId: string;
  date: string;
  amount: number;
  currency: CurrencyIsoCode;
  amountInUserCurrency: {
    __typename?: 'OverviewMetric';
    inUserCurrency?: number | null;
    inPinnedUserCurrency?: number | null;
  };
};

export type StoredMetricsFieldsFragment = {
  __typename?: 'StoredPortfolioCompanyMetrics';
  id: string;
  markdownDate?: string | null;
};

export type PortfolioCompanyQueryVariables = Exact<{
  viewerId: Scalars['ID']['input'];
  portfolioCompanyId: Scalars['ID']['input'];
  asOfDate: Scalars['Date']['input'];
  userCurrency: CurrencyIsoCode;
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PortfolioCompanyQuery = {
  __typename?: 'Query';
  viewerLegalEntity?: {
    __typename?: 'LegalEntity';
    legalName?: string | null;
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
    portfolioCompany?: {
      __typename?: 'PortfolioCompany';
      profileSlug?: string | null;
      portfolioLegalEntity: {
        __typename?: 'LegalEntity';
        legalName?: string | null;
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
        publicProfile?:
          | { __typename?: 'CompanyProfile'; id: string; slug?: string | null; website?: string | null }
          | { __typename?: 'Profile' }
          | null;
        transactionlessEvents: Array<{
          __typename?: 'Event';
          id: string;
          date: string;
          type: EventType;
          data?: any | null;
          round?: {
            __typename?: 'Round';
            id: string;
            roundType: EquityRoundType;
            date: string;
            raisedAmount?: number | null;
            raisedCurrency?: CurrencyIsoCode | null;
            valuation?: number | null;
            valuationCurrency?: CurrencyIsoCode | null;
            valuationType: ValuationType;
            price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
            roundDocuments: Array<{
              __typename?: 'RoundDocument';
              id: string;
              createdAt: string;
              downloadURL?: string | null;
              document: { __typename?: 'Document'; id: string; description?: string | null };
              creator: { __typename?: 'User'; id: string; email: string };
            }>;
          } | null;
          otherPortfolios: Array<{
            __typename?: 'LegalEntity';
            id: string;
            displayName?: string | null;
            investingEntityId?: string | null;
            profileLink?: string | null;
          }>;
        }>;
      };
      companyMetrics: {
        __typename?: 'PortfolioCompanyMetrics';
        numInvestments: number;
        userCurrency: CurrencyIsoCode;
        asOfDate: string;
        investedAmount?: number | null;
        stored?: { __typename?: 'StoredPortfolioCompanyMetrics'; id: string; markdownDate?: string | null } | null;
        unrealisedValue?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        realisedValue?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        moic?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        irr?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
      };
      investments: Array<{
        __typename?: 'Investment';
        id: string;
        transactionDate: string;
        isSecondary: boolean;
        numAssets?: number | null;
        issuedByLegalEntity: {
          __typename?: 'LegalEntity';
          id: string;
          displayName?: string | null;
          investingEntityId?: string | null;
          profileLink?: string | null;
        };
        spvLegalEntity?: {
          __typename?: 'LegalEntity';
          id: string;
          displayName?: string | null;
          investingEntityId?: string | null;
          profileLink?: string | null;
        } | null;
        asset:
          | {
              __typename: 'BaseAsset';
              id: string;
              type: AssetType;
              name: string;
              latestPrice?: {
                __typename?: 'Price';
                date: string;
                id: string;
                price: number;
                currency: CurrencyIsoCode;
              } | null;
            }
          | {
              __typename: 'ConvertibleAsset';
              id: string;
              assetId: string;
              name: string;
              convertibleType: ConvertibleType;
              currency: CurrencyIsoCode;
              valuationCap?: number | null;
              discountRate?: number | null;
              annualisedInterestRate?: number | null;
              maturityDate?: string | null;
              conversionPrice?: number | null;
              interest?: number | null;
              latestPrice?: {
                __typename?: 'Price';
                date: string;
                id: string;
                price: number;
                currency: CurrencyIsoCode;
              } | null;
            };
        conversion?: {
          __typename?: 'InvestmentConversion';
          id: string;
          conversionDate: string;
          numConvertedAssets: Array<number>;
          event?: {
            __typename?: 'Event';
            id: string;
            date: string;
            type: EventType;
            data?: any | null;
            round?: {
              __typename?: 'Round';
              id: string;
              roundType: EquityRoundType;
              date: string;
              raisedAmount?: number | null;
              raisedCurrency?: CurrencyIsoCode | null;
              valuation?: number | null;
              valuationCurrency?: CurrencyIsoCode | null;
              valuationType: ValuationType;
              price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
              roundDocuments: Array<{
                __typename?: 'RoundDocument';
                id: string;
                createdAt: string;
                downloadURL?: string | null;
                document: { __typename?: 'Document'; id: string; description?: string | null };
                creator: { __typename?: 'User'; id: string; email: string };
              }>;
            } | null;
          } | null;
          convertedInto: Array<{ __typename?: 'BaseAsset'; id: string; type: AssetType; name: string }>;
        } | null;
        event?: {
          __typename?: 'Event';
          id: string;
          date: string;
          type: EventType;
          data?: any | null;
          round?: {
            __typename?: 'Round';
            id: string;
            roundType: EquityRoundType;
            date: string;
            raisedAmount?: number | null;
            raisedCurrency?: CurrencyIsoCode | null;
            valuation?: number | null;
            valuationCurrency?: CurrencyIsoCode | null;
            valuationType: ValuationType;
            price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
            roundDocuments: Array<{
              __typename?: 'RoundDocument';
              id: string;
              createdAt: string;
              downloadURL?: string | null;
              document: { __typename?: 'Document'; id: string; description?: string | null };
              creator: { __typename?: 'User'; id: string; email: string };
            }>;
          } | null;
          otherPortfolios: Array<{
            __typename?: 'LegalEntity';
            id: string;
            displayName?: string | null;
            investingEntityId?: string | null;
            profileLink?: string | null;
          }>;
        } | null;
        investmentMetrics: {
          __typename?: 'InvestmentMetrics';
          asOfDate: string;
          userCurrency: CurrencyIsoCode;
          investmentCurrency?: CurrencyIsoCode | null;
          investedAmount?: {
            __typename?: 'Metric';
            inInvestmentCurrency?: number | null;
            inPinnedUserCurrency?: number | null;
            inUserCurrency?: number | null;
          } | null;
          holdingsValue?: {
            __typename?: 'Metric';
            inPinnedUserCurrency?: number | null;
            inUserCurrency?: number | null;
          } | null;
          moic?: { __typename?: 'Metric'; inPinnedUserCurrency?: number | null; inUserCurrency?: number | null } | null;
          irr?: { __typename?: 'Metric'; inPinnedUserCurrency?: number | null; inUserCurrency?: number | null } | null;
        };
      }>;
      distributions: Array<{
        __typename?: 'Distribution';
        id: string;
        type: EventType;
        eventId: string;
        date: string;
        amount: number;
        currency: CurrencyIsoCode;
        amountInUserCurrency: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        };
      }>;
      dividends: Array<{
        __typename?: 'Distribution';
        id: string;
        type: EventType;
        eventId: string;
        date: string;
        amount: number;
        currency: CurrencyIsoCode;
        amountInUserCurrency: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        };
      }>;
      fundDistributions: Array<{
        __typename?: 'Distribution';
        id: string;
        type: EventType;
        eventId: string;
        date: string;
        amount: number;
        currency: CurrencyIsoCode;
        amountInUserCurrency: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        };
      }>;
    } | null;
  } | null;
};

export type UpsertOwnershipMutationVariables = Exact<{
  companyId: Scalars['ID']['input'];
  investorId: Scalars['ID']['input'];
  newValue: Scalars['Float']['input'];
}>;

export type UpsertOwnershipMutation = { __typename?: 'Mutation'; upsertOwnership: boolean };

export type DeleteOwnershipMutationVariables = Exact<{
  portfolioCompanyMetricsId: Scalars['ID']['input'];
}>;

export type DeleteOwnershipMutation = { __typename?: 'Mutation'; deleteOwnership: boolean };

export type PortfolioOverviewInvestmentFieldsFragment = {
  __typename?: 'Investment';
  id: string;
  transactionDate: string;
  isSecondary: boolean;
  spvLegalEntity?: {
    __typename?: 'LegalEntity';
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
  } | null;
  event?: { __typename?: 'Event'; id: string; name: string } | null;
  asset:
    | { __typename: 'BaseAsset'; id: string; type: AssetType }
    | { __typename: 'ConvertibleAsset'; id: string; convertibleType: ConvertibleType };
  conversion?: { __typename?: 'InvestmentConversion'; id: string } | null;
  investmentMetrics: {
    __typename?: 'InvestmentMetrics';
    investedAmount?: {
      __typename?: 'Metric';
      inPinnedUserCurrency?: number | null;
      inUserCurrency?: number | null;
    } | null;
    holdingsValue?: {
      __typename?: 'Metric';
      inPinnedUserCurrency?: number | null;
      inUserCurrency?: number | null;
    } | null;
    moic?: { __typename?: 'Metric'; inUserCurrency?: number | null; inPinnedUserCurrency?: number | null } | null;
    irr?: { __typename?: 'Metric'; inUserCurrency?: number | null; inPinnedUserCurrency?: number | null } | null;
  };
};

export type PortfolioOverviewQueryVariables = Exact<{
  legalEntityId: Scalars['ID']['input'];
  asOfDate: Scalars['Date']['input'];
  userCurrency: CurrencyIsoCode;
  cohortYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PortfolioOverviewQuery = {
  __typename?: 'Query';
  legalEntity?: {
    __typename?: 'LegalEntity';
    type?: LegalEntityType | null;
    legalName?: string | null;
    id: string;
    displayName?: string | null;
    investingEntityId?: string | null;
    profileLink?: string | null;
    portfolioMetrics: {
      __typename?: 'PortfolioMetrics';
      investedAmount?: number | null;
      paidIntoFund?: number | null;
      unrealisedValue?: {
        __typename?: 'OverviewMetric';
        inUserCurrency?: number | null;
        inPinnedUserCurrency?: number | null;
      } | null;
      realisedValue?: {
        __typename?: 'OverviewMetric';
        inUserCurrency?: number | null;
        inPinnedUserCurrency?: number | null;
      } | null;
      lpIrr?: {
        __typename?: 'OverviewMetric';
        inUserCurrency?: number | null;
        inPinnedUserCurrency?: number | null;
      } | null;
      tvpi?: {
        __typename?: 'OverviewMetric';
        inUserCurrency?: number | null;
        inPinnedUserCurrency?: number | null;
      } | null;
      moic?: {
        __typename?: 'OverviewMetric';
        inUserCurrency?: number | null;
        inPinnedUserCurrency?: number | null;
      } | null;
      dealIrr?: {
        __typename?: 'OverviewMetric';
        inUserCurrency?: number | null;
        inPinnedUserCurrency?: number | null;
      } | null;
    };
    portfolioCompanies: Array<{
      __typename?: 'PortfolioCompany';
      legalEntity: {
        __typename?: 'LegalEntity';
        legalName?: string | null;
        id: string;
        displayName?: string | null;
        investingEntityId?: string | null;
        profileLink?: string | null;
      };
      companyMetrics: {
        __typename?: 'PortfolioCompanyMetrics';
        numInvestments: number;
        firstInvestedDate?: string | null;
        lastUpdated?: string | null;
        userCurrency: CurrencyIsoCode;
        asOfDate: string;
        investedAmount?: number | null;
        stored?: {
          __typename?: 'StoredPortfolioCompanyMetrics';
          id: string;
          owned?: number | null;
          markdownDate?: string | null;
        } | null;
        unrealisedValue?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        realisedValue?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        moic?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
        irr?: {
          __typename?: 'OverviewMetric';
          inUserCurrency?: number | null;
          inPinnedUserCurrency?: number | null;
        } | null;
      };
      investments: Array<{
        __typename?: 'Investment';
        id: string;
        transactionDate: string;
        isSecondary: boolean;
        spvLegalEntity?: {
          __typename?: 'LegalEntity';
          id: string;
          displayName?: string | null;
          investingEntityId?: string | null;
          profileLink?: string | null;
        } | null;
        event?: { __typename?: 'Event'; id: string; name: string } | null;
        asset:
          | { __typename: 'BaseAsset'; id: string; type: AssetType }
          | { __typename: 'ConvertibleAsset'; id: string; convertibleType: ConvertibleType };
        conversion?: { __typename?: 'InvestmentConversion'; id: string } | null;
        investmentMetrics: {
          __typename?: 'InvestmentMetrics';
          investedAmount?: {
            __typename?: 'Metric';
            inPinnedUserCurrency?: number | null;
            inUserCurrency?: number | null;
          } | null;
          holdingsValue?: {
            __typename?: 'Metric';
            inPinnedUserCurrency?: number | null;
            inUserCurrency?: number | null;
          } | null;
          moic?: { __typename?: 'Metric'; inUserCurrency?: number | null; inPinnedUserCurrency?: number | null } | null;
          irr?: { __typename?: 'Metric'; inUserCurrency?: number | null; inPinnedUserCurrency?: number | null } | null;
        };
      }>;
    }>;
  } | null;
};

export type DatelessPriceFieldsFragment = {
  __typename?: 'Price';
  id: string;
  price: number;
  currency: CurrencyIsoCode;
};

export type PriceFieldsFragment = {
  __typename?: 'Price';
  date: string;
  id: string;
  price: number;
  currency: CurrencyIsoCode;
};

export type RoundFieldsFragment = {
  __typename?: 'Round';
  id: string;
  roundType: EquityRoundType;
  date: string;
  raisedAmount?: number | null;
  raisedCurrency?: CurrencyIsoCode | null;
  valuation?: number | null;
  valuationCurrency?: CurrencyIsoCode | null;
  valuationType: ValuationType;
  price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
  roundDocuments: Array<{
    __typename?: 'RoundDocument';
    id: string;
    createdAt: string;
    downloadURL?: string | null;
    document: { __typename?: 'Document'; id: string; description?: string | null };
    creator: { __typename?: 'User'; id: string; email: string };
  }>;
};

export type DeleteRoundDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteRoundDocumentMutation = {
  __typename?: 'Mutation';
  roundDocument: { __typename?: 'RoundDocumentMutations'; delete: { __typename?: 'DealDocument'; id: string } };
};

export type UpdateConvertibleRoundMutationVariables = Exact<{
  roundInput: UpdateRoundInput;
  convertibleInput: UpdateConvertiblePropertiesInput;
}>;

export type UpdateConvertibleRoundMutation = {
  __typename?: 'Mutation';
  updateConvertibleProperties: boolean;
  round: {
    __typename?: 'RoundMutations';
    update?: {
      __typename?: 'Round';
      id: string;
      roundType: EquityRoundType;
      date: string;
      raisedAmount?: number | null;
      raisedCurrency?: CurrencyIsoCode | null;
      valuation?: number | null;
      valuationCurrency?: CurrencyIsoCode | null;
      valuationType: ValuationType;
      price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
      roundDocuments: Array<{
        __typename?: 'RoundDocument';
        id: string;
        createdAt: string;
        downloadURL?: string | null;
        document: { __typename?: 'Document'; id: string; description?: string | null };
        creator: { __typename?: 'User'; id: string; email: string };
      }>;
    } | null;
  };
};

export type UpdateRoundAndPriceMutationVariables = Exact<{
  roundInput: UpdateRoundInput;
  priceInput: UpdateRoundPriceInput;
}>;

export type UpdateRoundAndPriceMutation = {
  __typename?: 'Mutation';
  round: {
    __typename?: 'RoundMutations';
    update?: {
      __typename?: 'Round';
      id: string;
      roundType: EquityRoundType;
      date: string;
      raisedAmount?: number | null;
      raisedCurrency?: CurrencyIsoCode | null;
      valuation?: number | null;
      valuationCurrency?: CurrencyIsoCode | null;
      valuationType: ValuationType;
      price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
      roundDocuments: Array<{
        __typename?: 'RoundDocument';
        id: string;
        createdAt: string;
        downloadURL?: string | null;
        document: { __typename?: 'Document'; id: string; description?: string | null };
        creator: { __typename?: 'User'; id: string; email: string };
      }>;
    } | null;
  };
  roundPrice: {
    __typename?: 'RoundPriceMutations';
    update: {
      __typename?: 'RoundPriceResponse';
      price?: { __typename?: 'Price'; date: string; id: string; price: number; currency: CurrencyIsoCode } | null;
    };
  };
};

export type UpdateRoundAndCreatePriceMutationVariables = Exact<{
  roundInput: UpdateRoundInput;
  priceInput: CreateRoundPriceInput;
}>;

export type UpdateRoundAndCreatePriceMutation = {
  __typename?: 'Mutation';
  round: {
    __typename?: 'RoundMutations';
    update?: {
      __typename?: 'Round';
      id: string;
      roundType: EquityRoundType;
      date: string;
      raisedAmount?: number | null;
      raisedCurrency?: CurrencyIsoCode | null;
      valuation?: number | null;
      valuationCurrency?: CurrencyIsoCode | null;
      valuationType: ValuationType;
      price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
      roundDocuments: Array<{
        __typename?: 'RoundDocument';
        id: string;
        createdAt: string;
        downloadURL?: string | null;
        document: { __typename?: 'Document'; id: string; description?: string | null };
        creator: { __typename?: 'User'; id: string; email: string };
      }>;
    } | null;
  };
  roundPrice: {
    __typename?: 'RoundPriceMutations';
    create: {
      __typename?: 'RoundPriceResponse';
      price?: { __typename?: 'Price'; date: string; id: string; price: number; currency: CurrencyIsoCode } | null;
    };
  };
};

export type CreateRoundAndPriceMutationVariables = Exact<{
  input: CreateRoundInput;
}>;

export type CreateRoundAndPriceMutation = {
  __typename?: 'Mutation';
  round: {
    __typename?: 'RoundMutations';
    create: {
      __typename?: 'RoundPriceResponse';
      round?: {
        __typename?: 'Round';
        id: string;
        roundType: EquityRoundType;
        date: string;
        raisedAmount?: number | null;
        raisedCurrency?: CurrencyIsoCode | null;
        valuation?: number | null;
        valuationCurrency?: CurrencyIsoCode | null;
        valuationType: ValuationType;
        price?: { __typename?: 'Price'; id: string; price: number; currency: CurrencyIsoCode } | null;
        roundDocuments: Array<{
          __typename?: 'RoundDocument';
          id: string;
          createdAt: string;
          downloadURL?: string | null;
          document: { __typename?: 'Document'; id: string; description?: string | null };
          creator: { __typename?: 'User'; id: string; email: string };
        }>;
      } | null;
      price?: { __typename?: 'Price'; date: string; id: string; price: number; currency: CurrencyIsoCode } | null;
    };
  };
};

export type SchemaHashQueryVariables = Exact<{ [key: string]: never }>;

export type SchemaHashQuery = { __typename?: 'Query'; schemaHash: string };

export type TeamFieldsFragment = {
  __typename?: 'Team';
  id: string;
  name: string;
  members: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    username: string;
    publicProfile?: { __typename?: 'Profile'; id: string; imageUrl?: string | null } | null;
  }>;
};

export type FindManyTeamByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type FindManyTeamByNameQuery = {
  __typename?: 'Query';
  teamsByName: Array<{ __typename?: 'Team'; id: string; name: string }>;
};

export type UpdateValuationAndAddNoteMutationVariables = Exact<{
  assetId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
  markupDate: Scalars['Date']['input'];
  newValue: Scalars['Float']['input'];
  valueCurrency: CurrencyIsoCode;
  note?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateValuationAndAddNoteMutation = { __typename?: 'Mutation'; markupValuationByAmount: boolean };

export type UserContextQueryVariables = Exact<{
  email: Scalars['EmailAddress']['input'];
}>;

export type UserContextQuery = {
  __typename?: 'Query';
  userByEmail: {
    __typename?: 'User';
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    phoneNumber?: string | null;
    username: string;
    isPlatformAdmin: boolean;
    hasAccess: boolean;
    hasCompletedRegistration: boolean;
    hasPublishedToDealFeed: boolean;
    teamHasDeals: boolean;
    defaultTeam: {
      __typename?: 'Team';
      id: string;
      name: string;
      members: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: { __typename?: 'Profile'; id: string; imageUrl?: string | null } | null;
      }>;
    };
    teams: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      members: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        username: string;
        publicProfile?: { __typename?: 'Profile'; id: string; imageUrl?: string | null } | null;
      }>;
    }>;
    permission: {
      __typename?: 'PermissionSummary';
      readonly: boolean;
      readonlyByTeam: Array<{ __typename?: 'ReadonlyByTeam'; id: string; teamId: string; readonly: boolean }>;
    };
    publicProfile?: {
      __typename?: 'Profile';
      id: string;
      fullname: string;
      imageUrl?: string | null;
      description: string;
      linkedin?: string | null;
      descriptorsGeo: Array<string>;
      descriptorsInvestorType: Array<string>;
      descriptorsStage: Array<string>;
      descriptorsMiscTags: Array<string>;
      slug?: string | null;
      roles: Array<{
        __typename?: 'ProfileRole';
        id: string;
        description?: string | null;
        entityProfile?: { __typename?: 'Profile'; id: string; fullname: string; slug?: string | null } | null;
      }>;
    } | null;
    userSettings: {
      __typename?: 'UserSettings';
      id: string;
      dealFeedDefaultShared: boolean;
      notifyCompletedDeals: boolean;
      notifySameDealEmail: boolean;
      notifySameDealWhatsapp: boolean;
      notifyCuriousEmail: boolean;
      notifyCuriousWhatsapp: boolean;
      notifyLikesEmail: boolean;
      notifyLikesWhatsapp: boolean;
      dealFeedLastSeenAt?: string | null;
      notificationsLastSeenAt?: string | null;
      dealFeedShowIntroCallout: ShowHide;
      promptProfileSetup: boolean;
      discoverableInConnections: boolean;
    };
  };
};

export type UsersByEmailPrefixQueryVariables = Exact<{
  emailPrefix: Scalars['String']['input'];
}>;

export type UsersByEmailPrefixQuery = {
  __typename?: 'Query';
  usersByEmailPrefix: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    nemoToken: string;
    username: string;
    publicProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
  }>;
};

export type UsersNotCompletedRegistrationQueryVariables = Exact<{ [key: string]: never }>;

export type UsersNotCompletedRegistrationQuery = {
  __typename?: 'Query';
  usersNotCompletedRegistration: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    createdAt: string;
    hasCompletedRegistration: boolean;
    hasAccess: boolean;
    lastRemindedAt?: string | null;
  }>;
};

export type UsersBlockedQueryVariables = Exact<{ [key: string]: never }>;

export type UsersBlockedQuery = {
  __typename?: 'Query';
  usersBlocked: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    createdAt: string;
    hasCompletedRegistration: boolean;
    hasAccess: boolean;
    lastRemindedAt?: string | null;
  }>;
};

export type UpdateUsernameMutationVariables = Exact<{
  input: UpdateUsernameInput;
}>;

export type UpdateUsernameMutation = {
  __typename?: 'Mutation';
  user: { __typename?: 'UserMutations'; updateUsername: { __typename?: 'User'; id: string; username: string } };
};

export type CreateUserFromEmailOnlyMutationVariables = Exact<{
  input: CreateUserFromEmailOnlyInput;
}>;

export type CreateUserFromEmailOnlyMutation = {
  __typename?: 'Mutation';
  createUserFromEmailOnly: { __typename?: 'User'; id: string };
};

export type FillOutUserEmailOnlyAccountMutationVariables = Exact<{
  input: FillOutUserEmailOnlyAccountInput;
}>;

export type FillOutUserEmailOnlyAccountMutation = {
  __typename?: 'Mutation';
  user: { __typename?: 'UserMutations'; fillOutUserEmailOnlyAccount: { __typename?: 'User'; id: string } };
};

export type IsEmailTakenQueryVariables = Exact<{
  email: Scalars['EmailAddress']['input'];
}>;

export type IsEmailTakenQuery = { __typename?: 'Query'; isEmailTaken: boolean };

export type CheckSlugIsValidQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type CheckSlugIsValidQuery = { __typename?: 'Query'; checkSlugIsValid: boolean };

export type SetLastRemindedAtMutationVariables = Exact<{
  input: SetLastRemindedAtInput;
}>;

export type SetLastRemindedAtMutation = {
  __typename?: 'Mutation';
  user: {
    __typename?: 'UserMutations';
    setLastRemindedAt: { __typename?: 'User'; id: string; lastRemindedAt?: string | null };
  };
};

export type RevokeAccessMutationVariables = Exact<{
  input: RevokeAccessInput;
}>;

export type RevokeAccessMutation = {
  __typename?: 'Mutation';
  user: {
    __typename?: 'UserMutations';
    revokeAccess: { __typename?: 'User'; id: string; username: string; hasAccess: boolean };
  };
};

export type UpdatePublicProfileMutationVariables = Exact<{
  input: UpdatePublicProfileInput;
}>;

export type UpdatePublicProfileMutation = {
  __typename?: 'Mutation';
  user: {
    __typename?: 'UserMutations';
    updatePublicProfile: {
      __typename?: 'User';
      id: string;
      publicProfile?: {
        __typename?: 'Profile';
        id: string;
        fullname: string;
        linkedin?: string | null;
        roles: Array<{
          __typename?: 'ProfileRole';
          id: string;
          entityProfile?: { __typename?: 'Profile'; id: string; fullname: string } | null;
        }>;
      } | null;
    };
  };
};

export const PostInteractionDisplayFieldsFragmentDoc = gql`
  fragment PostInteractionDisplayFields on PostInteraction {
    id
    type
    createdAt
    postId
    creator {
      id
      phoneNumber
      publicProfile {
        id
        fullname
        imageUrl
        slug
        roles {
          id
          description
          entityProfile {
            id
            fullname
          }
        }
      }
    }
  }
`;
export const NotificationDisplayFieldsFragmentDoc = gql`
  fragment NotificationDisplayFields on NotificationGroup {
    id
    type
    updatedAt
    nemoSubmission {
      id
      companyName
      companyUrl
      roundType
      raisedCurrency
      raisedAmount
      createdAt
      companyCity
      companyCountry
      teaserSummary
      sharedToDealFeedAt
      list {
        slug
      }
    }
    notifications {
      __typename
      ... on InteractionNotification {
        id
        updatedAt
        interaction {
          id
          type
          ...PostInteractionDisplayFields
          dealShareRequests {
            id
            channel
            accepted
            whatsAppIntroUrl
          }
        }
      }
      ... on FounderIntroRequestNotification {
        id
        updatedAt
        founderIntroRequest {
          id
          accepted
          comment
          profile {
            id
            fullname
            imageUrl
            slug
            emails {
              email
            }
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
          nemoSubmission {
            id
            founders {
              id
              isSource
              profile {
                id
                fullname
                emails {
                  email
                }
              }
            }
          }
        }
      }
      ... on DealActivityNotification {
        id
        updatedAt
        change {
          __typename
          ... on NemoSubmissionNote {
            id
            createdAt
            creator {
              id
              email
              username
              publicProfile {
                id
                fullname
                slug
                imageUrl
              }
            }
            content
          }
          ... on NemoSubmissionChange {
            id
            createdAt
            creator {
              id
              email
              username
              publicProfile {
                id
                fullname
                slug
                imageUrl
                roles {
                  id
                  description
                  entityProfile {
                    id
                    fullname
                  }
                }
              }
            }
            section
            diffs {
              field
              identifier
              oldValue
              newValue
            }
          }
        }
      }
      ... on InviteeJoinedNotification {
        id
        updatedAt
        invitee {
          id
          publicProfile {
            id
            fullname
            slug
            imageUrl
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
        }
      }
      ... on ReceivedDealflowAccessNotification {
        id
        updatedAt
        issuer {
          id
          publicProfile {
            id
            slug
            fullname
            imageUrl
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
        }
      }
      ... on DealReceivedNotification {
        id
        updatedAt
        nemoSubmission {
          id
          message
          companyName
          companyUrl
          teaserSummary
          raisedAmount
          roundType
          raisedCurrency
          list {
            slug
          }
        }
        sender {
          id
          publicProfile {
            id
            slug
            fullname
            imageUrl
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
        }
      }
    }
  }
  ${PostInteractionDisplayFieldsFragmentDoc}
`;
export const CompanyProfileFieldsFragmentDoc = gql`
  fragment CompanyProfileFields on CompanyProfile {
    id
    name
    slug
    otherNames
    website
    description
    themes
    country
    city
    status
    yearFounded
    teamId
  }
`;
export const ProfileFieldsFragmentDoc = gql`
  fragment ProfileFields on Profile {
    teamId
    id
    firstInvested
    type
    fullname
    slug
    imageUrl
    country
    city
    description
    personalWebsite
    linkedin
    locations
    themes
    stages
    markets
    customers
    businessModels
    description
    totalInvested
    totalValue
    deals {
      createdAt
      creator {
        username
      }
      roundType
      companyName
      raisedAmount
      raisedCurrency
      list {
        slug
      }
      sourceProfiles {
        slug
        fullname
      }
    }
    investments {
      id
      roundType
      date
      companyName
      companyProfile {
        ...CompanyProfileFields
        founders {
          id
          fullname
          linkedin
        }
      }
    }
    socialLinks {
      id
      url
    }
    roles {
      id
      description
      entityProfile {
        id
        fullname
        slug
      }
    }
    emails {
      id
      email
    }
    azavaUsers {
      id
      username
    }
    dealsSourced {
      id
      companyName
      companyUrl
      companyCity
      companyCountry
      teaserSummary
      roundType
      raisedCurrency
      raisedAmount
      createdAt
      status
      teamId
      userTags
      markets
      sharedToDealFeedAt
      seenBy {
        id
        publicProfile {
          id
          fullname
          imageUrl
          roles {
            id
            description
            entityProfile {
              id
              fullname
            }
          }
        }
      }
      list {
        id
        slug
      }
      assignee {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
    }
    lastInteractedWith
  }
  ${CompanyProfileFieldsFragmentDoc}
`;
export const ProfileFieldsNestedFragmentDoc = gql`
  fragment ProfileFieldsNested on Profile {
    ...ProfileFields
    privateProfiles {
      ...ProfileFields
    }
  }
  ${ProfileFieldsFragmentDoc}
`;
export const PorfolioViewFieldsFragmentDoc = gql`
  fragment PorfolioViewFields on PortfolioView {
    id
    name
    slug
  }
`;
export const PostDisplayFieldsFragmentDoc = gql`
  fragment PostDisplayFields on Post {
    id
    comment
    createdAt
    fidelity
    tags
    interactions {
      id
      type
      creator {
        id
      }
      dealShareRequests {
        accepted
        dealRequester {
          id
          publicProfile {
            id
            fullname
          }
        }
        dealOwner {
          id
          publicProfile {
            id
            fullname
          }
        }
      }
    }
    nemoSubmission {
      id
      teamId
      roundType
      raisedCurrency
      raisedAmount
      hasLead
      leadBy
      comment
      feedbackGiven
      notificationAsked
      rawSource
      companyName
      companyUrl
      companyCity
      companyCountry
      status
      sharedToDealFeedAt
      teaserSummary
      tags {
        id
        tag
      }
      markets
      customerTypes
      businessModels
      list {
        id
        slug
      }
      assignee {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
      creator {
        id
        email
        username
      }
      founders {
        id
        name
        linkedin
        role
        description
        profile {
          id
          emails {
            id
            email
          }
        }
        isSource
      }
    }
    creator {
      id
      publicProfile {
        id
        fullname
        shortDescription
        imageUrl
        roles {
          id
          description
          entityProfile {
            id
            fullname
          }
        }
        firstCoinvestmentProfile {
          id
          fullname
        }
        descriptorsGeo
        descriptorsInvestorType
        descriptorsStage
        descriptorsMiscTags
      }
    }
  }
`;
export const FeedDisplayFieldsFragmentDoc = gql`
  fragment FeedDisplayFields on NemoSubmission {
    id
    teamId
    roundType
    raisedCurrency
    raisedAmount
    createdAt
    hasLead
    leadBy
    comment
    feedbackGiven
    notificationAsked
    rawSource
    companyName
    companyUrl
    companyCity
    companyCountry
    teaserSummary
    sharedToDealFeedAt
    status
    post {
      id
      comment
    }
    tags {
      id
      tag
    }
    markets
    customerTypes
    businessModels
    list {
      id
      slug
    }
    assignee {
      id
      publicProfile {
        id
        fullname
        imageUrl
        roles {
          id
          description
          entityProfile {
            id
            fullname
          }
        }
      }
    }
    creator {
      id
      email
      username
    }
    founders {
      id
      name
      linkedin
      role
      description
      profile {
        id
        emails {
          id
          email
        }
      }
      isSource
    }
  }
`;
export const ListDisplayFieldsFragmentDoc = gql`
  fragment ListDisplayFields on List {
    id
    slug
    submissions {
      id
      teamId
      roundType
      raisedCurrency
      raisedAmount
      hasLead
      leadBy
      comment
      feedbackGiven
      notificationAsked
      rawSource
      companyName
      companyUrl
      companyCity
      companyCountry
      status
      sharedToDealFeedAt
      teaserSummary
      isDemo
      secondarySubmissions {
        id
        list {
          id
          slug
        }
      }
      seenBy {
        id
        publicProfile {
          id
          fullname
          imageUrl
          roles {
            id
            description
            entityProfile {
              id
              fullname
            }
          }
        }
      }
      tags {
        id
        tag
      }
      assignee {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
          slug
        }
      }
      creator {
        id
        email
        username
      }
      dealDocuments {
        id
        document {
          id
          description
          objectUri
        }
      }
      founders {
        id
        name
        linkedin
        linkedinData
        profilePicUrl
        role
        description
        profile {
          id
          emails {
            id
            email
          }
        }
        isSource
      }
      myForks {
        id
        list {
          id
          slug
        }
      }
      post {
        id
        comment
        createdAt
        tags
        interactions {
          id
          type
          creator {
            id
          }
        }
        creator {
          id
          publicProfile {
            id
            fullname
            imageUrl
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
        }
      }
      markets
      userTags
      customerTypes
      businessModels
      companyProfile {
        id
        competitors {
          id
          fullname
        }
      }
    }
  }
`;
export const NoteFieldsFragmentDoc = gql`
  fragment NoteFields on Note {
    id
    message
    updatedAt
    createdAt
  }
`;
export const LegalEntityDisplayFieldsFragmentDoc = gql`
  fragment LegalEntityDisplayFields on LegalEntity {
    id
    displayName
    investingEntityId
    profileLink
  }
`;
export const LegalEntityFieldsFragmentDoc = gql`
  fragment LegalEntityFields on LegalEntity {
    ...LegalEntityDisplayFields
    legalName
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;
export const ActivityPriceFragmentDoc = gql`
  fragment ActivityPrice on Price {
    id
    price
    currency
  }
`;
export const ActivityRoundFragmentDoc = gql`
  fragment ActivityRound on Round {
    id
    date
    roundType
    urlPressRelease
    raisedAmount
    raisedCurrency
    valuation
    valuationCurrency
    valuationType
    legalEntity: company {
      ...LegalEntityFields
    }
    price {
      ...ActivityPrice
    }
  }
  ${LegalEntityFieldsFragmentDoc}
  ${ActivityPriceFragmentDoc}
`;
export const ActivityInvestmentFragmentDoc = gql`
  fragment ActivityInvestment on Investment {
    id
    isSecondary
    date: transactionDate
    numAssets
    conversion {
      id
    }
    asset {
      __typename
      ... on BaseAsset {
        id
        type
        name
      }
      ... on ConvertibleAsset {
        id
        name
        convertibleType
      }
    }
    event {
      id
      type
      round {
        ...ActivityRound
      }
    }
    issuedByLegalEntity {
      id
      displayName
    }
    numAssets
    investmentMetrics {
      userCurrency
      investedAmount {
        inUserCurrency
      }
      moic {
        inUserCurrency
      }
    }
  }
  ${ActivityRoundFragmentDoc}
`;
export const ActivityDistributionFragmentDoc = gql`
  fragment ActivityDistribution on Distribution {
    id
    date
    amount
    currency
  }
`;
export const ActivityFundDistributionFragmentDoc = gql`
  fragment ActivityFundDistribution on Distribution {
    id
    date
    amount
    currency
  }
`;
export const ActivityEventFragmentDoc = gql`
  fragment ActivityEvent on Event {
    id
    date
    name
    type
  }
`;
export const DatelessPriceFieldsFragmentDoc = gql`
  fragment DatelessPriceFields on Price {
    id
    price
    currency
  }
`;
export const PriceFieldsFragmentDoc = gql`
  fragment PriceFields on Price {
    ...DatelessPriceFields
    date
  }
  ${DatelessPriceFieldsFragmentDoc}
`;
export const ConvertibleAssetFieldsFragmentDoc = gql`
  fragment ConvertibleAssetFields on ConvertibleAsset {
    id
    assetId
    name
    convertibleType
    currency
    valuationCap
    discountRate
    annualisedInterestRate
    maturityDate
    conversionPrice
    interest
  }
`;
export const ConversionFieldsFragmentDoc = gql`
  fragment ConversionFields on InvestmentConversion {
    id
    conversionDate
    numConvertedAssets
    convertedInto {
      id
      type
      name
    }
  }
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on Event {
    id
    date
    type
    data
  }
`;
export const RoundFieldsFragmentDoc = gql`
  fragment RoundFields on Round {
    id
    roundType
    date
    raisedAmount
    raisedCurrency
    valuation
    valuationCurrency
    valuationType
    price {
      ...DatelessPriceFields
    }
    roundDocuments {
      id
      document {
        id
        description
      }
      creator {
        id
        email
      }
      createdAt
      downloadURL
    }
  }
  ${DatelessPriceFieldsFragmentDoc}
`;
export const InvestmentFieldsFragmentDoc = gql`
  fragment InvestmentFields on Investment {
    id
    transactionDate
    isSecondary
    issuedByLegalEntity {
      ...LegalEntityDisplayFields
    }
    spvLegalEntity {
      ...LegalEntityDisplayFields
    }
    asset {
      __typename
      ... on BaseAsset {
        id
        type
        name
        latestPrice(asOfDate: $asOfDate) {
          ...PriceFields
        }
      }
      ... on ConvertibleAsset {
        ...ConvertibleAssetFields
        latestPrice(asOfDate: $asOfDate) {
          ...PriceFields
        }
      }
    }
    numAssets
    conversion {
      ...ConversionFields
      event {
        ...EventFields
        round {
          ...RoundFields
        }
      }
    }
    event {
      ...EventFields
      round {
        ...RoundFields
      }
      otherPortfolios(portfolioId: $viewerId) {
        ...LegalEntityDisplayFields
      }
    }
    investmentMetrics {
      asOfDate
      userCurrency
      investmentCurrency
      investedAmount {
        inInvestmentCurrency
        inPinnedUserCurrency
        inUserCurrency
      }
      holdingsValue {
        inPinnedUserCurrency
        inUserCurrency
      }
      moic {
        inPinnedUserCurrency
        inUserCurrency
      }
      irr {
        inPinnedUserCurrency
        inUserCurrency
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
  ${ConvertibleAssetFieldsFragmentDoc}
  ${ConversionFieldsFragmentDoc}
  ${EventFieldsFragmentDoc}
  ${RoundFieldsFragmentDoc}
`;
export const DistributionFieldsFragmentDoc = gql`
  fragment DistributionFields on Distribution {
    id
    type
    eventId
    date
    amount
    currency
    amountInUserCurrency {
      inUserCurrency
      inPinnedUserCurrency
    }
  }
`;
export const StoredMetricsFieldsFragmentDoc = gql`
  fragment StoredMetricsFields on StoredPortfolioCompanyMetrics {
    id
    markdownDate
  }
`;
export const PortfolioOverviewInvestmentFieldsFragmentDoc = gql`
  fragment PortfolioOverviewInvestmentFields on Investment {
    id
    transactionDate
    isSecondary
    spvLegalEntity {
      ...LegalEntityDisplayFields
    }
    event {
      id
      name
    }
    asset {
      __typename
      ... on BaseAsset {
        id
        type
      }
      ... on ConvertibleAsset {
        id
        convertibleType
      }
    }
    conversion {
      id
    }
    investmentMetrics {
      investedAmount {
        inPinnedUserCurrency
        inUserCurrency
      }
      holdingsValue {
        inPinnedUserCurrency
        inUserCurrency
      }
      moic {
        inUserCurrency
        inPinnedUserCurrency
      }
      irr {
        inUserCurrency
        inPinnedUserCurrency
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;
export const TeamFieldsFragmentDoc = gql`
  fragment TeamFields on Team {
    id
    name
    members {
      id
      email
      username
      publicProfile {
        id
        imageUrl
      }
    }
  }
`;
export const MyNotificationsDocument = gql`
  query MyNotifications {
    notificationGroups(meta: { limit: 50 }) {
      ...NotificationDisplayFields
    }
  }
  ${NotificationDisplayFieldsFragmentDoc}
`;

/**
 * __useMyNotificationsQuery__
 *
 * To run a query within a React component, call `useMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
}
export function useMyNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
}
export function useMyNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
}
export type MyNotificationsQueryHookResult = ReturnType<typeof useMyNotificationsQuery>;
export type MyNotificationsLazyQueryHookResult = ReturnType<typeof useMyNotificationsLazyQuery>;
export type MyNotificationsSuspenseQueryHookResult = ReturnType<typeof useMyNotificationsSuspenseQuery>;
export type MyNotificationsQueryResult = Apollo.QueryResult<MyNotificationsQuery, MyNotificationsQueryVariables>;
export const NotificationsSubscriptionDocument = gql`
  subscription NotificationsSubscription {
    newNotification {
      ...NotificationDisplayFields
    }
  }
  ${NotificationDisplayFieldsFragmentDoc}
`;

/**
 * __useNotificationsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NotificationsSubscriptionSubscription,
    NotificationsSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NotificationsSubscriptionSubscription, NotificationsSubscriptionSubscriptionVariables>(
    NotificationsSubscriptionDocument,
    options,
  );
}
export type NotificationsSubscriptionSubscriptionHookResult = ReturnType<
  typeof useNotificationsSubscriptionSubscription
>;
export type NotificationsSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<NotificationsSubscriptionSubscription>;
export const NotificationsCountDocument = gql`
  query NotificationsCount($since: DateTime) {
    notificationsCount(since: $since)
  }
`;

/**
 * __useNotificationsCountQuery__
 *
 * To run a query within a React component, call `useNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsCountQuery({
 *   variables: {
 *      since: // value for 'since'
 *   },
 * });
 */
export function useNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsCountQuery, NotificationsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsCountQuery, NotificationsCountQueryVariables>(
    NotificationsCountDocument,
    options,
  );
}
export function useNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsCountQuery, NotificationsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsCountQuery, NotificationsCountQueryVariables>(
    NotificationsCountDocument,
    options,
  );
}
export function useNotificationsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsCountQuery, NotificationsCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NotificationsCountQuery, NotificationsCountQueryVariables>(
    NotificationsCountDocument,
    options,
  );
}
export type NotificationsCountQueryHookResult = ReturnType<typeof useNotificationsCountQuery>;
export type NotificationsCountLazyQueryHookResult = ReturnType<typeof useNotificationsCountLazyQuery>;
export type NotificationsCountSuspenseQueryHookResult = ReturnType<typeof useNotificationsCountSuspenseQuery>;
export type NotificationsCountQueryResult = Apollo.QueryResult<
  NotificationsCountQuery,
  NotificationsCountQueryVariables
>;
export const NotificationsCountSubscriptionDocument = gql`
  subscription NotificationsCountSubscription {
    newNotification {
      id
    }
  }
`;

/**
 * __useNotificationsCountSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationsCountSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsCountSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsCountSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsCountSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NotificationsCountSubscriptionSubscription,
    NotificationsCountSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationsCountSubscriptionSubscription,
    NotificationsCountSubscriptionSubscriptionVariables
  >(NotificationsCountSubscriptionDocument, options);
}
export type NotificationsCountSubscriptionSubscriptionHookResult = ReturnType<
  typeof useNotificationsCountSubscriptionSubscription
>;
export type NotificationsCountSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<NotificationsCountSubscriptionSubscription>;
export const AcceptDealShareRequestDocument = gql`
  mutation AcceptDealShareRequest($id: ID!) {
    dealShareRequest {
      accept(id: $id) {
        id
        accepted
        whatsAppIntroUrl
      }
    }
  }
`;
export type AcceptDealShareRequestMutationFn = Apollo.MutationFunction<
  AcceptDealShareRequestMutation,
  AcceptDealShareRequestMutationVariables
>;

/**
 * __useAcceptDealShareRequestMutation__
 *
 * To run a mutation, you first call `useAcceptDealShareRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDealShareRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDealShareRequestMutation, { data, loading, error }] = useAcceptDealShareRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptDealShareRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptDealShareRequestMutation, AcceptDealShareRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptDealShareRequestMutation, AcceptDealShareRequestMutationVariables>(
    AcceptDealShareRequestDocument,
    options,
  );
}
export type AcceptDealShareRequestMutationHookResult = ReturnType<typeof useAcceptDealShareRequestMutation>;
export type AcceptDealShareRequestMutationResult = Apollo.MutationResult<AcceptDealShareRequestMutation>;
export type AcceptDealShareRequestMutationOptions = Apollo.BaseMutationOptions<
  AcceptDealShareRequestMutation,
  AcceptDealShareRequestMutationVariables
>;
export const AdminRoundsToCheckDocument = gql`
  query AdminRoundsToCheck {
    adminRoundsToCheck {
      id
      roundType
      lastCheckedAt
      company {
        id
        displayName
      }
      team {
        id
        name
      }
    }
  }
`;

/**
 * __useAdminRoundsToCheckQuery__
 *
 * To run a query within a React component, call `useAdminRoundsToCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminRoundsToCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminRoundsToCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminRoundsToCheckQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminRoundsToCheckQuery, AdminRoundsToCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminRoundsToCheckQuery, AdminRoundsToCheckQueryVariables>(
    AdminRoundsToCheckDocument,
    options,
  );
}
export function useAdminRoundsToCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminRoundsToCheckQuery, AdminRoundsToCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminRoundsToCheckQuery, AdminRoundsToCheckQueryVariables>(
    AdminRoundsToCheckDocument,
    options,
  );
}
export function useAdminRoundsToCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AdminRoundsToCheckQuery, AdminRoundsToCheckQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminRoundsToCheckQuery, AdminRoundsToCheckQueryVariables>(
    AdminRoundsToCheckDocument,
    options,
  );
}
export type AdminRoundsToCheckQueryHookResult = ReturnType<typeof useAdminRoundsToCheckQuery>;
export type AdminRoundsToCheckLazyQueryHookResult = ReturnType<typeof useAdminRoundsToCheckLazyQuery>;
export type AdminRoundsToCheckSuspenseQueryHookResult = ReturnType<typeof useAdminRoundsToCheckSuspenseQuery>;
export type AdminRoundsToCheckQueryResult = Apollo.QueryResult<
  AdminRoundsToCheckQuery,
  AdminRoundsToCheckQueryVariables
>;
export const ProfilesMatchSuggestionsDocument = gql`
  query ProfilesMatchSuggestions {
    profilesMatchSuggestions {
      id
      publicProfile {
        id
        type
        slug
        fullname
        imageUrl
        description
        personalWebsite
        socialLinks {
          url
        }
        city
        country
        emails {
          email
        }
      }
      privateProfile {
        id
        slug
        type
        fullname
        imageUrl
        description
        personalWebsite
        team {
          name
        }
        socialLinks {
          url
        }
        city
        country
        emails {
          email
        }
      }
      team {
        id
        name
      }
    }
  }
`;

/**
 * __useProfilesMatchSuggestionsQuery__
 *
 * To run a query within a React component, call `useProfilesMatchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesMatchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesMatchSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilesMatchSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfilesMatchSuggestionsQuery, ProfilesMatchSuggestionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfilesMatchSuggestionsQuery, ProfilesMatchSuggestionsQueryVariables>(
    ProfilesMatchSuggestionsDocument,
    options,
  );
}
export function useProfilesMatchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfilesMatchSuggestionsQuery, ProfilesMatchSuggestionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfilesMatchSuggestionsQuery, ProfilesMatchSuggestionsQueryVariables>(
    ProfilesMatchSuggestionsDocument,
    options,
  );
}
export function useProfilesMatchSuggestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProfilesMatchSuggestionsQuery, ProfilesMatchSuggestionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProfilesMatchSuggestionsQuery, ProfilesMatchSuggestionsQueryVariables>(
    ProfilesMatchSuggestionsDocument,
    options,
  );
}
export type ProfilesMatchSuggestionsQueryHookResult = ReturnType<typeof useProfilesMatchSuggestionsQuery>;
export type ProfilesMatchSuggestionsLazyQueryHookResult = ReturnType<typeof useProfilesMatchSuggestionsLazyQuery>;
export type ProfilesMatchSuggestionsSuspenseQueryHookResult = ReturnType<
  typeof useProfilesMatchSuggestionsSuspenseQuery
>;
export type ProfilesMatchSuggestionsQueryResult = Apollo.QueryResult<
  ProfilesMatchSuggestionsQuery,
  ProfilesMatchSuggestionsQueryVariables
>;
export const AcceptMatchSuggestionDocument = gql`
  mutation AcceptMatchSuggestion($input: UpdateSuggestionInput!) {
    profile {
      acceptMatchSuggestion(input: $input) {
        id
      }
    }
  }
`;
export type AcceptMatchSuggestionMutationFn = Apollo.MutationFunction<
  AcceptMatchSuggestionMutation,
  AcceptMatchSuggestionMutationVariables
>;

/**
 * __useAcceptMatchSuggestionMutation__
 *
 * To run a mutation, you first call `useAcceptMatchSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMatchSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMatchSuggestionMutation, { data, loading, error }] = useAcceptMatchSuggestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMatchSuggestionMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptMatchSuggestionMutation, AcceptMatchSuggestionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptMatchSuggestionMutation, AcceptMatchSuggestionMutationVariables>(
    AcceptMatchSuggestionDocument,
    options,
  );
}
export type AcceptMatchSuggestionMutationHookResult = ReturnType<typeof useAcceptMatchSuggestionMutation>;
export type AcceptMatchSuggestionMutationResult = Apollo.MutationResult<AcceptMatchSuggestionMutation>;
export type AcceptMatchSuggestionMutationOptions = Apollo.BaseMutationOptions<
  AcceptMatchSuggestionMutation,
  AcceptMatchSuggestionMutationVariables
>;
export const RejectMatchSuggestionDocument = gql`
  mutation RejectMatchSuggestion($input: UpdateSuggestionInput!) {
    profile {
      rejectMatchSuggestion(input: $input) {
        id
      }
    }
  }
`;
export type RejectMatchSuggestionMutationFn = Apollo.MutationFunction<
  RejectMatchSuggestionMutation,
  RejectMatchSuggestionMutationVariables
>;

/**
 * __useRejectMatchSuggestionMutation__
 *
 * To run a mutation, you first call `useRejectMatchSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMatchSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMatchSuggestionMutation, { data, loading, error }] = useRejectMatchSuggestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMatchSuggestionMutation(
  baseOptions?: Apollo.MutationHookOptions<RejectMatchSuggestionMutation, RejectMatchSuggestionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectMatchSuggestionMutation, RejectMatchSuggestionMutationVariables>(
    RejectMatchSuggestionDocument,
    options,
  );
}
export type RejectMatchSuggestionMutationHookResult = ReturnType<typeof useRejectMatchSuggestionMutation>;
export type RejectMatchSuggestionMutationResult = Apollo.MutationResult<RejectMatchSuggestionMutation>;
export type RejectMatchSuggestionMutationOptions = Apollo.BaseMutationOptions<
  RejectMatchSuggestionMutation,
  RejectMatchSuggestionMutationVariables
>;
export const AdminSnoozeDocument = gql`
  mutation AdminSnooze($id: ID!) {
    round {
      adminSnooze(id: $id) {
        id
      }
    }
  }
`;
export type AdminSnoozeMutationFn = Apollo.MutationFunction<AdminSnoozeMutation, AdminSnoozeMutationVariables>;

/**
 * __useAdminSnoozeMutation__
 *
 * To run a mutation, you first call `useAdminSnoozeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSnoozeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSnoozeMutation, { data, loading, error }] = useAdminSnoozeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminSnoozeMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminSnoozeMutation, AdminSnoozeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminSnoozeMutation, AdminSnoozeMutationVariables>(AdminSnoozeDocument, options);
}
export type AdminSnoozeMutationHookResult = ReturnType<typeof useAdminSnoozeMutation>;
export type AdminSnoozeMutationResult = Apollo.MutationResult<AdminSnoozeMutation>;
export type AdminSnoozeMutationOptions = Apollo.BaseMutationOptions<AdminSnoozeMutation, AdminSnoozeMutationVariables>;
export const AdminMarkNeverPublicDocument = gql`
  mutation AdminMarkNeverPublic($id: ID!) {
    round {
      adminMarkNeverPublic(id: $id) {
        id
      }
    }
  }
`;
export type AdminMarkNeverPublicMutationFn = Apollo.MutationFunction<
  AdminMarkNeverPublicMutation,
  AdminMarkNeverPublicMutationVariables
>;

/**
 * __useAdminMarkNeverPublicMutation__
 *
 * To run a mutation, you first call `useAdminMarkNeverPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMarkNeverPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMarkNeverPublicMutation, { data, loading, error }] = useAdminMarkNeverPublicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminMarkNeverPublicMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminMarkNeverPublicMutation, AdminMarkNeverPublicMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminMarkNeverPublicMutation, AdminMarkNeverPublicMutationVariables>(
    AdminMarkNeverPublicDocument,
    options,
  );
}
export type AdminMarkNeverPublicMutationHookResult = ReturnType<typeof useAdminMarkNeverPublicMutation>;
export type AdminMarkNeverPublicMutationResult = Apollo.MutationResult<AdminMarkNeverPublicMutation>;
export type AdminMarkNeverPublicMutationOptions = Apollo.BaseMutationOptions<
  AdminMarkNeverPublicMutation,
  AdminMarkNeverPublicMutationVariables
>;
export const AdminInviteProposalsDocument = gql`
  query AdminInviteProposals {
    inviteProposals {
      id
      name
      fund
      status
      createdAt
      comment
      linkedin
      email
      emailVerified
      descriptorsGeo
      descriptorsInvestorType
      descriptorsMiscTags
      descriptorsStage
      creator {
        username
        email
      }
    }
  }
`;

/**
 * __useAdminInviteProposalsQuery__
 *
 * To run a query within a React component, call `useAdminInviteProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminInviteProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminInviteProposalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminInviteProposalsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminInviteProposalsQuery, AdminInviteProposalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminInviteProposalsQuery, AdminInviteProposalsQueryVariables>(
    AdminInviteProposalsDocument,
    options,
  );
}
export function useAdminInviteProposalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminInviteProposalsQuery, AdminInviteProposalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminInviteProposalsQuery, AdminInviteProposalsQueryVariables>(
    AdminInviteProposalsDocument,
    options,
  );
}
export function useAdminInviteProposalsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AdminInviteProposalsQuery, AdminInviteProposalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminInviteProposalsQuery, AdminInviteProposalsQueryVariables>(
    AdminInviteProposalsDocument,
    options,
  );
}
export type AdminInviteProposalsQueryHookResult = ReturnType<typeof useAdminInviteProposalsQuery>;
export type AdminInviteProposalsLazyQueryHookResult = ReturnType<typeof useAdminInviteProposalsLazyQuery>;
export type AdminInviteProposalsSuspenseQueryHookResult = ReturnType<typeof useAdminInviteProposalsSuspenseQuery>;
export type AdminInviteProposalsQueryResult = Apollo.QueryResult<
  AdminInviteProposalsQuery,
  AdminInviteProposalsQueryVariables
>;
export const UpdateInviteProposalDocument = gql`
  mutation UpdateInviteProposal($input: UpdateInviteProposalInput!) {
    inviteProposal {
      update(input: $input) {
        id
      }
    }
  }
`;
export type UpdateInviteProposalMutationFn = Apollo.MutationFunction<
  UpdateInviteProposalMutation,
  UpdateInviteProposalMutationVariables
>;

/**
 * __useUpdateInviteProposalMutation__
 *
 * To run a mutation, you first call `useUpdateInviteProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInviteProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInviteProposalMutation, { data, loading, error }] = useUpdateInviteProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInviteProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInviteProposalMutation, UpdateInviteProposalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInviteProposalMutation, UpdateInviteProposalMutationVariables>(
    UpdateInviteProposalDocument,
    options,
  );
}
export type UpdateInviteProposalMutationHookResult = ReturnType<typeof useUpdateInviteProposalMutation>;
export type UpdateInviteProposalMutationResult = Apollo.MutationResult<UpdateInviteProposalMutation>;
export type UpdateInviteProposalMutationOptions = Apollo.BaseMutationOptions<
  UpdateInviteProposalMutation,
  UpdateInviteProposalMutationVariables
>;
export const CapTableDocument = gql`
  query CapTable($legalEntityId: ID!, $asOfDate: Date!) {
    legalEntity(id: $legalEntityId) {
      issuedAssets(asOfDate: $asOfDate) {
        asset {
          id
          type
          name
        }
        assetHolder {
          ...LegalEntityDisplayFields
        }
        numAssets
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;

/**
 * __useCapTableQuery__
 *
 * To run a query within a React component, call `useCapTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapTableQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      asOfDate: // value for 'asOfDate'
 *   },
 * });
 */
export function useCapTableQuery(
  baseOptions: Apollo.QueryHookOptions<CapTableQuery, CapTableQueryVariables> &
    ({ variables: CapTableQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CapTableQuery, CapTableQueryVariables>(CapTableDocument, options);
}
export function useCapTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapTableQuery, CapTableQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CapTableQuery, CapTableQueryVariables>(CapTableDocument, options);
}
export function useCapTableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CapTableQuery, CapTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CapTableQuery, CapTableQueryVariables>(CapTableDocument, options);
}
export type CapTableQueryHookResult = ReturnType<typeof useCapTableQuery>;
export type CapTableLazyQueryHookResult = ReturnType<typeof useCapTableLazyQuery>;
export type CapTableSuspenseQueryHookResult = ReturnType<typeof useCapTableSuspenseQuery>;
export type CapTableQueryResult = Apollo.QueryResult<CapTableQuery, CapTableQueryVariables>;
export const ChangeLogDocument = gql`
  query ChangeLog($portfolioId: ID) {
    changeLogs(portfolioId: $portfolioId) {
      id
      createdAt
      createdBy {
        id
        email
      }
      type
      message
      legalEntity {
        ...LegalEntityDisplayFields
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;

/**
 * __useChangeLogQuery__
 *
 * To run a query within a React component, call `useChangeLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeLogQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useChangeLogQuery(baseOptions?: Apollo.QueryHookOptions<ChangeLogQuery, ChangeLogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChangeLogQuery, ChangeLogQueryVariables>(ChangeLogDocument, options);
}
export function useChangeLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChangeLogQuery, ChangeLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChangeLogQuery, ChangeLogQueryVariables>(ChangeLogDocument, options);
}
export function useChangeLogSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ChangeLogQuery, ChangeLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChangeLogQuery, ChangeLogQueryVariables>(ChangeLogDocument, options);
}
export type ChangeLogQueryHookResult = ReturnType<typeof useChangeLogQuery>;
export type ChangeLogLazyQueryHookResult = ReturnType<typeof useChangeLogLazyQuery>;
export type ChangeLogSuspenseQueryHookResult = ReturnType<typeof useChangeLogSuspenseQuery>;
export type ChangeLogQueryResult = Apollo.QueryResult<ChangeLogQuery, ChangeLogQueryVariables>;
export const PublicProfileBySlugDocument = gql`
  query PublicProfileBySlug($slug: String) {
    publicProfileBySlug(slug: $slug) {
      ... on CompanyProfile {
        ...CompanyProfileFields
      }
      ... on Profile {
        ...ProfileFieldsNested
        investmentStatus
        legalEntity {
          id
        }
        investingEntities {
          id
          displayName
        }
        profileInvestments {
          id
          company {
            ...ProfileFields
          }
          roundType
          date
        }
        profileInvestors {
          id
          investor {
            ...ProfileFields
          }
          roundType
          date
        }
      }
    }
  }
  ${CompanyProfileFieldsFragmentDoc}
  ${ProfileFieldsNestedFragmentDoc}
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __usePublicProfileBySlugQuery__
 *
 * To run a query within a React component, call `usePublicProfileBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProfileBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProfileBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicProfileBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicProfileBySlugQuery, PublicProfileBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicProfileBySlugQuery, PublicProfileBySlugQueryVariables>(
    PublicProfileBySlugDocument,
    options,
  );
}
export function usePublicProfileBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicProfileBySlugQuery, PublicProfileBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicProfileBySlugQuery, PublicProfileBySlugQueryVariables>(
    PublicProfileBySlugDocument,
    options,
  );
}
export function usePublicProfileBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PublicProfileBySlugQuery, PublicProfileBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PublicProfileBySlugQuery, PublicProfileBySlugQueryVariables>(
    PublicProfileBySlugDocument,
    options,
  );
}
export type PublicProfileBySlugQueryHookResult = ReturnType<typeof usePublicProfileBySlugQuery>;
export type PublicProfileBySlugLazyQueryHookResult = ReturnType<typeof usePublicProfileBySlugLazyQuery>;
export type PublicProfileBySlugSuspenseQueryHookResult = ReturnType<typeof usePublicProfileBySlugSuspenseQuery>;
export type PublicProfileBySlugQueryResult = Apollo.QueryResult<
  PublicProfileBySlugQuery,
  PublicProfileBySlugQueryVariables
>;
export const PublicProfileByNameOrWebsiteDocument = gql`
  query PublicProfileByNameOrWebsite($input: ProfileByNameOrWebsiteInput!) {
    publicProfileByNameOrWebsite(input: $input) {
      ... on CompanyProfile {
        ...CompanyProfileFields
      }
    }
  }
  ${CompanyProfileFieldsFragmentDoc}
`;

/**
 * __usePublicProfileByNameOrWebsiteQuery__
 *
 * To run a query within a React component, call `usePublicProfileByNameOrWebsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProfileByNameOrWebsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProfileByNameOrWebsiteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicProfileByNameOrWebsiteQuery(
  baseOptions: Apollo.QueryHookOptions<PublicProfileByNameOrWebsiteQuery, PublicProfileByNameOrWebsiteQueryVariables> &
    ({ variables: PublicProfileByNameOrWebsiteQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicProfileByNameOrWebsiteQuery, PublicProfileByNameOrWebsiteQueryVariables>(
    PublicProfileByNameOrWebsiteDocument,
    options,
  );
}
export function usePublicProfileByNameOrWebsiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicProfileByNameOrWebsiteQuery,
    PublicProfileByNameOrWebsiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicProfileByNameOrWebsiteQuery, PublicProfileByNameOrWebsiteQueryVariables>(
    PublicProfileByNameOrWebsiteDocument,
    options,
  );
}
export function usePublicProfileByNameOrWebsiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PublicProfileByNameOrWebsiteQuery,
    PublicProfileByNameOrWebsiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PublicProfileByNameOrWebsiteQuery, PublicProfileByNameOrWebsiteQueryVariables>(
    PublicProfileByNameOrWebsiteDocument,
    options,
  );
}
export type PublicProfileByNameOrWebsiteQueryHookResult = ReturnType<typeof usePublicProfileByNameOrWebsiteQuery>;
export type PublicProfileByNameOrWebsiteLazyQueryHookResult = ReturnType<
  typeof usePublicProfileByNameOrWebsiteLazyQuery
>;
export type PublicProfileByNameOrWebsiteSuspenseQueryHookResult = ReturnType<
  typeof usePublicProfileByNameOrWebsiteSuspenseQuery
>;
export type PublicProfileByNameOrWebsiteQueryResult = Apollo.QueryResult<
  PublicProfileByNameOrWebsiteQuery,
  PublicProfileByNameOrWebsiteQueryVariables
>;
export const CreateCompanyProfileDocument = gql`
  mutation CreateCompanyProfile($input: CreateCompanyProfileInput!) {
    companyProfile {
      create(input: $input) {
        ...CompanyProfileFields
      }
    }
  }
  ${CompanyProfileFieldsFragmentDoc}
`;
export type CreateCompanyProfileMutationFn = Apollo.MutationFunction<
  CreateCompanyProfileMutation,
  CreateCompanyProfileMutationVariables
>;

/**
 * __useCreateCompanyProfileMutation__
 *
 * To run a mutation, you first call `useCreateCompanyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyProfileMutation, { data, loading, error }] = useCreateCompanyProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyProfileMutation, CreateCompanyProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanyProfileMutation, CreateCompanyProfileMutationVariables>(
    CreateCompanyProfileDocument,
    options,
  );
}
export type CreateCompanyProfileMutationHookResult = ReturnType<typeof useCreateCompanyProfileMutation>;
export type CreateCompanyProfileMutationResult = Apollo.MutationResult<CreateCompanyProfileMutation>;
export type CreateCompanyProfileMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyProfileMutation,
  CreateCompanyProfileMutationVariables
>;
export const UpdateInvestmentStatusDocument = gql`
  mutation UpdateInvestmentStatus($input: UpdateInvestmentStatusInput!) {
    profile {
      updateInvestmentStatus(input: $input) {
        id
        investmentStatus
      }
    }
  }
`;
export type UpdateInvestmentStatusMutationFn = Apollo.MutationFunction<
  UpdateInvestmentStatusMutation,
  UpdateInvestmentStatusMutationVariables
>;

/**
 * __useUpdateInvestmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInvestmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestmentStatusMutation, { data, loading, error }] = useUpdateInvestmentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestmentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvestmentStatusMutation, UpdateInvestmentStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvestmentStatusMutation, UpdateInvestmentStatusMutationVariables>(
    UpdateInvestmentStatusDocument,
    options,
  );
}
export type UpdateInvestmentStatusMutationHookResult = ReturnType<typeof useUpdateInvestmentStatusMutation>;
export type UpdateInvestmentStatusMutationResult = Apollo.MutationResult<UpdateInvestmentStatusMutation>;
export type UpdateInvestmentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvestmentStatusMutation,
  UpdateInvestmentStatusMutationVariables
>;
export const UpdateProfileDescriptorsDocument = gql`
  mutation UpdateProfileDescriptors($input: UpdateDescriptorsInput!) {
    profile {
      updateDescriptors(input: $input) {
        id
        descriptorsGeo
        descriptorsInvestorType
        descriptorsStage
        descriptorsMiscTags
      }
    }
  }
`;
export type UpdateProfileDescriptorsMutationFn = Apollo.MutationFunction<
  UpdateProfileDescriptorsMutation,
  UpdateProfileDescriptorsMutationVariables
>;

/**
 * __useUpdateProfileDescriptorsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileDescriptorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileDescriptorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileDescriptorsMutation, { data, loading, error }] = useUpdateProfileDescriptorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileDescriptorsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileDescriptorsMutation, UpdateProfileDescriptorsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileDescriptorsMutation, UpdateProfileDescriptorsMutationVariables>(
    UpdateProfileDescriptorsDocument,
    options,
  );
}
export type UpdateProfileDescriptorsMutationHookResult = ReturnType<typeof useUpdateProfileDescriptorsMutation>;
export type UpdateProfileDescriptorsMutationResult = Apollo.MutationResult<UpdateProfileDescriptorsMutation>;
export type UpdateProfileDescriptorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileDescriptorsMutation,
  UpdateProfileDescriptorsMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    profile {
      updateProfile(input: $input) {
        id
      }
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const FindCompanyProfilesByNameDocument = gql`
  query FindCompanyProfilesByName($name: String!) {
    findCompanyProfilesByName(name: $name) {
      id
      name
    }
  }
`;

/**
 * __useFindCompanyProfilesByNameQuery__
 *
 * To run a query within a React component, call `useFindCompanyProfilesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCompanyProfilesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCompanyProfilesByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindCompanyProfilesByNameQuery(
  baseOptions: Apollo.QueryHookOptions<FindCompanyProfilesByNameQuery, FindCompanyProfilesByNameQueryVariables> &
    ({ variables: FindCompanyProfilesByNameQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindCompanyProfilesByNameQuery, FindCompanyProfilesByNameQueryVariables>(
    FindCompanyProfilesByNameDocument,
    options,
  );
}
export function useFindCompanyProfilesByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindCompanyProfilesByNameQuery, FindCompanyProfilesByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindCompanyProfilesByNameQuery, FindCompanyProfilesByNameQueryVariables>(
    FindCompanyProfilesByNameDocument,
    options,
  );
}
export function useFindCompanyProfilesByNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FindCompanyProfilesByNameQuery,
    FindCompanyProfilesByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindCompanyProfilesByNameQuery, FindCompanyProfilesByNameQueryVariables>(
    FindCompanyProfilesByNameDocument,
    options,
  );
}
export type FindCompanyProfilesByNameQueryHookResult = ReturnType<typeof useFindCompanyProfilesByNameQuery>;
export type FindCompanyProfilesByNameLazyQueryHookResult = ReturnType<typeof useFindCompanyProfilesByNameLazyQuery>;
export type FindCompanyProfilesByNameSuspenseQueryHookResult = ReturnType<
  typeof useFindCompanyProfilesByNameSuspenseQuery
>;
export type FindCompanyProfilesByNameQueryResult = Apollo.QueryResult<
  FindCompanyProfilesByNameQuery,
  FindCompanyProfilesByNameQueryVariables
>;
export const GetCompanyProfileByIdDocument = gql`
  query getCompanyProfileById($id: ID!) {
    companyProfile(id: $id) {
      ...CompanyProfileFields
      rounds {
        id
        roundType
        date
      }
    }
  }
  ${CompanyProfileFieldsFragmentDoc}
`;

/**
 * __useGetCompanyProfileByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyProfileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyProfileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyProfileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyProfileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyProfileByIdQuery, GetCompanyProfileByIdQueryVariables> &
    ({ variables: GetCompanyProfileByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyProfileByIdQuery, GetCompanyProfileByIdQueryVariables>(
    GetCompanyProfileByIdDocument,
    options,
  );
}
export function useGetCompanyProfileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyProfileByIdQuery, GetCompanyProfileByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyProfileByIdQuery, GetCompanyProfileByIdQueryVariables>(
    GetCompanyProfileByIdDocument,
    options,
  );
}
export function useGetCompanyProfileByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyProfileByIdQuery, GetCompanyProfileByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyProfileByIdQuery, GetCompanyProfileByIdQueryVariables>(
    GetCompanyProfileByIdDocument,
    options,
  );
}
export type GetCompanyProfileByIdQueryHookResult = ReturnType<typeof useGetCompanyProfileByIdQuery>;
export type GetCompanyProfileByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyProfileByIdLazyQuery>;
export type GetCompanyProfileByIdSuspenseQueryHookResult = ReturnType<typeof useGetCompanyProfileByIdSuspenseQuery>;
export type GetCompanyProfileByIdQueryResult = Apollo.QueryResult<
  GetCompanyProfileByIdQuery,
  GetCompanyProfileByIdQueryVariables
>;
export const GenerateCompanyProfileFieldsDocument = gql`
  query GenerateCompanyProfileFields($text: String!) {
    generateCompanyProfileFields(text: $text) {
      website
      description
      themes
      country
      city
    }
  }
`;

/**
 * __useGenerateCompanyProfileFieldsQuery__
 *
 * To run a query within a React component, call `useGenerateCompanyProfileFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCompanyProfileFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCompanyProfileFieldsQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useGenerateCompanyProfileFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<GenerateCompanyProfileFieldsQuery, GenerateCompanyProfileFieldsQueryVariables> &
    ({ variables: GenerateCompanyProfileFieldsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateCompanyProfileFieldsQuery, GenerateCompanyProfileFieldsQueryVariables>(
    GenerateCompanyProfileFieldsDocument,
    options,
  );
}
export function useGenerateCompanyProfileFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateCompanyProfileFieldsQuery,
    GenerateCompanyProfileFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateCompanyProfileFieldsQuery, GenerateCompanyProfileFieldsQueryVariables>(
    GenerateCompanyProfileFieldsDocument,
    options,
  );
}
export function useGenerateCompanyProfileFieldsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateCompanyProfileFieldsQuery,
    GenerateCompanyProfileFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GenerateCompanyProfileFieldsQuery, GenerateCompanyProfileFieldsQueryVariables>(
    GenerateCompanyProfileFieldsDocument,
    options,
  );
}
export type GenerateCompanyProfileFieldsQueryHookResult = ReturnType<typeof useGenerateCompanyProfileFieldsQuery>;
export type GenerateCompanyProfileFieldsLazyQueryHookResult = ReturnType<
  typeof useGenerateCompanyProfileFieldsLazyQuery
>;
export type GenerateCompanyProfileFieldsSuspenseQueryHookResult = ReturnType<
  typeof useGenerateCompanyProfileFieldsSuspenseQuery
>;
export type GenerateCompanyProfileFieldsQueryResult = Apollo.QueryResult<
  GenerateCompanyProfileFieldsQuery,
  GenerateCompanyProfileFieldsQueryVariables
>;
export const DealflowInsightsDocument = gql`
  query DealflowInsights($filter: DealflowInsightsFilter!) {
    dealflowInsights(filter: $filter) {
      totalDealsAdded
      totalDealsConsidering
      totalDealsCommited
      totalDealsInvested
      totalDealsPassed
      totalDealsLost
      chartData {
        labels
        datasets {
          label
          data
        }
      }
    }
  }
`;

/**
 * __useDealflowInsightsQuery__
 *
 * To run a query within a React component, call `useDealflowInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealflowInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealflowInsightsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDealflowInsightsQuery(
  baseOptions: Apollo.QueryHookOptions<DealflowInsightsQuery, DealflowInsightsQueryVariables> &
    ({ variables: DealflowInsightsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealflowInsightsQuery, DealflowInsightsQueryVariables>(DealflowInsightsDocument, options);
}
export function useDealflowInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealflowInsightsQuery, DealflowInsightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealflowInsightsQuery, DealflowInsightsQueryVariables>(DealflowInsightsDocument, options);
}
export function useDealflowInsightsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealflowInsightsQuery, DealflowInsightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealflowInsightsQuery, DealflowInsightsQueryVariables>(
    DealflowInsightsDocument,
    options,
  );
}
export type DealflowInsightsQueryHookResult = ReturnType<typeof useDealflowInsightsQuery>;
export type DealflowInsightsLazyQueryHookResult = ReturnType<typeof useDealflowInsightsLazyQuery>;
export type DealflowInsightsSuspenseQueryHookResult = ReturnType<typeof useDealflowInsightsSuspenseQuery>;
export type DealflowInsightsQueryResult = Apollo.QueryResult<DealflowInsightsQuery, DealflowInsightsQueryVariables>;
export const DirectoryDocument = gql`
  query Directory($filter: ProfilesFilter!, $meta: ProfilesQueryMeta, $orderBy: ProfilesOrderBy) {
    findManyProfiles(filter: $filter, meta: $meta, orderBy: $orderBy) {
      profiles {
        ...ProfileFieldsNested
        emails {
          id
          email
        }
        azavaUsers {
          id
          username
        }
      }
      totalCount
    }
  }
  ${ProfileFieldsNestedFragmentDoc}
`;

/**
 * __useDirectoryQuery__
 *
 * To run a query within a React component, call `useDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      meta: // value for 'meta'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDirectoryQuery(
  baseOptions: Apollo.QueryHookOptions<DirectoryQuery, DirectoryQueryVariables> &
    ({ variables: DirectoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DirectoryQuery, DirectoryQueryVariables>(DirectoryDocument, options);
}
export function useDirectoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DirectoryQuery, DirectoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DirectoryQuery, DirectoryQueryVariables>(DirectoryDocument, options);
}
export function useDirectorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DirectoryQuery, DirectoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DirectoryQuery, DirectoryQueryVariables>(DirectoryDocument, options);
}
export type DirectoryQueryHookResult = ReturnType<typeof useDirectoryQuery>;
export type DirectoryLazyQueryHookResult = ReturnType<typeof useDirectoryLazyQuery>;
export type DirectorySuspenseQueryHookResult = ReturnType<typeof useDirectorySuspenseQuery>;
export type DirectoryQueryResult = Apollo.QueryResult<DirectoryQuery, DirectoryQueryVariables>;
export const CoInvestorsDocument = gql`
  query CoInvestors($filter: ProfilesFilter!, $meta: ProfilesQueryMeta, $orderBy: ProfilesOrderBy) {
    findManyProfiles(filter: $filter, meta: $meta, orderBy: $orderBy) {
      profiles {
        ...ProfileFields
        profileInvestments {
          id
          company {
            ...ProfileFields
          }
          roundType
          date
        }
        roundTypes
      }
      totalCount
    }
  }
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __useCoInvestorsQuery__
 *
 * To run a query within a React component, call `useCoInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoInvestorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      meta: // value for 'meta'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCoInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<CoInvestorsQuery, CoInvestorsQueryVariables> &
    ({ variables: CoInvestorsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoInvestorsQuery, CoInvestorsQueryVariables>(CoInvestorsDocument, options);
}
export function useCoInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoInvestorsQuery, CoInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoInvestorsQuery, CoInvestorsQueryVariables>(CoInvestorsDocument, options);
}
export function useCoInvestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CoInvestorsQuery, CoInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoInvestorsQuery, CoInvestorsQueryVariables>(CoInvestorsDocument, options);
}
export type CoInvestorsQueryHookResult = ReturnType<typeof useCoInvestorsQuery>;
export type CoInvestorsLazyQueryHookResult = ReturnType<typeof useCoInvestorsLazyQuery>;
export type CoInvestorsSuspenseQueryHookResult = ReturnType<typeof useCoInvestorsSuspenseQuery>;
export type CoInvestorsQueryResult = Apollo.QueryResult<CoInvestorsQuery, CoInvestorsQueryVariables>;
export const CompanyProfilesDocument = gql`
  query CompanyProfiles($filter: ProfilesFilter!, $meta: ProfilesQueryMeta, $orderBy: ProfilesOrderBy) {
    findManyCompanyProfiles(filter: $filter, meta: $meta, orderBy: $orderBy) {
      id
      fullname
      personalWebsite
      description
      firstInvested
      slug
      investmentStatus
      latestRoundValuation {
        date
        roundType
        valuation {
          valuationType
          value
        }
      }
      totalInvested
      totalValue
      legalEntity {
        id
      }
      investingEntities {
        id
        displayName
      }
      markets
      customers
      businessModels
      notionNotesImported
      country
      themes
    }
  }
`;

/**
 * __useCompanyProfilesQuery__
 *
 * To run a query within a React component, call `useCompanyProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProfilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      meta: // value for 'meta'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCompanyProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyProfilesQuery, CompanyProfilesQueryVariables> &
    ({ variables: CompanyProfilesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyProfilesQuery, CompanyProfilesQueryVariables>(CompanyProfilesDocument, options);
}
export function useCompanyProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyProfilesQuery, CompanyProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyProfilesQuery, CompanyProfilesQueryVariables>(CompanyProfilesDocument, options);
}
export function useCompanyProfilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyProfilesQuery, CompanyProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyProfilesQuery, CompanyProfilesQueryVariables>(CompanyProfilesDocument, options);
}
export type CompanyProfilesQueryHookResult = ReturnType<typeof useCompanyProfilesQuery>;
export type CompanyProfilesLazyQueryHookResult = ReturnType<typeof useCompanyProfilesLazyQuery>;
export type CompanyProfilesSuspenseQueryHookResult = ReturnType<typeof useCompanyProfilesSuspenseQuery>;
export type CompanyProfilesQueryResult = Apollo.QueryResult<CompanyProfilesQuery, CompanyProfilesQueryVariables>;
export const CreatePortfolioViewDocument = gql`
  mutation CreatePortfolioView($input: CreatePortfolioViewInput!) {
    createPortfolioView(input: $input) {
      slug
    }
  }
`;
export type CreatePortfolioViewMutationFn = Apollo.MutationFunction<
  CreatePortfolioViewMutation,
  CreatePortfolioViewMutationVariables
>;

/**
 * __useCreatePortfolioViewMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioViewMutation, { data, loading, error }] = useCreatePortfolioViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePortfolioViewMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePortfolioViewMutation, CreatePortfolioViewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePortfolioViewMutation, CreatePortfolioViewMutationVariables>(
    CreatePortfolioViewDocument,
    options,
  );
}
export type CreatePortfolioViewMutationHookResult = ReturnType<typeof useCreatePortfolioViewMutation>;
export type CreatePortfolioViewMutationResult = Apollo.MutationResult<CreatePortfolioViewMutation>;
export type CreatePortfolioViewMutationOptions = Apollo.BaseMutationOptions<
  CreatePortfolioViewMutation,
  CreatePortfolioViewMutationVariables
>;
export const PortfolioViewBySlugDocument = gql`
  query PortfolioViewBySlug($slug: String!) {
    portfolioViewBySlug(slug: $slug) {
      id
      name
      data
      filterState
      columnState
      profileSlug
    }
  }
`;

/**
 * __usePortfolioViewBySlugQuery__
 *
 * To run a query within a React component, call `usePortfolioViewBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioViewBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioViewBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePortfolioViewBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<PortfolioViewBySlugQuery, PortfolioViewBySlugQueryVariables> &
    ({ variables: PortfolioViewBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioViewBySlugQuery, PortfolioViewBySlugQueryVariables>(
    PortfolioViewBySlugDocument,
    options,
  );
}
export function usePortfolioViewBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioViewBySlugQuery, PortfolioViewBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioViewBySlugQuery, PortfolioViewBySlugQueryVariables>(
    PortfolioViewBySlugDocument,
    options,
  );
}
export function usePortfolioViewBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PortfolioViewBySlugQuery, PortfolioViewBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PortfolioViewBySlugQuery, PortfolioViewBySlugQueryVariables>(
    PortfolioViewBySlugDocument,
    options,
  );
}
export type PortfolioViewBySlugQueryHookResult = ReturnType<typeof usePortfolioViewBySlugQuery>;
export type PortfolioViewBySlugLazyQueryHookResult = ReturnType<typeof usePortfolioViewBySlugLazyQuery>;
export type PortfolioViewBySlugSuspenseQueryHookResult = ReturnType<typeof usePortfolioViewBySlugSuspenseQuery>;
export type PortfolioViewBySlugQueryResult = Apollo.QueryResult<
  PortfolioViewBySlugQuery,
  PortfolioViewBySlugQueryVariables
>;
export const GetPortfolioViewsDocument = gql`
  query GetPortfolioViews {
    portfolioViews {
      ...PorfolioViewFields
    }
  }
  ${PorfolioViewFieldsFragmentDoc}
`;

/**
 * __useGetPortfolioViewsQuery__
 *
 * To run a query within a React component, call `useGetPortfolioViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPortfolioViewsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>(GetPortfolioViewsDocument, options);
}
export function useGetPortfolioViewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>(
    GetPortfolioViewsDocument,
    options,
  );
}
export function useGetPortfolioViewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>(
    GetPortfolioViewsDocument,
    options,
  );
}
export type GetPortfolioViewsQueryHookResult = ReturnType<typeof useGetPortfolioViewsQuery>;
export type GetPortfolioViewsLazyQueryHookResult = ReturnType<typeof useGetPortfolioViewsLazyQuery>;
export type GetPortfolioViewsSuspenseQueryHookResult = ReturnType<typeof useGetPortfolioViewsSuspenseQuery>;
export type GetPortfolioViewsQueryResult = Apollo.QueryResult<GetPortfolioViewsQuery, GetPortfolioViewsQueryVariables>;
export const DeletePortfolioViewDocument = gql`
  mutation DeletePortfolioView($id: ID!) {
    deletePortfolioView(id: $id) {
      id
    }
  }
`;
export type DeletePortfolioViewMutationFn = Apollo.MutationFunction<
  DeletePortfolioViewMutation,
  DeletePortfolioViewMutationVariables
>;

/**
 * __useDeletePortfolioViewMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioViewMutation, { data, loading, error }] = useDeletePortfolioViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePortfolioViewMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePortfolioViewMutation, DeletePortfolioViewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePortfolioViewMutation, DeletePortfolioViewMutationVariables>(
    DeletePortfolioViewDocument,
    options,
  );
}
export type DeletePortfolioViewMutationHookResult = ReturnType<typeof useDeletePortfolioViewMutation>;
export type DeletePortfolioViewMutationResult = Apollo.MutationResult<DeletePortfolioViewMutation>;
export type DeletePortfolioViewMutationOptions = Apollo.BaseMutationOptions<
  DeletePortfolioViewMutation,
  DeletePortfolioViewMutationVariables
>;
export const UpsertConvertibleAndNoteDocument = gql`
  mutation UpsertConvertibleAndNote($convertibleInput: ConvertibleInput!, $transactionNote: String) {
    upsertConvertibleAndNote(convertibleInput: $convertibleInput, note: $transactionNote) {
      transactionId
    }
  }
`;
export type UpsertConvertibleAndNoteMutationFn = Apollo.MutationFunction<
  UpsertConvertibleAndNoteMutation,
  UpsertConvertibleAndNoteMutationVariables
>;

/**
 * __useUpsertConvertibleAndNoteMutation__
 *
 * To run a mutation, you first call `useUpsertConvertibleAndNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertConvertibleAndNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertConvertibleAndNoteMutation, { data, loading, error }] = useUpsertConvertibleAndNoteMutation({
 *   variables: {
 *      convertibleInput: // value for 'convertibleInput'
 *      transactionNote: // value for 'transactionNote'
 *   },
 * });
 */
export function useUpsertConvertibleAndNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertConvertibleAndNoteMutation, UpsertConvertibleAndNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertConvertibleAndNoteMutation, UpsertConvertibleAndNoteMutationVariables>(
    UpsertConvertibleAndNoteDocument,
    options,
  );
}
export type UpsertConvertibleAndNoteMutationHookResult = ReturnType<typeof useUpsertConvertibleAndNoteMutation>;
export type UpsertConvertibleAndNoteMutationResult = Apollo.MutationResult<UpsertConvertibleAndNoteMutation>;
export type UpsertConvertibleAndNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertConvertibleAndNoteMutation,
  UpsertConvertibleAndNoteMutationVariables
>;
export const UpdateConvertibleTransactionDocument = gql`
  mutation UpdateConvertibleTransaction(
    $investedAmountInput: UpdateInvestedAmountInput!
    $investmentDateInput: UpdateInvestmentDateInput!
    $assetNameInput: UpdateAssetNameInput!
    $convertibleInput: UpdateConvertiblePropertiesInput!
  ) {
    updateInvestedAmount(input: $investedAmountInput)
    updateInvestmentDate(input: $investmentDateInput)
    updateConvertibleProperties(input: $convertibleInput)
    asset {
      updateName(input: $assetNameInput) {
        ... on BaseAsset {
          name
        }
        ... on ConvertibleAsset {
          name
        }
      }
    }
  }
`;
export type UpdateConvertibleTransactionMutationFn = Apollo.MutationFunction<
  UpdateConvertibleTransactionMutation,
  UpdateConvertibleTransactionMutationVariables
>;

/**
 * __useUpdateConvertibleTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateConvertibleTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConvertibleTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConvertibleTransactionMutation, { data, loading, error }] = useUpdateConvertibleTransactionMutation({
 *   variables: {
 *      investedAmountInput: // value for 'investedAmountInput'
 *      investmentDateInput: // value for 'investmentDateInput'
 *      assetNameInput: // value for 'assetNameInput'
 *      convertibleInput: // value for 'convertibleInput'
 *   },
 * });
 */
export function useUpdateConvertibleTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConvertibleTransactionMutation,
    UpdateConvertibleTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateConvertibleTransactionMutation, UpdateConvertibleTransactionMutationVariables>(
    UpdateConvertibleTransactionDocument,
    options,
  );
}
export type UpdateConvertibleTransactionMutationHookResult = ReturnType<typeof useUpdateConvertibleTransactionMutation>;
export type UpdateConvertibleTransactionMutationResult = Apollo.MutationResult<UpdateConvertibleTransactionMutation>;
export type UpdateConvertibleTransactionMutationOptions = Apollo.BaseMutationOptions<
  UpdateConvertibleTransactionMutation,
  UpdateConvertibleTransactionMutationVariables
>;
export const ConvertConvertibleDocument = gql`
  mutation ConvertConvertible($input: ConvertConvertibleInput!, $note: String) {
    convertConvertible(input: $input, note: $note)
  }
`;
export type ConvertConvertibleMutationFn = Apollo.MutationFunction<
  ConvertConvertibleMutation,
  ConvertConvertibleMutationVariables
>;

/**
 * __useConvertConvertibleMutation__
 *
 * To run a mutation, you first call `useConvertConvertibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertConvertibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertConvertibleMutation, { data, loading, error }] = useConvertConvertibleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useConvertConvertibleMutation(
  baseOptions?: Apollo.MutationHookOptions<ConvertConvertibleMutation, ConvertConvertibleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertConvertibleMutation, ConvertConvertibleMutationVariables>(
    ConvertConvertibleDocument,
    options,
  );
}
export type ConvertConvertibleMutationHookResult = ReturnType<typeof useConvertConvertibleMutation>;
export type ConvertConvertibleMutationResult = Apollo.MutationResult<ConvertConvertibleMutation>;
export type ConvertConvertibleMutationOptions = Apollo.BaseMutationOptions<
  ConvertConvertibleMutation,
  ConvertConvertibleMutationVariables
>;
export const UpdateConversionDocument = gql`
  mutation UpdateConversion(
    $investedAmountInput: UpdateInvestedAmountInput!
    $investmentDateInput: UpdateInvestmentDateInput!
    $conversionDateInput: UpdateInvestmentDateInput!
    $numAssetsFirstInput: UpdateNumAssetsInput!
    $assetNameFirstInput: UpdateAssetNameInput!
    $numAssetsSecondInput: UpdateNumAssetsInput
    $assetNameSecondInput: UpdateAssetNameInput
    $convertibleInput: UpdateConvertiblePropertiesInput!
  ) {
    updateInvestedAmount(input: $investedAmountInput)
    updateInvestmentDate: updateInvestmentDate(input: $investmentDateInput)
    updateConversionDate: updateInvestmentDate(input: $conversionDateInput)
    updateShares1: updateNumAssets(input: $numAssetsFirstInput)
    updateSharesName1: asset {
      updateName(input: $assetNameFirstInput) {
        ... on BaseAsset {
          name
        }
        ... on ConvertibleAsset {
          name
        }
      }
    }
    updateShares2: updateNumAssets(input: $numAssetsSecondInput)
    updateSharesName2: asset {
      updateName(input: $assetNameSecondInput) {
        ... on BaseAsset {
          name
        }
        ... on ConvertibleAsset {
          name
        }
      }
    }
    updateConvertibleProperties(input: $convertibleInput)
  }
`;
export type UpdateConversionMutationFn = Apollo.MutationFunction<
  UpdateConversionMutation,
  UpdateConversionMutationVariables
>;

/**
 * __useUpdateConversionMutation__
 *
 * To run a mutation, you first call `useUpdateConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversionMutation, { data, loading, error }] = useUpdateConversionMutation({
 *   variables: {
 *      investedAmountInput: // value for 'investedAmountInput'
 *      investmentDateInput: // value for 'investmentDateInput'
 *      conversionDateInput: // value for 'conversionDateInput'
 *      numAssetsFirstInput: // value for 'numAssetsFirstInput'
 *      assetNameFirstInput: // value for 'assetNameFirstInput'
 *      numAssetsSecondInput: // value for 'numAssetsSecondInput'
 *      assetNameSecondInput: // value for 'assetNameSecondInput'
 *      convertibleInput: // value for 'convertibleInput'
 *   },
 * });
 */
export function useUpdateConversionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateConversionMutation, UpdateConversionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateConversionMutation, UpdateConversionMutationVariables>(
    UpdateConversionDocument,
    options,
  );
}
export type UpdateConversionMutationHookResult = ReturnType<typeof useUpdateConversionMutation>;
export type UpdateConversionMutationResult = Apollo.MutationResult<UpdateConversionMutation>;
export type UpdateConversionMutationOptions = Apollo.BaseMutationOptions<
  UpdateConversionMutation,
  UpdateConversionMutationVariables
>;
export const UpdateDistributionDocument = gql`
  mutation UpdateDistribution(
    $assetTransferId: ID!
    $newDate: Date!
    $newAmount: Float!
    $newCurrency: CurrencyIsoCode!
  ) {
    updateDistributionDate(assetTransferId: $assetTransferId, newDate: $newDate)
    updateDistributionAmount(assetTransferId: $assetTransferId, newAmount: $newAmount, newCurrency: $newCurrency)
  }
`;
export type UpdateDistributionMutationFn = Apollo.MutationFunction<
  UpdateDistributionMutation,
  UpdateDistributionMutationVariables
>;

/**
 * __useUpdateDistributionMutation__
 *
 * To run a mutation, you first call `useUpdateDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistributionMutation, { data, loading, error }] = useUpdateDistributionMutation({
 *   variables: {
 *      assetTransferId: // value for 'assetTransferId'
 *      newDate: // value for 'newDate'
 *      newAmount: // value for 'newAmount'
 *      newCurrency: // value for 'newCurrency'
 *   },
 * });
 */
export function useUpdateDistributionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDistributionMutation, UpdateDistributionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDistributionMutation, UpdateDistributionMutationVariables>(
    UpdateDistributionDocument,
    options,
  );
}
export type UpdateDistributionMutationHookResult = ReturnType<typeof useUpdateDistributionMutation>;
export type UpdateDistributionMutationResult = Apollo.MutationResult<UpdateDistributionMutation>;
export type UpdateDistributionMutationOptions = Apollo.BaseMutationOptions<
  UpdateDistributionMutation,
  UpdateDistributionMutationVariables
>;
export const DeleteDistributionDocument = gql`
  mutation DeleteDistribution($assetTransferId: ID!) {
    deleteDistribution(assetTransferId: $assetTransferId)
  }
`;
export type DeleteDistributionMutationFn = Apollo.MutationFunction<
  DeleteDistributionMutation,
  DeleteDistributionMutationVariables
>;

/**
 * __useDeleteDistributionMutation__
 *
 * To run a mutation, you first call `useDeleteDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDistributionMutation, { data, loading, error }] = useDeleteDistributionMutation({
 *   variables: {
 *      assetTransferId: // value for 'assetTransferId'
 *   },
 * });
 */
export function useDeleteDistributionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDistributionMutation, DeleteDistributionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDistributionMutation, DeleteDistributionMutationVariables>(
    DeleteDistributionDocument,
    options,
  );
}
export type DeleteDistributionMutationHookResult = ReturnType<typeof useDeleteDistributionMutation>;
export type DeleteDistributionMutationResult = Apollo.MutationResult<DeleteDistributionMutation>;
export type DeleteDistributionMutationOptions = Apollo.BaseMutationOptions<
  DeleteDistributionMutation,
  DeleteDistributionMutationVariables
>;
export const ImportDistributionsDocument = gql`
  mutation ImportDistributions($input: ImportDistributionInput!) {
    importDistributions(input: $input)
  }
`;
export type ImportDistributionsMutationFn = Apollo.MutationFunction<
  ImportDistributionsMutation,
  ImportDistributionsMutationVariables
>;

/**
 * __useImportDistributionsMutation__
 *
 * To run a mutation, you first call `useImportDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDistributionsMutation, { data, loading, error }] = useImportDistributionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportDistributionsMutation, ImportDistributionsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportDistributionsMutation, ImportDistributionsMutationVariables>(
    ImportDistributionsDocument,
    options,
  );
}
export type ImportDistributionsMutationHookResult = ReturnType<typeof useImportDistributionsMutation>;
export type ImportDistributionsMutationResult = Apollo.MutationResult<ImportDistributionsMutation>;
export type ImportDistributionsMutationOptions = Apollo.BaseMutationOptions<
  ImportDistributionsMutation,
  ImportDistributionsMutationVariables
>;
export const ImportFundDistributionsDocument = gql`
  mutation ImportFundDistributions($input: ImportFundDistributionsInput!) {
    importFundDistributions(input: $input)
  }
`;
export type ImportFundDistributionsMutationFn = Apollo.MutationFunction<
  ImportFundDistributionsMutation,
  ImportFundDistributionsMutationVariables
>;

/**
 * __useImportFundDistributionsMutation__
 *
 * To run a mutation, you first call `useImportFundDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportFundDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importFundDistributionsMutation, { data, loading, error }] = useImportFundDistributionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportFundDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportFundDistributionsMutation, ImportFundDistributionsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportFundDistributionsMutation, ImportFundDistributionsMutationVariables>(
    ImportFundDistributionsDocument,
    options,
  );
}
export type ImportFundDistributionsMutationHookResult = ReturnType<typeof useImportFundDistributionsMutation>;
export type ImportFundDistributionsMutationResult = Apollo.MutationResult<ImportFundDistributionsMutation>;
export type ImportFundDistributionsMutationOptions = Apollo.BaseMutationOptions<
  ImportFundDistributionsMutation,
  ImportFundDistributionsMutationVariables
>;
export const UpsertDividendDocument = gql`
  mutation UpsertDividend($input: DividendInput!) {
    upsertDividend(input: $input) {
      id
    }
  }
`;
export type UpsertDividendMutationFn = Apollo.MutationFunction<UpsertDividendMutation, UpsertDividendMutationVariables>;

/**
 * __useUpsertDividendMutation__
 *
 * To run a mutation, you first call `useUpsertDividendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDividendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDividendMutation, { data, loading, error }] = useUpsertDividendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDividendMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertDividendMutation, UpsertDividendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertDividendMutation, UpsertDividendMutationVariables>(UpsertDividendDocument, options);
}
export type UpsertDividendMutationHookResult = ReturnType<typeof useUpsertDividendMutation>;
export type UpsertDividendMutationResult = Apollo.MutationResult<UpsertDividendMutation>;
export type UpsertDividendMutationOptions = Apollo.BaseMutationOptions<
  UpsertDividendMutation,
  UpsertDividendMutationVariables
>;
export const UpdateDividendDocument = gql`
  mutation UpdateDividend($assetTransferId: ID!, $newAmountInput: MoneyInput, $newDate: Date) {
    updateDividend(assetTransferId: $assetTransferId, newAmountInput: $newAmountInput, newDate: $newDate)
  }
`;
export type UpdateDividendMutationFn = Apollo.MutationFunction<UpdateDividendMutation, UpdateDividendMutationVariables>;

/**
 * __useUpdateDividendMutation__
 *
 * To run a mutation, you first call `useUpdateDividendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDividendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDividendMutation, { data, loading, error }] = useUpdateDividendMutation({
 *   variables: {
 *      assetTransferId: // value for 'assetTransferId'
 *      newAmountInput: // value for 'newAmountInput'
 *      newDate: // value for 'newDate'
 *   },
 * });
 */
export function useUpdateDividendMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDividendMutation, UpdateDividendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDividendMutation, UpdateDividendMutationVariables>(UpdateDividendDocument, options);
}
export type UpdateDividendMutationHookResult = ReturnType<typeof useUpdateDividendMutation>;
export type UpdateDividendMutationResult = Apollo.MutationResult<UpdateDividendMutation>;
export type UpdateDividendMutationOptions = Apollo.BaseMutationOptions<
  UpdateDividendMutation,
  UpdateDividendMutationVariables
>;
export const DeleteDividendDocument = gql`
  mutation DeleteDividend($assetTransferId: ID!) {
    deleteDividend(assetTransferId: $assetTransferId)
  }
`;
export type DeleteDividendMutationFn = Apollo.MutationFunction<DeleteDividendMutation, DeleteDividendMutationVariables>;

/**
 * __useDeleteDividendMutation__
 *
 * To run a mutation, you first call `useDeleteDividendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDividendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDividendMutation, { data, loading, error }] = useDeleteDividendMutation({
 *   variables: {
 *      assetTransferId: // value for 'assetTransferId'
 *   },
 * });
 */
export function useDeleteDividendMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDividendMutation, DeleteDividendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDividendMutation, DeleteDividendMutationVariables>(DeleteDividendDocument, options);
}
export type DeleteDividendMutationHookResult = ReturnType<typeof useDeleteDividendMutation>;
export type DeleteDividendMutationResult = Apollo.MutationResult<DeleteDividendMutation>;
export type DeleteDividendMutationOptions = Apollo.BaseMutationOptions<
  DeleteDividendMutation,
  DeleteDividendMutationVariables
>;
export const UpdateEquityTransactionDocument = gql`
  mutation UpdateEquityTransaction(
    $investedAmountInput: UpdateInvestedAmountInput!
    $numAssetsInput: UpdateNumAssetsInput!
    $investmentDateInput: UpdateInvestmentDateInput!
    $assetNameInput: UpdateAssetNameInput!
  ) {
    updateInvestedAmount(input: $investedAmountInput)
    updateNumAssets(input: $numAssetsInput)
    updateInvestmentDate(input: $investmentDateInput)
    asset {
      updateName(input: $assetNameInput) {
        ... on BaseAsset {
          name
        }
        ... on ConvertibleAsset {
          name
        }
      }
    }
  }
`;
export type UpdateEquityTransactionMutationFn = Apollo.MutationFunction<
  UpdateEquityTransactionMutation,
  UpdateEquityTransactionMutationVariables
>;

/**
 * __useUpdateEquityTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateEquityTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquityTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquityTransactionMutation, { data, loading, error }] = useUpdateEquityTransactionMutation({
 *   variables: {
 *      investedAmountInput: // value for 'investedAmountInput'
 *      numAssetsInput: // value for 'numAssetsInput'
 *      investmentDateInput: // value for 'investmentDateInput'
 *      assetNameInput: // value for 'assetNameInput'
 *   },
 * });
 */
export function useUpdateEquityTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEquityTransactionMutation, UpdateEquityTransactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEquityTransactionMutation, UpdateEquityTransactionMutationVariables>(
    UpdateEquityTransactionDocument,
    options,
  );
}
export type UpdateEquityTransactionMutationHookResult = ReturnType<typeof useUpdateEquityTransactionMutation>;
export type UpdateEquityTransactionMutationResult = Apollo.MutationResult<UpdateEquityTransactionMutation>;
export type UpdateEquityTransactionMutationOptions = Apollo.BaseMutationOptions<
  UpdateEquityTransactionMutation,
  UpdateEquityTransactionMutationVariables
>;
export const UpsertEquityAndNoteDocument = gql`
  mutation UpsertEquityAndNote($input: UpsertEquityInput!, $transactionNote: String) {
    upsertEquityAndNote(input: $input, note: $transactionNote) {
      note {
        id
        message
      }
    }
  }
`;
export type UpsertEquityAndNoteMutationFn = Apollo.MutationFunction<
  UpsertEquityAndNoteMutation,
  UpsertEquityAndNoteMutationVariables
>;

/**
 * __useUpsertEquityAndNoteMutation__
 *
 * To run a mutation, you first call `useUpsertEquityAndNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEquityAndNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEquityAndNoteMutation, { data, loading, error }] = useUpsertEquityAndNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      transactionNote: // value for 'transactionNote'
 *   },
 * });
 */
export function useUpsertEquityAndNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertEquityAndNoteMutation, UpsertEquityAndNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertEquityAndNoteMutation, UpsertEquityAndNoteMutationVariables>(
    UpsertEquityAndNoteDocument,
    options,
  );
}
export type UpsertEquityAndNoteMutationHookResult = ReturnType<typeof useUpsertEquityAndNoteMutation>;
export type UpsertEquityAndNoteMutationResult = Apollo.MutationResult<UpsertEquityAndNoteMutation>;
export type UpsertEquityAndNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertEquityAndNoteMutation,
  UpsertEquityAndNoteMutationVariables
>;
export const UpsertEquityByAmountAndNoteDocument = gql`
  mutation UpsertEquityByAmountAndNote($input: UpsertEquityByAmountInput!, $transactionNote: String) {
    upsertEquityByAmountAndNote(input: $input, note: $transactionNote) {
      note {
        id
        message
      }
    }
  }
`;
export type UpsertEquityByAmountAndNoteMutationFn = Apollo.MutationFunction<
  UpsertEquityByAmountAndNoteMutation,
  UpsertEquityByAmountAndNoteMutationVariables
>;

/**
 * __useUpsertEquityByAmountAndNoteMutation__
 *
 * To run a mutation, you first call `useUpsertEquityByAmountAndNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEquityByAmountAndNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEquityByAmountAndNoteMutation, { data, loading, error }] = useUpsertEquityByAmountAndNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      transactionNote: // value for 'transactionNote'
 *   },
 * });
 */
export function useUpsertEquityByAmountAndNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertEquityByAmountAndNoteMutation,
    UpsertEquityByAmountAndNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertEquityByAmountAndNoteMutation, UpsertEquityByAmountAndNoteMutationVariables>(
    UpsertEquityByAmountAndNoteDocument,
    options,
  );
}
export type UpsertEquityByAmountAndNoteMutationHookResult = ReturnType<typeof useUpsertEquityByAmountAndNoteMutation>;
export type UpsertEquityByAmountAndNoteMutationResult = Apollo.MutationResult<UpsertEquityByAmountAndNoteMutation>;
export type UpsertEquityByAmountAndNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertEquityByAmountAndNoteMutation,
  UpsertEquityByAmountAndNoteMutationVariables
>;
export const UpsertSecondaryEquityAndNoteDocument = gql`
  mutation UpsertSecondaryEquityAndNote($input: UpsertSecondaryInput!, $transactionNote: String) {
    upsertSecondaryAndNote(input: $input, note: $transactionNote) {
      note {
        id
        message
      }
    }
  }
`;
export type UpsertSecondaryEquityAndNoteMutationFn = Apollo.MutationFunction<
  UpsertSecondaryEquityAndNoteMutation,
  UpsertSecondaryEquityAndNoteMutationVariables
>;

/**
 * __useUpsertSecondaryEquityAndNoteMutation__
 *
 * To run a mutation, you first call `useUpsertSecondaryEquityAndNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSecondaryEquityAndNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSecondaryEquityAndNoteMutation, { data, loading, error }] = useUpsertSecondaryEquityAndNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      transactionNote: // value for 'transactionNote'
 *   },
 * });
 */
export function useUpsertSecondaryEquityAndNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertSecondaryEquityAndNoteMutation,
    UpsertSecondaryEquityAndNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertSecondaryEquityAndNoteMutation, UpsertSecondaryEquityAndNoteMutationVariables>(
    UpsertSecondaryEquityAndNoteDocument,
    options,
  );
}
export type UpsertSecondaryEquityAndNoteMutationHookResult = ReturnType<typeof useUpsertSecondaryEquityAndNoteMutation>;
export type UpsertSecondaryEquityAndNoteMutationResult = Apollo.MutationResult<UpsertSecondaryEquityAndNoteMutation>;
export type UpsertSecondaryEquityAndNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertSecondaryEquityAndNoteMutation,
  UpsertSecondaryEquityAndNoteMutationVariables
>;
export const UpdateFundTransactionDocument = gql`
  mutation UpdateFundTransaction(
    $investedAmountInput: UpdateInvestedAmountInput!
    $investmentDateInput: UpdateInvestmentDateInput!
  ) {
    updateInvestedAmount(input: $investedAmountInput)
    updateInvestmentDate(input: $investmentDateInput)
  }
`;
export type UpdateFundTransactionMutationFn = Apollo.MutationFunction<
  UpdateFundTransactionMutation,
  UpdateFundTransactionMutationVariables
>;

/**
 * __useUpdateFundTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateFundTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFundTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFundTransactionMutation, { data, loading, error }] = useUpdateFundTransactionMutation({
 *   variables: {
 *      investedAmountInput: // value for 'investedAmountInput'
 *      investmentDateInput: // value for 'investmentDateInput'
 *   },
 * });
 */
export function useUpdateFundTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFundTransactionMutation, UpdateFundTransactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFundTransactionMutation, UpdateFundTransactionMutationVariables>(
    UpdateFundTransactionDocument,
    options,
  );
}
export type UpdateFundTransactionMutationHookResult = ReturnType<typeof useUpdateFundTransactionMutation>;
export type UpdateFundTransactionMutationResult = Apollo.MutationResult<UpdateFundTransactionMutation>;
export type UpdateFundTransactionMutationOptions = Apollo.BaseMutationOptions<
  UpdateFundTransactionMutation,
  UpdateFundTransactionMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation DeleteEvent($eventId: ID!) {
    event {
      delete(id: $eventId) {
        id
      }
    }
  }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
}
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const CreateMarkdownEventDocument = gql`
  mutation CreateMarkdownEvent($input: CreateMarkdownInput!) {
    markdown {
      create(input: $input) {
        id
      }
    }
  }
`;
export type CreateMarkdownEventMutationFn = Apollo.MutationFunction<
  CreateMarkdownEventMutation,
  CreateMarkdownEventMutationVariables
>;

/**
 * __useCreateMarkdownEventMutation__
 *
 * To run a mutation, you first call `useCreateMarkdownEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarkdownEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarkdownEventMutation, { data, loading, error }] = useCreateMarkdownEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarkdownEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMarkdownEventMutation, CreateMarkdownEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMarkdownEventMutation, CreateMarkdownEventMutationVariables>(
    CreateMarkdownEventDocument,
    options,
  );
}
export type CreateMarkdownEventMutationHookResult = ReturnType<typeof useCreateMarkdownEventMutation>;
export type CreateMarkdownEventMutationResult = Apollo.MutationResult<CreateMarkdownEventMutation>;
export type CreateMarkdownEventMutationOptions = Apollo.BaseMutationOptions<
  CreateMarkdownEventMutation,
  CreateMarkdownEventMutationVariables
>;
export const UpdateMarkdownEventDocument = gql`
  mutation UpdateMarkdownEvent($input: UpdateMarkdownInput!) {
    markdown {
      update(input: $input) {
        id
      }
    }
  }
`;
export type UpdateMarkdownEventMutationFn = Apollo.MutationFunction<
  UpdateMarkdownEventMutation,
  UpdateMarkdownEventMutationVariables
>;

/**
 * __useUpdateMarkdownEventMutation__
 *
 * To run a mutation, you first call `useUpdateMarkdownEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarkdownEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarkdownEventMutation, { data, loading, error }] = useUpdateMarkdownEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMarkdownEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMarkdownEventMutation, UpdateMarkdownEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMarkdownEventMutation, UpdateMarkdownEventMutationVariables>(
    UpdateMarkdownEventDocument,
    options,
  );
}
export type UpdateMarkdownEventMutationHookResult = ReturnType<typeof useUpdateMarkdownEventMutation>;
export type UpdateMarkdownEventMutationResult = Apollo.MutationResult<UpdateMarkdownEventMutation>;
export type UpdateMarkdownEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateMarkdownEventMutation,
  UpdateMarkdownEventMutationVariables
>;
export const DeleteMarkdownEventDocument = gql`
  mutation DeleteMarkdownEvent($input: DeleteMarkdownInput!) {
    markdown {
      delete(input: $input) {
        id
      }
    }
  }
`;
export type DeleteMarkdownEventMutationFn = Apollo.MutationFunction<
  DeleteMarkdownEventMutation,
  DeleteMarkdownEventMutationVariables
>;

/**
 * __useDeleteMarkdownEventMutation__
 *
 * To run a mutation, you first call `useDeleteMarkdownEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarkdownEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMarkdownEventMutation, { data, loading, error }] = useDeleteMarkdownEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMarkdownEventMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMarkdownEventMutation, DeleteMarkdownEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMarkdownEventMutation, DeleteMarkdownEventMutationVariables>(
    DeleteMarkdownEventDocument,
    options,
  );
}
export type DeleteMarkdownEventMutationHookResult = ReturnType<typeof useDeleteMarkdownEventMutation>;
export type DeleteMarkdownEventMutationResult = Apollo.MutationResult<DeleteMarkdownEventMutation>;
export type DeleteMarkdownEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteMarkdownEventMutation,
  DeleteMarkdownEventMutationVariables
>;
export const UpdateSpvTransactionDocument = gql`
  mutation UpdateSpvTransaction(
    $investedAmountInput: UpdateInvestedAmountInput!
    $investmentDateInput: UpdateInvestmentDateInput!
  ) {
    updateInvestedAmount(input: $investedAmountInput)
    updateInvestmentDate(input: $investmentDateInput)
  }
`;
export type UpdateSpvTransactionMutationFn = Apollo.MutationFunction<
  UpdateSpvTransactionMutation,
  UpdateSpvTransactionMutationVariables
>;

/**
 * __useUpdateSpvTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateSpvTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpvTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpvTransactionMutation, { data, loading, error }] = useUpdateSpvTransactionMutation({
 *   variables: {
 *      investedAmountInput: // value for 'investedAmountInput'
 *      investmentDateInput: // value for 'investmentDateInput'
 *   },
 * });
 */
export function useUpdateSpvTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSpvTransactionMutation, UpdateSpvTransactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSpvTransactionMutation, UpdateSpvTransactionMutationVariables>(
    UpdateSpvTransactionDocument,
    options,
  );
}
export type UpdateSpvTransactionMutationHookResult = ReturnType<typeof useUpdateSpvTransactionMutation>;
export type UpdateSpvTransactionMutationResult = Apollo.MutationResult<UpdateSpvTransactionMutation>;
export type UpdateSpvTransactionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpvTransactionMutation,
  UpdateSpvTransactionMutationVariables
>;
export const UpsertSpvAndNoteDocument = gql`
  mutation UpsertSpvAndNote($input: UpsertSpvInput!, $transactionNote: String) {
    upsertSpvAndNote(input: $input, note: $transactionNote) {
      note {
        id
        message
      }
    }
  }
`;
export type UpsertSpvAndNoteMutationFn = Apollo.MutationFunction<
  UpsertSpvAndNoteMutation,
  UpsertSpvAndNoteMutationVariables
>;

/**
 * __useUpsertSpvAndNoteMutation__
 *
 * To run a mutation, you first call `useUpsertSpvAndNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSpvAndNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSpvAndNoteMutation, { data, loading, error }] = useUpsertSpvAndNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      transactionNote: // value for 'transactionNote'
 *   },
 * });
 */
export function useUpsertSpvAndNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertSpvAndNoteMutation, UpsertSpvAndNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertSpvAndNoteMutation, UpsertSpvAndNoteMutationVariables>(
    UpsertSpvAndNoteDocument,
    options,
  );
}
export type UpsertSpvAndNoteMutationHookResult = ReturnType<typeof useUpsertSpvAndNoteMutation>;
export type UpsertSpvAndNoteMutationResult = Apollo.MutationResult<UpsertSpvAndNoteMutation>;
export type UpsertSpvAndNoteMutationOptions = Apollo.BaseMutationOptions<
  UpsertSpvAndNoteMutation,
  UpsertSpvAndNoteMutationVariables
>;
export const PostDealToExploreFeedDocument = gql`
  mutation postDealToExploreFeed($input: PostInput!) {
    post {
      createOrUpdate(input: $input) {
        id
      }
    }
  }
`;
export type PostDealToExploreFeedMutationFn = Apollo.MutationFunction<
  PostDealToExploreFeedMutation,
  PostDealToExploreFeedMutationVariables
>;

/**
 * __usePostDealToExploreFeedMutation__
 *
 * To run a mutation, you first call `usePostDealToExploreFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDealToExploreFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDealToExploreFeedMutation, { data, loading, error }] = usePostDealToExploreFeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostDealToExploreFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<PostDealToExploreFeedMutation, PostDealToExploreFeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PostDealToExploreFeedMutation, PostDealToExploreFeedMutationVariables>(
    PostDealToExploreFeedDocument,
    options,
  );
}
export type PostDealToExploreFeedMutationHookResult = ReturnType<typeof usePostDealToExploreFeedMutation>;
export type PostDealToExploreFeedMutationResult = Apollo.MutationResult<PostDealToExploreFeedMutation>;
export type PostDealToExploreFeedMutationOptions = Apollo.BaseMutationOptions<
  PostDealToExploreFeedMutation,
  PostDealToExploreFeedMutationVariables
>;
export const RemoveDealFromExploreFeedDocument = gql`
  mutation removeDealFromExploreFeed($submissionId: ID!) {
    post {
      deleteBySubmissionId(submissionId: $submissionId) {
        id
      }
    }
  }
`;
export type RemoveDealFromExploreFeedMutationFn = Apollo.MutationFunction<
  RemoveDealFromExploreFeedMutation,
  RemoveDealFromExploreFeedMutationVariables
>;

/**
 * __useRemoveDealFromExploreFeedMutation__
 *
 * To run a mutation, you first call `useRemoveDealFromExploreFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDealFromExploreFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDealFromExploreFeedMutation, { data, loading, error }] = useRemoveDealFromExploreFeedMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useRemoveDealFromExploreFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDealFromExploreFeedMutation,
    RemoveDealFromExploreFeedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDealFromExploreFeedMutation, RemoveDealFromExploreFeedMutationVariables>(
    RemoveDealFromExploreFeedDocument,
    options,
  );
}
export type RemoveDealFromExploreFeedMutationHookResult = ReturnType<typeof useRemoveDealFromExploreFeedMutation>;
export type RemoveDealFromExploreFeedMutationResult = Apollo.MutationResult<RemoveDealFromExploreFeedMutation>;
export type RemoveDealFromExploreFeedMutationOptions = Apollo.BaseMutationOptions<
  RemoveDealFromExploreFeedMutation,
  RemoveDealFromExploreFeedMutationVariables
>;
export const ExploreFeedDocument = gql`
  query ExploreFeed($input: ExploreFeedInput!) {
    exploreFeed(input: $input) {
      ...PostDisplayFields
    }
  }
  ${PostDisplayFieldsFragmentDoc}
`;

/**
 * __useExploreFeedQuery__
 *
 * To run a query within a React component, call `useExploreFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreFeedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExploreFeedQuery(
  baseOptions: Apollo.QueryHookOptions<ExploreFeedQuery, ExploreFeedQueryVariables> &
    ({ variables: ExploreFeedQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExploreFeedQuery, ExploreFeedQueryVariables>(ExploreFeedDocument, options);
}
export function useExploreFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExploreFeedQuery, ExploreFeedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExploreFeedQuery, ExploreFeedQueryVariables>(ExploreFeedDocument, options);
}
export function useExploreFeedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExploreFeedQuery, ExploreFeedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExploreFeedQuery, ExploreFeedQueryVariables>(ExploreFeedDocument, options);
}
export type ExploreFeedQueryHookResult = ReturnType<typeof useExploreFeedQuery>;
export type ExploreFeedLazyQueryHookResult = ReturnType<typeof useExploreFeedLazyQuery>;
export type ExploreFeedSuspenseQueryHookResult = ReturnType<typeof useExploreFeedSuspenseQuery>;
export type ExploreFeedQueryResult = Apollo.QueryResult<ExploreFeedQuery, ExploreFeedQueryVariables>;
export const SuggestedTagsDocument = gql`
  query SuggestedTags($submissionId: ID!) {
    sugestedTags(submissionId: $submissionId)
  }
`;

/**
 * __useSuggestedTagsQuery__
 *
 * To run a query within a React component, call `useSuggestedTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedTagsQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useSuggestedTagsQuery(
  baseOptions: Apollo.QueryHookOptions<SuggestedTagsQuery, SuggestedTagsQueryVariables> &
    ({ variables: SuggestedTagsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestedTagsQuery, SuggestedTagsQueryVariables>(SuggestedTagsDocument, options);
}
export function useSuggestedTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuggestedTagsQuery, SuggestedTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestedTagsQuery, SuggestedTagsQueryVariables>(SuggestedTagsDocument, options);
}
export function useSuggestedTagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedTagsQuery, SuggestedTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuggestedTagsQuery, SuggestedTagsQueryVariables>(SuggestedTagsDocument, options);
}
export type SuggestedTagsQueryHookResult = ReturnType<typeof useSuggestedTagsQuery>;
export type SuggestedTagsLazyQueryHookResult = ReturnType<typeof useSuggestedTagsLazyQuery>;
export type SuggestedTagsSuspenseQueryHookResult = ReturnType<typeof useSuggestedTagsSuspenseQuery>;
export type SuggestedTagsQueryResult = Apollo.QueryResult<SuggestedTagsQuery, SuggestedTagsQueryVariables>;
export const CreatePostInteractionDocument = gql`
  mutation CreatePostInteraction($input: PostInteractionInput!) {
    postInteraction {
      create(input: $input) {
        id
        type
        creator {
          id
        }
      }
    }
  }
`;
export type CreatePostInteractionMutationFn = Apollo.MutationFunction<
  CreatePostInteractionMutation,
  CreatePostInteractionMutationVariables
>;

/**
 * __useCreatePostInteractionMutation__
 *
 * To run a mutation, you first call `useCreatePostInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostInteractionMutation, { data, loading, error }] = useCreatePostInteractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePostInteractionMutation, CreatePostInteractionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostInteractionMutation, CreatePostInteractionMutationVariables>(
    CreatePostInteractionDocument,
    options,
  );
}
export type CreatePostInteractionMutationHookResult = ReturnType<typeof useCreatePostInteractionMutation>;
export type CreatePostInteractionMutationResult = Apollo.MutationResult<CreatePostInteractionMutation>;
export type CreatePostInteractionMutationOptions = Apollo.BaseMutationOptions<
  CreatePostInteractionMutation,
  CreatePostInteractionMutationVariables
>;
export const DeletePostInteractionDocument = gql`
  mutation DeletePostInteraction($id: ID!) {
    postInteraction {
      delete(id: $id) {
        id
      }
    }
  }
`;
export type DeletePostInteractionMutationFn = Apollo.MutationFunction<
  DeletePostInteractionMutation,
  DeletePostInteractionMutationVariables
>;

/**
 * __useDeletePostInteractionMutation__
 *
 * To run a mutation, you first call `useDeletePostInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostInteractionMutation, { data, loading, error }] = useDeletePostInteractionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePostInteractionMutation, DeletePostInteractionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePostInteractionMutation, DeletePostInteractionMutationVariables>(
    DeletePostInteractionDocument,
    options,
  );
}
export type DeletePostInteractionMutationHookResult = ReturnType<typeof useDeletePostInteractionMutation>;
export type DeletePostInteractionMutationResult = Apollo.MutationResult<DeletePostInteractionMutation>;
export type DeletePostInteractionMutationOptions = Apollo.BaseMutationOptions<
  DeletePostInteractionMutation,
  DeletePostInteractionMutationVariables
>;
export const FindFirstPostCreatedByMeDocument = gql`
  query FindFirstPostCreatedByMe {
    findFirstPostCreatedByMe {
      ...PostDisplayFields
    }
  }
  ${PostDisplayFieldsFragmentDoc}
`;

/**
 * __useFindFirstPostCreatedByMeQuery__
 *
 * To run a query within a React component, call `useFindFirstPostCreatedByMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstPostCreatedByMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstPostCreatedByMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindFirstPostCreatedByMeQuery(
  baseOptions?: Apollo.QueryHookOptions<FindFirstPostCreatedByMeQuery, FindFirstPostCreatedByMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindFirstPostCreatedByMeQuery, FindFirstPostCreatedByMeQueryVariables>(
    FindFirstPostCreatedByMeDocument,
    options,
  );
}
export function useFindFirstPostCreatedByMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindFirstPostCreatedByMeQuery, FindFirstPostCreatedByMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindFirstPostCreatedByMeQuery, FindFirstPostCreatedByMeQueryVariables>(
    FindFirstPostCreatedByMeDocument,
    options,
  );
}
export function useFindFirstPostCreatedByMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FindFirstPostCreatedByMeQuery, FindFirstPostCreatedByMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindFirstPostCreatedByMeQuery, FindFirstPostCreatedByMeQueryVariables>(
    FindFirstPostCreatedByMeDocument,
    options,
  );
}
export type FindFirstPostCreatedByMeQueryHookResult = ReturnType<typeof useFindFirstPostCreatedByMeQuery>;
export type FindFirstPostCreatedByMeLazyQueryHookResult = ReturnType<typeof useFindFirstPostCreatedByMeLazyQuery>;
export type FindFirstPostCreatedByMeSuspenseQueryHookResult = ReturnType<
  typeof useFindFirstPostCreatedByMeSuspenseQuery
>;
export type FindFirstPostCreatedByMeQueryResult = Apollo.QueryResult<
  FindFirstPostCreatedByMeQuery,
  FindFirstPostCreatedByMeQueryVariables
>;
export const FindPeopleIveGivenAccessDocument = gql`
  query FindPeopleIveGivenAccess {
    myIssuedAccessKeys {
      id
      viewer {
        id
        username
        publicProfile {
          id
          fullname
        }
      }
    }
  }
`;

/**
 * __useFindPeopleIveGivenAccessQuery__
 *
 * To run a query within a React component, call `useFindPeopleIveGivenAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPeopleIveGivenAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPeopleIveGivenAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindPeopleIveGivenAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<FindPeopleIveGivenAccessQuery, FindPeopleIveGivenAccessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPeopleIveGivenAccessQuery, FindPeopleIveGivenAccessQueryVariables>(
    FindPeopleIveGivenAccessDocument,
    options,
  );
}
export function useFindPeopleIveGivenAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindPeopleIveGivenAccessQuery, FindPeopleIveGivenAccessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPeopleIveGivenAccessQuery, FindPeopleIveGivenAccessQueryVariables>(
    FindPeopleIveGivenAccessDocument,
    options,
  );
}
export function useFindPeopleIveGivenAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FindPeopleIveGivenAccessQuery, FindPeopleIveGivenAccessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindPeopleIveGivenAccessQuery, FindPeopleIveGivenAccessQueryVariables>(
    FindPeopleIveGivenAccessDocument,
    options,
  );
}
export type FindPeopleIveGivenAccessQueryHookResult = ReturnType<typeof useFindPeopleIveGivenAccessQuery>;
export type FindPeopleIveGivenAccessLazyQueryHookResult = ReturnType<typeof useFindPeopleIveGivenAccessLazyQuery>;
export type FindPeopleIveGivenAccessSuspenseQueryHookResult = ReturnType<
  typeof useFindPeopleIveGivenAccessSuspenseQuery
>;
export type FindPeopleIveGivenAccessQueryResult = Apollo.QueryResult<
  FindPeopleIveGivenAccessQuery,
  FindPeopleIveGivenAccessQueryVariables
>;
export const FindPeopleWhoveGivenMeAccessDocument = gql`
  query FindPeopleWhoveGivenMeAccess {
    myVisibleAccessKeys {
      id
      issuer {
        id
        username
        publicProfile {
          id
          fullname
        }
      }
    }
  }
`;

/**
 * __useFindPeopleWhoveGivenMeAccessQuery__
 *
 * To run a query within a React component, call `useFindPeopleWhoveGivenMeAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPeopleWhoveGivenMeAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPeopleWhoveGivenMeAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindPeopleWhoveGivenMeAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<FindPeopleWhoveGivenMeAccessQuery, FindPeopleWhoveGivenMeAccessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPeopleWhoveGivenMeAccessQuery, FindPeopleWhoveGivenMeAccessQueryVariables>(
    FindPeopleWhoveGivenMeAccessDocument,
    options,
  );
}
export function useFindPeopleWhoveGivenMeAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPeopleWhoveGivenMeAccessQuery,
    FindPeopleWhoveGivenMeAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPeopleWhoveGivenMeAccessQuery, FindPeopleWhoveGivenMeAccessQueryVariables>(
    FindPeopleWhoveGivenMeAccessDocument,
    options,
  );
}
export function useFindPeopleWhoveGivenMeAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FindPeopleWhoveGivenMeAccessQuery,
    FindPeopleWhoveGivenMeAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindPeopleWhoveGivenMeAccessQuery, FindPeopleWhoveGivenMeAccessQueryVariables>(
    FindPeopleWhoveGivenMeAccessDocument,
    options,
  );
}
export type FindPeopleWhoveGivenMeAccessQueryHookResult = ReturnType<typeof useFindPeopleWhoveGivenMeAccessQuery>;
export type FindPeopleWhoveGivenMeAccessLazyQueryHookResult = ReturnType<
  typeof useFindPeopleWhoveGivenMeAccessLazyQuery
>;
export type FindPeopleWhoveGivenMeAccessSuspenseQueryHookResult = ReturnType<
  typeof useFindPeopleWhoveGivenMeAccessSuspenseQuery
>;
export type FindPeopleWhoveGivenMeAccessQueryResult = Apollo.QueryResult<
  FindPeopleWhoveGivenMeAccessQuery,
  FindPeopleWhoveGivenMeAccessQueryVariables
>;
export const ExplorePostDocument = gql`
  query ExplorePost($slug: String!) {
    explorePost(slug: $slug) {
      ...PostDisplayFields
    }
  }
  ${PostDisplayFieldsFragmentDoc}
`;

/**
 * __useExplorePostQuery__
 *
 * To run a query within a React component, call `useExplorePostQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorePostQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useExplorePostQuery(
  baseOptions: Apollo.QueryHookOptions<ExplorePostQuery, ExplorePostQueryVariables> &
    ({ variables: ExplorePostQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorePostQuery, ExplorePostQueryVariables>(ExplorePostDocument, options);
}
export function useExplorePostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExplorePostQuery, ExplorePostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorePostQuery, ExplorePostQueryVariables>(ExplorePostDocument, options);
}
export function useExplorePostSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExplorePostQuery, ExplorePostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorePostQuery, ExplorePostQueryVariables>(ExplorePostDocument, options);
}
export type ExplorePostQueryHookResult = ReturnType<typeof useExplorePostQuery>;
export type ExplorePostLazyQueryHookResult = ReturnType<typeof useExplorePostLazyQuery>;
export type ExplorePostSuspenseQueryHookResult = ReturnType<typeof useExplorePostSuspenseQuery>;
export type ExplorePostQueryResult = Apollo.QueryResult<ExplorePostQuery, ExplorePostQueryVariables>;
export const DetailedInvestmentsDocument = gql`
  query DetailedInvestments($viewerId: ID!, $asOfDate: Date!, $userCurrency: CurrencyIsoCode!, $cohortYear: Int) {
    viewerLegalEntity: legalEntity(id: $viewerId) {
      ...LegalEntityDisplayFields
      portfolioCompanies(userCurrency: $userCurrency, asOfDate: $asOfDate, cohortYear: $cohortYear) {
        legalEntity {
          ...LegalEntityDisplayFields
        }
        companyMetrics {
          lastPricePerShare
        }
      }
      allDetailedInvestments(userCurrency: $userCurrency, asOfDate: $asOfDate, cohortYear: $cohortYear) {
        id
        transactionDate
        issuedByLegalEntity {
          ...LegalEntityFields
          publicProfile {
            ... on CompanyProfile {
              city
              country
              themes
              website
            }
          }
        }
        spvLegalEntity {
          ...LegalEntityDisplayFields
        }
        asset {
          __typename
          ... on BaseAsset {
            id
            type
            name
          }
          ... on ConvertibleAsset {
            id
            name
            convertibleType
            currency
            valuationCap
            valuationCapInPinnedUserCurrency(userCurrency: $userCurrency, asOfDate: $asOfDate)
          }
        }
        numAssets
        conversion {
          id
          conversionDate
          numConvertedAssets
          conversionPrice
          convertedInto {
            id
            name
          }
          event {
            id
            name
            round {
              valuationCurrency
              raisedCurrency
            }
            investors {
              id
              displayName
              investingEntity {
                id
                name
              }
            }
          }
          notes {
            ...NoteFields
          }
        }
        event {
          id
          name
          round {
            valuationCurrency
            raisedCurrency
            raisedAmount
            raisedAmountInPinnedUserCurrency(userCurrency: $userCurrency, asOfDate: $asOfDate)
            valuation
            valuationInPinnedUserCurrency(userCurrency: $userCurrency, asOfDate: $asOfDate)
            valuationType
          }
          investors {
            id
            displayName
            investingEntity {
              id
              name
            }
          }
        }
        investmentMetrics {
          userCurrency
          investmentCurrency
          investedAmount {
            inInvestmentCurrency
            inUserCurrency
            inPinnedUserCurrency
          }
          holdingsValue {
            inUserCurrency
            inPinnedUserCurrency
          }
          moic {
            inUserCurrency
            inPinnedUserCurrency
          }
          irr {
            inUserCurrency
            inPinnedUserCurrency
          }
        }
        notes {
          ...NoteFields
        }
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
  ${LegalEntityFieldsFragmentDoc}
  ${NoteFieldsFragmentDoc}
`;

/**
 * __useDetailedInvestmentsQuery__
 *
 * To run a query within a React component, call `useDetailedInvestmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedInvestmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedInvestmentsQuery({
 *   variables: {
 *      viewerId: // value for 'viewerId'
 *      asOfDate: // value for 'asOfDate'
 *      userCurrency: // value for 'userCurrency'
 *      cohortYear: // value for 'cohortYear'
 *   },
 * });
 */
export function useDetailedInvestmentsQuery(
  baseOptions: Apollo.QueryHookOptions<DetailedInvestmentsQuery, DetailedInvestmentsQueryVariables> &
    ({ variables: DetailedInvestmentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DetailedInvestmentsQuery, DetailedInvestmentsQueryVariables>(
    DetailedInvestmentsDocument,
    options,
  );
}
export function useDetailedInvestmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DetailedInvestmentsQuery, DetailedInvestmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DetailedInvestmentsQuery, DetailedInvestmentsQueryVariables>(
    DetailedInvestmentsDocument,
    options,
  );
}
export function useDetailedInvestmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DetailedInvestmentsQuery, DetailedInvestmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DetailedInvestmentsQuery, DetailedInvestmentsQueryVariables>(
    DetailedInvestmentsDocument,
    options,
  );
}
export type DetailedInvestmentsQueryHookResult = ReturnType<typeof useDetailedInvestmentsQuery>;
export type DetailedInvestmentsLazyQueryHookResult = ReturnType<typeof useDetailedInvestmentsLazyQuery>;
export type DetailedInvestmentsSuspenseQueryHookResult = ReturnType<typeof useDetailedInvestmentsSuspenseQuery>;
export type DetailedInvestmentsQueryResult = Apollo.QueryResult<
  DetailedInvestmentsQuery,
  DetailedInvestmentsQueryVariables
>;
export const DetailedDistributionsDocument = gql`
  query DetailedDistributions($viewerId: ID!) {
    viewerLegalEntity: legalEntity(id: $viewerId) {
      ...LegalEntityFields
      portfolioCompanyDistributions {
        companyName
        date
        currency
        numAssets
      }
    }
  }
  ${LegalEntityFieldsFragmentDoc}
`;

/**
 * __useDetailedDistributionsQuery__
 *
 * To run a query within a React component, call `useDetailedDistributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedDistributionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedDistributionsQuery({
 *   variables: {
 *      viewerId: // value for 'viewerId'
 *   },
 * });
 */
export function useDetailedDistributionsQuery(
  baseOptions: Apollo.QueryHookOptions<DetailedDistributionsQuery, DetailedDistributionsQueryVariables> &
    ({ variables: DetailedDistributionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DetailedDistributionsQuery, DetailedDistributionsQueryVariables>(
    DetailedDistributionsDocument,
    options,
  );
}
export function useDetailedDistributionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DetailedDistributionsQuery, DetailedDistributionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DetailedDistributionsQuery, DetailedDistributionsQueryVariables>(
    DetailedDistributionsDocument,
    options,
  );
}
export function useDetailedDistributionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DetailedDistributionsQuery, DetailedDistributionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DetailedDistributionsQuery, DetailedDistributionsQueryVariables>(
    DetailedDistributionsDocument,
    options,
  );
}
export type DetailedDistributionsQueryHookResult = ReturnType<typeof useDetailedDistributionsQuery>;
export type DetailedDistributionsLazyQueryHookResult = ReturnType<typeof useDetailedDistributionsLazyQuery>;
export type DetailedDistributionsSuspenseQueryHookResult = ReturnType<typeof useDetailedDistributionsSuspenseQuery>;
export type DetailedDistributionsQueryResult = Apollo.QueryResult<
  DetailedDistributionsQuery,
  DetailedDistributionsQueryVariables
>;
export const DealFeedDocument = gql`
  query DealFeed($days: Int) {
    dealFeed(days: $days) {
      ...FeedDisplayFields
    }
  }
  ${FeedDisplayFieldsFragmentDoc}
`;

/**
 * __useDealFeedQuery__
 *
 * To run a query within a React component, call `useDealFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealFeedQuery({
 *   variables: {
 *      days: // value for 'days'
 *   },
 * });
 */
export function useDealFeedQuery(baseOptions?: Apollo.QueryHookOptions<DealFeedQuery, DealFeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealFeedQuery, DealFeedQueryVariables>(DealFeedDocument, options);
}
export function useDealFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealFeedQuery, DealFeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealFeedQuery, DealFeedQueryVariables>(DealFeedDocument, options);
}
export function useDealFeedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealFeedQuery, DealFeedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealFeedQuery, DealFeedQueryVariables>(DealFeedDocument, options);
}
export type DealFeedQueryHookResult = ReturnType<typeof useDealFeedQuery>;
export type DealFeedLazyQueryHookResult = ReturnType<typeof useDealFeedLazyQuery>;
export type DealFeedSuspenseQueryHookResult = ReturnType<typeof useDealFeedSuspenseQuery>;
export type DealFeedQueryResult = Apollo.QueryResult<DealFeedQuery, DealFeedQueryVariables>;
export const DealFeedMyDealsDocument = gql`
  query DealFeedMyDeals {
    dealFeedMyDeals {
      ...FeedDisplayFields
    }
  }
  ${FeedDisplayFieldsFragmentDoc}
`;

/**
 * __useDealFeedMyDealsQuery__
 *
 * To run a query within a React component, call `useDealFeedMyDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealFeedMyDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealFeedMyDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealFeedMyDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>(DealFeedMyDealsDocument, options);
}
export function useDealFeedMyDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>(DealFeedMyDealsDocument, options);
}
export function useDealFeedMyDealsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>(DealFeedMyDealsDocument, options);
}
export type DealFeedMyDealsQueryHookResult = ReturnType<typeof useDealFeedMyDealsQuery>;
export type DealFeedMyDealsLazyQueryHookResult = ReturnType<typeof useDealFeedMyDealsLazyQuery>;
export type DealFeedMyDealsSuspenseQueryHookResult = ReturnType<typeof useDealFeedMyDealsSuspenseQuery>;
export type DealFeedMyDealsQueryResult = Apollo.QueryResult<DealFeedMyDealsQuery, DealFeedMyDealsQueryVariables>;
export const DealFeedDealByIdDocument = gql`
  query DealFeedDealById($id: ID!) {
    submission(id: $id) {
      ...FeedDisplayFields
    }
  }
  ${FeedDisplayFieldsFragmentDoc}
`;

/**
 * __useDealFeedDealByIdQuery__
 *
 * To run a query within a React component, call `useDealFeedDealByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealFeedDealByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealFeedDealByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealFeedDealByIdQuery(
  baseOptions: Apollo.QueryHookOptions<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables> &
    ({ variables: DealFeedDealByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables>(DealFeedDealByIdDocument, options);
}
export function useDealFeedDealByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables>(DealFeedDealByIdDocument, options);
}
export function useDealFeedDealByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables>(
    DealFeedDealByIdDocument,
    options,
  );
}
export type DealFeedDealByIdQueryHookResult = ReturnType<typeof useDealFeedDealByIdQuery>;
export type DealFeedDealByIdLazyQueryHookResult = ReturnType<typeof useDealFeedDealByIdLazyQuery>;
export type DealFeedDealByIdSuspenseQueryHookResult = ReturnType<typeof useDealFeedDealByIdSuspenseQuery>;
export type DealFeedDealByIdQueryResult = Apollo.QueryResult<DealFeedDealByIdQuery, DealFeedDealByIdQueryVariables>;
export const DemoDealDocument = gql`
  query DemoDeal {
    demoDeal {
      ...FeedDisplayFields
    }
  }
  ${FeedDisplayFieldsFragmentDoc}
`;

/**
 * __useDemoDealQuery__
 *
 * To run a query within a React component, call `useDemoDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoDealQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoDealQuery(baseOptions?: Apollo.QueryHookOptions<DemoDealQuery, DemoDealQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoDealQuery, DemoDealQueryVariables>(DemoDealDocument, options);
}
export function useDemoDealLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DemoDealQuery, DemoDealQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemoDealQuery, DemoDealQueryVariables>(DemoDealDocument, options);
}
export function useDemoDealSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DemoDealQuery, DemoDealQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DemoDealQuery, DemoDealQueryVariables>(DemoDealDocument, options);
}
export type DemoDealQueryHookResult = ReturnType<typeof useDemoDealQuery>;
export type DemoDealLazyQueryHookResult = ReturnType<typeof useDemoDealLazyQuery>;
export type DemoDealSuspenseQueryHookResult = ReturnType<typeof useDemoDealSuspenseQuery>;
export type DemoDealQueryResult = Apollo.QueryResult<DemoDealQuery, DemoDealQueryVariables>;
export const CreateFounderIntroRequestDocument = gql`
  mutation CreateFounderIntroRequest($input: CreateFounderIntroRequestInput!) {
    founderIntroRequest {
      create(input: $input) {
        id
      }
    }
  }
`;
export type CreateFounderIntroRequestMutationFn = Apollo.MutationFunction<
  CreateFounderIntroRequestMutation,
  CreateFounderIntroRequestMutationVariables
>;

/**
 * __useCreateFounderIntroRequestMutation__
 *
 * To run a mutation, you first call `useCreateFounderIntroRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFounderIntroRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFounderIntroRequestMutation, { data, loading, error }] = useCreateFounderIntroRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFounderIntroRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFounderIntroRequestMutation,
    CreateFounderIntroRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFounderIntroRequestMutation, CreateFounderIntroRequestMutationVariables>(
    CreateFounderIntroRequestDocument,
    options,
  );
}
export type CreateFounderIntroRequestMutationHookResult = ReturnType<typeof useCreateFounderIntroRequestMutation>;
export type CreateFounderIntroRequestMutationResult = Apollo.MutationResult<CreateFounderIntroRequestMutation>;
export type CreateFounderIntroRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateFounderIntroRequestMutation,
  CreateFounderIntroRequestMutationVariables
>;
export const InvestingEntityDocument = gql`
  query InvestingEntity($investingEntityId: ID!) {
    investingEntity(id: $investingEntityId) {
      id
      name
      teamId
    }
  }
`;

/**
 * __useInvestingEntityQuery__
 *
 * To run a query within a React component, call `useInvestingEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestingEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestingEntityQuery({
 *   variables: {
 *      investingEntityId: // value for 'investingEntityId'
 *   },
 * });
 */
export function useInvestingEntityQuery(
  baseOptions: Apollo.QueryHookOptions<InvestingEntityQuery, InvestingEntityQueryVariables> &
    ({ variables: InvestingEntityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestingEntityQuery, InvestingEntityQueryVariables>(InvestingEntityDocument, options);
}
export function useInvestingEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestingEntityQuery, InvestingEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestingEntityQuery, InvestingEntityQueryVariables>(InvestingEntityDocument, options);
}
export function useInvestingEntitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvestingEntityQuery, InvestingEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvestingEntityQuery, InvestingEntityQueryVariables>(InvestingEntityDocument, options);
}
export type InvestingEntityQueryHookResult = ReturnType<typeof useInvestingEntityQuery>;
export type InvestingEntityLazyQueryHookResult = ReturnType<typeof useInvestingEntityLazyQuery>;
export type InvestingEntitySuspenseQueryHookResult = ReturnType<typeof useInvestingEntitySuspenseQuery>;
export type InvestingEntityQueryResult = Apollo.QueryResult<InvestingEntityQuery, InvestingEntityQueryVariables>;
export const InvestingEntitiesDocument = gql`
  query InvestingEntities {
    investingEntities {
      id
      name
      teamId
    }
  }
`;

/**
 * __useInvestingEntitiesQuery__
 *
 * To run a query within a React component, call `useInvestingEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestingEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestingEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestingEntitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>(InvestingEntitiesDocument, options);
}
export function useInvestingEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>(
    InvestingEntitiesDocument,
    options,
  );
}
export function useInvestingEntitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>(
    InvestingEntitiesDocument,
    options,
  );
}
export type InvestingEntitiesQueryHookResult = ReturnType<typeof useInvestingEntitiesQuery>;
export type InvestingEntitiesLazyQueryHookResult = ReturnType<typeof useInvestingEntitiesLazyQuery>;
export type InvestingEntitiesSuspenseQueryHookResult = ReturnType<typeof useInvestingEntitiesSuspenseQuery>;
export type InvestingEntitiesQueryResult = Apollo.QueryResult<InvestingEntitiesQuery, InvestingEntitiesQueryVariables>;
export const DeleteInvestmentDocument = gql`
  mutation DeleteInvestment($transactionId: ID!) {
    deleteInvestment(transactionId: $transactionId)
  }
`;
export type DeleteInvestmentMutationFn = Apollo.MutationFunction<
  DeleteInvestmentMutation,
  DeleteInvestmentMutationVariables
>;

/**
 * __useDeleteInvestmentMutation__
 *
 * To run a mutation, you first call `useDeleteInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestmentMutation, { data, loading, error }] = useDeleteInvestmentMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useDeleteInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvestmentMutation, DeleteInvestmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvestmentMutation, DeleteInvestmentMutationVariables>(
    DeleteInvestmentDocument,
    options,
  );
}
export type DeleteInvestmentMutationHookResult = ReturnType<typeof useDeleteInvestmentMutation>;
export type DeleteInvestmentMutationResult = Apollo.MutationResult<DeleteInvestmentMutation>;
export type DeleteInvestmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvestmentMutation,
  DeleteInvestmentMutationVariables
>;
export const ProfilesDocument = gql`
  query Profiles($meta: ProfilesQueryMeta!) {
    profiles(meta: $meta) {
      id
      fullname
      slug
      imageUrl
      roles {
        description
        entityProfile {
          fullname
        }
      }
    }
  }
`;

/**
 * __useProfilesQuery__
 *
 * To run a query within a React component, call `useProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesQuery({
 *   variables: {
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<ProfilesQuery, ProfilesQueryVariables> &
    ({ variables: ProfilesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
}
export function useProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesQuery, ProfilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
}
export function useProfilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProfilesQuery, ProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProfilesQuery, ProfilesQueryVariables>(ProfilesDocument, options);
}
export type ProfilesQueryHookResult = ReturnType<typeof useProfilesQuery>;
export type ProfilesLazyQueryHookResult = ReturnType<typeof useProfilesLazyQuery>;
export type ProfilesSuspenseQueryHookResult = ReturnType<typeof useProfilesSuspenseQuery>;
export type ProfilesQueryResult = Apollo.QueryResult<ProfilesQuery, ProfilesQueryVariables>;
export const HardCodedUsersForWelcomeScreenDocument = gql`
  query HardCodedUsersForWelcomeScreen {
    hardCodedUsersForWelcomeScreen {
      id
      fullname
      slug
      imageUrl
      roles {
        description
        entityProfile {
          fullname
        }
      }
    }
  }
`;

/**
 * __useHardCodedUsersForWelcomeScreenQuery__
 *
 * To run a query within a React component, call `useHardCodedUsersForWelcomeScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useHardCodedUsersForWelcomeScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHardCodedUsersForWelcomeScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useHardCodedUsersForWelcomeScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HardCodedUsersForWelcomeScreenQuery,
    HardCodedUsersForWelcomeScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HardCodedUsersForWelcomeScreenQuery, HardCodedUsersForWelcomeScreenQueryVariables>(
    HardCodedUsersForWelcomeScreenDocument,
    options,
  );
}
export function useHardCodedUsersForWelcomeScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HardCodedUsersForWelcomeScreenQuery,
    HardCodedUsersForWelcomeScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HardCodedUsersForWelcomeScreenQuery, HardCodedUsersForWelcomeScreenQueryVariables>(
    HardCodedUsersForWelcomeScreenDocument,
    options,
  );
}
export function useHardCodedUsersForWelcomeScreenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HardCodedUsersForWelcomeScreenQuery,
    HardCodedUsersForWelcomeScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HardCodedUsersForWelcomeScreenQuery, HardCodedUsersForWelcomeScreenQueryVariables>(
    HardCodedUsersForWelcomeScreenDocument,
    options,
  );
}
export type HardCodedUsersForWelcomeScreenQueryHookResult = ReturnType<typeof useHardCodedUsersForWelcomeScreenQuery>;
export type HardCodedUsersForWelcomeScreenLazyQueryHookResult = ReturnType<
  typeof useHardCodedUsersForWelcomeScreenLazyQuery
>;
export type HardCodedUsersForWelcomeScreenSuspenseQueryHookResult = ReturnType<
  typeof useHardCodedUsersForWelcomeScreenSuspenseQuery
>;
export type HardCodedUsersForWelcomeScreenQueryResult = Apollo.QueryResult<
  HardCodedUsersForWelcomeScreenQuery,
  HardCodedUsersForWelcomeScreenQueryVariables
>;
export const UpdateProfileImageDocument = gql`
  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {
    profile {
      updateImageUrl(input: $input) {
        id
        imageUrl
      }
    }
  }
`;
export type UpdateProfileImageMutationFn = Apollo.MutationFunction<
  UpdateProfileImageMutation,
  UpdateProfileImageMutationVariables
>;

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>(
    UpdateProfileImageDocument,
    options,
  );
}
export type UpdateProfileImageMutationHookResult = ReturnType<typeof useUpdateProfileImageMutation>;
export type UpdateProfileImageMutationResult = Apollo.MutationResult<UpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileImageMutation,
  UpdateProfileImageMutationVariables
>;
export const AddProfileInvestmentDocument = gql`
  mutation AddProfileInvestment($input: AddProfileInvestmentInput!) {
    profile {
      addProfileInvestment(input: $input) {
        id
      }
    }
  }
`;
export type AddProfileInvestmentMutationFn = Apollo.MutationFunction<
  AddProfileInvestmentMutation,
  AddProfileInvestmentMutationVariables
>;

/**
 * __useAddProfileInvestmentMutation__
 *
 * To run a mutation, you first call `useAddProfileInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileInvestmentMutation, { data, loading, error }] = useAddProfileInvestmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProfileInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddProfileInvestmentMutation, AddProfileInvestmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProfileInvestmentMutation, AddProfileInvestmentMutationVariables>(
    AddProfileInvestmentDocument,
    options,
  );
}
export type AddProfileInvestmentMutationHookResult = ReturnType<typeof useAddProfileInvestmentMutation>;
export type AddProfileInvestmentMutationResult = Apollo.MutationResult<AddProfileInvestmentMutation>;
export type AddProfileInvestmentMutationOptions = Apollo.BaseMutationOptions<
  AddProfileInvestmentMutation,
  AddProfileInvestmentMutationVariables
>;
export const AddProfilePublicInvestmentDocument = gql`
  mutation AddProfilePublicInvestment($input: AddPublicInvestmentInput!) {
    profile {
      addPublicInvestment(input: $input) {
        id
      }
    }
  }
`;
export type AddProfilePublicInvestmentMutationFn = Apollo.MutationFunction<
  AddProfilePublicInvestmentMutation,
  AddProfilePublicInvestmentMutationVariables
>;

/**
 * __useAddProfilePublicInvestmentMutation__
 *
 * To run a mutation, you first call `useAddProfilePublicInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfilePublicInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfilePublicInvestmentMutation, { data, loading, error }] = useAddProfilePublicInvestmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProfilePublicInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProfilePublicInvestmentMutation,
    AddProfilePublicInvestmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProfilePublicInvestmentMutation, AddProfilePublicInvestmentMutationVariables>(
    AddProfilePublicInvestmentDocument,
    options,
  );
}
export type AddProfilePublicInvestmentMutationHookResult = ReturnType<typeof useAddProfilePublicInvestmentMutation>;
export type AddProfilePublicInvestmentMutationResult = Apollo.MutationResult<AddProfilePublicInvestmentMutation>;
export type AddProfilePublicInvestmentMutationOptions = Apollo.BaseMutationOptions<
  AddProfilePublicInvestmentMutation,
  AddProfilePublicInvestmentMutationVariables
>;
export const DeleteProfilePublicInvestmentDocument = gql`
  mutation DeleteProfilePublicInvestment($id: ID!) {
    profile {
      deletePublicInvestment(id: $id) {
        id
      }
    }
  }
`;
export type DeleteProfilePublicInvestmentMutationFn = Apollo.MutationFunction<
  DeleteProfilePublicInvestmentMutation,
  DeleteProfilePublicInvestmentMutationVariables
>;

/**
 * __useDeleteProfilePublicInvestmentMutation__
 *
 * To run a mutation, you first call `useDeleteProfilePublicInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfilePublicInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfilePublicInvestmentMutation, { data, loading, error }] = useDeleteProfilePublicInvestmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfilePublicInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProfilePublicInvestmentMutation,
    DeleteProfilePublicInvestmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProfilePublicInvestmentMutation, DeleteProfilePublicInvestmentMutationVariables>(
    DeleteProfilePublicInvestmentDocument,
    options,
  );
}
export type DeleteProfilePublicInvestmentMutationHookResult = ReturnType<
  typeof useDeleteProfilePublicInvestmentMutation
>;
export type DeleteProfilePublicInvestmentMutationResult = Apollo.MutationResult<DeleteProfilePublicInvestmentMutation>;
export type DeleteProfilePublicInvestmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteProfilePublicInvestmentMutation,
  DeleteProfilePublicInvestmentMutationVariables
>;
export const PublicProfileFromSlugDocument = gql`
  query PublicProfileFromSlug($slug: String) {
    publicProfileBySlug(slug: $slug) {
      ... on Profile {
        id
        fullname
        imageUrl
        description
        linkedin
        descriptorsGeo
        descriptorsInvestorType
        descriptorsStage
        descriptorsMiscTags
        inviteProposalStatus
        connections
        slug
        roles {
          id
          description
          entityProfile {
            id
            fullname
            slug
            personalWebsite
          }
        }
        publicProfileInvestments {
          id
          company {
            id
            fullname
            personalWebsite
          }
          roundType
          date
        }
      }
    }
  }
`;

/**
 * __usePublicProfileFromSlugQuery__
 *
 * To run a query within a React component, call `usePublicProfileFromSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProfileFromSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProfileFromSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicProfileFromSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicProfileFromSlugQuery, PublicProfileFromSlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicProfileFromSlugQuery, PublicProfileFromSlugQueryVariables>(
    PublicProfileFromSlugDocument,
    options,
  );
}
export function usePublicProfileFromSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicProfileFromSlugQuery, PublicProfileFromSlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicProfileFromSlugQuery, PublicProfileFromSlugQueryVariables>(
    PublicProfileFromSlugDocument,
    options,
  );
}
export function usePublicProfileFromSlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PublicProfileFromSlugQuery, PublicProfileFromSlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PublicProfileFromSlugQuery, PublicProfileFromSlugQueryVariables>(
    PublicProfileFromSlugDocument,
    options,
  );
}
export type PublicProfileFromSlugQueryHookResult = ReturnType<typeof usePublicProfileFromSlugQuery>;
export type PublicProfileFromSlugLazyQueryHookResult = ReturnType<typeof usePublicProfileFromSlugLazyQuery>;
export type PublicProfileFromSlugSuspenseQueryHookResult = ReturnType<typeof usePublicProfileFromSlugSuspenseQuery>;
export type PublicProfileFromSlugQueryResult = Apollo.QueryResult<
  PublicProfileFromSlugQuery,
  PublicProfileFromSlugQueryVariables
>;
export const InvestorsDocument = gql`
  query Investors {
    investors {
      id
      displayName
      type
      investingEntity {
        id
        name
      }
      investments {
        id
        roundType
        date
        company {
          id
          displayName
          locations
          themes
          publicProfile {
            ... on CompanyProfile {
              id
              slug
            }
            ... on Profile {
              id
              slug
            }
          }
        }
      }
      coInvestors {
        id
        displayName
        type
      }
    }
  }
`;

/**
 * __useInvestorsQuery__
 *
 * To run a query within a React component, call `useInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorsQuery(baseOptions?: Apollo.QueryHookOptions<InvestorsQuery, InvestorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorsQuery, InvestorsQueryVariables>(InvestorsDocument, options);
}
export function useInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorsQuery, InvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorsQuery, InvestorsQueryVariables>(InvestorsDocument, options);
}
export function useInvestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvestorsQuery, InvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvestorsQuery, InvestorsQueryVariables>(InvestorsDocument, options);
}
export type InvestorsQueryHookResult = ReturnType<typeof useInvestorsQuery>;
export type InvestorsLazyQueryHookResult = ReturnType<typeof useInvestorsLazyQuery>;
export type InvestorsSuspenseQueryHookResult = ReturnType<typeof useInvestorsSuspenseQuery>;
export type InvestorsQueryResult = Apollo.QueryResult<InvestorsQuery, InvestorsQueryVariables>;
export const AllInvestorsDocument = gql`
  query AllInvestors {
    investors {
      id
      displayName
      type
      investingEntity {
        id
        name
      }
    }
  }
`;

/**
 * __useAllInvestorsQuery__
 *
 * To run a query within a React component, call `useAllInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvestorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllInvestorsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllInvestorsQuery, AllInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllInvestorsQuery, AllInvestorsQueryVariables>(AllInvestorsDocument, options);
}
export function useAllInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllInvestorsQuery, AllInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllInvestorsQuery, AllInvestorsQueryVariables>(AllInvestorsDocument, options);
}
export function useAllInvestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AllInvestorsQuery, AllInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllInvestorsQuery, AllInvestorsQueryVariables>(AllInvestorsDocument, options);
}
export type AllInvestorsQueryHookResult = ReturnType<typeof useAllInvestorsQuery>;
export type AllInvestorsLazyQueryHookResult = ReturnType<typeof useAllInvestorsLazyQuery>;
export type AllInvestorsSuspenseQueryHookResult = ReturnType<typeof useAllInvestorsSuspenseQuery>;
export type AllInvestorsQueryResult = Apollo.QueryResult<AllInvestorsQuery, AllInvestorsQueryVariables>;
export const FindInvestorsByFullnameDocument = gql`
  query FindInvestorsByFullname($fullname: String!) {
    findProfilesByFullname(fullname: $fullname) {
      id
      fullname
      type
    }
  }
`;

/**
 * __useFindInvestorsByFullnameQuery__
 *
 * To run a query within a React component, call `useFindInvestorsByFullnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInvestorsByFullnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInvestorsByFullnameQuery({
 *   variables: {
 *      fullname: // value for 'fullname'
 *   },
 * });
 */
export function useFindInvestorsByFullnameQuery(
  baseOptions: Apollo.QueryHookOptions<FindInvestorsByFullnameQuery, FindInvestorsByFullnameQueryVariables> &
    ({ variables: FindInvestorsByFullnameQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindInvestorsByFullnameQuery, FindInvestorsByFullnameQueryVariables>(
    FindInvestorsByFullnameDocument,
    options,
  );
}
export function useFindInvestorsByFullnameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindInvestorsByFullnameQuery, FindInvestorsByFullnameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindInvestorsByFullnameQuery, FindInvestorsByFullnameQueryVariables>(
    FindInvestorsByFullnameDocument,
    options,
  );
}
export function useFindInvestorsByFullnameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FindInvestorsByFullnameQuery, FindInvestorsByFullnameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindInvestorsByFullnameQuery, FindInvestorsByFullnameQueryVariables>(
    FindInvestorsByFullnameDocument,
    options,
  );
}
export type FindInvestorsByFullnameQueryHookResult = ReturnType<typeof useFindInvestorsByFullnameQuery>;
export type FindInvestorsByFullnameLazyQueryHookResult = ReturnType<typeof useFindInvestorsByFullnameLazyQuery>;
export type FindInvestorsByFullnameSuspenseQueryHookResult = ReturnType<typeof useFindInvestorsByFullnameSuspenseQuery>;
export type FindInvestorsByFullnameQueryResult = Apollo.QueryResult<
  FindInvestorsByFullnameQuery,
  FindInvestorsByFullnameQueryVariables
>;
export const InvestorsByRoundDocument = gql`
  query InvestorsByRound($eventId: ID!) {
    investorsByRound(eventId: $eventId) {
      id
      displayName
      type
    }
  }
`;

/**
 * __useInvestorsByRoundQuery__
 *
 * To run a query within a React component, call `useInvestorsByRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorsByRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorsByRoundQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useInvestorsByRoundQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorsByRoundQuery, InvestorsByRoundQueryVariables> &
    ({ variables: InvestorsByRoundQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorsByRoundQuery, InvestorsByRoundQueryVariables>(InvestorsByRoundDocument, options);
}
export function useInvestorsByRoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorsByRoundQuery, InvestorsByRoundQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorsByRoundQuery, InvestorsByRoundQueryVariables>(InvestorsByRoundDocument, options);
}
export function useInvestorsByRoundSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvestorsByRoundQuery, InvestorsByRoundQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvestorsByRoundQuery, InvestorsByRoundQueryVariables>(
    InvestorsByRoundDocument,
    options,
  );
}
export type InvestorsByRoundQueryHookResult = ReturnType<typeof useInvestorsByRoundQuery>;
export type InvestorsByRoundLazyQueryHookResult = ReturnType<typeof useInvestorsByRoundLazyQuery>;
export type InvestorsByRoundSuspenseQueryHookResult = ReturnType<typeof useInvestorsByRoundSuspenseQuery>;
export type InvestorsByRoundQueryResult = Apollo.QueryResult<InvestorsByRoundQuery, InvestorsByRoundQueryVariables>;
export const AddInvestorToRoundDocument = gql`
  mutation AddInvestorToRound($input: AddInvestorToRoundInput!) {
    addInvestorToRound(input: $input)
  }
`;
export type AddInvestorToRoundMutationFn = Apollo.MutationFunction<
  AddInvestorToRoundMutation,
  AddInvestorToRoundMutationVariables
>;

/**
 * __useAddInvestorToRoundMutation__
 *
 * To run a mutation, you first call `useAddInvestorToRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvestorToRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvestorToRoundMutation, { data, loading, error }] = useAddInvestorToRoundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInvestorToRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<AddInvestorToRoundMutation, AddInvestorToRoundMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddInvestorToRoundMutation, AddInvestorToRoundMutationVariables>(
    AddInvestorToRoundDocument,
    options,
  );
}
export type AddInvestorToRoundMutationHookResult = ReturnType<typeof useAddInvestorToRoundMutation>;
export type AddInvestorToRoundMutationResult = Apollo.MutationResult<AddInvestorToRoundMutation>;
export type AddInvestorToRoundMutationOptions = Apollo.BaseMutationOptions<
  AddInvestorToRoundMutation,
  AddInvestorToRoundMutationVariables
>;
export const SuggestInvestorsDocument = gql`
  mutation SuggestInvestors($feedback: String!) {
    suggestInvestors(feedback: $feedback)
  }
`;
export type SuggestInvestorsMutationFn = Apollo.MutationFunction<
  SuggestInvestorsMutation,
  SuggestInvestorsMutationVariables
>;

/**
 * __useSuggestInvestorsMutation__
 *
 * To run a mutation, you first call `useSuggestInvestorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestInvestorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestInvestorsMutation, { data, loading, error }] = useSuggestInvestorsMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSuggestInvestorsMutation(
  baseOptions?: Apollo.MutationHookOptions<SuggestInvestorsMutation, SuggestInvestorsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SuggestInvestorsMutation, SuggestInvestorsMutationVariables>(
    SuggestInvestorsDocument,
    options,
  );
}
export type SuggestInvestorsMutationHookResult = ReturnType<typeof useSuggestInvestorsMutation>;
export type SuggestInvestorsMutationResult = Apollo.MutationResult<SuggestInvestorsMutation>;
export type SuggestInvestorsMutationOptions = Apollo.BaseMutationOptions<
  SuggestInvestorsMutation,
  SuggestInvestorsMutationVariables
>;
export const CreatePublicInvestmentsDocument = gql`
  mutation CreatePublicInvestments($input: GraphCreatePublicInvestmentsInput!) {
    graphCreatePublicInvestments(input: $input)
  }
`;
export type CreatePublicInvestmentsMutationFn = Apollo.MutationFunction<
  CreatePublicInvestmentsMutation,
  CreatePublicInvestmentsMutationVariables
>;

/**
 * __useCreatePublicInvestmentsMutation__
 *
 * To run a mutation, you first call `useCreatePublicInvestmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicInvestmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicInvestmentsMutation, { data, loading, error }] = useCreatePublicInvestmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublicInvestmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePublicInvestmentsMutation, CreatePublicInvestmentsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePublicInvestmentsMutation, CreatePublicInvestmentsMutationVariables>(
    CreatePublicInvestmentsDocument,
    options,
  );
}
export type CreatePublicInvestmentsMutationHookResult = ReturnType<typeof useCreatePublicInvestmentsMutation>;
export type CreatePublicInvestmentsMutationResult = Apollo.MutationResult<CreatePublicInvestmentsMutation>;
export type CreatePublicInvestmentsMutationOptions = Apollo.BaseMutationOptions<
  CreatePublicInvestmentsMutation,
  CreatePublicInvestmentsMutationVariables
>;
export const CreateInviteProposalDocument = gql`
  mutation CreateInviteProposal($input: CreateInviteProposalInput!) {
    inviteProposal {
      create(input: $input) {
        id
        name
        fund
      }
    }
  }
`;
export type CreateInviteProposalMutationFn = Apollo.MutationFunction<
  CreateInviteProposalMutation,
  CreateInviteProposalMutationVariables
>;

/**
 * __useCreateInviteProposalMutation__
 *
 * To run a mutation, you first call `useCreateInviteProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteProposalMutation, { data, loading, error }] = useCreateInviteProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInviteProposalMutation, CreateInviteProposalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInviteProposalMutation, CreateInviteProposalMutationVariables>(
    CreateInviteProposalDocument,
    options,
  );
}
export type CreateInviteProposalMutationHookResult = ReturnType<typeof useCreateInviteProposalMutation>;
export type CreateInviteProposalMutationResult = Apollo.MutationResult<CreateInviteProposalMutation>;
export type CreateInviteProposalMutationOptions = Apollo.BaseMutationOptions<
  CreateInviteProposalMutation,
  CreateInviteProposalMutationVariables
>;
export const CoinvestorsNotOnAzavaDocument = gql`
  query CoinvestorsNotOnAzava($input: String!) {
    coInvestorsNotOnAzava(fullname: $input) {
      id
      fullname
      emails {
        id
        email
      }
    }
  }
`;

/**
 * __useCoinvestorsNotOnAzavaQuery__
 *
 * To run a query within a React component, call `useCoinvestorsNotOnAzavaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoinvestorsNotOnAzavaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoinvestorsNotOnAzavaQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoinvestorsNotOnAzavaQuery(
  baseOptions: Apollo.QueryHookOptions<CoinvestorsNotOnAzavaQuery, CoinvestorsNotOnAzavaQueryVariables> &
    ({ variables: CoinvestorsNotOnAzavaQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoinvestorsNotOnAzavaQuery, CoinvestorsNotOnAzavaQueryVariables>(
    CoinvestorsNotOnAzavaDocument,
    options,
  );
}
export function useCoinvestorsNotOnAzavaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoinvestorsNotOnAzavaQuery, CoinvestorsNotOnAzavaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoinvestorsNotOnAzavaQuery, CoinvestorsNotOnAzavaQueryVariables>(
    CoinvestorsNotOnAzavaDocument,
    options,
  );
}
export function useCoinvestorsNotOnAzavaSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CoinvestorsNotOnAzavaQuery, CoinvestorsNotOnAzavaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CoinvestorsNotOnAzavaQuery, CoinvestorsNotOnAzavaQueryVariables>(
    CoinvestorsNotOnAzavaDocument,
    options,
  );
}
export type CoinvestorsNotOnAzavaQueryHookResult = ReturnType<typeof useCoinvestorsNotOnAzavaQuery>;
export type CoinvestorsNotOnAzavaLazyQueryHookResult = ReturnType<typeof useCoinvestorsNotOnAzavaLazyQuery>;
export type CoinvestorsNotOnAzavaSuspenseQueryHookResult = ReturnType<typeof useCoinvestorsNotOnAzavaSuspenseQuery>;
export type CoinvestorsNotOnAzavaQueryResult = Apollo.QueryResult<
  CoinvestorsNotOnAzavaQuery,
  CoinvestorsNotOnAzavaQueryVariables
>;
export const InviteProposalForUserDocument = gql`
  query InviteProposalForUser {
    inviteProposalForUser {
      id
      linkedin
      fund
    }
  }
`;

/**
 * __useInviteProposalForUserQuery__
 *
 * To run a query within a React component, call `useInviteProposalForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteProposalForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteProposalForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteProposalForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<InviteProposalForUserQuery, InviteProposalForUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InviteProposalForUserQuery, InviteProposalForUserQueryVariables>(
    InviteProposalForUserDocument,
    options,
  );
}
export function useInviteProposalForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InviteProposalForUserQuery, InviteProposalForUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InviteProposalForUserQuery, InviteProposalForUserQueryVariables>(
    InviteProposalForUserDocument,
    options,
  );
}
export function useInviteProposalForUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InviteProposalForUserQuery, InviteProposalForUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InviteProposalForUserQuery, InviteProposalForUserQueryVariables>(
    InviteProposalForUserDocument,
    options,
  );
}
export type InviteProposalForUserQueryHookResult = ReturnType<typeof useInviteProposalForUserQuery>;
export type InviteProposalForUserLazyQueryHookResult = ReturnType<typeof useInviteProposalForUserLazyQuery>;
export type InviteProposalForUserSuspenseQueryHookResult = ReturnType<typeof useInviteProposalForUserSuspenseQuery>;
export type InviteProposalForUserQueryResult = Apollo.QueryResult<
  InviteProposalForUserQuery,
  InviteProposalForUserQueryVariables
>;
export const LegalEntityDocument = gql`
  query LegalEntity($legalEntityId: ID!) {
    legalEntity(id: $legalEntityId) {
      ...LegalEntityFields
      publicProfile {
        ... on CompanyProfile {
          id
          slug
          website
        }
        ... on Profile {
          id
          slug
          personalWebsite
          linkedin
          imageUrl
        }
      }
    }
  }
  ${LegalEntityFieldsFragmentDoc}
`;

/**
 * __useLegalEntityQuery__
 *
 * To run a query within a React component, call `useLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalEntityQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useLegalEntityQuery(
  baseOptions: Apollo.QueryHookOptions<LegalEntityQuery, LegalEntityQueryVariables> &
    ({ variables: LegalEntityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegalEntityQuery, LegalEntityQueryVariables>(LegalEntityDocument, options);
}
export function useLegalEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegalEntityQuery, LegalEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegalEntityQuery, LegalEntityQueryVariables>(LegalEntityDocument, options);
}
export function useLegalEntitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LegalEntityQuery, LegalEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegalEntityQuery, LegalEntityQueryVariables>(LegalEntityDocument, options);
}
export type LegalEntityQueryHookResult = ReturnType<typeof useLegalEntityQuery>;
export type LegalEntityLazyQueryHookResult = ReturnType<typeof useLegalEntityLazyQuery>;
export type LegalEntitySuspenseQueryHookResult = ReturnType<typeof useLegalEntitySuspenseQuery>;
export type LegalEntityQueryResult = Apollo.QueryResult<LegalEntityQuery, LegalEntityQueryVariables>;
export const CreateLegalEntityDocument = gql`
  mutation CreateLegalEntity($input: CreateLegalEntityInput!) {
    legalEntity {
      create(input: $input) {
        ...LegalEntityDisplayFields
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;
export type CreateLegalEntityMutationFn = Apollo.MutationFunction<
  CreateLegalEntityMutation,
  CreateLegalEntityMutationVariables
>;

/**
 * __useCreateLegalEntityMutation__
 *
 * To run a mutation, you first call `useCreateLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLegalEntityMutation, { data, loading, error }] = useCreateLegalEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLegalEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLegalEntityMutation, CreateLegalEntityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLegalEntityMutation, CreateLegalEntityMutationVariables>(
    CreateLegalEntityDocument,
    options,
  );
}
export type CreateLegalEntityMutationHookResult = ReturnType<typeof useCreateLegalEntityMutation>;
export type CreateLegalEntityMutationResult = Apollo.MutationResult<CreateLegalEntityMutation>;
export type CreateLegalEntityMutationOptions = Apollo.BaseMutationOptions<
  CreateLegalEntityMutation,
  CreateLegalEntityMutationVariables
>;
export const UpdateDisplayNameDocument = gql`
  mutation UpdateDisplayName($input: UpdateLegalEntityDisplayNameInput!) {
    legalEntity {
      updateDisplayName(input: $input) {
        ...LegalEntityDisplayFields
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;
export type UpdateDisplayNameMutationFn = Apollo.MutationFunction<
  UpdateDisplayNameMutation,
  UpdateDisplayNameMutationVariables
>;

/**
 * __useUpdateDisplayNameMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayNameMutation, { data, loading, error }] = useUpdateDisplayNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDisplayNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDisplayNameMutation, UpdateDisplayNameMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDisplayNameMutation, UpdateDisplayNameMutationVariables>(
    UpdateDisplayNameDocument,
    options,
  );
}
export type UpdateDisplayNameMutationHookResult = ReturnType<typeof useUpdateDisplayNameMutation>;
export type UpdateDisplayNameMutationResult = Apollo.MutationResult<UpdateDisplayNameMutation>;
export type UpdateDisplayNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateDisplayNameMutation,
  UpdateDisplayNameMutationVariables
>;
export const UpdateInvestingEntityDocument = gql`
  mutation UpdateInvestingEntity($input: UpdateLegalEntityInvestingEntityInput!) {
    legalEntity {
      updateInvestingEntity(input: $input) {
        ...LegalEntityDisplayFields
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;
export type UpdateInvestingEntityMutationFn = Apollo.MutationFunction<
  UpdateInvestingEntityMutation,
  UpdateInvestingEntityMutationVariables
>;

/**
 * __useUpdateInvestingEntityMutation__
 *
 * To run a mutation, you first call `useUpdateInvestingEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestingEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestingEntityMutation, { data, loading, error }] = useUpdateInvestingEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvestingEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvestingEntityMutation, UpdateInvestingEntityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvestingEntityMutation, UpdateInvestingEntityMutationVariables>(
    UpdateInvestingEntityDocument,
    options,
  );
}
export type UpdateInvestingEntityMutationHookResult = ReturnType<typeof useUpdateInvestingEntityMutation>;
export type UpdateInvestingEntityMutationResult = Apollo.MutationResult<UpdateInvestingEntityMutation>;
export type UpdateInvestingEntityMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvestingEntityMutation,
  UpdateInvestingEntityMutationVariables
>;
export const ListByIdDocument = gql`
  query ListById($id: String!) {
    list(id: $id) {
      ...ListDisplayFields
    }
  }
  ${ListDisplayFieldsFragmentDoc}
`;

/**
 * __useListByIdQuery__
 *
 * To run a query within a React component, call `useListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<ListByIdQuery, ListByIdQueryVariables> &
    ({ variables: ListByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListByIdQuery, ListByIdQueryVariables>(ListByIdDocument, options);
}
export function useListByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListByIdQuery, ListByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListByIdQuery, ListByIdQueryVariables>(ListByIdDocument, options);
}
export function useListByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListByIdQuery, ListByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListByIdQuery, ListByIdQueryVariables>(ListByIdDocument, options);
}
export type ListByIdQueryHookResult = ReturnType<typeof useListByIdQuery>;
export type ListByIdLazyQueryHookResult = ReturnType<typeof useListByIdLazyQuery>;
export type ListByIdSuspenseQueryHookResult = ReturnType<typeof useListByIdSuspenseQuery>;
export type ListByIdQueryResult = Apollo.QueryResult<ListByIdQuery, ListByIdQueryVariables>;
export const ListBySlugDocument = gql`
  query ListBySlug($slug: String!) {
    listBySlug(slug: $slug) {
      ...ListDisplayFields
    }
  }
  ${ListDisplayFieldsFragmentDoc}
`;

/**
 * __useListBySlugQuery__
 *
 * To run a query within a React component, call `useListBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useListBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<ListBySlugQuery, ListBySlugQueryVariables> &
    ({ variables: ListBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListBySlugQuery, ListBySlugQueryVariables>(ListBySlugDocument, options);
}
export function useListBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListBySlugQuery, ListBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListBySlugQuery, ListBySlugQueryVariables>(ListBySlugDocument, options);
}
export function useListBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListBySlugQuery, ListBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListBySlugQuery, ListBySlugQueryVariables>(ListBySlugDocument, options);
}
export type ListBySlugQueryHookResult = ReturnType<typeof useListBySlugQuery>;
export type ListBySlugLazyQueryHookResult = ReturnType<typeof useListBySlugLazyQuery>;
export type ListBySlugSuspenseQueryHookResult = ReturnType<typeof useListBySlugSuspenseQuery>;
export type ListBySlugQueryResult = Apollo.QueryResult<ListBySlugQuery, ListBySlugQueryVariables>;
export const SubmissionSubscriptionDocument = gql`
  subscription SubmissionSubscription {
    myNewSubmissions {
      id
      companyName
      companyUrl
      roundType
      raisedCurrency
      raisedAmount
      createdAt
      status
      teamId
      list {
        id
        slug
      }
      assignee {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
    }
  }
`;

/**
 * __useSubmissionSubscriptionSubscription__
 *
 * To run a query within a React component, call `useSubmissionSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubmissionSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubmissionSubscriptionSubscription,
    SubmissionSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubmissionSubscriptionSubscription, SubmissionSubscriptionSubscriptionVariables>(
    SubmissionSubscriptionDocument,
    options,
  );
}
export type SubmissionSubscriptionSubscriptionHookResult = ReturnType<typeof useSubmissionSubscriptionSubscription>;
export type SubmissionSubscriptionSubscriptionResult = Apollo.SubscriptionResult<SubmissionSubscriptionSubscription>;
export const PipelineSubscriptionDocument = gql`
  subscription PipelineSubscription {
    updatedDealflowPipeline {
      id
      status
      creator {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
      errors
    }
  }
`;

/**
 * __usePipelineSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePipelineSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePipelineSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipelineSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePipelineSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    PipelineSubscriptionSubscription,
    PipelineSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<PipelineSubscriptionSubscription, PipelineSubscriptionSubscriptionVariables>(
    PipelineSubscriptionDocument,
    options,
  );
}
export type PipelineSubscriptionSubscriptionHookResult = ReturnType<typeof usePipelineSubscriptionSubscription>;
export type PipelineSubscriptionSubscriptionResult = Apollo.SubscriptionResult<PipelineSubscriptionSubscription>;
export const ProcessingPipelinesDocument = gql`
  query ProcessingPipelines {
    processingDealflowPipelines {
      id
      status
      creator {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
      communication {
        outputChannel
      }
    }
  }
`;

/**
 * __useProcessingPipelinesQuery__
 *
 * To run a query within a React component, call `useProcessingPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingPipelinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessingPipelinesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProcessingPipelinesQuery, ProcessingPipelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProcessingPipelinesQuery, ProcessingPipelinesQueryVariables>(
    ProcessingPipelinesDocument,
    options,
  );
}
export function useProcessingPipelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProcessingPipelinesQuery, ProcessingPipelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProcessingPipelinesQuery, ProcessingPipelinesQueryVariables>(
    ProcessingPipelinesDocument,
    options,
  );
}
export function useProcessingPipelinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProcessingPipelinesQuery, ProcessingPipelinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProcessingPipelinesQuery, ProcessingPipelinesQueryVariables>(
    ProcessingPipelinesDocument,
    options,
  );
}
export type ProcessingPipelinesQueryHookResult = ReturnType<typeof useProcessingPipelinesQuery>;
export type ProcessingPipelinesLazyQueryHookResult = ReturnType<typeof useProcessingPipelinesLazyQuery>;
export type ProcessingPipelinesSuspenseQueryHookResult = ReturnType<typeof useProcessingPipelinesSuspenseQuery>;
export type ProcessingPipelinesQueryResult = Apollo.QueryResult<
  ProcessingPipelinesQuery,
  ProcessingPipelinesQueryVariables
>;
export const CreateBlankSubmissionDocument = gql`
  mutation CreateBlankSubmission {
    nemoSubmission {
      createBlank {
        id
        status
        list {
          id
          slug
        }
      }
    }
  }
`;
export type CreateBlankSubmissionMutationFn = Apollo.MutationFunction<
  CreateBlankSubmissionMutation,
  CreateBlankSubmissionMutationVariables
>;

/**
 * __useCreateBlankSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateBlankSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlankSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlankSubmissionMutation, { data, loading, error }] = useCreateBlankSubmissionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBlankSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBlankSubmissionMutation, CreateBlankSubmissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBlankSubmissionMutation, CreateBlankSubmissionMutationVariables>(
    CreateBlankSubmissionDocument,
    options,
  );
}
export type CreateBlankSubmissionMutationHookResult = ReturnType<typeof useCreateBlankSubmissionMutation>;
export type CreateBlankSubmissionMutationResult = Apollo.MutationResult<CreateBlankSubmissionMutation>;
export type CreateBlankSubmissionMutationOptions = Apollo.BaseMutationOptions<
  CreateBlankSubmissionMutation,
  CreateBlankSubmissionMutationVariables
>;
export const DealWithAugmentedMessageDocument = gql`
  query DealWithAugmentedMessage($id: ID!) {
    submission(id: $id) {
      id
      companyName
      teaserSummary
      shortSummary
      augmentedMessage
      companyUrl
      companyCity
      comment
      companyCountry
      raisedAmount
      raisedCurrency
      founders {
        id
        name
        linkedin
        role
      }
      roundType
      list {
        id
        slug
      }
    }
  }
`;

/**
 * __useDealWithAugmentedMessageQuery__
 *
 * To run a query within a React component, call `useDealWithAugmentedMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealWithAugmentedMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealWithAugmentedMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealWithAugmentedMessageQuery(
  baseOptions: Apollo.QueryHookOptions<DealWithAugmentedMessageQuery, DealWithAugmentedMessageQueryVariables> &
    ({ variables: DealWithAugmentedMessageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealWithAugmentedMessageQuery, DealWithAugmentedMessageQueryVariables>(
    DealWithAugmentedMessageDocument,
    options,
  );
}
export function useDealWithAugmentedMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealWithAugmentedMessageQuery, DealWithAugmentedMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealWithAugmentedMessageQuery, DealWithAugmentedMessageQueryVariables>(
    DealWithAugmentedMessageDocument,
    options,
  );
}
export function useDealWithAugmentedMessageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealWithAugmentedMessageQuery, DealWithAugmentedMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealWithAugmentedMessageQuery, DealWithAugmentedMessageQueryVariables>(
    DealWithAugmentedMessageDocument,
    options,
  );
}
export type DealWithAugmentedMessageQueryHookResult = ReturnType<typeof useDealWithAugmentedMessageQuery>;
export type DealWithAugmentedMessageLazyQueryHookResult = ReturnType<typeof useDealWithAugmentedMessageLazyQuery>;
export type DealWithAugmentedMessageSuspenseQueryHookResult = ReturnType<
  typeof useDealWithAugmentedMessageSuspenseQuery
>;
export type DealWithAugmentedMessageQueryResult = Apollo.QueryResult<
  DealWithAugmentedMessageQuery,
  DealWithAugmentedMessageQueryVariables
>;
export const ForkDealDocument = gql`
  mutation ForkDeal($id: ID!) {
    nemoSubmission {
      fork(id: $id) {
        id
        list {
          id
          slug
        }
      }
    }
  }
`;
export type ForkDealMutationFn = Apollo.MutationFunction<ForkDealMutation, ForkDealMutationVariables>;

/**
 * __useForkDealMutation__
 *
 * To run a mutation, you first call `useForkDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForkDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forkDealMutation, { data, loading, error }] = useForkDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForkDealMutation(
  baseOptions?: Apollo.MutationHookOptions<ForkDealMutation, ForkDealMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ForkDealMutation, ForkDealMutationVariables>(ForkDealDocument, options);
}
export type ForkDealMutationHookResult = ReturnType<typeof useForkDealMutation>;
export type ForkDealMutationResult = Apollo.MutationResult<ForkDealMutation>;
export type ForkDealMutationOptions = Apollo.BaseMutationOptions<ForkDealMutation, ForkDealMutationVariables>;
export const SubmittedDealsDocument = gql`
  query SubmittedDeals($filter: SubmittedDealsFilter!, $meta: SubmittedDealsMeta!, $orderBy: SubmittedDealsOrderBy) {
    submittedDeals(filter: $filter, meta: $meta, orderBy: $orderBy) {
      count
      submissions {
        id
        companyName
        companyUrl
        companyCity
        companyCountry
        teaserSummary
        roundType
        raisedCurrency
        raisedAmount
        createdAt
        status
        teamId
        sharedToDealFeedAt
        markets
        userTags
        tags {
          tag
        }
        seenBy {
          id
          publicProfile {
            id
            fullname
            imageUrl
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
        }
        list {
          id
          slug
        }
        assignee {
          id
          email
          username
          publicProfile {
            id
            fullname
            imageUrl
            slug
          }
        }
      }
    }
  }
`;

/**
 * __useSubmittedDealsQuery__
 *
 * To run a query within a React component, call `useSubmittedDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedDealsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      meta: // value for 'meta'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSubmittedDealsQuery(
  baseOptions: Apollo.QueryHookOptions<SubmittedDealsQuery, SubmittedDealsQueryVariables> &
    ({ variables: SubmittedDealsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmittedDealsQuery, SubmittedDealsQueryVariables>(SubmittedDealsDocument, options);
}
export function useSubmittedDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubmittedDealsQuery, SubmittedDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmittedDealsQuery, SubmittedDealsQueryVariables>(SubmittedDealsDocument, options);
}
export function useSubmittedDealsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SubmittedDealsQuery, SubmittedDealsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubmittedDealsQuery, SubmittedDealsQueryVariables>(SubmittedDealsDocument, options);
}
export type SubmittedDealsQueryHookResult = ReturnType<typeof useSubmittedDealsQuery>;
export type SubmittedDealsLazyQueryHookResult = ReturnType<typeof useSubmittedDealsLazyQuery>;
export type SubmittedDealsSuspenseQueryHookResult = ReturnType<typeof useSubmittedDealsSuspenseQuery>;
export type SubmittedDealsQueryResult = Apollo.QueryResult<SubmittedDealsQuery, SubmittedDealsQueryVariables>;
export const OrganisationsDocument = gql`
  query Organisations($filter: SubmittedDealsFilter!, $meta: SubmittedDealsMeta!, $orderBy: SubmittedDealsOrderBy) {
    submittedDeals(filter: $filter, meta: $meta, orderBy: $orderBy) {
      count
      submissions {
        id
        companyName
        companyUrl
        companyCity
        companyCountry
        teaserSummary
        roundType
        raisedCurrency
        raisedAmount
        createdAt
        status
        teamId
        sharedToDealFeedAt
        tags {
          tag
        }
        seenBy {
          id
          publicProfile {
            id
            fullname
            imageUrl
            roles {
              id
              description
              entityProfile {
                id
                fullname
              }
            }
          }
        }
        list {
          id
          slug
        }
        assignee {
          id
          email
          username
          publicProfile {
            id
            fullname
            imageUrl
            slug
          }
        }
        founders {
          id
          profile {
            id
            emails {
              id
              email
            }
          }
          isSource
        }
      }
    }
  }
`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      meta: // value for 'meta'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOrganisationsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables> &
    ({ variables: OrganisationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
}
export function useOrganisationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
}
export function useOrganisationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
}
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsSuspenseQueryHookResult = ReturnType<typeof useOrganisationsSuspenseQuery>;
export type OrganisationsQueryResult = Apollo.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const UpdateSubmissionRoundDetailsDocument = gql`
  mutation updateSubmissionRoundDetails(
    $id: ID!
    $raisedAmount: Float
    $raisedCurrency: CurrencyIsoCode
    $roundType: EquityRoundType
    $leadBy: String
    $hasLead: Boolean
  ) {
    nemoSubmission {
      update(
        id: $id
        args: {
          section: "Overview"
          raisedAmount: $raisedAmount
          raisedCurrency: $raisedCurrency
          roundType: $roundType
          leadBy: $leadBy
          hasLead: $hasLead
        }
      ) {
        id
        raisedCurrency
        raisedAmount
        hasLead
        leadBy
        roundType
      }
    }
  }
`;
export type UpdateSubmissionRoundDetailsMutationFn = Apollo.MutationFunction<
  UpdateSubmissionRoundDetailsMutation,
  UpdateSubmissionRoundDetailsMutationVariables
>;

/**
 * __useUpdateSubmissionRoundDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionRoundDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionRoundDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionRoundDetailsMutation, { data, loading, error }] = useUpdateSubmissionRoundDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      raisedAmount: // value for 'raisedAmount'
 *      raisedCurrency: // value for 'raisedCurrency'
 *      roundType: // value for 'roundType'
 *      leadBy: // value for 'leadBy'
 *      hasLead: // value for 'hasLead'
 *   },
 * });
 */
export function useUpdateSubmissionRoundDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubmissionRoundDetailsMutation,
    UpdateSubmissionRoundDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionRoundDetailsMutation, UpdateSubmissionRoundDetailsMutationVariables>(
    UpdateSubmissionRoundDetailsDocument,
    options,
  );
}
export type UpdateSubmissionRoundDetailsMutationHookResult = ReturnType<typeof useUpdateSubmissionRoundDetailsMutation>;
export type UpdateSubmissionRoundDetailsMutationResult = Apollo.MutationResult<UpdateSubmissionRoundDetailsMutation>;
export type UpdateSubmissionRoundDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionRoundDetailsMutation,
  UpdateSubmissionRoundDetailsMutationVariables
>;
export const UpdateSubmissionCompanyDetailsDocument = gql`
  mutation updateSubmissionCompanyDetails(
    $id: ID!
    $companyName: String
    $companyUrl: String
    $companyCity: String
    $companyCountry: String
    $companyDescription: String
    $teaserSummary: String
  ) {
    nemoSubmission {
      update(
        id: $id
        args: {
          section: "Company Details"
          companyName: $companyName
          companyUrl: $companyUrl
          companyCity: $companyCity
          companyCountry: $companyCountry
          companyDescription: $companyDescription
          teaserSummary: $teaserSummary
        }
      ) {
        id
        companyName
        companyUrl
        companyCity
        companyCountry
        companyDescription
        teaserSummary
      }
    }
  }
`;
export type UpdateSubmissionCompanyDetailsMutationFn = Apollo.MutationFunction<
  UpdateSubmissionCompanyDetailsMutation,
  UpdateSubmissionCompanyDetailsMutationVariables
>;

/**
 * __useUpdateSubmissionCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionCompanyDetailsMutation, { data, loading, error }] = useUpdateSubmissionCompanyDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyName: // value for 'companyName'
 *      companyUrl: // value for 'companyUrl'
 *      companyCity: // value for 'companyCity'
 *      companyCountry: // value for 'companyCountry'
 *      companyDescription: // value for 'companyDescription'
 *      teaserSummary: // value for 'teaserSummary'
 *   },
 * });
 */
export function useUpdateSubmissionCompanyDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubmissionCompanyDetailsMutation,
    UpdateSubmissionCompanyDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionCompanyDetailsMutation, UpdateSubmissionCompanyDetailsMutationVariables>(
    UpdateSubmissionCompanyDetailsDocument,
    options,
  );
}
export type UpdateSubmissionCompanyDetailsMutationHookResult = ReturnType<
  typeof useUpdateSubmissionCompanyDetailsMutation
>;
export type UpdateSubmissionCompanyDetailsMutationResult =
  Apollo.MutationResult<UpdateSubmissionCompanyDetailsMutation>;
export type UpdateSubmissionCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionCompanyDetailsMutation,
  UpdateSubmissionCompanyDetailsMutationVariables
>;
export const UpdateSubmissionAssigneeDocument = gql`
  mutation updateSubmissionAssignee($id: ID!, $assignedTo: String) {
    nemoSubmission {
      update(id: $id, args: { section: "Assignee", assignedTo: $assignedTo }) {
        id
        sharedToDealFeedAt
        assignee {
          id
          email
          username
        }
      }
    }
  }
`;
export type UpdateSubmissionAssigneeMutationFn = Apollo.MutationFunction<
  UpdateSubmissionAssigneeMutation,
  UpdateSubmissionAssigneeMutationVariables
>;

/**
 * __useUpdateSubmissionAssigneeMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionAssigneeMutation, { data, loading, error }] = useUpdateSubmissionAssigneeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assignedTo: // value for 'assignedTo'
 *   },
 * });
 */
export function useUpdateSubmissionAssigneeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionAssigneeMutation, UpdateSubmissionAssigneeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionAssigneeMutation, UpdateSubmissionAssigneeMutationVariables>(
    UpdateSubmissionAssigneeDocument,
    options,
  );
}
export type UpdateSubmissionAssigneeMutationHookResult = ReturnType<typeof useUpdateSubmissionAssigneeMutation>;
export type UpdateSubmissionAssigneeMutationResult = Apollo.MutationResult<UpdateSubmissionAssigneeMutation>;
export type UpdateSubmissionAssigneeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionAssigneeMutation,
  UpdateSubmissionAssigneeMutationVariables
>;
export const AddDeckUrlToSubmissionDocument = gql`
  mutation addDeckUrlToSubmission($id: ID!, $deckUrl: URL!) {
    nemoSubmission {
      update(id: $id, args: { section: "Pitch Deck", deckUrl: $deckUrl }) {
        id
        deckUrl
      }
    }
  }
`;
export type AddDeckUrlToSubmissionMutationFn = Apollo.MutationFunction<
  AddDeckUrlToSubmissionMutation,
  AddDeckUrlToSubmissionMutationVariables
>;

/**
 * __useAddDeckUrlToSubmissionMutation__
 *
 * To run a mutation, you first call `useAddDeckUrlToSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeckUrlToSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeckUrlToSubmissionMutation, { data, loading, error }] = useAddDeckUrlToSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deckUrl: // value for 'deckUrl'
 *   },
 * });
 */
export function useAddDeckUrlToSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<AddDeckUrlToSubmissionMutation, AddDeckUrlToSubmissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDeckUrlToSubmissionMutation, AddDeckUrlToSubmissionMutationVariables>(
    AddDeckUrlToSubmissionDocument,
    options,
  );
}
export type AddDeckUrlToSubmissionMutationHookResult = ReturnType<typeof useAddDeckUrlToSubmissionMutation>;
export type AddDeckUrlToSubmissionMutationResult = Apollo.MutationResult<AddDeckUrlToSubmissionMutation>;
export type AddDeckUrlToSubmissionMutationOptions = Apollo.BaseMutationOptions<
  AddDeckUrlToSubmissionMutation,
  AddDeckUrlToSubmissionMutationVariables
>;
export const RemoveDeckUrlFromSubmissionDocument = gql`
  mutation removeDeckUrlFromSubmission($id: ID!) {
    nemoSubmission {
      update(id: $id, args: { section: "Pitch Deck", deckUrl: null }) {
        id
        deckUrl
      }
    }
  }
`;
export type RemoveDeckUrlFromSubmissionMutationFn = Apollo.MutationFunction<
  RemoveDeckUrlFromSubmissionMutation,
  RemoveDeckUrlFromSubmissionMutationVariables
>;

/**
 * __useRemoveDeckUrlFromSubmissionMutation__
 *
 * To run a mutation, you first call `useRemoveDeckUrlFromSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeckUrlFromSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeckUrlFromSubmissionMutation, { data, loading, error }] = useRemoveDeckUrlFromSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDeckUrlFromSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDeckUrlFromSubmissionMutation,
    RemoveDeckUrlFromSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDeckUrlFromSubmissionMutation, RemoveDeckUrlFromSubmissionMutationVariables>(
    RemoveDeckUrlFromSubmissionDocument,
    options,
  );
}
export type RemoveDeckUrlFromSubmissionMutationHookResult = ReturnType<typeof useRemoveDeckUrlFromSubmissionMutation>;
export type RemoveDeckUrlFromSubmissionMutationResult = Apollo.MutationResult<RemoveDeckUrlFromSubmissionMutation>;
export type RemoveDeckUrlFromSubmissionMutationOptions = Apollo.BaseMutationOptions<
  RemoveDeckUrlFromSubmissionMutation,
  RemoveDeckUrlFromSubmissionMutationVariables
>;
export const UpdateSubmissionCommentDocument = gql`
  mutation updateSubmissionComment($id: ID!, $comment: String!) {
    nemoSubmission {
      update(id: $id, args: { section: "Overview", comment: $comment }) {
        id
        comment
      }
    }
  }
`;
export type UpdateSubmissionCommentMutationFn = Apollo.MutationFunction<
  UpdateSubmissionCommentMutation,
  UpdateSubmissionCommentMutationVariables
>;

/**
 * __useUpdateSubmissionCommentMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionCommentMutation, { data, loading, error }] = useUpdateSubmissionCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateSubmissionCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionCommentMutation, UpdateSubmissionCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionCommentMutation, UpdateSubmissionCommentMutationVariables>(
    UpdateSubmissionCommentDocument,
    options,
  );
}
export type UpdateSubmissionCommentMutationHookResult = ReturnType<typeof useUpdateSubmissionCommentMutation>;
export type UpdateSubmissionCommentMutationResult = Apollo.MutationResult<UpdateSubmissionCommentMutation>;
export type UpdateSubmissionCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionCommentMutation,
  UpdateSubmissionCommentMutationVariables
>;
export const RemoveSubmissionCommentDocument = gql`
  mutation removeSubmissionComment($id: ID!) {
    nemoSubmission {
      update(id: $id, args: { section: "Overview", comment: null }) {
        id
        comment
      }
    }
  }
`;
export type RemoveSubmissionCommentMutationFn = Apollo.MutationFunction<
  RemoveSubmissionCommentMutation,
  RemoveSubmissionCommentMutationVariables
>;

/**
 * __useRemoveSubmissionCommentMutation__
 *
 * To run a mutation, you first call `useRemoveSubmissionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubmissionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubmissionCommentMutation, { data, loading, error }] = useRemoveSubmissionCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSubmissionCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveSubmissionCommentMutation, RemoveSubmissionCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSubmissionCommentMutation, RemoveSubmissionCommentMutationVariables>(
    RemoveSubmissionCommentDocument,
    options,
  );
}
export type RemoveSubmissionCommentMutationHookResult = ReturnType<typeof useRemoveSubmissionCommentMutation>;
export type RemoveSubmissionCommentMutationResult = Apollo.MutationResult<RemoveSubmissionCommentMutation>;
export type RemoveSubmissionCommentMutationOptions = Apollo.BaseMutationOptions<
  RemoveSubmissionCommentMutation,
  RemoveSubmissionCommentMutationVariables
>;
export const UpdateSubmissionStatusDocument = gql`
  mutation updateSubmissionStatus($id: ID!, $status: DealStatus!) {
    nemoSubmission {
      update(id: $id, args: { section: "Status", status: $status }) {
        id
        status
      }
    }
  }
`;
export type UpdateSubmissionStatusMutationFn = Apollo.MutationFunction<
  UpdateSubmissionStatusMutation,
  UpdateSubmissionStatusMutationVariables
>;

/**
 * __useUpdateSubmissionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionStatusMutation, { data, loading, error }] = useUpdateSubmissionStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateSubmissionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionStatusMutation, UpdateSubmissionStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionStatusMutation, UpdateSubmissionStatusMutationVariables>(
    UpdateSubmissionStatusDocument,
    options,
  );
}
export type UpdateSubmissionStatusMutationHookResult = ReturnType<typeof useUpdateSubmissionStatusMutation>;
export type UpdateSubmissionStatusMutationResult = Apollo.MutationResult<UpdateSubmissionStatusMutation>;
export type UpdateSubmissionStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionStatusMutation,
  UpdateSubmissionStatusMutationVariables
>;
export const DeleteSubmissionDocument = gql`
  mutation deleteSubmission($id: ID!) {
    nemoSubmission {
      delete(id: $id) {
        id
      }
    }
  }
`;
export type DeleteSubmissionMutationFn = Apollo.MutationFunction<
  DeleteSubmissionMutation,
  DeleteSubmissionMutationVariables
>;

/**
 * __useDeleteSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionMutation, { data, loading, error }] = useDeleteSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubmissionMutation, DeleteSubmissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubmissionMutation, DeleteSubmissionMutationVariables>(
    DeleteSubmissionDocument,
    options,
  );
}
export type DeleteSubmissionMutationHookResult = ReturnType<typeof useDeleteSubmissionMutation>;
export type DeleteSubmissionMutationResult = Apollo.MutationResult<DeleteSubmissionMutation>;
export type DeleteSubmissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubmissionMutation,
  DeleteSubmissionMutationVariables
>;
export const GiveFeedbackOnSubmissionDocument = gql`
  mutation giveFeedbackOnSubmission($id: ID!, $args: FeedBackNemoSubmissionInput!) {
    nemoSubmission {
      feedback(id: $id, args: $args) {
        id
        feedbackGiven
      }
    }
  }
`;
export type GiveFeedbackOnSubmissionMutationFn = Apollo.MutationFunction<
  GiveFeedbackOnSubmissionMutation,
  GiveFeedbackOnSubmissionMutationVariables
>;

/**
 * __useGiveFeedbackOnSubmissionMutation__
 *
 * To run a mutation, you first call `useGiveFeedbackOnSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveFeedbackOnSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveFeedbackOnSubmissionMutation, { data, loading, error }] = useGiveFeedbackOnSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGiveFeedbackOnSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<GiveFeedbackOnSubmissionMutation, GiveFeedbackOnSubmissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GiveFeedbackOnSubmissionMutation, GiveFeedbackOnSubmissionMutationVariables>(
    GiveFeedbackOnSubmissionDocument,
    options,
  );
}
export type GiveFeedbackOnSubmissionMutationHookResult = ReturnType<typeof useGiveFeedbackOnSubmissionMutation>;
export type GiveFeedbackOnSubmissionMutationResult = Apollo.MutationResult<GiveFeedbackOnSubmissionMutation>;
export type GiveFeedbackOnSubmissionMutationOptions = Apollo.BaseMutationOptions<
  GiveFeedbackOnSubmissionMutation,
  GiveFeedbackOnSubmissionMutationVariables
>;
export const NotifyWhenOthersAddDocument = gql`
  mutation notifyWhenOthersAdd($id: ID!) {
    nemoSubmission {
      notifyWhenOthersAdd(id: $id) {
        id
        notificationAsked
      }
    }
  }
`;
export type NotifyWhenOthersAddMutationFn = Apollo.MutationFunction<
  NotifyWhenOthersAddMutation,
  NotifyWhenOthersAddMutationVariables
>;

/**
 * __useNotifyWhenOthersAddMutation__
 *
 * To run a mutation, you first call `useNotifyWhenOthersAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyWhenOthersAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyWhenOthersAddMutation, { data, loading, error }] = useNotifyWhenOthersAddMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotifyWhenOthersAddMutation(
  baseOptions?: Apollo.MutationHookOptions<NotifyWhenOthersAddMutation, NotifyWhenOthersAddMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotifyWhenOthersAddMutation, NotifyWhenOthersAddMutationVariables>(
    NotifyWhenOthersAddDocument,
    options,
  );
}
export type NotifyWhenOthersAddMutationHookResult = ReturnType<typeof useNotifyWhenOthersAddMutation>;
export type NotifyWhenOthersAddMutationResult = Apollo.MutationResult<NotifyWhenOthersAddMutation>;
export type NotifyWhenOthersAddMutationOptions = Apollo.BaseMutationOptions<
  NotifyWhenOthersAddMutation,
  NotifyWhenOthersAddMutationVariables
>;
export const AskSubmissionDocument = gql`
  query AskSubmission($input: AskSubmissionInput!) {
    askSubmission(input: $input)
  }
`;

/**
 * __useAskSubmissionQuery__
 *
 * To run a query within a React component, call `useAskSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAskSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskSubmissionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAskSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<AskSubmissionQuery, AskSubmissionQueryVariables> &
    ({ variables: AskSubmissionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AskSubmissionQuery, AskSubmissionQueryVariables>(AskSubmissionDocument, options);
}
export function useAskSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AskSubmissionQuery, AskSubmissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AskSubmissionQuery, AskSubmissionQueryVariables>(AskSubmissionDocument, options);
}
export function useAskSubmissionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AskSubmissionQuery, AskSubmissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AskSubmissionQuery, AskSubmissionQueryVariables>(AskSubmissionDocument, options);
}
export type AskSubmissionQueryHookResult = ReturnType<typeof useAskSubmissionQuery>;
export type AskSubmissionLazyQueryHookResult = ReturnType<typeof useAskSubmissionLazyQuery>;
export type AskSubmissionSuspenseQueryHookResult = ReturnType<typeof useAskSubmissionSuspenseQuery>;
export type AskSubmissionQueryResult = Apollo.QueryResult<AskSubmissionQuery, AskSubmissionQueryVariables>;
export const AskDocumentDocument = gql`
  mutation AskDocument($input: AskDocumentInput!) {
    document {
      ask(input: $input) {
        question
        answer
      }
    }
  }
`;
export type AskDocumentMutationFn = Apollo.MutationFunction<AskDocumentMutation, AskDocumentMutationVariables>;

/**
 * __useAskDocumentMutation__
 *
 * To run a mutation, you first call `useAskDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askDocumentMutation, { data, loading, error }] = useAskDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAskDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<AskDocumentMutation, AskDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AskDocumentMutation, AskDocumentMutationVariables>(AskDocumentDocument, options);
}
export type AskDocumentMutationHookResult = ReturnType<typeof useAskDocumentMutation>;
export type AskDocumentMutationResult = Apollo.MutationResult<AskDocumentMutation>;
export type AskDocumentMutationOptions = Apollo.BaseMutationOptions<AskDocumentMutation, AskDocumentMutationVariables>;
export const NemoParticipantsDocument = gql`
  query NemoParticipants {
    nemoProfiles {
      id
      type
      fullname
      imageUrl
      slug
      socialLinks {
        id
        url
      }
      roles {
        id
        description
        entityProfile {
          id
          fullname
          slug
        }
      }
      investments {
        id
        companyName
        roundType
        companyProfile {
          id
          themes
          country
        }
      }
      dealsSourced {
        id
      }
    }
  }
`;

/**
 * __useNemoParticipantsQuery__
 *
 * To run a query within a React component, call `useNemoParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNemoParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNemoParticipantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNemoParticipantsQuery(
  baseOptions?: Apollo.QueryHookOptions<NemoParticipantsQuery, NemoParticipantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NemoParticipantsQuery, NemoParticipantsQueryVariables>(NemoParticipantsDocument, options);
}
export function useNemoParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NemoParticipantsQuery, NemoParticipantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NemoParticipantsQuery, NemoParticipantsQueryVariables>(NemoParticipantsDocument, options);
}
export function useNemoParticipantsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NemoParticipantsQuery, NemoParticipantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NemoParticipantsQuery, NemoParticipantsQueryVariables>(
    NemoParticipantsDocument,
    options,
  );
}
export type NemoParticipantsQueryHookResult = ReturnType<typeof useNemoParticipantsQuery>;
export type NemoParticipantsLazyQueryHookResult = ReturnType<typeof useNemoParticipantsLazyQuery>;
export type NemoParticipantsSuspenseQueryHookResult = ReturnType<typeof useNemoParticipantsSuspenseQuery>;
export type NemoParticipantsQueryResult = Apollo.QueryResult<NemoParticipantsQuery, NemoParticipantsQueryVariables>;
export const CreateSubmissionFounderDocument = gql`
  mutation CreateSubmissionFounder(
    $submissionId: ID!
    $name: String!
    $description: String
    $linkedin: String
    $twitter: String
    $role: String
  ) {
    submissionFounder {
      create(
        input: {
          submissionId: $submissionId
          name: $name
          description: $description
          linkedin: $linkedin
          twitter: $twitter
          role: $role
        }
      ) {
        id
        name
        description
        linkedin
        twitter
        role
      }
    }
  }
`;
export type CreateSubmissionFounderMutationFn = Apollo.MutationFunction<
  CreateSubmissionFounderMutation,
  CreateSubmissionFounderMutationVariables
>;

/**
 * __useCreateSubmissionFounderMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionFounderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionFounderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionFounderMutation, { data, loading, error }] = useCreateSubmissionFounderMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      linkedin: // value for 'linkedin'
 *      twitter: // value for 'twitter'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateSubmissionFounderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubmissionFounderMutation, CreateSubmissionFounderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubmissionFounderMutation, CreateSubmissionFounderMutationVariables>(
    CreateSubmissionFounderDocument,
    options,
  );
}
export type CreateSubmissionFounderMutationHookResult = ReturnType<typeof useCreateSubmissionFounderMutation>;
export type CreateSubmissionFounderMutationResult = Apollo.MutationResult<CreateSubmissionFounderMutation>;
export type CreateSubmissionFounderMutationOptions = Apollo.BaseMutationOptions<
  CreateSubmissionFounderMutation,
  CreateSubmissionFounderMutationVariables
>;
export const UpdateSubmissionFounderDocument = gql`
  mutation UpdateSubmissionFounder(
    $id: ID!
    $name: String
    $description: String
    $linkedin: String
    $twitter: String
    $role: String
  ) {
    submissionFounder {
      update(
        id: $id
        input: { name: $name, description: $description, linkedin: $linkedin, twitter: $twitter, role: $role }
      ) {
        id
        name
        description
        linkedin
        twitter
        role
      }
    }
  }
`;
export type UpdateSubmissionFounderMutationFn = Apollo.MutationFunction<
  UpdateSubmissionFounderMutation,
  UpdateSubmissionFounderMutationVariables
>;

/**
 * __useUpdateSubmissionFounderMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionFounderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionFounderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionFounderMutation, { data, loading, error }] = useUpdateSubmissionFounderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      linkedin: // value for 'linkedin'
 *      twitter: // value for 'twitter'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateSubmissionFounderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionFounderMutation, UpdateSubmissionFounderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionFounderMutation, UpdateSubmissionFounderMutationVariables>(
    UpdateSubmissionFounderDocument,
    options,
  );
}
export type UpdateSubmissionFounderMutationHookResult = ReturnType<typeof useUpdateSubmissionFounderMutation>;
export type UpdateSubmissionFounderMutationResult = Apollo.MutationResult<UpdateSubmissionFounderMutation>;
export type UpdateSubmissionFounderMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionFounderMutation,
  UpdateSubmissionFounderMutationVariables
>;
export const DeleteSubmissionFounderDocument = gql`
  mutation DeleteSubmissionFounder($id: ID!) {
    submissionFounder {
      delete(id: $id) {
        id
      }
    }
  }
`;
export type DeleteSubmissionFounderMutationFn = Apollo.MutationFunction<
  DeleteSubmissionFounderMutation,
  DeleteSubmissionFounderMutationVariables
>;

/**
 * __useDeleteSubmissionFounderMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionFounderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionFounderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionFounderMutation, { data, loading, error }] = useDeleteSubmissionFounderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubmissionFounderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubmissionFounderMutation, DeleteSubmissionFounderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubmissionFounderMutation, DeleteSubmissionFounderMutationVariables>(
    DeleteSubmissionFounderDocument,
    options,
  );
}
export type DeleteSubmissionFounderMutationHookResult = ReturnType<typeof useDeleteSubmissionFounderMutation>;
export type DeleteSubmissionFounderMutationResult = Apollo.MutationResult<DeleteSubmissionFounderMutation>;
export type DeleteSubmissionFounderMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubmissionFounderMutation,
  DeleteSubmissionFounderMutationVariables
>;
export const DealActivityDocument = gql`
  query DealActivity($submissionId: ID!) {
    dealActivity(id: $submissionId) {
      ... on NemoSubmission {
        id
        createdAt
        creator {
          id
          email
          username
          publicProfile {
            id
            imageUrl
          }
        }
        rawSource
        forkedFrom {
          id
          list {
            id
            slug
          }
        }
        sourceProfiles {
          id
          fullname
          imageUrl
          roles {
            id
            description
            entityProfile {
              id
              fullname
            }
          }
        }
      }
      ... on NemoSubmissionNote {
        id
        createdAt
        creator {
          id
          email
          username
          publicProfile {
            id
            imageUrl
          }
        }
        content
      }
      ... on NemoSubmissionChange {
        id
        createdAt
        creator {
          id
          email
          username
          publicProfile {
            id
            imageUrl
          }
        }
        section
        diffs {
          field
          identifier
          oldValue
          newValue
        }
      }
    }
  }
`;

/**
 * __useDealActivityQuery__
 *
 * To run a query within a React component, call `useDealActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealActivityQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useDealActivityQuery(
  baseOptions: Apollo.QueryHookOptions<DealActivityQuery, DealActivityQueryVariables> &
    ({ variables: DealActivityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealActivityQuery, DealActivityQueryVariables>(DealActivityDocument, options);
}
export function useDealActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealActivityQuery, DealActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealActivityQuery, DealActivityQueryVariables>(DealActivityDocument, options);
}
export function useDealActivitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealActivityQuery, DealActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealActivityQuery, DealActivityQueryVariables>(DealActivityDocument, options);
}
export type DealActivityQueryHookResult = ReturnType<typeof useDealActivityQuery>;
export type DealActivityLazyQueryHookResult = ReturnType<typeof useDealActivityLazyQuery>;
export type DealActivitySuspenseQueryHookResult = ReturnType<typeof useDealActivitySuspenseQuery>;
export type DealActivityQueryResult = Apollo.QueryResult<DealActivityQuery, DealActivityQueryVariables>;
export const CreateSubmissionNoteDocument = gql`
  mutation CreateSubmissionNote($submissionId: ID!, $content: String!) {
    nemoSubmissionNote {
      create(input: { nemoSubmissionId: $submissionId, content: $content }) {
        id
        content
      }
    }
  }
`;
export type CreateSubmissionNoteMutationFn = Apollo.MutationFunction<
  CreateSubmissionNoteMutation,
  CreateSubmissionNoteMutationVariables
>;

/**
 * __useCreateSubmissionNoteMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionNoteMutation, { data, loading, error }] = useCreateSubmissionNoteMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateSubmissionNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubmissionNoteMutation, CreateSubmissionNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubmissionNoteMutation, CreateSubmissionNoteMutationVariables>(
    CreateSubmissionNoteDocument,
    options,
  );
}
export type CreateSubmissionNoteMutationHookResult = ReturnType<typeof useCreateSubmissionNoteMutation>;
export type CreateSubmissionNoteMutationResult = Apollo.MutationResult<CreateSubmissionNoteMutation>;
export type CreateSubmissionNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateSubmissionNoteMutation,
  CreateSubmissionNoteMutationVariables
>;
export const DeleteSubmissionNoteDocument = gql`
  mutation DeleteSubmissionNote($id: ID!) {
    nemoSubmissionNote {
      delete(id: $id)
    }
  }
`;
export type DeleteSubmissionNoteMutationFn = Apollo.MutationFunction<
  DeleteSubmissionNoteMutation,
  DeleteSubmissionNoteMutationVariables
>;

/**
 * __useDeleteSubmissionNoteMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionNoteMutation, { data, loading, error }] = useDeleteSubmissionNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubmissionNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubmissionNoteMutation, DeleteSubmissionNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubmissionNoteMutation, DeleteSubmissionNoteMutationVariables>(
    DeleteSubmissionNoteDocument,
    options,
  );
}
export type DeleteSubmissionNoteMutationHookResult = ReturnType<typeof useDeleteSubmissionNoteMutation>;
export type DeleteSubmissionNoteMutationResult = Apollo.MutationResult<DeleteSubmissionNoteMutation>;
export type DeleteSubmissionNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubmissionNoteMutation,
  DeleteSubmissionNoteMutationVariables
>;
export const UpdateSubmissionNoteDocument = gql`
  mutation UpdateSubmissionNote($id: ID!, $content: String!) {
    nemoSubmissionNote {
      update(input: { id: $id, content: $content }) {
        id
        content
      }
    }
  }
`;
export type UpdateSubmissionNoteMutationFn = Apollo.MutationFunction<
  UpdateSubmissionNoteMutation,
  UpdateSubmissionNoteMutationVariables
>;

/**
 * __useUpdateSubmissionNoteMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionNoteMutation, { data, loading, error }] = useUpdateSubmissionNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateSubmissionNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionNoteMutation, UpdateSubmissionNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionNoteMutation, UpdateSubmissionNoteMutationVariables>(
    UpdateSubmissionNoteDocument,
    options,
  );
}
export type UpdateSubmissionNoteMutationHookResult = ReturnType<typeof useUpdateSubmissionNoteMutation>;
export type UpdateSubmissionNoteMutationResult = Apollo.MutationResult<UpdateSubmissionNoteMutation>;
export type UpdateSubmissionNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionNoteMutation,
  UpdateSubmissionNoteMutationVariables
>;
export const DeleteDealDocumentDocument = gql`
  mutation DeleteDealDocument($id: ID!) {
    dealDocument {
      delete(id: $id) {
        id
      }
    }
  }
`;
export type DeleteDealDocumentMutationFn = Apollo.MutationFunction<
  DeleteDealDocumentMutation,
  DeleteDealDocumentMutationVariables
>;

/**
 * __useDeleteDealDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDealDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealDocumentMutation, { data, loading, error }] = useDeleteDealDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDealDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDealDocumentMutation, DeleteDealDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDealDocumentMutation, DeleteDealDocumentMutationVariables>(
    DeleteDealDocumentDocument,
    options,
  );
}
export type DeleteDealDocumentMutationHookResult = ReturnType<typeof useDeleteDealDocumentMutation>;
export type DeleteDealDocumentMutationResult = Apollo.MutationResult<DeleteDealDocumentMutation>;
export type DeleteDealDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDealDocumentMutation,
  DeleteDealDocumentMutationVariables
>;
export const CreateSubmissionTagDocument = gql`
  mutation CreateSubmissionTag($submissionId: ID!, $tag: String!) {
    nemoSubmissionTag {
      create(input: { nemoSubmissionId: $submissionId, tag: $tag }) {
        id
        tag
      }
    }
  }
`;
export type CreateSubmissionTagMutationFn = Apollo.MutationFunction<
  CreateSubmissionTagMutation,
  CreateSubmissionTagMutationVariables
>;

/**
 * __useCreateSubmissionTagMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionTagMutation, { data, loading, error }] = useCreateSubmissionTagMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateSubmissionTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubmissionTagMutation, CreateSubmissionTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubmissionTagMutation, CreateSubmissionTagMutationVariables>(
    CreateSubmissionTagDocument,
    options,
  );
}
export type CreateSubmissionTagMutationHookResult = ReturnType<typeof useCreateSubmissionTagMutation>;
export type CreateSubmissionTagMutationResult = Apollo.MutationResult<CreateSubmissionTagMutation>;
export type CreateSubmissionTagMutationOptions = Apollo.BaseMutationOptions<
  CreateSubmissionTagMutation,
  CreateSubmissionTagMutationVariables
>;
export const DeleteSubmissionTagDocument = gql`
  mutation DeleteSubmissionTag($id: ID!) {
    nemoSubmissionTag {
      delete(id: $id) {
        id
      }
    }
  }
`;
export type DeleteSubmissionTagMutationFn = Apollo.MutationFunction<
  DeleteSubmissionTagMutation,
  DeleteSubmissionTagMutationVariables
>;

/**
 * __useDeleteSubmissionTagMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionTagMutation, { data, loading, error }] = useDeleteSubmissionTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubmissionTagMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubmissionTagMutation, DeleteSubmissionTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubmissionTagMutation, DeleteSubmissionTagMutationVariables>(
    DeleteSubmissionTagDocument,
    options,
  );
}
export type DeleteSubmissionTagMutationHookResult = ReturnType<typeof useDeleteSubmissionTagMutation>;
export type DeleteSubmissionTagMutationResult = Apollo.MutationResult<DeleteSubmissionTagMutation>;
export type DeleteSubmissionTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubmissionTagMutation,
  DeleteSubmissionTagMutationVariables
>;
export const GetAllTagsDocument = gql`
  query GetAllTags {
    nemoSubmissionTags {
      id
      tag
    }
  }
`;

/**
 * __useGetAllTagsQuery__
 *
 * To run a query within a React component, call `useGetAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
}
export function useGetAllTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
}
export function useGetAllTagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
}
export type GetAllTagsQueryHookResult = ReturnType<typeof useGetAllTagsQuery>;
export type GetAllTagsLazyQueryHookResult = ReturnType<typeof useGetAllTagsLazyQuery>;
export type GetAllTagsSuspenseQueryHookResult = ReturnType<typeof useGetAllTagsSuspenseQuery>;
export type GetAllTagsQueryResult = Apollo.QueryResult<GetAllTagsQuery, GetAllTagsQueryVariables>;
export const GetUploadedCountDocument = gql`
  query GetUploadedCount {
    uploadedDealCount
  }
`;

/**
 * __useGetUploadedCountQuery__
 *
 * To run a query within a React component, call `useGetUploadedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadedCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUploadedCountQuery, GetUploadedCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUploadedCountQuery, GetUploadedCountQueryVariables>(GetUploadedCountDocument, options);
}
export function useGetUploadedCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUploadedCountQuery, GetUploadedCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUploadedCountQuery, GetUploadedCountQueryVariables>(GetUploadedCountDocument, options);
}
export function useGetUploadedCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUploadedCountQuery, GetUploadedCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUploadedCountQuery, GetUploadedCountQueryVariables>(
    GetUploadedCountDocument,
    options,
  );
}
export type GetUploadedCountQueryHookResult = ReturnType<typeof useGetUploadedCountQuery>;
export type GetUploadedCountLazyQueryHookResult = ReturnType<typeof useGetUploadedCountLazyQuery>;
export type GetUploadedCountSuspenseQueryHookResult = ReturnType<typeof useGetUploadedCountSuspenseQuery>;
export type GetUploadedCountQueryResult = Apollo.QueryResult<GetUploadedCountQuery, GetUploadedCountQueryVariables>;
export const DealsMatchingThisOrganisationDocument = gql`
  query DealsMatchingThisOrganisation($id: ID!) {
    dealsMatchingThisOrganisation(id: $id) {
      id
      createdAt
      updatedAt
      list {
        id
        slug
      }
      creator {
        publicProfile {
          fullname
        }
      }
      status
      teamId
      sharedToDealFeedAt
      assignee {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
      sourceProfiles {
        id
        fullname
      }
      roundType
    }
  }
`;

/**
 * __useDealsMatchingThisOrganisationQuery__
 *
 * To run a query within a React component, call `useDealsMatchingThisOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsMatchingThisOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsMatchingThisOrganisationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealsMatchingThisOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<
    DealsMatchingThisOrganisationQuery,
    DealsMatchingThisOrganisationQueryVariables
  > &
    ({ variables: DealsMatchingThisOrganisationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealsMatchingThisOrganisationQuery, DealsMatchingThisOrganisationQueryVariables>(
    DealsMatchingThisOrganisationDocument,
    options,
  );
}
export function useDealsMatchingThisOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DealsMatchingThisOrganisationQuery,
    DealsMatchingThisOrganisationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealsMatchingThisOrganisationQuery, DealsMatchingThisOrganisationQueryVariables>(
    DealsMatchingThisOrganisationDocument,
    options,
  );
}
export function useDealsMatchingThisOrganisationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DealsMatchingThisOrganisationQuery,
    DealsMatchingThisOrganisationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealsMatchingThisOrganisationQuery, DealsMatchingThisOrganisationQueryVariables>(
    DealsMatchingThisOrganisationDocument,
    options,
  );
}
export type DealsMatchingThisOrganisationQueryHookResult = ReturnType<typeof useDealsMatchingThisOrganisationQuery>;
export type DealsMatchingThisOrganisationLazyQueryHookResult = ReturnType<
  typeof useDealsMatchingThisOrganisationLazyQuery
>;
export type DealsMatchingThisOrganisationSuspenseQueryHookResult = ReturnType<
  typeof useDealsMatchingThisOrganisationSuspenseQuery
>;
export type DealsMatchingThisOrganisationQueryResult = Apollo.QueryResult<
  DealsMatchingThisOrganisationQuery,
  DealsMatchingThisOrganisationQueryVariables
>;
export const DealByIdDocument = gql`
  query DealById($id: ID!) {
    submission(id: $id) {
      id
      companyName
      companyUrl
      companyCity
      companyCountry
      teaserSummary
      roundType
      raisedCurrency
      raisedAmount
      createdAt
      updatedAt
      creator {
        publicProfile {
          fullname
        }
      }
      status
      teamId
      sharedToDealFeedAt
      assignee {
        id
        email
        username
        publicProfile {
          id
          fullname
          imageUrl
        }
      }
      sourceProfiles {
        id
        fullname
      }
    }
  }
`;

/**
 * __useDealByIdQuery__
 *
 * To run a query within a React component, call `useDealByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealByIdQuery(
  baseOptions: Apollo.QueryHookOptions<DealByIdQuery, DealByIdQueryVariables> &
    ({ variables: DealByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealByIdQuery, DealByIdQueryVariables>(DealByIdDocument, options);
}
export function useDealByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealByIdQuery, DealByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealByIdQuery, DealByIdQueryVariables>(DealByIdDocument, options);
}
export function useDealByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DealByIdQuery, DealByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DealByIdQuery, DealByIdQueryVariables>(DealByIdDocument, options);
}
export type DealByIdQueryHookResult = ReturnType<typeof useDealByIdQuery>;
export type DealByIdLazyQueryHookResult = ReturnType<typeof useDealByIdLazyQuery>;
export type DealByIdSuspenseQueryHookResult = ReturnType<typeof useDealByIdSuspenseQuery>;
export type DealByIdQueryResult = Apollo.QueryResult<DealByIdQuery, DealByIdQueryVariables>;
export const UserDealDigestDocument = gql`
  query UserDealDigest($userId: ID!) {
    userDealDigest(userId: $userId) {
      id
      company {
        name
        website
        location
        countryFlag
      }
      founders {
        fullname
        role
        linkedin
      }
      teaserSummary
      roundName
      amount
      status
      azavaUrl
      leadBy
    }
  }
`;

/**
 * __useUserDealDigestQuery__
 *
 * To run a query within a React component, call `useUserDealDigestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDealDigestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDealDigestQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDealDigestQuery(
  baseOptions: Apollo.QueryHookOptions<UserDealDigestQuery, UserDealDigestQueryVariables> &
    ({ variables: UserDealDigestQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDealDigestQuery, UserDealDigestQueryVariables>(UserDealDigestDocument, options);
}
export function useUserDealDigestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDealDigestQuery, UserDealDigestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDealDigestQuery, UserDealDigestQueryVariables>(UserDealDigestDocument, options);
}
export function useUserDealDigestSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserDealDigestQuery, UserDealDigestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserDealDigestQuery, UserDealDigestQueryVariables>(UserDealDigestDocument, options);
}
export type UserDealDigestQueryHookResult = ReturnType<typeof useUserDealDigestQuery>;
export type UserDealDigestLazyQueryHookResult = ReturnType<typeof useUserDealDigestLazyQuery>;
export type UserDealDigestSuspenseQueryHookResult = ReturnType<typeof useUserDealDigestSuspenseQuery>;
export type UserDealDigestQueryResult = Apollo.QueryResult<UserDealDigestQuery, UserDealDigestQueryVariables>;
export const UpdateSubmissionMarketsDocument = gql`
  mutation updateSubmissionMarkets($id: ID!, $markets: [String!]!) {
    nemoSubmission {
      update(id: $id, args: { section: "Markets", markets: $markets }) {
        id
        markets
      }
    }
  }
`;
export type UpdateSubmissionMarketsMutationFn = Apollo.MutationFunction<
  UpdateSubmissionMarketsMutation,
  UpdateSubmissionMarketsMutationVariables
>;

/**
 * __useUpdateSubmissionMarketsMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionMarketsMutation, { data, loading, error }] = useUpdateSubmissionMarketsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      markets: // value for 'markets'
 *   },
 * });
 */
export function useUpdateSubmissionMarketsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionMarketsMutation, UpdateSubmissionMarketsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionMarketsMutation, UpdateSubmissionMarketsMutationVariables>(
    UpdateSubmissionMarketsDocument,
    options,
  );
}
export type UpdateSubmissionMarketsMutationHookResult = ReturnType<typeof useUpdateSubmissionMarketsMutation>;
export type UpdateSubmissionMarketsMutationResult = Apollo.MutationResult<UpdateSubmissionMarketsMutation>;
export type UpdateSubmissionMarketsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionMarketsMutation,
  UpdateSubmissionMarketsMutationVariables
>;
export const UpdateSubmissionTagsDocument = gql`
  mutation updateSubmissionTags($id: ID!, $userTags: [String!]!) {
    nemoSubmission {
      update(id: $id, args: { section: "UserTags", userTags: $userTags }) {
        id
        userTags
      }
    }
  }
`;
export type UpdateSubmissionTagsMutationFn = Apollo.MutationFunction<
  UpdateSubmissionTagsMutation,
  UpdateSubmissionTagsMutationVariables
>;

/**
 * __useUpdateSubmissionTagsMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionTagsMutation, { data, loading, error }] = useUpdateSubmissionTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userTags: // value for 'userTags'
 *   },
 * });
 */
export function useUpdateSubmissionTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionTagsMutation, UpdateSubmissionTagsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionTagsMutation, UpdateSubmissionTagsMutationVariables>(
    UpdateSubmissionTagsDocument,
    options,
  );
}
export type UpdateSubmissionTagsMutationHookResult = ReturnType<typeof useUpdateSubmissionTagsMutation>;
export type UpdateSubmissionTagsMutationResult = Apollo.MutationResult<UpdateSubmissionTagsMutation>;
export type UpdateSubmissionTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionTagsMutation,
  UpdateSubmissionTagsMutationVariables
>;
export const NotesDocument = gql`
  query Notes($referenceIds: [ID!]!) {
    notes(referenceIds: $referenceIds) {
      ...NoteFields
      createdBy {
        id
        email
        username
        publicProfile {
          imageUrl
        }
      }
    }
  }
  ${NoteFieldsFragmentDoc}
`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      referenceIds: // value for 'referenceIds'
 *   },
 * });
 */
export function useNotesQuery(
  baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables> &
    ({ variables: NotesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export function useNotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotesQuery, NotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
}
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesSuspenseQueryHookResult = ReturnType<typeof useNotesSuspenseQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const DeleteNoteDocument = gql`
  mutation DeleteNote($noteId: ID!) {
    note {
      delete(id: $noteId) {
        id
      }
    }
  }
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
}
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const CreateNoteDocument = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    note {
      create(input: $input) {
        id
      }
    }
  }
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const UpdateNoteDocument = gql`
  mutation UpdateNote($input: UpdateNoteInput!) {
    note {
      update(input: $input) {
        id
      }
    }
  }
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
}
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const UpdateUserSettingsDocument = gql`
  mutation updateUserSettings($userId: ID!, $args: UserSettingsUpdateInput!) {
    userSettings {
      updateByUserId(userId: $userId, args: $args) {
        id
        notifyCompletedDeals
        notifySameDealEmail
        notifySameDealWhatsapp
        notifyCuriousEmail
        notifyCuriousWhatsapp
        notifyLikesEmail
        notifyLikesWhatsapp
        dealFeedDefaultShared
        dealFeedShowIntroCallout
      }
    }
  }
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options,
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const UpdateDealFeedShowIntroDocument = gql`
  mutation updateDealFeedShowIntro($userId: ID!, $showIntro: ShowHide!) {
    userSettings {
      updateByUserId(userId: $userId, args: { dealFeedShowIntroCallout: $showIntro }) {
        id
        dealFeedShowIntroCallout
      }
    }
  }
`;
export type UpdateDealFeedShowIntroMutationFn = Apollo.MutationFunction<
  UpdateDealFeedShowIntroMutation,
  UpdateDealFeedShowIntroMutationVariables
>;

/**
 * __useUpdateDealFeedShowIntroMutation__
 *
 * To run a mutation, you first call `useUpdateDealFeedShowIntroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealFeedShowIntroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealFeedShowIntroMutation, { data, loading, error }] = useUpdateDealFeedShowIntroMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      showIntro: // value for 'showIntro'
 *   },
 * });
 */
export function useUpdateDealFeedShowIntroMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDealFeedShowIntroMutation, UpdateDealFeedShowIntroMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDealFeedShowIntroMutation, UpdateDealFeedShowIntroMutationVariables>(
    UpdateDealFeedShowIntroDocument,
    options,
  );
}
export type UpdateDealFeedShowIntroMutationHookResult = ReturnType<typeof useUpdateDealFeedShowIntroMutation>;
export type UpdateDealFeedShowIntroMutationResult = Apollo.MutationResult<UpdateDealFeedShowIntroMutation>;
export type UpdateDealFeedShowIntroMutationOptions = Apollo.BaseMutationOptions<
  UpdateDealFeedShowIntroMutation,
  UpdateDealFeedShowIntroMutationVariables
>;
export const TouchDealFeedLastSeenAtDocument = gql`
  mutation touchDealFeedLastSeenAt {
    touchDealFeedLastSeenAt {
      id
      dealFeedLastSeenAt
    }
  }
`;
export type TouchDealFeedLastSeenAtMutationFn = Apollo.MutationFunction<
  TouchDealFeedLastSeenAtMutation,
  TouchDealFeedLastSeenAtMutationVariables
>;

/**
 * __useTouchDealFeedLastSeenAtMutation__
 *
 * To run a mutation, you first call `useTouchDealFeedLastSeenAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTouchDealFeedLastSeenAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [touchDealFeedLastSeenAtMutation, { data, loading, error }] = useTouchDealFeedLastSeenAtMutation({
 *   variables: {
 *   },
 * });
 */
export function useTouchDealFeedLastSeenAtMutation(
  baseOptions?: Apollo.MutationHookOptions<TouchDealFeedLastSeenAtMutation, TouchDealFeedLastSeenAtMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TouchDealFeedLastSeenAtMutation, TouchDealFeedLastSeenAtMutationVariables>(
    TouchDealFeedLastSeenAtDocument,
    options,
  );
}
export type TouchDealFeedLastSeenAtMutationHookResult = ReturnType<typeof useTouchDealFeedLastSeenAtMutation>;
export type TouchDealFeedLastSeenAtMutationResult = Apollo.MutationResult<TouchDealFeedLastSeenAtMutation>;
export type TouchDealFeedLastSeenAtMutationOptions = Apollo.BaseMutationOptions<
  TouchDealFeedLastSeenAtMutation,
  TouchDealFeedLastSeenAtMutationVariables
>;
export const TouchNotificationsLastSeenAtDocument = gql`
  mutation touchNotificationsLastSeenAt {
    touchNotificationsLastSeenAt {
      id
      notificationsLastSeenAt
    }
  }
`;
export type TouchNotificationsLastSeenAtMutationFn = Apollo.MutationFunction<
  TouchNotificationsLastSeenAtMutation,
  TouchNotificationsLastSeenAtMutationVariables
>;

/**
 * __useTouchNotificationsLastSeenAtMutation__
 *
 * To run a mutation, you first call `useTouchNotificationsLastSeenAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTouchNotificationsLastSeenAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [touchNotificationsLastSeenAtMutation, { data, loading, error }] = useTouchNotificationsLastSeenAtMutation({
 *   variables: {
 *   },
 * });
 */
export function useTouchNotificationsLastSeenAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TouchNotificationsLastSeenAtMutation,
    TouchNotificationsLastSeenAtMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TouchNotificationsLastSeenAtMutation, TouchNotificationsLastSeenAtMutationVariables>(
    TouchNotificationsLastSeenAtDocument,
    options,
  );
}
export type TouchNotificationsLastSeenAtMutationHookResult = ReturnType<typeof useTouchNotificationsLastSeenAtMutation>;
export type TouchNotificationsLastSeenAtMutationResult = Apollo.MutationResult<TouchNotificationsLastSeenAtMutation>;
export type TouchNotificationsLastSeenAtMutationOptions = Apollo.BaseMutationOptions<
  TouchNotificationsLastSeenAtMutation,
  TouchNotificationsLastSeenAtMutationVariables
>;
export const FindPortfoliosDocument = gql`
  query FindPortfolios($teamId: ID!) {
    team(id: $teamId) {
      id
      portfolios {
        ...LegalEntityDisplayFields
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;

/**
 * __useFindPortfoliosQuery__
 *
 * To run a query within a React component, call `useFindPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPortfoliosQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useFindPortfoliosQuery(
  baseOptions: Apollo.QueryHookOptions<FindPortfoliosQuery, FindPortfoliosQueryVariables> &
    ({ variables: FindPortfoliosQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPortfoliosQuery, FindPortfoliosQueryVariables>(FindPortfoliosDocument, options);
}
export function useFindPortfoliosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindPortfoliosQuery, FindPortfoliosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPortfoliosQuery, FindPortfoliosQueryVariables>(FindPortfoliosDocument, options);
}
export function useFindPortfoliosSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FindPortfoliosQuery, FindPortfoliosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindPortfoliosQuery, FindPortfoliosQueryVariables>(FindPortfoliosDocument, options);
}
export type FindPortfoliosQueryHookResult = ReturnType<typeof useFindPortfoliosQuery>;
export type FindPortfoliosLazyQueryHookResult = ReturnType<typeof useFindPortfoliosLazyQuery>;
export type FindPortfoliosSuspenseQueryHookResult = ReturnType<typeof useFindPortfoliosSuspenseQuery>;
export type FindPortfoliosQueryResult = Apollo.QueryResult<FindPortfoliosQuery, FindPortfoliosQueryVariables>;
export const SearchInvestedIntoDocument = gql`
  query SearchInvestedInto($teamId: ID!, $input: LegalEntitySearchInput!) {
    team(id: $teamId) {
      id
      investedInto(input: $input) {
        ...LegalEntityDisplayFields
      }
    }
  }
  ${LegalEntityDisplayFieldsFragmentDoc}
`;

/**
 * __useSearchInvestedIntoQuery__
 *
 * To run a query within a React component, call `useSearchInvestedIntoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvestedIntoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvestedIntoQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchInvestedIntoQuery(
  baseOptions: Apollo.QueryHookOptions<SearchInvestedIntoQuery, SearchInvestedIntoQueryVariables> &
    ({ variables: SearchInvestedIntoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchInvestedIntoQuery, SearchInvestedIntoQueryVariables>(
    SearchInvestedIntoDocument,
    options,
  );
}
export function useSearchInvestedIntoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchInvestedIntoQuery, SearchInvestedIntoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchInvestedIntoQuery, SearchInvestedIntoQueryVariables>(
    SearchInvestedIntoDocument,
    options,
  );
}
export function useSearchInvestedIntoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchInvestedIntoQuery, SearchInvestedIntoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchInvestedIntoQuery, SearchInvestedIntoQueryVariables>(
    SearchInvestedIntoDocument,
    options,
  );
}
export type SearchInvestedIntoQueryHookResult = ReturnType<typeof useSearchInvestedIntoQuery>;
export type SearchInvestedIntoLazyQueryHookResult = ReturnType<typeof useSearchInvestedIntoLazyQuery>;
export type SearchInvestedIntoSuspenseQueryHookResult = ReturnType<typeof useSearchInvestedIntoSuspenseQuery>;
export type SearchInvestedIntoQueryResult = Apollo.QueryResult<
  SearchInvestedIntoQuery,
  SearchInvestedIntoQueryVariables
>;
export const PortfolioActivityDocument = gql`
  query PortfolioActivity($portfolioId: ID!, $asOfDate: Date!, $cohortYear: Int, $userCurrency: CurrencyIsoCode!) {
    rounds(portfolioId: $portfolioId) {
      ...ActivityRound
    }
    legalEntity(id: $portfolioId) {
      transactionlessEvents(investorId: $portfolioId, asOfDate: $asOfDate) {
        ...EventFields
      }
      portfolioCompanies(userCurrency: $userCurrency, asOfDate: $asOfDate, cohortYear: $cohortYear) {
        legalEntity {
          ...LegalEntityFields
          transactionlessEvents(investorId: $portfolioId, asOfDate: $asOfDate) {
            ...ActivityEvent
          }
        }
        distributions {
          ...ActivityDistribution
        }
        dividends {
          ...ActivityDistribution
        }
        investments {
          ...ActivityInvestment
        }
      }
    }
  }
  ${ActivityRoundFragmentDoc}
  ${EventFieldsFragmentDoc}
  ${LegalEntityFieldsFragmentDoc}
  ${ActivityEventFragmentDoc}
  ${ActivityDistributionFragmentDoc}
  ${ActivityInvestmentFragmentDoc}
`;

/**
 * __usePortfolioActivityQuery__
 *
 * To run a query within a React component, call `usePortfolioActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioActivityQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      asOfDate: // value for 'asOfDate'
 *      cohortYear: // value for 'cohortYear'
 *      userCurrency: // value for 'userCurrency'
 *   },
 * });
 */
export function usePortfolioActivityQuery(
  baseOptions: Apollo.QueryHookOptions<PortfolioActivityQuery, PortfolioActivityQueryVariables> &
    ({ variables: PortfolioActivityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioActivityQuery, PortfolioActivityQueryVariables>(PortfolioActivityDocument, options);
}
export function usePortfolioActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioActivityQuery, PortfolioActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioActivityQuery, PortfolioActivityQueryVariables>(
    PortfolioActivityDocument,
    options,
  );
}
export function usePortfolioActivitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PortfolioActivityQuery, PortfolioActivityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PortfolioActivityQuery, PortfolioActivityQueryVariables>(
    PortfolioActivityDocument,
    options,
  );
}
export type PortfolioActivityQueryHookResult = ReturnType<typeof usePortfolioActivityQuery>;
export type PortfolioActivityLazyQueryHookResult = ReturnType<typeof usePortfolioActivityLazyQuery>;
export type PortfolioActivitySuspenseQueryHookResult = ReturnType<typeof usePortfolioActivitySuspenseQuery>;
export type PortfolioActivityQueryResult = Apollo.QueryResult<PortfolioActivityQuery, PortfolioActivityQueryVariables>;
export const PortfolioCompanyDocument = gql`
  query PortfolioCompany(
    $viewerId: ID!
    $portfolioCompanyId: ID!
    $asOfDate: Date!
    $userCurrency: CurrencyIsoCode!
    $cohortYear: Int
  ) {
    viewerLegalEntity: legalEntity(id: $viewerId) {
      ...LegalEntityFields
      portfolioCompany(
        portfolioCompanyId: $portfolioCompanyId
        userCurrency: $userCurrency
        asOfDate: $asOfDate
        cohortYear: $cohortYear
      ) {
        portfolioLegalEntity: legalEntity {
          ...LegalEntityFields
          publicProfile {
            ... on CompanyProfile {
              id
              slug
              website
            }
          }
          transactionlessEvents(investorId: $viewerId, asOfDate: $asOfDate) {
            ...EventFields
            round {
              ...RoundFields
            }
            otherPortfolios(portfolioId: $viewerId) {
              ...LegalEntityDisplayFields
            }
          }
        }
        profileSlug
        companyMetrics {
          numInvestments
          userCurrency
          asOfDate
          stored {
            ...StoredMetricsFields
          }
          investedAmount
          unrealisedValue {
            inUserCurrency
            inPinnedUserCurrency
          }
          realisedValue {
            inUserCurrency
            inPinnedUserCurrency
          }
          moic {
            inUserCurrency
            inPinnedUserCurrency
          }
          irr {
            inUserCurrency
            inPinnedUserCurrency
          }
        }
        investments {
          ...InvestmentFields
        }
        distributions {
          ...DistributionFields
        }
        dividends {
          ...DistributionFields
        }
        fundDistributions {
          ...DistributionFields
        }
      }
    }
  }
  ${LegalEntityFieldsFragmentDoc}
  ${EventFieldsFragmentDoc}
  ${RoundFieldsFragmentDoc}
  ${LegalEntityDisplayFieldsFragmentDoc}
  ${StoredMetricsFieldsFragmentDoc}
  ${InvestmentFieldsFragmentDoc}
  ${DistributionFieldsFragmentDoc}
`;

/**
 * __usePortfolioCompanyQuery__
 *
 * To run a query within a React component, call `usePortfolioCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioCompanyQuery({
 *   variables: {
 *      viewerId: // value for 'viewerId'
 *      portfolioCompanyId: // value for 'portfolioCompanyId'
 *      asOfDate: // value for 'asOfDate'
 *      userCurrency: // value for 'userCurrency'
 *      cohortYear: // value for 'cohortYear'
 *   },
 * });
 */
export function usePortfolioCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<PortfolioCompanyQuery, PortfolioCompanyQueryVariables> &
    ({ variables: PortfolioCompanyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioCompanyQuery, PortfolioCompanyQueryVariables>(PortfolioCompanyDocument, options);
}
export function usePortfolioCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioCompanyQuery, PortfolioCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioCompanyQuery, PortfolioCompanyQueryVariables>(PortfolioCompanyDocument, options);
}
export function usePortfolioCompanySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PortfolioCompanyQuery, PortfolioCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PortfolioCompanyQuery, PortfolioCompanyQueryVariables>(
    PortfolioCompanyDocument,
    options,
  );
}
export type PortfolioCompanyQueryHookResult = ReturnType<typeof usePortfolioCompanyQuery>;
export type PortfolioCompanyLazyQueryHookResult = ReturnType<typeof usePortfolioCompanyLazyQuery>;
export type PortfolioCompanySuspenseQueryHookResult = ReturnType<typeof usePortfolioCompanySuspenseQuery>;
export type PortfolioCompanyQueryResult = Apollo.QueryResult<PortfolioCompanyQuery, PortfolioCompanyQueryVariables>;
export const UpsertOwnershipDocument = gql`
  mutation UpsertOwnership($companyId: ID!, $investorId: ID!, $newValue: Float!) {
    upsertOwnership(companyId: $companyId, investorId: $investorId, newValue: $newValue)
  }
`;
export type UpsertOwnershipMutationFn = Apollo.MutationFunction<
  UpsertOwnershipMutation,
  UpsertOwnershipMutationVariables
>;

/**
 * __useUpsertOwnershipMutation__
 *
 * To run a mutation, you first call `useUpsertOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOwnershipMutation, { data, loading, error }] = useUpsertOwnershipMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      investorId: // value for 'investorId'
 *      newValue: // value for 'newValue'
 *   },
 * });
 */
export function useUpsertOwnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertOwnershipMutation, UpsertOwnershipMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertOwnershipMutation, UpsertOwnershipMutationVariables>(
    UpsertOwnershipDocument,
    options,
  );
}
export type UpsertOwnershipMutationHookResult = ReturnType<typeof useUpsertOwnershipMutation>;
export type UpsertOwnershipMutationResult = Apollo.MutationResult<UpsertOwnershipMutation>;
export type UpsertOwnershipMutationOptions = Apollo.BaseMutationOptions<
  UpsertOwnershipMutation,
  UpsertOwnershipMutationVariables
>;
export const DeleteOwnershipDocument = gql`
  mutation DeleteOwnership($portfolioCompanyMetricsId: ID!) {
    deleteOwnership(portfolioCompanyMetricsId: $portfolioCompanyMetricsId)
  }
`;
export type DeleteOwnershipMutationFn = Apollo.MutationFunction<
  DeleteOwnershipMutation,
  DeleteOwnershipMutationVariables
>;

/**
 * __useDeleteOwnershipMutation__
 *
 * To run a mutation, you first call `useDeleteOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnershipMutation, { data, loading, error }] = useDeleteOwnershipMutation({
 *   variables: {
 *      portfolioCompanyMetricsId: // value for 'portfolioCompanyMetricsId'
 *   },
 * });
 */
export function useDeleteOwnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOwnershipMutation, DeleteOwnershipMutationVariables>(
    DeleteOwnershipDocument,
    options,
  );
}
export type DeleteOwnershipMutationHookResult = ReturnType<typeof useDeleteOwnershipMutation>;
export type DeleteOwnershipMutationResult = Apollo.MutationResult<DeleteOwnershipMutation>;
export type DeleteOwnershipMutationOptions = Apollo.BaseMutationOptions<
  DeleteOwnershipMutation,
  DeleteOwnershipMutationVariables
>;
export const PortfolioOverviewDocument = gql`
  query PortfolioOverview($legalEntityId: ID!, $asOfDate: Date!, $userCurrency: CurrencyIsoCode!, $cohortYear: Int) {
    legalEntity(id: $legalEntityId) {
      ...LegalEntityFields
      type
      portfolioMetrics(userCurrency: $userCurrency, asOfDate: $asOfDate, cohortYear: $cohortYear) {
        investedAmount
        paidIntoFund
        unrealisedValue {
          inUserCurrency
          inPinnedUserCurrency
        }
        realisedValue {
          inUserCurrency
          inPinnedUserCurrency
        }
        lpIrr {
          inUserCurrency
          inPinnedUserCurrency
        }
        tvpi {
          inUserCurrency
          inPinnedUserCurrency
        }
        moic {
          inUserCurrency
          inPinnedUserCurrency
        }
        dealIrr {
          inUserCurrency
          inPinnedUserCurrency
        }
      }
      portfolioCompanies(userCurrency: $userCurrency, asOfDate: $asOfDate, cohortYear: $cohortYear) {
        legalEntity {
          ...LegalEntityFields
        }
        companyMetrics {
          numInvestments
          firstInvestedDate
          lastUpdated
          userCurrency
          asOfDate
          stored {
            id
            owned
            markdownDate
          }
          investedAmount
          unrealisedValue {
            inUserCurrency
            inPinnedUserCurrency
          }
          realisedValue {
            inUserCurrency
            inPinnedUserCurrency
          }
          moic {
            inUserCurrency
            inPinnedUserCurrency
          }
          irr {
            inUserCurrency
            inPinnedUserCurrency
          }
        }
        investments {
          ...PortfolioOverviewInvestmentFields
        }
      }
    }
  }
  ${LegalEntityFieldsFragmentDoc}
  ${PortfolioOverviewInvestmentFieldsFragmentDoc}
`;

/**
 * __usePortfolioOverviewQuery__
 *
 * To run a query within a React component, call `usePortfolioOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioOverviewQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      asOfDate: // value for 'asOfDate'
 *      userCurrency: // value for 'userCurrency'
 *      cohortYear: // value for 'cohortYear'
 *   },
 * });
 */
export function usePortfolioOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<PortfolioOverviewQuery, PortfolioOverviewQueryVariables> &
    ({ variables: PortfolioOverviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioOverviewQuery, PortfolioOverviewQueryVariables>(PortfolioOverviewDocument, options);
}
export function usePortfolioOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioOverviewQuery, PortfolioOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioOverviewQuery, PortfolioOverviewQueryVariables>(
    PortfolioOverviewDocument,
    options,
  );
}
export function usePortfolioOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PortfolioOverviewQuery, PortfolioOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PortfolioOverviewQuery, PortfolioOverviewQueryVariables>(
    PortfolioOverviewDocument,
    options,
  );
}
export type PortfolioOverviewQueryHookResult = ReturnType<typeof usePortfolioOverviewQuery>;
export type PortfolioOverviewLazyQueryHookResult = ReturnType<typeof usePortfolioOverviewLazyQuery>;
export type PortfolioOverviewSuspenseQueryHookResult = ReturnType<typeof usePortfolioOverviewSuspenseQuery>;
export type PortfolioOverviewQueryResult = Apollo.QueryResult<PortfolioOverviewQuery, PortfolioOverviewQueryVariables>;
export const DeleteRoundDocumentDocument = gql`
  mutation DeleteRoundDocument($id: ID!) {
    roundDocument {
      delete(id: $id) {
        id
      }
    }
  }
`;
export type DeleteRoundDocumentMutationFn = Apollo.MutationFunction<
  DeleteRoundDocumentMutation,
  DeleteRoundDocumentMutationVariables
>;

/**
 * __useDeleteRoundDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteRoundDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoundDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoundDocumentMutation, { data, loading, error }] = useDeleteRoundDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoundDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRoundDocumentMutation, DeleteRoundDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoundDocumentMutation, DeleteRoundDocumentMutationVariables>(
    DeleteRoundDocumentDocument,
    options,
  );
}
export type DeleteRoundDocumentMutationHookResult = ReturnType<typeof useDeleteRoundDocumentMutation>;
export type DeleteRoundDocumentMutationResult = Apollo.MutationResult<DeleteRoundDocumentMutation>;
export type DeleteRoundDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoundDocumentMutation,
  DeleteRoundDocumentMutationVariables
>;
export const UpdateConvertibleRoundDocument = gql`
  mutation UpdateConvertibleRound(
    $roundInput: UpdateRoundInput!
    $convertibleInput: UpdateConvertiblePropertiesInput!
  ) {
    round {
      update(input: $roundInput) {
        ...RoundFields
      }
    }
    updateConvertibleProperties(input: $convertibleInput)
  }
  ${RoundFieldsFragmentDoc}
`;
export type UpdateConvertibleRoundMutationFn = Apollo.MutationFunction<
  UpdateConvertibleRoundMutation,
  UpdateConvertibleRoundMutationVariables
>;

/**
 * __useUpdateConvertibleRoundMutation__
 *
 * To run a mutation, you first call `useUpdateConvertibleRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConvertibleRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConvertibleRoundMutation, { data, loading, error }] = useUpdateConvertibleRoundMutation({
 *   variables: {
 *      roundInput: // value for 'roundInput'
 *      convertibleInput: // value for 'convertibleInput'
 *   },
 * });
 */
export function useUpdateConvertibleRoundMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateConvertibleRoundMutation, UpdateConvertibleRoundMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateConvertibleRoundMutation, UpdateConvertibleRoundMutationVariables>(
    UpdateConvertibleRoundDocument,
    options,
  );
}
export type UpdateConvertibleRoundMutationHookResult = ReturnType<typeof useUpdateConvertibleRoundMutation>;
export type UpdateConvertibleRoundMutationResult = Apollo.MutationResult<UpdateConvertibleRoundMutation>;
export type UpdateConvertibleRoundMutationOptions = Apollo.BaseMutationOptions<
  UpdateConvertibleRoundMutation,
  UpdateConvertibleRoundMutationVariables
>;
export const UpdateRoundAndPriceDocument = gql`
  mutation UpdateRoundAndPrice($roundInput: UpdateRoundInput!, $priceInput: UpdateRoundPriceInput!) {
    round {
      update(input: $roundInput) {
        ...RoundFields
      }
    }
    roundPrice {
      update(input: $priceInput) {
        price {
          ...PriceFields
        }
      }
    }
  }
  ${RoundFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
`;
export type UpdateRoundAndPriceMutationFn = Apollo.MutationFunction<
  UpdateRoundAndPriceMutation,
  UpdateRoundAndPriceMutationVariables
>;

/**
 * __useUpdateRoundAndPriceMutation__
 *
 * To run a mutation, you first call `useUpdateRoundAndPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoundAndPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoundAndPriceMutation, { data, loading, error }] = useUpdateRoundAndPriceMutation({
 *   variables: {
 *      roundInput: // value for 'roundInput'
 *      priceInput: // value for 'priceInput'
 *   },
 * });
 */
export function useUpdateRoundAndPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRoundAndPriceMutation, UpdateRoundAndPriceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoundAndPriceMutation, UpdateRoundAndPriceMutationVariables>(
    UpdateRoundAndPriceDocument,
    options,
  );
}
export type UpdateRoundAndPriceMutationHookResult = ReturnType<typeof useUpdateRoundAndPriceMutation>;
export type UpdateRoundAndPriceMutationResult = Apollo.MutationResult<UpdateRoundAndPriceMutation>;
export type UpdateRoundAndPriceMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoundAndPriceMutation,
  UpdateRoundAndPriceMutationVariables
>;
export const UpdateRoundAndCreatePriceDocument = gql`
  mutation UpdateRoundAndCreatePrice($roundInput: UpdateRoundInput!, $priceInput: CreateRoundPriceInput!) {
    round {
      update(input: $roundInput) {
        ...RoundFields
      }
    }
    roundPrice {
      create(input: $priceInput) {
        price {
          ...PriceFields
        }
      }
    }
  }
  ${RoundFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
`;
export type UpdateRoundAndCreatePriceMutationFn = Apollo.MutationFunction<
  UpdateRoundAndCreatePriceMutation,
  UpdateRoundAndCreatePriceMutationVariables
>;

/**
 * __useUpdateRoundAndCreatePriceMutation__
 *
 * To run a mutation, you first call `useUpdateRoundAndCreatePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoundAndCreatePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoundAndCreatePriceMutation, { data, loading, error }] = useUpdateRoundAndCreatePriceMutation({
 *   variables: {
 *      roundInput: // value for 'roundInput'
 *      priceInput: // value for 'priceInput'
 *   },
 * });
 */
export function useUpdateRoundAndCreatePriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoundAndCreatePriceMutation,
    UpdateRoundAndCreatePriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoundAndCreatePriceMutation, UpdateRoundAndCreatePriceMutationVariables>(
    UpdateRoundAndCreatePriceDocument,
    options,
  );
}
export type UpdateRoundAndCreatePriceMutationHookResult = ReturnType<typeof useUpdateRoundAndCreatePriceMutation>;
export type UpdateRoundAndCreatePriceMutationResult = Apollo.MutationResult<UpdateRoundAndCreatePriceMutation>;
export type UpdateRoundAndCreatePriceMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoundAndCreatePriceMutation,
  UpdateRoundAndCreatePriceMutationVariables
>;
export const CreateRoundAndPriceDocument = gql`
  mutation CreateRoundAndPrice($input: CreateRoundInput!) {
    round {
      create(input: $input) {
        round {
          ...RoundFields
        }
        price {
          ...PriceFields
        }
      }
    }
  }
  ${RoundFieldsFragmentDoc}
  ${PriceFieldsFragmentDoc}
`;
export type CreateRoundAndPriceMutationFn = Apollo.MutationFunction<
  CreateRoundAndPriceMutation,
  CreateRoundAndPriceMutationVariables
>;

/**
 * __useCreateRoundAndPriceMutation__
 *
 * To run a mutation, you first call `useCreateRoundAndPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoundAndPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoundAndPriceMutation, { data, loading, error }] = useCreateRoundAndPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoundAndPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRoundAndPriceMutation, CreateRoundAndPriceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoundAndPriceMutation, CreateRoundAndPriceMutationVariables>(
    CreateRoundAndPriceDocument,
    options,
  );
}
export type CreateRoundAndPriceMutationHookResult = ReturnType<typeof useCreateRoundAndPriceMutation>;
export type CreateRoundAndPriceMutationResult = Apollo.MutationResult<CreateRoundAndPriceMutation>;
export type CreateRoundAndPriceMutationOptions = Apollo.BaseMutationOptions<
  CreateRoundAndPriceMutation,
  CreateRoundAndPriceMutationVariables
>;
export const SchemaHashDocument = gql`
  query SchemaHash {
    schemaHash
  }
`;

/**
 * __useSchemaHashQuery__
 *
 * To run a query within a React component, call `useSchemaHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchemaHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchemaHashQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchemaHashQuery(baseOptions?: Apollo.QueryHookOptions<SchemaHashQuery, SchemaHashQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchemaHashQuery, SchemaHashQueryVariables>(SchemaHashDocument, options);
}
export function useSchemaHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SchemaHashQuery, SchemaHashQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchemaHashQuery, SchemaHashQueryVariables>(SchemaHashDocument, options);
}
export function useSchemaHashSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SchemaHashQuery, SchemaHashQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SchemaHashQuery, SchemaHashQueryVariables>(SchemaHashDocument, options);
}
export type SchemaHashQueryHookResult = ReturnType<typeof useSchemaHashQuery>;
export type SchemaHashLazyQueryHookResult = ReturnType<typeof useSchemaHashLazyQuery>;
export type SchemaHashSuspenseQueryHookResult = ReturnType<typeof useSchemaHashSuspenseQuery>;
export type SchemaHashQueryResult = Apollo.QueryResult<SchemaHashQuery, SchemaHashQueryVariables>;
export const FindManyTeamByNameDocument = gql`
  query findManyTeamByName($name: String!) {
    teamsByName(name: $name) {
      id
      name
    }
  }
`;

/**
 * __useFindManyTeamByNameQuery__
 *
 * To run a query within a React component, call `useFindManyTeamByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyTeamByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyTeamByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindManyTeamByNameQuery(
  baseOptions: Apollo.QueryHookOptions<FindManyTeamByNameQuery, FindManyTeamByNameQueryVariables> &
    ({ variables: FindManyTeamByNameQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindManyTeamByNameQuery, FindManyTeamByNameQueryVariables>(
    FindManyTeamByNameDocument,
    options,
  );
}
export function useFindManyTeamByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindManyTeamByNameQuery, FindManyTeamByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindManyTeamByNameQuery, FindManyTeamByNameQueryVariables>(
    FindManyTeamByNameDocument,
    options,
  );
}
export function useFindManyTeamByNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FindManyTeamByNameQuery, FindManyTeamByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FindManyTeamByNameQuery, FindManyTeamByNameQueryVariables>(
    FindManyTeamByNameDocument,
    options,
  );
}
export type FindManyTeamByNameQueryHookResult = ReturnType<typeof useFindManyTeamByNameQuery>;
export type FindManyTeamByNameLazyQueryHookResult = ReturnType<typeof useFindManyTeamByNameLazyQuery>;
export type FindManyTeamByNameSuspenseQueryHookResult = ReturnType<typeof useFindManyTeamByNameSuspenseQuery>;
export type FindManyTeamByNameQueryResult = Apollo.QueryResult<
  FindManyTeamByNameQuery,
  FindManyTeamByNameQueryVariables
>;
export const UpdateValuationAndAddNoteDocument = gql`
  mutation UpdateValuationAndAddNote(
    $assetId: ID!
    $transactionId: ID!
    $markupDate: Date!
    $newValue: Float!
    $valueCurrency: CurrencyIsoCode!
    $note: String
  ) {
    markupValuationByAmount(
      assetId: $assetId
      transactionId: $transactionId
      markupDate: $markupDate
      newValue: $newValue
      valueCurrency: $valueCurrency
      note: $note
    )
  }
`;
export type UpdateValuationAndAddNoteMutationFn = Apollo.MutationFunction<
  UpdateValuationAndAddNoteMutation,
  UpdateValuationAndAddNoteMutationVariables
>;

/**
 * __useUpdateValuationAndAddNoteMutation__
 *
 * To run a mutation, you first call `useUpdateValuationAndAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateValuationAndAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateValuationAndAddNoteMutation, { data, loading, error }] = useUpdateValuationAndAddNoteMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      transactionId: // value for 'transactionId'
 *      markupDate: // value for 'markupDate'
 *      newValue: // value for 'newValue'
 *      valueCurrency: // value for 'valueCurrency'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateValuationAndAddNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateValuationAndAddNoteMutation,
    UpdateValuationAndAddNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateValuationAndAddNoteMutation, UpdateValuationAndAddNoteMutationVariables>(
    UpdateValuationAndAddNoteDocument,
    options,
  );
}
export type UpdateValuationAndAddNoteMutationHookResult = ReturnType<typeof useUpdateValuationAndAddNoteMutation>;
export type UpdateValuationAndAddNoteMutationResult = Apollo.MutationResult<UpdateValuationAndAddNoteMutation>;
export type UpdateValuationAndAddNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateValuationAndAddNoteMutation,
  UpdateValuationAndAddNoteMutationVariables
>;
export const UserContextDocument = gql`
  query UserContext($email: EmailAddress!) {
    userByEmail(email: $email) {
      id
      email
      secondaryEmails
      phoneNumber
      username
      isPlatformAdmin
      hasAccess
      hasCompletedRegistration
      hasPublishedToDealFeed
      teamHasDeals
      defaultTeam {
        ...TeamFields
      }
      teams {
        ...TeamFields
      }
      permission {
        readonly
        readonlyByTeam {
          id
          teamId
          readonly
        }
      }
      publicProfile {
        id
        fullname
        imageUrl
        description
        linkedin
        descriptorsGeo
        descriptorsInvestorType
        descriptorsStage
        descriptorsMiscTags
        slug
        roles {
          id
          description
          entityProfile {
            id
            fullname
            slug
          }
        }
      }
      userSettings {
        id
        dealFeedDefaultShared
        notifyCompletedDeals
        notifySameDealEmail
        notifySameDealWhatsapp
        notifyCuriousEmail
        notifyCuriousWhatsapp
        notifyLikesEmail
        notifyLikesWhatsapp
        dealFeedLastSeenAt
        notificationsLastSeenAt
        dealFeedShowIntroCallout
        promptProfileSetup
        discoverableInConnections
      }
    }
  }
  ${TeamFieldsFragmentDoc}
`;

/**
 * __useUserContextQuery__
 *
 * To run a query within a React component, call `useUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserContextQuery(
  baseOptions: Apollo.QueryHookOptions<UserContextQuery, UserContextQueryVariables> &
    ({ variables: UserContextQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, options);
}
export function useUserContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserContextQuery, UserContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, options);
}
export function useUserContextSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserContextQuery, UserContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, options);
}
export type UserContextQueryHookResult = ReturnType<typeof useUserContextQuery>;
export type UserContextLazyQueryHookResult = ReturnType<typeof useUserContextLazyQuery>;
export type UserContextSuspenseQueryHookResult = ReturnType<typeof useUserContextSuspenseQuery>;
export type UserContextQueryResult = Apollo.QueryResult<UserContextQuery, UserContextQueryVariables>;
export const UsersByEmailPrefixDocument = gql`
  query UsersByEmailPrefix($emailPrefix: String!) {
    usersByEmailPrefix(emailPrefix: $emailPrefix) {
      id
      email
      nemoToken
      username
      publicProfile {
        id
        fullname
      }
    }
  }
`;

/**
 * __useUsersByEmailPrefixQuery__
 *
 * To run a query within a React component, call `useUsersByEmailPrefixQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByEmailPrefixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByEmailPrefixQuery({
 *   variables: {
 *      emailPrefix: // value for 'emailPrefix'
 *   },
 * });
 */
export function useUsersByEmailPrefixQuery(
  baseOptions: Apollo.QueryHookOptions<UsersByEmailPrefixQuery, UsersByEmailPrefixQueryVariables> &
    ({ variables: UsersByEmailPrefixQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersByEmailPrefixQuery, UsersByEmailPrefixQueryVariables>(
    UsersByEmailPrefixDocument,
    options,
  );
}
export function useUsersByEmailPrefixLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersByEmailPrefixQuery, UsersByEmailPrefixQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersByEmailPrefixQuery, UsersByEmailPrefixQueryVariables>(
    UsersByEmailPrefixDocument,
    options,
  );
}
export function useUsersByEmailPrefixSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UsersByEmailPrefixQuery, UsersByEmailPrefixQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersByEmailPrefixQuery, UsersByEmailPrefixQueryVariables>(
    UsersByEmailPrefixDocument,
    options,
  );
}
export type UsersByEmailPrefixQueryHookResult = ReturnType<typeof useUsersByEmailPrefixQuery>;
export type UsersByEmailPrefixLazyQueryHookResult = ReturnType<typeof useUsersByEmailPrefixLazyQuery>;
export type UsersByEmailPrefixSuspenseQueryHookResult = ReturnType<typeof useUsersByEmailPrefixSuspenseQuery>;
export type UsersByEmailPrefixQueryResult = Apollo.QueryResult<
  UsersByEmailPrefixQuery,
  UsersByEmailPrefixQueryVariables
>;
export const UsersNotCompletedRegistrationDocument = gql`
  query UsersNotCompletedRegistration {
    usersNotCompletedRegistration {
      id
      email
      createdAt
      hasCompletedRegistration
      hasAccess
      lastRemindedAt
    }
  }
`;

/**
 * __useUsersNotCompletedRegistrationQuery__
 *
 * To run a query within a React component, call `useUsersNotCompletedRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersNotCompletedRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersNotCompletedRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersNotCompletedRegistrationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersNotCompletedRegistrationQuery,
    UsersNotCompletedRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersNotCompletedRegistrationQuery, UsersNotCompletedRegistrationQueryVariables>(
    UsersNotCompletedRegistrationDocument,
    options,
  );
}
export function useUsersNotCompletedRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersNotCompletedRegistrationQuery,
    UsersNotCompletedRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersNotCompletedRegistrationQuery, UsersNotCompletedRegistrationQueryVariables>(
    UsersNotCompletedRegistrationDocument,
    options,
  );
}
export function useUsersNotCompletedRegistrationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersNotCompletedRegistrationQuery,
    UsersNotCompletedRegistrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersNotCompletedRegistrationQuery, UsersNotCompletedRegistrationQueryVariables>(
    UsersNotCompletedRegistrationDocument,
    options,
  );
}
export type UsersNotCompletedRegistrationQueryHookResult = ReturnType<typeof useUsersNotCompletedRegistrationQuery>;
export type UsersNotCompletedRegistrationLazyQueryHookResult = ReturnType<
  typeof useUsersNotCompletedRegistrationLazyQuery
>;
export type UsersNotCompletedRegistrationSuspenseQueryHookResult = ReturnType<
  typeof useUsersNotCompletedRegistrationSuspenseQuery
>;
export type UsersNotCompletedRegistrationQueryResult = Apollo.QueryResult<
  UsersNotCompletedRegistrationQuery,
  UsersNotCompletedRegistrationQueryVariables
>;
export const UsersBlockedDocument = gql`
  query UsersBlocked {
    usersBlocked {
      id
      email
      createdAt
      hasCompletedRegistration
      hasAccess
      lastRemindedAt
    }
  }
`;

/**
 * __useUsersBlockedQuery__
 *
 * To run a query within a React component, call `useUsersBlockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersBlockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersBlockedQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersBlockedQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersBlockedQuery, UsersBlockedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersBlockedQuery, UsersBlockedQueryVariables>(UsersBlockedDocument, options);
}
export function useUsersBlockedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersBlockedQuery, UsersBlockedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersBlockedQuery, UsersBlockedQueryVariables>(UsersBlockedDocument, options);
}
export function useUsersBlockedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UsersBlockedQuery, UsersBlockedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersBlockedQuery, UsersBlockedQueryVariables>(UsersBlockedDocument, options);
}
export type UsersBlockedQueryHookResult = ReturnType<typeof useUsersBlockedQuery>;
export type UsersBlockedLazyQueryHookResult = ReturnType<typeof useUsersBlockedLazyQuery>;
export type UsersBlockedSuspenseQueryHookResult = ReturnType<typeof useUsersBlockedSuspenseQuery>;
export type UsersBlockedQueryResult = Apollo.QueryResult<UsersBlockedQuery, UsersBlockedQueryVariables>;
export const UpdateUsernameDocument = gql`
  mutation UpdateUsername($input: UpdateUsernameInput!) {
    user {
      updateUsername(input: $input) {
        id
        username
      }
    }
  }
`;
export type UpdateUsernameMutationFn = Apollo.MutationFunction<UpdateUsernameMutation, UpdateUsernameMutationVariables>;

/**
 * __useUpdateUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsernameMutation, { data, loading, error }] = useUpdateUsernameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsernameMutation, UpdateUsernameMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUsernameMutation, UpdateUsernameMutationVariables>(UpdateUsernameDocument, options);
}
export type UpdateUsernameMutationHookResult = ReturnType<typeof useUpdateUsernameMutation>;
export type UpdateUsernameMutationResult = Apollo.MutationResult<UpdateUsernameMutation>;
export type UpdateUsernameMutationOptions = Apollo.BaseMutationOptions<
  UpdateUsernameMutation,
  UpdateUsernameMutationVariables
>;
export const CreateUserFromEmailOnlyDocument = gql`
  mutation CreateUserFromEmailOnly($input: CreateUserFromEmailOnlyInput!) {
    createUserFromEmailOnly(input: $input) {
      id
    }
  }
`;
export type CreateUserFromEmailOnlyMutationFn = Apollo.MutationFunction<
  CreateUserFromEmailOnlyMutation,
  CreateUserFromEmailOnlyMutationVariables
>;

/**
 * __useCreateUserFromEmailOnlyMutation__
 *
 * To run a mutation, you first call `useCreateUserFromEmailOnlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFromEmailOnlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFromEmailOnlyMutation, { data, loading, error }] = useCreateUserFromEmailOnlyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserFromEmailOnlyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserFromEmailOnlyMutation, CreateUserFromEmailOnlyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserFromEmailOnlyMutation, CreateUserFromEmailOnlyMutationVariables>(
    CreateUserFromEmailOnlyDocument,
    options,
  );
}
export type CreateUserFromEmailOnlyMutationHookResult = ReturnType<typeof useCreateUserFromEmailOnlyMutation>;
export type CreateUserFromEmailOnlyMutationResult = Apollo.MutationResult<CreateUserFromEmailOnlyMutation>;
export type CreateUserFromEmailOnlyMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFromEmailOnlyMutation,
  CreateUserFromEmailOnlyMutationVariables
>;
export const FillOutUserEmailOnlyAccountDocument = gql`
  mutation FillOutUserEmailOnlyAccount($input: FillOutUserEmailOnlyAccountInput!) {
    user {
      fillOutUserEmailOnlyAccount(input: $input) {
        id
      }
    }
  }
`;
export type FillOutUserEmailOnlyAccountMutationFn = Apollo.MutationFunction<
  FillOutUserEmailOnlyAccountMutation,
  FillOutUserEmailOnlyAccountMutationVariables
>;

/**
 * __useFillOutUserEmailOnlyAccountMutation__
 *
 * To run a mutation, you first call `useFillOutUserEmailOnlyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFillOutUserEmailOnlyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fillOutUserEmailOnlyAccountMutation, { data, loading, error }] = useFillOutUserEmailOnlyAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFillOutUserEmailOnlyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FillOutUserEmailOnlyAccountMutation,
    FillOutUserEmailOnlyAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FillOutUserEmailOnlyAccountMutation, FillOutUserEmailOnlyAccountMutationVariables>(
    FillOutUserEmailOnlyAccountDocument,
    options,
  );
}
export type FillOutUserEmailOnlyAccountMutationHookResult = ReturnType<typeof useFillOutUserEmailOnlyAccountMutation>;
export type FillOutUserEmailOnlyAccountMutationResult = Apollo.MutationResult<FillOutUserEmailOnlyAccountMutation>;
export type FillOutUserEmailOnlyAccountMutationOptions = Apollo.BaseMutationOptions<
  FillOutUserEmailOnlyAccountMutation,
  FillOutUserEmailOnlyAccountMutationVariables
>;
export const IsEmailTakenDocument = gql`
  query IsEmailTaken($email: EmailAddress!) {
    isEmailTaken(email: $email)
  }
`;

/**
 * __useIsEmailTakenQuery__
 *
 * To run a query within a React component, call `useIsEmailTakenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailTakenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailTakenQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailTakenQuery(
  baseOptions: Apollo.QueryHookOptions<IsEmailTakenQuery, IsEmailTakenQueryVariables> &
    ({ variables: IsEmailTakenQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsEmailTakenQuery, IsEmailTakenQueryVariables>(IsEmailTakenDocument, options);
}
export function useIsEmailTakenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsEmailTakenQuery, IsEmailTakenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsEmailTakenQuery, IsEmailTakenQueryVariables>(IsEmailTakenDocument, options);
}
export function useIsEmailTakenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IsEmailTakenQuery, IsEmailTakenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IsEmailTakenQuery, IsEmailTakenQueryVariables>(IsEmailTakenDocument, options);
}
export type IsEmailTakenQueryHookResult = ReturnType<typeof useIsEmailTakenQuery>;
export type IsEmailTakenLazyQueryHookResult = ReturnType<typeof useIsEmailTakenLazyQuery>;
export type IsEmailTakenSuspenseQueryHookResult = ReturnType<typeof useIsEmailTakenSuspenseQuery>;
export type IsEmailTakenQueryResult = Apollo.QueryResult<IsEmailTakenQuery, IsEmailTakenQueryVariables>;
export const CheckSlugIsValidDocument = gql`
  query checkSlugIsValid($slug: String!) {
    checkSlugIsValid(slug: $slug)
  }
`;

/**
 * __useCheckSlugIsValidQuery__
 *
 * To run a query within a React component, call `useCheckSlugIsValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSlugIsValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSlugIsValidQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCheckSlugIsValidQuery(
  baseOptions: Apollo.QueryHookOptions<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables> &
    ({ variables: CheckSlugIsValidQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables>(CheckSlugIsValidDocument, options);
}
export function useCheckSlugIsValidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables>(CheckSlugIsValidDocument, options);
}
export function useCheckSlugIsValidSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables>(
    CheckSlugIsValidDocument,
    options,
  );
}
export type CheckSlugIsValidQueryHookResult = ReturnType<typeof useCheckSlugIsValidQuery>;
export type CheckSlugIsValidLazyQueryHookResult = ReturnType<typeof useCheckSlugIsValidLazyQuery>;
export type CheckSlugIsValidSuspenseQueryHookResult = ReturnType<typeof useCheckSlugIsValidSuspenseQuery>;
export type CheckSlugIsValidQueryResult = Apollo.QueryResult<CheckSlugIsValidQuery, CheckSlugIsValidQueryVariables>;
export const SetLastRemindedAtDocument = gql`
  mutation SetLastRemindedAt($input: SetLastRemindedAtInput!) {
    user {
      setLastRemindedAt(input: $input) {
        id
        lastRemindedAt
      }
    }
  }
`;
export type SetLastRemindedAtMutationFn = Apollo.MutationFunction<
  SetLastRemindedAtMutation,
  SetLastRemindedAtMutationVariables
>;

/**
 * __useSetLastRemindedAtMutation__
 *
 * To run a mutation, you first call `useSetLastRemindedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLastRemindedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLastRemindedAtMutation, { data, loading, error }] = useSetLastRemindedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLastRemindedAtMutation(
  baseOptions?: Apollo.MutationHookOptions<SetLastRemindedAtMutation, SetLastRemindedAtMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetLastRemindedAtMutation, SetLastRemindedAtMutationVariables>(
    SetLastRemindedAtDocument,
    options,
  );
}
export type SetLastRemindedAtMutationHookResult = ReturnType<typeof useSetLastRemindedAtMutation>;
export type SetLastRemindedAtMutationResult = Apollo.MutationResult<SetLastRemindedAtMutation>;
export type SetLastRemindedAtMutationOptions = Apollo.BaseMutationOptions<
  SetLastRemindedAtMutation,
  SetLastRemindedAtMutationVariables
>;
export const RevokeAccessDocument = gql`
  mutation RevokeAccess($input: RevokeAccessInput!) {
    user {
      revokeAccess(input: $input) {
        id
        username
        hasAccess
      }
    }
  }
`;
export type RevokeAccessMutationFn = Apollo.MutationFunction<RevokeAccessMutation, RevokeAccessMutationVariables>;

/**
 * __useRevokeAccessMutation__
 *
 * To run a mutation, you first call `useRevokeAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessMutation, { data, loading, error }] = useRevokeAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<RevokeAccessMutation, RevokeAccessMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RevokeAccessMutation, RevokeAccessMutationVariables>(RevokeAccessDocument, options);
}
export type RevokeAccessMutationHookResult = ReturnType<typeof useRevokeAccessMutation>;
export type RevokeAccessMutationResult = Apollo.MutationResult<RevokeAccessMutation>;
export type RevokeAccessMutationOptions = Apollo.BaseMutationOptions<
  RevokeAccessMutation,
  RevokeAccessMutationVariables
>;
export const UpdatePublicProfileDocument = gql`
  mutation UpdatePublicProfile($input: UpdatePublicProfileInput!) {
    user {
      updatePublicProfile(input: $input) {
        id
        publicProfile {
          id
          fullname
          linkedin
          roles {
            id
            entityProfile {
              id
              fullname
            }
          }
        }
      }
    }
  }
`;
export type UpdatePublicProfileMutationFn = Apollo.MutationFunction<
  UpdatePublicProfileMutation,
  UpdatePublicProfileMutationVariables
>;

/**
 * __useUpdatePublicProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePublicProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicProfileMutation, { data, loading, error }] = useUpdatePublicProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublicProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables>(
    UpdatePublicProfileDocument,
    options,
  );
}
export type UpdatePublicProfileMutationHookResult = ReturnType<typeof useUpdatePublicProfileMutation>;
export type UpdatePublicProfileMutationResult = Apollo.MutationResult<UpdatePublicProfileMutation>;
export type UpdatePublicProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicProfileMutation,
  UpdatePublicProfileMutationVariables
>;
