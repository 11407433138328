import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    padding: '3px 8px',
    borderRadius: '5px',
    background: 'purple.veryLight',
    color: 'purple.primary',
    fontSize: '14px',
    lineHeight: '100%',
    gap: '4px',
  },
});

export const tagTheme = defineMultiStyleConfig({ baseStyle });
