const hexToRgba = (hex: string, alpha = 1) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    const formatted = hex.length === 4 ? [hex[1], hex[1], hex[2], hex[2], hex[3], hex[3]].join('') : hex.substring(1);
    const r = parseInt(formatted.slice(0, 2), 16);
    const g = parseInt(formatted.slice(2, 4), 16);
    const b = parseInt(formatted.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  throw new Error('Bad Hex');
};

export default hexToRgba;
