import styled from 'styled-components';

import { Container } from '@layouts/common/styled';

const Wrap = styled(Container)`
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;
  row-gap: 20px;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;

  .crying-unicorn-icon {
    margin-top: 20px;
  }
  .logo {
    width: 100px;
    height: auto;
  }
`;

export { Wrap };
