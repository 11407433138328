import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { Color } from '@styled/themes/v2';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  control: {
    color: Color.BLACK,
    width: '14px',
    height: '14px',
    border: `2.5px solid ${Color.WHITE}`,
    outline: `2px solid ${Color.BLACK}`,
    borderRadius: '50%',
    _checked: {
      bg: Color.BLACK,
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
