import styled, { css, keyframes } from 'styled-components';

export const animationDuration = 300;

const appearingAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const disappearingAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const dropdownAnimationMixin = css`
  &.appearing {
    animation: ${appearingAnimation} ${animationDuration}ms forwards;
  }
  &.disappearing {
    animation: ${disappearingAnimation} ${animationDuration}ms forwards;
  }
`;

const dropdownOptionsContainerMixin = css`
  padding: 10px 6px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 10px 60px rgba(28, 0, 142, 0.05);
  ${dropdownAnimationMixin};
`;

const DropdownOption = styled.button`
  text-align: left;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  transition: 0.2s;
  color: ${(props) => props.theme.colors.black};

  :not([aria-selected='true']):hover {
    background-color: ${(props) => props.theme.colors.lightPurple};
    color: ${(props) => props.theme.colors.purple1};
  }
  &[aria-selected='true'] {
    color: ${(props) => props.theme.colors.lightText};
  }
`;

const DropdownOptions = styled.div`
  display: grid;
  row-gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 80px;
  min-height: 20px;
  max-height: 220px;
`;

export { dropdownAnimationMixin, dropdownOptionsContainerMixin, DropdownOptions, DropdownOption };
