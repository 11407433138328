import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

import { randomUUID } from '@utils/uuid';

import { getTokenStateStore, updateTokenStateStore } from './localStorage';
import { sendLoggedOutMessageToExtension } from './chrome/auth';

interface TokenState {
  token: string | null;
  email: string | null;
}

const useToken = (): TokenState => {
  const [params, setParams] = useSearchParams();
  const token = params.get('token');
  const email = params.get('email');
  const [tokenState, setTokenState] = useState(getTokenStateStore());

  useEffect(() => {
    if (token && email) {
      updateTokenStateStore({ token, email });
      setTokenState({ token, email });
      params.delete('token');
      params.delete('email');
      setParams(params);
    }
  }, [email, token]);

  return tokenState ?? { token: null, email: null };
};

interface TokenAuth {
  error: Error | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: { email: string | null };
  token: string | null;

  logout: (...args: unknown[]) => Promise<void>;
  getAccessTokenSilently: (...args: unknown[]) => Promise<string | null>;
}

const TokenAuthContext = createContext<TokenAuth>({
  error: null,
  isAuthenticated: false,
  isLoading: false,
  token: null,
  user: { email: null },
  logout: async () => {},
  getAccessTokenSilently: async () => null,
});

const NOT_AUTHENTICATED_ERROR = new Error('Not authenticated');

const TokenAuthProvider = ({ children }: { children: ReactNode }) => {
  const tokenState = useToken();
  const [params] = useSearchParams();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tokenState.token) {
      const url = new URL(
        `/api/authenticated`,
        import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000',
      ).toString();

      setLoading(true);
      fetch(url, {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'X-Request-ID': randomUUID(),
          'X-TOKEN-AUTH': 'APPLICATION',
        },
      })
        .then((response) => {
          setError(response.ok ? null : NOT_AUTHENTICATED_ERROR);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tokenState.token]);

  const memoisedTokenState = useMemo(() => {
    const { token, email } = tokenState;

    return {
      error,
      isAuthenticated: token !== null,
      isLoading: loading,
      user: { email },
      token,

      logout: async () => {
        googleLogout();
        params.delete('token');
        params.delete('email');
        window.location.search = params.toString();
        updateTokenStateStore(undefined);
        sendLoggedOutMessageToExtension();
      },
      getAccessTokenSilently: async () => token,
    };
  }, [params, tokenState.token, tokenState.email, error, loading]);

  return <TokenAuthContext.Provider value={memoisedTokenState}>{children}</TokenAuthContext.Provider>;
};

const useAuthToken = () => {
  return useContext(TokenAuthContext);
};

export { TokenAuthProvider, useAuthToken };
