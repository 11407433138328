import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';

import { Callout } from '@components/v2/Callout';
import { NemoLoadingCentered } from '@components/Loading';
import Spacer from '@components/util/Spacer';
import { trpc } from '@services/trpc';

export default function NotionRedirect() {
  const [params] = useSearchParams();
  const [sent, setSent] = useState(false);
  const code = params.get('code');
  const { mutateAsync: saveNotionToken } = trpc.models.notionToken.saveNotionToken.useMutation();
  const hasSaved = useRef(false);

  useEffect(() => {
    const save = async () => {
      if (!code || hasSaved.current) {
        return;
      }
      hasSaved.current = true;
      const result = await saveNotionToken({ token: code });
      setSent(result);
    };

    save();
  }, [code, saveNotionToken]);

  return (
    <Flex px="12px" direction="column" w="100vw">
      {sent ? (
        <Box flexGrow="1" display="flex" alignItems="center" justifyContent="center">
          <Callout padding={40} backgroundColour="white">
            <VStack align="center" mx="auto">
              <Text fontSize="18px" fontWeight="500">
                You have successfully connected your Notion account
              </Text>
              <Spacer size={20} />
              <Button
                onClick={() => {
                  window.location.href = '/deals';
                }}
              >
                Take me home
              </Button>
            </VStack>
          </Callout>
        </Box>
      ) : (
        <NemoLoadingCentered />
      )}
    </Flex>
  );
}
