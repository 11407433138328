import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const lineStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    p: '12px',
    pb: '9px',
    fontSize: '14px',
    borderBottom: '1px solid',
    borderColor: 'transparent',
    _selected: {
      borderColor: 'black',
    },
  },
  tablist: {
    borderBottom: '1px solid',
    borderColor: 'grey.divider',
    mb: '20px',
  },
});

const boldStyle = definePartsStyle({
  tab: {
    px: '10px',
    py: '6px',
    fontSize: '12px',
    fontWeight: '500',
    color: 'grey.text_dark',
    _selected: {
      color: 'black',
    },
  },
});

const wrappedStyle = definePartsStyle({
  tab: {
    px: '10px',
    py: '6px',
    fontSize: '12px',
    color: 'grey.text_dark',
    _selected: {
      color: 'purple.primary',
    },
    ':not(:last-child)': {
      borderRight: '1px solid',
      borderColor: 'grey.divider',
    },
  },
  tablist: {
    border: '1px solid',
    borderColor: 'grey.divider',
    borderRadius: '5px',
  },
});

export const tabsTheme = defineMultiStyleConfig({
  variants: { line: lineStyle, bold: boldStyle, wrapped: wrappedStyle },
  defaultProps: {
    variant: 'line',
  },
});
