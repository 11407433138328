import { ComponentStyleConfig } from '@chakra-ui/react';

const textTheme: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '14px',
    lineHeight: 1.2,
    textAlign: 'left',
  },
  variants: {
    accent: {
      color: 'black',
      fontWeight: '500',
    },
  },
};

export { textTheme };
