import { useState, createContext, useEffect, useMemo, useReducer } from 'react';
import debounce from 'lodash/debounce';

type Motion = Pick<DeviceMotionEvent, 'acceleration' | 'accelerationIncludingGravity' | 'rotationRate' | 'interval'>;

const DeviceMotionContext = createContext<{
  permitted: boolean;
  setPermitted: (value: boolean) => void;
  canGrantPermission: boolean;
  motion: Motion;
}>({
  permitted: false,
  setPermitted: () => {},
  canGrantPermission: false,
  motion: {
    acceleration: {
      x: null,
      y: null,
      z: null,
    },
    accelerationIncludingGravity: {
      x: null,
      y: null,
      z: null,
    },
    rotationRate: {
      alpha: null,
      beta: null,
      gamma: null,
    },
    interval: 0,
  },
});

function DeviceMotionProvider({ children }: { children: React.ReactNode }) {
  const canGrantPermission = useMemo(
    () =>
      typeof DeviceOrientationEvent !== 'undefined' &&
      typeof (DeviceOrientationEvent as { requestPermission?: () => unknown }).requestPermission === 'function',
    [],
  );

  const [permitted, setPermitted] = useReducer((state: boolean, value: boolean) => {
    if (value && canGrantPermission) {
      (DeviceOrientationEvent as { requestPermission?: () => unknown }).requestPermission?.();
    }

    return value;
  }, false);

  const [motion, setMotion] = useState<Motion>({
    acceleration: {
      x: null,
      y: null,
      z: null,
    },
    accelerationIncludingGravity: {
      x: null,
      y: null,
      z: null,
    },
    rotationRate: {
      alpha: null,
      beta: null,
      gamma: null,
    },
    interval: 0,
  });

  useEffect(() => {
    if (!permitted) return;

    const handle = debounce((deviceMotionEvent: DeviceMotionEvent) => setMotion(deviceMotionEvent), 10);

    window.addEventListener('devicemotion', handle);

    return () => {
      window.removeEventListener('devicemotion', handle);
    };
  }, [permitted]);

  return (
    <DeviceMotionContext.Provider
      value={{
        permitted,
        setPermitted,
        canGrantPermission,
        motion,
      }}
    >
      {children}
    </DeviceMotionContext.Provider>
  );
}

export { DeviceMotionContext, DeviceMotionProvider };
