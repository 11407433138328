import { useEffect } from 'react';

// relevant github thread: https://github.com/vitejs/vite/issues/11804
// cause may be ad-blockers: https://github.com/vitejs/vite/issues/11804#issuecomment-2009619365
// I followed this solution (without option of displaying 'Reloading in progress' screen): https://github.com/vitejs/vite/issues/11804#issuecomment-2055217776

// solution from vite docs: https://vitejs.dev/guide/build#load-error-handling
function useReloadOnVitePreloadError() {
  useEffect(() => {
    const reloadWindow = () => {
      window.location.reload();
    };
    window.addEventListener('vite:preloadError', reloadWindow);
    return () => {
      window.removeEventListener('vite:preloadError', reloadWindow);
    };
  }, []);
}

export { useReloadOnVitePreloadError };
