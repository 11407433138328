import { createContext, useCallback, useContext, useState } from 'react';

import { FillOutProfileModal } from '@pages/Deals/FillOutProfileModal';

type GlobalModalType = 'filloutprofile_from_deal_ingestion' | 'filloutprofile_from_request_info';

const GlobalModalsContext = createContext<{
  openThisModal: (value: GlobalModalType) => void;
  closeGlobalModal: () => void;
}>({
  openThisModal: () => {},
  closeGlobalModal: () => {},
});

function GlobalModalsContextProvider({ children }: { children: React.ReactNode }) {
  const [openModal, setOpenModal] = useState<GlobalModalType | null>(null);

  const openThisModal = useCallback(
    (value: GlobalModalType) => {
      setOpenModal(value);
    },
    [setOpenModal],
  );
  const closeGlobalModal = useCallback(() => {
    setOpenModal(null);
  }, [setOpenModal]);

  return (
    <GlobalModalsContext.Provider
      value={{
        openThisModal,
        closeGlobalModal,
      }}
    >
      {children}
      {openModal === 'filloutprofile_from_deal_ingestion' ? <FillOutProfileModal type="from_deal_ingestion" /> : null}
      {openModal === 'filloutprofile_from_request_info' ? <FillOutProfileModal type="from_request_info" /> : null}
    </GlobalModalsContext.Provider>
  );
}

function useGlobalModalsContext() {
  const { openThisModal, closeGlobalModal } = useContext(GlobalModalsContext);
  return { openThisModal, closeGlobalModal };
}

export { GlobalModalsContextProvider, useGlobalModalsContext };
