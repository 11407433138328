import { theme as chakraTheme } from '@chakra-ui/react';
import { cardAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
const { Card } = chakraTheme.components;

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
export const cardTheme = defineMultiStyleConfig({
  ...Card,
  defaultProps: {
    variant: 'elevated',
    size: 'md',
  },
});
