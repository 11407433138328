import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

// define the base component styles
const lineStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    appearance: 'none',
    border: 'none',
    borderBottom: '1px solid',
    borderColor: 'grey.divider',
    outline: 'none',
  },
  icon: {},
});

const baseStyle = {
  field: {
    appearance: 'none',
    border: 'none',
    outline: 'none',
  },
  icon: {},
};

export const selectTheme = defineMultiStyleConfig({ baseStyle, variants: { line: lineStyle } });
