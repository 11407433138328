import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

import { useAuthentication } from '@services/authentication_provider';
import { randomUUID } from '@utils/uuid';

export function usePageView() {
  const { token, isLoading } = useAuthentication();
  const location = useLocation();
  // use ref to bypass strict mode's double render
  const currentUrl = useRef<string | null>(null);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (currentUrl.current === location.pathname) {
      return;
    }

    currentUrl.current = location.pathname;
    (async () => {
      const fingerprint = await getFingerprint();

      try {
        const url = new URL('/api/public/page_view', import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000');
        const queryParams = new URLSearchParams({ url: window.location.href, fingerprint });

        if (queryParams) {
          url.search = queryParams.toString();
        }

        const inits = {
          headers: new Headers(),
        };

        inits.headers.set('X-Request-ID', randomUUID());

        if (token) {
          inits.headers.set('X-TOKEN-AUTH', 'APPLICATION');
          inits.headers.set('Authorization', `Bearer ${token}`);
        }

        const response = await fetch(url, inits);

        if (response.status >= 400) {
          const message = await response.text();

          throw new Error(message ?? `HTTP ${response.status} ${response.statusText}`);
        }

        if (response.status === 204 || response.headers.get('content-length') === '0') {
          return false;
        }

        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    })();
  }, [location, token, isLoading]);
}
