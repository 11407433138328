import styled from 'styled-components';

import { ViewModeQueries } from '@helpers/viewModeHelper';
import { h2Mixin, textMixin } from '@components/Typography';

const BackButtonGroup = styled.div`
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 20px;
  ${textMixin({ type: 't2', weight: 'bold' })};
`;

const CaptionBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
`;

const TopSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 180px auto 180px;
  justify-content: space-between;
  column-gap: 20px;
  align-items: top;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  grid-template-areas: 'left middle right';

  .left-side {
    grid-area: left;
  }
  .middle-side {
    grid-area: middle;
  }
  .right-side {
    grid-area: right;
  }

  .actions-dropdown {
    justify-self: end;
  }
`;

const PageCaption = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${h2Mixin};
`;

const Container = styled.div`
  max-width: ${(props) => props.theme.container.maxWidth};
  width: 100%;
  padding: 0 ${(props) => props.theme.container.sidePadding.default};
  margin: 0 auto;

  ${ViewModeQueries.XL} {
    padding: 0 ${(props) => props.theme.container.sidePadding.xl};
  }
`;

export { Container, PageCaption, TopSection, CaptionBlock, BackButtonGroup };
