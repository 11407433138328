import { createContext, useContext } from 'react';

interface SchemaHashContextType {
  isSchemaHashOutdated: boolean;
}

const SchemaHashContext = createContext<SchemaHashContextType | undefined>(undefined);

const useSchemaHashContext = () => {
  const ctx = useContext(SchemaHashContext);

  if (!ctx) {
    throw new Error('useSchemaHashContext must be used within the <SchemaHashProvider>');
  }

  return ctx;
};

export { SchemaHashContext, useSchemaHashContext };
