import { ReactNode } from 'react';
import { Hide, Show } from '@chakra-ui/react';

interface ResponsiveProps {
  breakpoint: string;
  above?: ReactNode;
  below?: ReactNode;
}

function Responsive({ above, below, breakpoint }: ResponsiveProps) {
  return (
    <>
      <Show above={breakpoint} ssr={false}>
        {above ?? null}
      </Show>
      <Hide above={breakpoint} ssr={false}>
        {below ?? null}
      </Hide>
    </>
  );
}

export { Responsive };
