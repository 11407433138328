import { theme as chakraTheme } from '@chakra-ui/react';
import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { Input } = chakraTheme.components;

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

// define the base component styles
const inputBaseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    width: '100%',
    border: '1px solid',
    borderRadius: '6px',
    padding: '7px 12px',
    fontSize: '14px',
    _focusVisible: {
      border: '1px solid',
      borderColor: 'purple.primary',
      boxShadow: '0px 0px 0px 3px #8778F71A',
      boxShadowColor: 'purple.primary',
      outline: 'none',
    },
  },
  addon: {
    padding: '0 12px',
    borderRadius: '6px',
  },
});

const smallStyle = definePartsStyle({
  field: {
    padding: '4px 8px',
    fontSize: '12px',
  },
});

const boldBorderVariant = definePartsStyle({
  field: {
    borderColor: 'gray.300',
  },
});

export const inputTheme = defineMultiStyleConfig({
  ...Input,
  variants: {
    ...Input.variants,
    small: smallStyle,
    bold: boldBorderVariant,
    outline: {
      field: {
        borderColor: 'grey.divider',
      },
      addon: {
        borderColor: 'grey.divider',
        background: 'gray.100',
      },
    },
  },
  baseStyle: {
    ...Input.baseStyle,
    ...inputBaseStyle,
  },
});
