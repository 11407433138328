import { lazy, useEffect, useMemo, useState, ReactNode } from 'react';

import { TokenAuthProvider, useAuthToken } from '@services/token_provider';
import { useUserContext } from '@services/user_provider';
import { NemoLoadingCentered } from '@components/Loading';

const Home = lazy(() => import('@pages/Home'));

const useAuthentication = () => {
  const tokenInfo = useAuthToken();

  return useMemo(() => ({ ...tokenInfo, isTokenAuth: true }), [tokenInfo]);
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return <TokenAuthProvider>{children}</TokenAuthProvider>;
};

// TODO I think this component can be removed?
// because of the split between AuthedApp and UnauthedApp, I don't think useAuthentication can ever error
// safer to keep it around just in case though
function RequireAuth({ children }: { children: ReactNode }) {
  const { logout, isAuthenticated, error: authError, isLoading: isAuthLoading } = useAuthentication();
  const { selectedTeam } = useUserContext();
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    if (authError) {
      if (authError.message.startsWith('Access denied')) {
        setAccessDenied(true);
      }

      logout({
        logoutParams: {
          returnTo: authError.message.startsWith('Access denied')
            ? `${window.location.origin}/login?accessDenied=true`
            : undefined,
        },
      });
    }
  }, [authError, logout]);

  if (isAuthLoading || (isAuthenticated && !selectedTeam)) {
    return <NemoLoadingCentered />;
  }

  if (!isAuthenticated && !accessDenied) {
    return <Home />;
  }

  return <>{children}</>;
}

export { AuthProvider, useAuthentication, RequireAuth };
