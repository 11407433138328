import { setLocale as yupSetLocale, string, mixed, addMethod } from 'yup';
import { isValid } from 'date-fns';

import { ConvertibleType, CurrencyIsoCode, EquityRoundType, ValuationType } from '@generated/graphql';
import { SelectOption } from '@components/FormElements/Fields/Select';
import { LegalEntityValue } from '@components/FormElements/Fields/Company';
import { defaultCurrency } from '@utils/currency';

enum ErrorMessages {
  REQUIRED = 'This field is required',
}

export const setLocale = () => {
  yupSetLocale({
    mixed: {
      required: ErrorMessages.REQUIRED,
    },
  });
};

addMethod(string, 'dateString', function (errorMessage = 'invalid date') {
  return this.test('date-string', errorMessage, function (value) {
    const { path, createError } = this;

    return (value && isValid(new Date(value))) || createError({ path, message: errorMessage });
  });
});

export const dateString = string().dateString();
export const currency = mixed<CurrencyIsoCode>().oneOf(Object.values(CurrencyIsoCode)).default(defaultCurrency);
export const legalEntity = mixed<SelectOption<LegalEntityValue>>();
export const roundType = mixed<EquityRoundType>();
export const convertibleType = mixed<ConvertibleType>();
export const valuationType = mixed<ValuationType>();
