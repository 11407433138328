import { ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyle, StyleFunctionProps } from '@chakra-ui/styled-system';

declare module '@chakra-ui/react' {
  interface ButtonProps {
    hidden?: boolean;
  }
}
interface ButtonStyleProps extends StyleFunctionProps {
  hidden?: boolean;
}
/**
 * DESIGN FILE - FIGMA
 * https://www.figma.com/file/vTsxJrQTHHl9QPOVG7301F?node-id=6301:220
 */

/**
 * COPIED AND TWEAKED FROM (branch v2) https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/button.ts
 */
const baseStyle = ({ hidden }: ButtonStyleProps) => ({
  lineHeight: '1.2',
  borderRadius: '16px',
  fontWeight: '500',

  /**
   * flex
   */
  display: hidden ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  flexWrap: 'nowrap',

  /**
   * text overflow ellipsis
   */
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',

  /**
   * disabled
   */
  _disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },

  /**
   * icons
   */
  '> svg': {
    stroke: 'currentColor',
  },
  '> svg > *': {
    stroke: 'currentColor',
  },

  /**
   * misc
   */

  _focusVisible: {
    boxShadow: 'outline',
  },
  transitionProperty: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, translate, transform',
  transitionDuration: '0.2s',
});

/**
 * SOLID
 */
const variantSolid = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = `gray.50`;

    return {
      bg,
      color: `gray.800`,
      _hover: {
        bg: `gray.100`,
        _disabled: {
          bg,
        },
      },
      _active: { bg: `gray.200` },
    };
  }

  const { bg = `${c}.500`, color = 'white', hoverBg = `${c}.600`, activeBg = `${c}.700` } = accessibleColorMap[c] ?? {};

  const background = bg;

  return {
    bg: background,
    color: color,
    _hover: {
      bg: hoverBg,
      // boxShadow: `0 0 8px var(--chakra-colors-${c}-200)`,
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: activeBg },
  };
});

/**
 * GHOST
 */
const variantGhost = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    return {
      color: `gray.800`,
      _hover: {
        bg: `gray.100`,
      },
      _active: { bg: `gray.200` },
    };
  }

  return {
    color: `${c}.600`,
    bg: 'transparent',
    _hover: {
      bg: `${c}.50`,
    },
    _active: {
      bg: `${c}.100`,
    },
  };
});

/**
 * OUTLINE
 */
const variantOutline = defineStyle((props) => {
  const { colorScheme: c } = props;
  const borderColor = `gray.200`;
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    '.chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)': { marginEnd: '-1px' },
    '.chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)': { marginBottom: '-1px' },
    ...variantGhost(props),
  };
});

/**
 * LINK
 */
const variantLink = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    borderRadius: 0,
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    color: `${c}.500`,
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none',
      },
    },
    _active: {
      color: `${c}.700`,
    },
  };
});

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
};

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

const sizes = {
  lg: defineStyle({
    fontSize: '18px',
    h: '48px',
    minW: '48px',
    px: '24px',
    gap: '12px',
  }),
  md: defineStyle({
    fontSize: '16px',
    h: '40px',
    minW: '40px',
    px: '20px',
    gap: '10px',
  }),
  sm: defineStyle({
    fontSize: '14px',
    h: '32px',
    minW: '32px',
    px: '16px',
  }),
  xs: defineStyle({
    fontSize: '12px',
    h: '24px',
    minW: '24px',
    px: '12px',
    gap: '6px',
  }),
};

const oldVariants = {
  text: {
    paddingBlock: '1px',
    paddingInline: '1px',
    bg: 'transparent',
    ...hoverHelper({ color: 'grey.text_medium' }),
  },
};

function hoverHelper({ bg, activeBg, color }: { bg?: string; activeBg?: string; color?: string }) {
  return {
    _active: {
      bg: activeBg ?? bg,
      color,
    },
    _hover: {
      bg,
      color,
    },
  };
}

const buttonTheme: ComponentStyleConfig = {
  baseStyle,
  sizes,
  variants: {
    ...variants,
    ...oldVariants,
  },
  defaultProps: {
    variant: 'solid',
    size: 'sm',
    colorScheme: 'gray',
  },
};

export { buttonTheme };
