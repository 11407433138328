import { Box, Divider, Flex, VStack } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';

import { PublicProfileFromSlugQuery, useUpdateProfileDescriptorsMutation } from '@generated/graphql';
import Spacer from '@components/util/Spacer';
import { ProfileCompactCardPresentational } from '@components/ProfileCompactCard';
import { Responsive } from '@components/Responsive';

import { InvestorTagline } from './InvestorTagline';
import { SelectInvestorDescriptors } from './SelectInvestorDescriptors';

import { FIXED_EXPLORE_DESCRIPTORS, FixedExploreTagType } from '#shared/constants/descriptors';

type PublicProfile = PublicProfileFromSlugQuery['publicProfileBySlug'] & { __typename: 'Profile' };

function InvestorDescriptorsForm({ publicProfile }: { publicProfile: PublicProfile }) {
  const existingDescriptors = {
    descriptorsGeo: publicProfile?.descriptorsGeo ?? [],
    descriptorsInvestorType: publicProfile?.descriptorsInvestorType ?? [],
    descriptorsStage: publicProfile?.descriptorsStage ?? [],
    descriptorsMiscTags: publicProfile?.descriptorsMiscTags ?? [],
  };
  const [updateDescriptors] = useUpdateProfileDescriptorsMutation();
  const [selected, setSelected] = useState<{
    descriptorsGeo: string[];
    descriptorsInvestorType: string[];
    descriptorsStage: string[];
    descriptorsMiscTags: string[];
  }>(existingDescriptors);

  const isThereAnyChange = useMemo(() => {
    return (
      selected.descriptorsGeo.length !== existingDescriptors.descriptorsGeo.length ||
      selected.descriptorsInvestorType.length !== existingDescriptors.descriptorsInvestorType.length ||
      selected.descriptorsStage.length !== existingDescriptors.descriptorsStage.length ||
      selected.descriptorsMiscTags.length !== existingDescriptors.descriptorsMiscTags.length
    );
  }, [selected, existingDescriptors]);

  const canDisplay = (type: FixedExploreTagType) => {
    if (type === 'geo') return true;
    if (type === 'investor_type') return selected.descriptorsGeo.length > 0;
    if (type === 'stage')
      return (
        selected.descriptorsInvestorType.length > 0 ||
        selected.descriptorsStage.length > 0 ||
        selected.descriptorsMiscTags.length > 0
      );
    if (type === 'misc') return selected.descriptorsStage.length > 0 || selected.descriptorsMiscTags.length > 0;
    return false;
  };

  const publicProfileWithEdits = useMemo(() => {
    return { ...publicProfile, descriptors: selected };
  }, [publicProfile, selected]);

  useEffect(() => {
    // 'isThereAnyChange' is to prevent mutation on first load
    if (publicProfile?.id && isThereAnyChange) {
      updateDescriptors({
        variables: {
          input: { profileId: publicProfile?.id, ...selected },
        },
      });
    }
  }, [selected]);
  return (
    <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={{ base: '20px', lg: '40px' }}>
      <Box w={{ base: undefined, lg: '50%' }}>
        <Text>Select the tags that best describe what kind of investor you are</Text>

        <Spacer size={10} />
        <Divider />
        <Spacer size={10} />

        <VStack alignItems="flex-start" gap="16px">
          <SelectInvestorDescriptors
            selected={selected.descriptorsGeo}
            options={[
              ...new Set([
                ...filter(FIXED_EXPLORE_DESCRIPTORS, { type: 'geo', subtype: 'region' }).map(
                  (descriptor) => descriptor.name,
                ),
                ...selected.descriptorsGeo,
              ]),
            ]}
            loading={false}
            onSelect={(theme) => {
              if (selected.descriptorsGeo.includes(theme)) {
                setSelected(() => ({
                  ...selected,
                  descriptorsGeo: selected.descriptorsGeo.filter((tag) => tag !== theme),
                }));
                return;
              }
              setSelected(() => ({
                ...selected,
                descriptorsGeo: [...selected.descriptorsGeo, theme],
              }));
            }}
          />

          <Box
            maxH={canDisplay('investor_type') ? '200px' : '0'}
            opacity={canDisplay('investor_type') ? '1' : '0'}
            visibility={canDisplay('investor_type') ? 'visible' : 'hidden'}
            transition={
              canDisplay('investor_type')
                ? 'max-height 0.7s ease-in, opacity 0.7s ease-in'
                : 'max-height 0.5s ease-out, opacity 0.5s ease-out'
            }
          >
            <Divider />
            <Spacer size={10} />
            <SelectInvestorDescriptors
              selected={selected.descriptorsInvestorType}
              options={[
                ...new Set([
                  ...filter(FIXED_EXPLORE_DESCRIPTORS, { type: 'investor_type' }).map((descriptor) => descriptor.name),
                  ...selected.descriptorsInvestorType,
                ]),
              ]}
              loading={false}
              onSelect={(theme) => {
                if (selected.descriptorsInvestorType.includes(theme)) {
                  setSelected(() => ({
                    ...selected,
                    descriptorsInvestorType: selected.descriptorsInvestorType.filter((tag) => tag !== theme),
                  }));
                  return;
                }
                setSelected(() => ({
                  ...selected,
                  descriptorsInvestorType: [...selected.descriptorsInvestorType, theme],
                }));
              }}
            />
          </Box>

          <Box
            maxH={canDisplay('stage') ? '200px' : '0'}
            opacity={canDisplay('stage') ? '1' : '0'}
            visibility={canDisplay('stage') ? 'visible' : 'hidden'}
            transition={
              canDisplay('stage')
                ? 'max-height 0.7s ease-in, opacity 0.7s ease-in'
                : 'max-height 0.5s ease-out, opacity 0.5s ease-out'
            }
          >
            <Divider />
            <Spacer size={10} />
            <SelectInvestorDescriptors
              selected={selected.descriptorsStage}
              options={[
                ...new Set([
                  ...filter(FIXED_EXPLORE_DESCRIPTORS, { type: 'stage' }).map((descriptor) => descriptor.name),
                  ...selected.descriptorsStage,
                ]),
              ]}
              loading={false}
              onSelect={(theme) => {
                if (selected.descriptorsStage.includes(theme)) {
                  setSelected(() => ({
                    ...selected,
                    descriptorsStage: selected.descriptorsStage.filter((tag) => tag !== theme),
                  }));
                  return;
                }
                setSelected(() => ({
                  ...selected,
                  descriptorsStage: [...selected.descriptorsStage, theme],
                }));
              }}
            />
          </Box>

          <Box
            maxH={canDisplay('misc') ? '200px' : '0'}
            opacity={canDisplay('misc') ? '1' : '0'}
            visibility={canDisplay('misc') ? 'visible' : 'hidden'}
            transition={
              canDisplay('misc')
                ? 'max-height 0.7s ease-in, opacity 0.7s ease-in'
                : 'max-height 0.5s ease-out, opacity 0.5s ease-out'
            }
          >
            <Divider />
            <Spacer size={10} />
            <SelectInvestorDescriptors
              selected={selected.descriptorsMiscTags}
              options={[
                ...new Set([
                  ...filter(FIXED_EXPLORE_DESCRIPTORS, { type: 'misc' }).map((descriptor) => descriptor.name),
                  ...selected.descriptorsMiscTags,
                ]),
              ]}
              loading={false}
              onSelect={(theme) => {
                if (selected.descriptorsMiscTags.includes(theme)) {
                  setSelected(() => ({
                    ...selected,
                    descriptorsMiscTags: selected.descriptorsMiscTags.filter((tag) => tag !== theme),
                  }));
                  return;
                }
                setSelected(() => ({
                  ...selected,
                  descriptorsMiscTags: [...selected.descriptorsMiscTags, theme],
                }));
              }}
              othersDisabled={selected.descriptorsMiscTags.length >= 3}
            />
            <Spacer size={4} />
          </Box>
        </VStack>
      </Box>

      <Box flexGrow="0">
        <Text fontSize="12px" color="grey.text_light" ml="6px">
          Preview
        </Text>
        <Spacer size={10} />
        <Responsive
          breakpoint="lg"
          above={
            <Box
              boxShadow="0px 2px 4px 2px rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              bg="white"
              pos="relative"
              mx="6px"
              width="max-content"
            >
              <ProfileCompactCardPresentational data={{ publicProfileBySlug: publicProfileWithEdits }} />
            </Box>
          }
          below={
            <Box
              boxShadow="0px 2px 4px 2px rgba(0, 0, 0, 0.1)"
              p="20px"
              borderRadius="8px"
              bg="white"
              pos="relative"
              mx="6px"
            >
              <InvestorTagline publicProfile={publicProfile} />
            </Box>
          }
        />
      </Box>
    </Flex>
  );
}

export { InvestorDescriptorsForm };
