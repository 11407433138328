const sendLoggedInMessageToExtension = async (token: string) => {
  try {
    if (typeof window.chrome !== 'undefined' && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(import.meta.env.VITE_CHROME_EXTENSION_ID, {
        type: 'logged-in',
        data: token,
      });
    }
  } catch (error) {
    console.error('Error sending message to extension', error);
  }
  return true;
};

const sendLoggedOutMessageToExtension = async () => {
  try {
    if (typeof window.chrome !== 'undefined' && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(import.meta.env.VITE_CHROME_EXTENSION_ID, {
        type: 'logged-out',
      });
    }
  } catch (error) {
    console.error('Error sending message to extension', error);
  }
};

export { sendLoggedInMessageToExtension, sendLoggedOutMessageToExtension };
