import {
  BrowserClient,
  Breadcrumbs,
  Dedupe,
  defaultStackParser,
  getCurrentHub,
  GlobalHandlers,
  makeFetchTransport,
  LinkedErrors,
} from '@sentry/browser';

const client = new BrowserClient({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV ?? 'localhost',
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  // Only the integrations listed here will be used
  // https://docs.sentry.io/platforms/javascript/configuration/integrations/
  integrations: [
    new Breadcrumbs(),
    new GlobalHandlers(), // the source of most errors! - "attaches global handlers to capture uncaught exceptions and unhandled rejections. It captures errors and unhandled promise rejections by default."
    new LinkedErrors(), // links frontend to backend errors
    new Dedupe(),
  ],
});

getCurrentHub().bindClient(client);
