import styled, { css } from 'styled-components';

import { textMixin } from '@components/Typography';

export type ButtonType = 'default' | 'primary' | 'secondary' | 'black' | 'light' | 'danger' | 'warning' | 'outlined';
export type ButtonSize = 'large' | 'medium';

const buttonMixin = css`
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 12px;
  border-radius: 100px;
  white-space: nowrap;
  transition: 0.3s;

  &&:disabled {
    pointer-events: none;
    opacity: 0.2;
  }
`;

const buttonSizeMixin = (size: ButtonSize) => {
  switch (size) {
    case 'large':
      return css`
        height: 40px;
        padding: 0 20px;
        ${textMixin({ type: 't3' })};
      `;
    case 'medium':
      return css`
        height: 32px;
        padding: 0 16px;
        ${textMixin({ type: 't4' })}
      `;
    default:
      return '';
  }
};

const buttonTypeMixin = (type: ButtonType) => {
  switch (type) {
    case 'default':
      return css`
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.gradient.linear};

        :before,
        :after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: inherit;
          z-index: -1;
        }
        :after {
          background: ${(props) => props.theme.gradient.key};
        }
        :before {
          background: ${(props) => props.theme.gradient.light};
        }

        :hover {
          :after {
            opacity: 1;
          }
        }
        :active {
          :after {
            opacity: 0;
          }
          :before {
            opacity: 1;
          }
        }
      `;
    case 'outlined':
      return css`
        border: 1px solid ${({ theme }) => theme.colors.button[type].default.color};
        background: ${({ theme }) => theme.colors.button[type].default.background};
        color: ${({ theme }) => theme.colors.button[type].default.color};

        :hover {
          background: ${({ theme }) => theme.colors.button[type].hover.background};
          color: ${({ theme }) => theme.colors.button[type].hover.color};
        }
        :active {
          background: ${({ theme }) => theme.colors.button[type].active.background};
          color: ${({ theme }) => theme.colors.button[type].active.color};
        }
      `;
    default:
      return css`
        background: ${({ theme }) => theme.colors.button[type].default.background};
        color: ${({ theme }) => theme.colors.button[type].default.color};

        :hover {
          background: ${({ theme }) => theme.colors.button[type].hover.background};
          color: ${({ theme }) => theme.colors.button[type].hover.color};
        }
        :active {
          background: ${({ theme }) => theme.colors.button[type].active.background};
          color: ${({ theme }) => theme.colors.button[type].active.color};
        }
      `;
  }
};

const StyledButton = styled.button<{ $type?: ButtonType; size?: ButtonSize }>`
  ${buttonMixin};

  // size
  ${({ size = 'large' }) => buttonSizeMixin(size)};

  // type
  ${({ $type = 'default' }) => buttonTypeMixin($type)};
`;

export { StyledButton, buttonMixin, buttonSizeMixin, buttonTypeMixin };
