import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { useRestEndpoint } from '@hooks/useRestEndpoint';

import { useUserContext } from './user_provider';

export const FeatureFlagsContext = createContext<{
  flags: Record<string, boolean>;
  isEnabled: (feature: string) => boolean;
  loading: boolean;
}>({ flags: {}, isEnabled: () => false, loading: true });

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);
  const { email } = useUserContext();
  const [getFeatureFlags] = useRestEndpoint<Record<string, boolean>>({
    route: `/api/public/feature_flags/${email}`,
  });

  useEffect(() => {
    setFeatureFlags({});
    setLoading(true);
    getFeatureFlags()
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        }
        setFeatureFlags(data ?? {});
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email]);

  const flags = useMemo(
    () => ({
      flags: featureFlags,
      isEnabled: (feature: string) => featureFlags[feature] || false,
      loading,
    }),
    [featureFlags, loading],
  );

  return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

// ts-prune-ignore-next
export const useFeatureFlagsContext = () => {
  const featureFlags = useContext(FeatureFlagsContext);

  if (!featureFlags) {
    throw new Error('useFeatureFlagsContext must be used within the <FeatureFlagsProvider>');
  }

  return featureFlags;
};

export const IfFlagEnabled = ({
  children,
  flag,
  fallback = null,
}: {
  children: ReactNode;
  flag: string;
  fallback?: JSX.Element | null;
}) => {
  const { loading, isEnabled } = useFeatureFlagsContext();

  if (loading) {
    return null;
  }

  if (!isEnabled(flag)) {
    return fallback;
  }

  return <>{children}</>;
};
