import { useReducer } from 'react';

import { getUserStore, upsertUserStore, User } from '@services/localStorage';

export const getStoredSelectedTeamId = (email: string): string | undefined => getUserStore(email)?.selectedTeamId;

export const upsertSelectedTeamId = ({ email, teamId }: { email: string; teamId: string }): void => {
  const user = getUserStore(email);

  upsertUserStore({ ...user, selectedTeamId: teamId });
};

export const useSelectedTeamId = (email: string) => {
  const [user, setTeamId] = useReducer((user: User, teamId: string) => {
    if (user.selectedTeamId === teamId) {
      return user;
    }

    const userWithNewSelectedTeamId = { ...user, selectedTeamId: teamId };

    upsertUserStore(userWithNewSelectedTeamId);

    return userWithNewSelectedTeamId;
  }, getUserStore(email));

  return [user.selectedTeamId, setTeamId] as const;
};
