function getHostname(url: string): string {
  for (const candidate of [url, `https://${url}`]) {
    try {
      return new URL(candidate).hostname;
    } catch (err) {
      // Ignore
    }
  }

  throw new Error(`Could not get hostname of "${url}"`);
}

function maybeHostnameWithPath(url: string): string {
  const urlObj = toUrl(url);

  if (!urlObj) {
    return url;
  }

  return `${urlObj.hostname}${urlObj.pathname === '/' ? '' : urlObj.pathname}`;
}

function toUrl(url: string) {
  const preparedUrl = url.trim().toLowerCase();

  for (const candidate of [preparedUrl, `https://${preparedUrl}`]) {
    try {
      return new URL(candidate);
    } catch (err) {
      // Ignore
    }
  }

  return undefined;
}

const getSubdomain = () => window.location.host.split('.')[0];
const dropSubdomainsFromHost = (host: string) => host.split('.').slice(-2).join('.');
const isNemoSubdomain = () => getSubdomain().toLowerCase().startsWith('nemo');
const nemoPageHref = () => window.location.protocol + '//nemo.' + dropSubdomainsFromHost(window.location.host);
const azavaPageHref = () => window.location.protocol + '//' + dropSubdomainsFromHost(window.location.host);

export { getHostname, maybeHostnameWithPath, isNemoSubdomain, nemoPageHref, azavaPageHref, toUrl };
