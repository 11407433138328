import { CurrencyIsoCode } from '@generated/graphql';

import type { CurrencyIsoCode as CurrencyIsoCodeTRPC } from '#trpc';

export const defaultCurrency = CurrencyIsoCode.EUR;

const currencies = [defaultCurrency].concat(
  Object.values(CurrencyIsoCode).filter((currency) => currency !== defaultCurrency),
);

function getCurrencySign(currency: CurrencyIsoCode | CurrencyIsoCodeTRPC) {
  switch (currency.toUpperCase()) {
    case CurrencyIsoCode.CHF:
      return 'CHF';
    case CurrencyIsoCode.NOK:
    case CurrencyIsoCode.SEK:
    case CurrencyIsoCode.DKK:
      return 'kr';
    case CurrencyIsoCode.GBP:
      return '£';
    case CurrencyIsoCode.EUR:
      return '€';
    case CurrencyIsoCode.USD:
      return '$';
    default:
      return undefined;
  }
}

export { currencies, getCurrencySign };
