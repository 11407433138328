import styled, { css } from 'styled-components';

export type AlertType = 'default' | 'warning' | 'error';

const StyledAlert = styled.div<{ type?: AlertType; maxWidth?: number }>`
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 10px;
  line-height: 12px;
  margin: 0 auto;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  ${({ type = 'default', theme }) => css`
    background-color: ${theme.colors.alert[type].background};
    color: ${theme.colors.alert[type].text};
  `};

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

export { StyledAlert };
