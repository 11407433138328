import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { NemoLoadingCentered } from '@components/Loading';
import { trpc } from '@services/trpc';

export default function ZoomCallback() {
  const [params] = useSearchParams();
  const [sent, setSent] = useState(false);
  const code = params.get('code');
  const { mutateAsync: saveZoomToken } = trpc.models.zoomToken.saveZoomToken.useMutation();
  const hasSaved = useRef(false);

  useEffect(() => {
    const save = async () => {
      if (!code || hasSaved.current) {
        return;
      }
      hasSaved.current = true;
      const result = await saveZoomToken({ token: code });
      setSent(result);
    };

    save();
  }, [code, saveZoomToken]);

  useEffect(() => {
    if (sent) {
      // Redirect to the desired URL when the token is successfully saved
      window.location.href = '/settings?tab=integration';
    }
  }, [sent]);

  return (
    <Flex px="12px" direction="column" w="100vw">
      {!sent && <NemoLoadingCentered />}
    </Flex>
  );
}
