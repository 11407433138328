import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function RedirectUsingSearchParam() {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const redirect = params.get('redirect');

  useEffect(() => {
    if (redirect) {
      params.delete('redirect');
      setParams(params);
      navigate(redirect.startsWith('/') ? redirect : '/' + redirect);
    }
  }, [params, setParams, navigate]);

  return null;
}
