import { cssVar, defineStyleConfig } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const skeletonTheme = defineStyleConfig({
  baseStyle: {
    [$startColor.variable]: '#F6F6F7',
    [$endColor.variable]: '#F0F0F0',
  },
});

export { skeletonTheme };
