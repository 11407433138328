export const getNameInitials = ({
  name,
  email,
}: {
  name?: string | null;
  email?: string | null;
}): undefined | string => {
  if (name && name.trim()) {
    return name
      .trim()
      .split(' ')
      .slice(0, 2)
      .reduce<string>((initials, word) => initials + (word ? word[0] : ''), '')
      .toUpperCase();
  } else if (email && email.trim() && email.includes('@')) {
    return email.trim().split('@')[0].slice(0, 2).toUpperCase();
  }
};
