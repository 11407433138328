import { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';

import { Wrap } from '@components/ErrorBoundary/styled';
import { H2 } from '@components/Typography';
import Link from '@components/Buttons/Button/Link';
import Alert from '@components/Alert';

interface Props {
  children: ReactNode | ReactNode[];
}
interface State {
  hasError: boolean;
  errorMessage?: string;
}

export class FullPageErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  /** @public - used by react - https://react.dev/reference/react/Component#static-getderivedstatefromerror */
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    Sentry.captureException(`ErrorBoundary hit: ${error}`);
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Wrap>
          <H2>Oops! Something went wrong!</H2>
          {errorMessage && <Alert type="error">{errorMessage}</Alert>}
          <Link href="/">Go back home</Link>
        </Wrap>
      );
    }

    return children;
  }
}
