import { createGlobalStyle } from 'styled-components';

import { dropdownOptionsContainerMixin } from '@components/Dropdowns/styled';
import { textMixin } from '@components/Typography';

import reset from './reset';
import { arrowTailMixin, hiddenScrollbarMixin } from './mixins';

const GlobalStyle = createGlobalStyle`
  ${reset};

  /* additional reset start */
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  b, strong {
    font-weight: bold;
  }
  code {
    font-family: monospace;
    font-size: inherit;
  }
  button {
    cursor: pointer;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    color: inherit;
  }
  input,
  textarea {
    font: inherit;
    color: inherit;
  }
  /* additional reset end */

  body {
    font-family: 'Anthro', sans-serif;
    color: ${({ theme }) => theme.colors.text.default};
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: auto;
    overflow-x: hidden;
  }

  #root {
    height: 100dvh;
  }

  /* custom scrollbar start */
  // Works on Firefox
  * {
    scrollbar-color: ${(props) => props.theme.colors.lightText} ${(props) => props.theme.colors.background};
  }
  // Works on Chrome, Edge, and Safari
  ::-webkit-scrollbar {
    width: 6px;
    right: 10px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.lightText};
    border-radius: 10px;

    :hover {
      background: ${(props) => props.theme.colors.darkGray};
    }
    :active {
      background: ${(props) => props.theme.colors.black};
    }
  }
  /* custom scrollbar start */

  /* text start */
  .text-bold {
    font-weight: bold;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /* text end */

  // Hide scrollbar
  .hidden-scrollbar {
    ${hiddenScrollbarMixin};
  }

  /* react toast start */
  .toast-wider {
    width: 500px;
    margin-left: calc((500px - var(--toastify-toast-width)) / -2);
  }
  /* react toast end */

  /* react-select */
  .react-select {
    flex-grow: 2;

    :not(.searchable) .react-select__control {
      cursor: pointer;
    }
    .react-select__control {
      border: none;
      display: grid;
      box-shadow: none;
      min-height: auto;
      column-gap: 4px;
      grid-template-columns: 1fr auto;
      background-color: ${(props) => props.theme.colors.white};
    }
    .react-select__value-container {
      padding: 0;
    }
    .react-select__input-container {
      margin: 0;
      padding: 0;
    }
    .react-select__input {
      font: inherit;
    }
    .react-select__clear-indicator {
      cursor: pointer;
    }
    .react-select__menu-list {
      padding: 0;
    }
    // multi
    .react-select__multi-value {
      border-radius: 5px;
      background-color: ${(props) => props.theme.colors.background};
      ${textMixin({ type: 't4' })};
      padding: 4px 10px;
      margin: 0 8px 0 0;
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      column-gap: 16px;
    }
    .react-select__multi-value__label {
      padding: 0;
    }
    .react-select__multi-value__remove {
      border-radius: 50%;
      padding: 0;
      background-color: ${(props) => props.theme.colors.lightText};
      width: 14px;
      height: 14px;
      cursor: pointer;
      transition: 0.3s;

      > svg {
        width: 100%;
        height: 10px;

        path {
          fill: ${(props) => props.theme.colors.white};
        }
      }

      :hover {
        background-color: ${(props) => props.theme.colors.darkGray};
      }
    }
    .react-select__indicator {
      padding: 0;
    }
  }
  .react-select__menu-portal div.react-select__menu {
    width: 100%;
    left: auto;
  }
  div.react-select__option {
    cursor: pointer;
    text-align: left;
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    transition: 0.2s;

    &--is-focused:not(&--is-selected) {
      background-color: ${(props) => props.theme.colors.lightPurple};
      color: ${(props) => props.theme.colors.purple1};
    }
    &--is-selected {
      color: ${(props) => props.theme.colors.lightText};
      background-color: initial;
    }
  }
  div.react-select__menu-portal {
    z-index: 9999;
    margin-top: 28px;
  }
  .react-select__value-container--is-multi {
    margin: -4px;
    row-gap: 4px;
  }
  /* react-select */
  /* react-datepicker start */
  .react-datepicker {
    width: 328px;
    padding: 20px 24px;
    display: grid;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    color: ${(props) => props.theme.colors.text.default};
    ${textMixin({ type: 't3' })};

    .react-datepicker__day-name {
      text-align: center;
      width: 40px;
      margin-bottom: 12px;
    }

    .react-datepicker__day {
      width: 40px;
      height: 40px;
    }

    .react-datepicker__month-text,
    .react-datepicker__year-text {
      height: 40px;
      width: 48px;
    }

    .react-datepicker__month-text,
    .react-datepicker__year-text,
    .react-datepicker__day {
      cursor: pointer;
      position: relative;
      z-index: 0;
      display: grid;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      background: linear-gradient(79.36deg, #fff 18.39%, #fff 102.7%);
      transition: 0.3s;

      :before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background: ${(props) => props.theme.gradient.key};
        transition: 0.3s;
      }

      &:hover,
      &[aria-selected='true'],
      &--selected {
        color: ${(props) => props.theme.colors.white};

        :before {
          opacity: 1;
        }
      }
    }

    .react-datepicker__week,
    .react-datepicker__day-names {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    .react-datepicker__day--outside-month {
      color: ${(props) => props.theme.colors.text.light};
    }

    .react-datepicker__month-text--disabled,
    .react-datepicker__year-text--disabled,
    .react-datepicker__day--disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    .react-datepicker__month-wrapper,
    .react-datepicker__year-wrapper {
      display: grid;
      column-gap: 8px;
      row-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
      grid-template-rows: repeat(auto, 1fr);
      align-items: center;
      justify-items: center;
      justify-content: space-between;
    }
  }
  .form-field-popper,
  div.react-select__menu {
    ${dropdownOptionsContainerMixin};
    margin-top: 10px;

    :before {
      ${arrowTailMixin('top')};
    }
  }
  div.react-select__menu {
    margin: 0;
    width: calc(100% + 32px);
    left: -16px;
  }
  .react-datepicker__aria-live {
    display: none;
  }
  /* react-datepicker end */
`;

export default GlobalStyle;
