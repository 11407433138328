import { Tag, TagLabel, Input, Box } from '@chakra-ui/react';
import { Button, VStack, Wrap } from '@chakra-ui/react';
import { useRef, useState } from 'react';

import PencilIcon from '@/assets/icons/pencil.svg?react';
import { Icon } from '@utils/icons';

// This is a copied and expanded on version of the SelectDescriptors component from Explore/PostFormV2.tsx
function SelectInvestorDescriptors({
  selected,
  options,
  loading,
  onSelect,
  othersDisabled,
}: {
  selected: string[];
  options: string[];
  loading: boolean;
  onSelect: (tag: string) => void;
  othersDisabled?: boolean;
}) {
  return (
    <VStack align="flex-start" spacing="20px">
      <Wrap rowGap={0}>
        {options.map((tag) => (
          <Button
            variant="text"
            key={tag}
            isDisabled={loading || (!selected.includes(tag) && othersDisabled)}
            padding="0px"
            onClick={() => onSelect(tag)}
            h="unset"
          >
            <Tag
              size="md"
              borderRadius="full"
              key={tag}
              bg={selected.includes(tag) ? 'purple.primaryActive' : 'grey.background'}
              paddingX="12px"
            >
              <TagLabel color="grey.text_dark" lineHeight="120%">
                {tag}
              </TagLabel>
              <Box mr="-3px">
                {selected.includes(tag) ? (
                  <Icon name="close" color="grey.text_dark" />
                ) : (
                  <Icon name="add" color="grey.text_dark" />
                )}
              </Box>
            </Tag>
          </Button>
        ))}
        {!othersDisabled ? (
          <CustomTag onSelect={onSelect} />
        ) : (
          // Placeholder to avoid changes in the layout
          <Tag h="22px" opacity={0}>
            Custom
          </Tag>
        )}
      </Wrap>
    </VStack>
  );
}

function CustomTag({ onSelect }: { onSelect: (tag: string) => void }) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputSubmit = () => {
    const inputValue = inputRef.current?.value;
    if (inputValue) {
      const startCasedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
      onSelect(startCasedValue);
    }
    setIsEditing(false);
  };

  return (
    <>
      {isEditing === false ? (
        <Tag
          as={Button}
          onClick={() => setIsEditing(true)}
          key="custom"
          h="22px"
          size="md"
          borderRadius="full"
          bg="white"
          border="1px dashed"
          borderColor="gray.300"
          _hover={{ borderColor: 'gray.400' }}
          role="group"
          px="12px"
          gap="8px"
        >
          <TagLabel color="grey.text_light" _groupHover={{ color: 'grey.text_medium' }} lineHeight="110%">
            Custom
          </TagLabel>
          <Box color="gray.300" _groupHover={{ color: 'gray.400' }}>
            <PencilIcon width={10} height={10} />
          </Box>
        </Tag>
      ) : (
        <Input
          ref={inputRef}
          name="custom"
          variant="small"
          autoFocus
          borderRadius="50px"
          w="94px"
          h="22px"
          onBlur={handleInputSubmit}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleInputSubmit();
            }
          }}
        />
      )}
    </>
  );
}

export { SelectInvestorDescriptors };
