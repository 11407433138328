import { AssetType, ConvertibleType } from '@generated/graphql';
import convertUnderscore from '@utils/convertUnderscore';
import { convertibleNames } from '@utils/convertibles';

export enum InvestmentType {
  CONVERTED = 'CONVERTED',
  EQUITY = 'EQUITY',
  UNCONVERTED = 'UNCONVERTED',
  SPV = 'SPV',
  EQUITY_NO_SHARES = 'EQUITY_NO_SHARES',
  FUND = 'FUND',
  UNKNOWN = 'UNKNOWN',
}

export interface InvestmentTypeOption {
  type: InvestmentType;
  name: string;
}

// We want to support a broader range of investment-like inputs, e.g. the PortfolioOverviewFieldsFragment
interface Investment {
  asset: { type?: AssetType; convertibleType?: ConvertibleType | null };
  conversion?: unknown;
  isSecondary: boolean;
}

export function getInvestmentType(investment: Investment): InvestmentTypeOption {
  if (investment.asset.type) {
    switch (investment.asset.type) {
      case AssetType.EQUITY:
        return {
          type: InvestmentType.EQUITY,
          name: investment.isSecondary ? 'Equity secondary' : convertUnderscore(InvestmentType.EQUITY),
        };

      case AssetType.EQUITY_UNKNOWN_SHARES:
        return {
          type: InvestmentType.EQUITY_NO_SHARES,
          name: convertUnderscore(InvestmentType.EQUITY_NO_SHARES, { case: 'capitalize' }),
        };
      case AssetType.LP_INTEREST_POINT:
        return { type: InvestmentType.FUND, name: convertUnderscore(InvestmentType.FUND) };
      case AssetType.SPV_INTEREST_POINT:
        return { type: InvestmentType.SPV, name: InvestmentType.SPV };
      case AssetType.UNKNOWN:
        return { type: InvestmentType.UNKNOWN, name: InvestmentType.UNKNOWN };
      default:
        throw new Error('Undefined investment type');
    }
  } else if (investment.asset.convertibleType) {
    return investment.conversion == null
      ? { type: InvestmentType.UNCONVERTED, name: convertibleNames[investment.asset.convertibleType] }
      : { type: InvestmentType.CONVERTED, name: convertUnderscore(InvestmentType.CONVERTED) };
  }

  throw new Error('Undefined investment type');
}
