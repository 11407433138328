import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import posthog from 'posthog-js';
import { Box, Heading, Link, VStack, Text } from '@chakra-ui/react';

import { UserContextQueryResult, useUserContextQuery } from '@generated/graphql';
import { useSelectedTeamId } from '@services/localStorage/user';
import { useAuthentication } from '@services/authentication_provider';
import { getNameInitials } from '@utils/nameInitials';
import Spacer from '@components/util/Spacer';

interface Team {
  id: string;
  name: string;
}

interface TeamMember {
  id: string;
  email: string;
  username: string;
  initials: string;
  publicProfile?: { imageUrl?: string | null } | null;
}

type UserByEmail = Exclude<UserContextQueryResult['data'], undefined>['userByEmail'] & { initials: string };

interface User extends UserByEmail {
  selectedTeam?: Team;
  setSelectedTeam: (team?: Team) => void;
  selectedTeamMembers: TeamMember[];
  isLoading: boolean;
  hasCalled: boolean;
  error?: ApolloError | string;
  refetch: () => Promise<unknown>;
}

function userWithInitials<T extends { username: string }>(user: T) {
  return { ...user, initials: getNameInitials({ name: user.username }) ?? '' };
}

const UserContext = createContext<User | undefined>(undefined);

const AuthenticatedUserProvider = ({
  children,
  email,
  logout,
}: {
  children: ReactNode;
  email: string;
  logout: (...args: unknown[]) => Promise<void>;
}) => {
  const { loading, error, called, data, refetch } = useUserContextQuery({
    variables: { email },
    onError: (error) => {
      // TODO move all useQuery onErrors into global onError in apollo_provider

      // if the error isn't a network error, then log out
      if (!error.networkError) {
        logout();
      }
      // if error is that user doesn't exist (isn't granted access) "No UserEmail found"
      // then logout, and navigate to homepage with query param (to show error alert)
      if (userIsNotGrantedAccess(error)) {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/?error=access_denied`,
          },
        });
      }
    },
  });
  const [selectedTeamId, setSelectedTeamId] = useSelectedTeamId(email);

  const userIsNotGrantedAccess = useCallback(
    (error: ApolloError) => {
      return (
        error?.networkError &&
        error.networkError.toString().includes(`Response not successful: Received status code 403`)
      );
    },
    [error],
  );

  const setSelectedTeam = useCallback(
    (team?: { id: string }) => team && setSelectedTeamId(team.id),
    [setSelectedTeamId],
  );

  const { selectedTeam, selectedTeamMembers } = useMemo(() => {
    if (!data) {
      return { selectedTeam: null, selectedTeamMembers: null };
    }

    const team = data.userByEmail.teams.find(({ id }) => id === selectedTeamId) ?? data.userByEmail.defaultTeam;

    return {
      selectedTeam: team,
      selectedTeamMembers: team.members.map(userWithInitials),
    };
  }, [selectedTeamId, data?.userByEmail]);

  // tell posthog about the user
  useEffect(() => {
    posthog.identify(email, { email });
  }, [email]);

  // apply initials to the user
  const retrievedUser = useMemo(() => (data ? userWithInitials(data.userByEmail) : null), [data?.userByEmail]);

  if (loading) {
    return null;
  } else if (!loading && error && (!error.networkError || userIsNotGrantedAccess(error))) {
    // see 'onError'
    // if either of these conditions are true, then we're about to log them out, so don't render anything
    // 1. if error, and it's not a network error
    // 2. if it's a network error specific describing that the user is not granted access
    return null;
  } else if (error?.networkError) {
    return (
      <Box height="100%" width="100%">
        <VStack align="center" justify="center" gap="20px" height="100%">
          <Heading fontSize="20px" fontWeight="bold">
            Azava is down for maintenance
          </Heading>
          <Box w="300px">
            <Text>Please refresh in a few seconds.</Text>
            <Spacer size={8} />
            <Text>
              If something has been wrong for a while, you can contact us at{' '}
              <Link href="mailto:team@azava.com">team@azava.com</Link>
            </Text>
          </Box>
        </VStack>
      </Box>
    );
  } else if (!retrievedUser || !selectedTeam || !selectedTeamMembers || error) {
    return (
      <Box height="100%" width="100%">
        <VStack align="center" justify="center" gap="20px" height="100%">
          <Heading fontSize="20px" fontWeight="bold">
            Something isn't right with your user account
          </Heading>
          <Text w="500px">
            We're really sorry about this, we're checking this on our end. If you want to contact us in the meantime you
            can do so at team@azava.com.
          </Text>
        </VStack>
      </Box>
    );
  }
  return (
    <UserContext.Provider
      value={{
        ...retrievedUser,
        selectedTeam,
        setSelectedTeam,
        selectedTeamMembers,
        isLoading: false,
        hasCalled: called,
        refetch,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserProvider = ({ children }: { children: ReactNode }) => {
  const { user, logout, isAuthenticated } = useAuthentication();

  if (!isAuthenticated || !user?.email) {
    return null;
  }

  return (
    <AuthenticatedUserProvider email={user.email} logout={logout}>
      {children}
    </AuthenticatedUserProvider>
  );
};

const useUserContext = () => {
  const ctx = useContext(UserContext);

  if (!ctx) {
    throw new Error('useUserContext must be used within the <UserProvider>');
  }

  return ctx;
};
const useSafeUserContext = () => {
  const ctx = useContext(UserContext);

  if (!ctx) {
    return null;
  }

  return ctx;
};

export { UserProvider, useUserContext, UserContext, useSafeUserContext };
